const changeContinuousNumber = (e, values, setValues) => {
  // check if it is a positive number and not empty
  if (/^\d+$/.test(e.target.value)) {
    let invoiceNumberItems = (typeof (values.invoiceNr) === "string" ? JSON.parse(values.invoiceNr) : values.invoiceNr);
    const currentIndex = invoiceNumberItems.findIndex((elements) => elements.type === "continuousNumber");
    if (currentIndex !== -1) {
      let currentId = invoiceNumberItems[currentIndex].id;
      const updatedItem = { ...invoiceNumberItems[currentIndex], id: currentId, type: "continuousNumber", value: e.target.value };
      const newItems = [...invoiceNumberItems];
      newItems[currentIndex] = updatedItem;
      setValues({
        ...values,
        invoiceNr: newItems
      });
    }
  }
};

export default changeContinuousNumber;

import React from "react";

// IMPORT hooks & context
import {
  Route,
  Routes,
} from "react-router-dom";
import { useTranslation } from "react-i18next";

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import userAtomState from "./_atoms/userAtom";
import loggedInAtomState from "./_atoms/loggedInAtom";

// IMPORT components
import UserProfile from "./user/pages/profile/UserProfile";
import Account from "./user/pages/account/Account";
import AccountTransactions from "./user/pages/account/AccountTransactions";

import Settings from "./user/pages/Settings";
import UserClients from "./clients/pages/UserClients";
// import NewInvoiceLiveEdit from "./invoices/pages/NewInvoiceLiveEdit";
import Products from "./products/Products";
import Invoices from "./invoices/Invoices";
import Expenses from "./expenses/pages/Expenses";
import InvoicesRecurrings from "./invoices/InvoicesRecurrings";
// import UpdateInvoice from "./invoices/pages/UpdateInvoice";
import Dashboard from "./dashboard/pages/Dashboard";
import Cashboard from "./cashboard/pages/Cashboard";
import Checkout from "./payment/pages/Checkout";
import PricingTable from "./helper_pages/pages/pricingTable";
import PricingTableLoggedIn from "./payment/pages/pricingTableLoggedIn";
import Login from "./user/pages/Login";
import Register from "./user/pages/Register";
import ForgotPassword from "./user/pages/password/ForgotPassword";
import ResetPassword from "./user/pages/password/ResetPassword";
import Test from "./user/pages/Test";
import Balance from "./balance/pages/Balance";
import Welcome from "./user/pages/profile/Welcome";

import Feedback from "./user/components/Feedback";
import AnimationLetter from "./shared/components/UIElements/animations/AnimationLetter";
import AnimationFailed from "./shared/components/UIElements/animations/AnimationFailed";
import AnimationSuccess from "./shared/components/UIElements/animations/AnimationSuccess";

const AppRoutes = () => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();

  // GLOBAL STATE (RECOIL)
  const [userAtom] = useRecoilState(userAtomState);
  const [loggedInAtom] = useRecoilState(loggedInAtomState);

  return (
    <React.Fragment>
      {/* Logged in */}
      {loggedInAtom && userAtom.data.verified && userAtom.data.verified && userAtom.data.signupCompleted &&
        <Routes>
          {/* <Route path="/dashboard" exact element={<Dashboard />} />
          <Route path="/cashboard" exact element={<Cashboard />} /> */}
          {/* <Route path="/estimates" exact element={<Invoices type={"estimates"}/>} /> */}
          {/* <Route path="/expenses" exact element={<Expenses />} /> */}
          {/* <Route path="/balance" exact element={<Balance />} /> */}
          <Route path="/profile" exact element={<UserProfile />} />
          <Route path="/account" exact element={<Account />} />
          <Route path="/account-transactions" exact element={<AccountTransactions />} />
          <Route path="/settings" exact element={<Settings />} />

          <Route path="/clients" exact element={<UserClients />} />
          <Route path="/products" exact element={<Products />} />
          <Route path="/invoices" exact element={<Invoices type={"invoices"}/>} />
          <Route path="/invoicesrecurrings" exact element={<InvoicesRecurrings type={"invoicesrecurrings"}/>} />

          <Route path="/verify-email" exact element={<Feedback h1={t("FEEDBACK.verification.email.h1")} text={t("FEEDBACK.verification.email.text")} icon={<AnimationLetter />} />} />
          <Route path="/success-confirmed-email" exact element={<Feedback h1={t("FEEDBACK.success.email.h1")} text={t("FEEDBACK.success.email.text")} icon={<AnimationSuccess />} button={t("BUTTONS.login")} buttonLink={"/"} />} />
          <Route path="/failed" exact element={<Feedback h1={t("FEEDBACK.fail.default.h1")} text={t("FEEDBACK.fail.default.text")} icon={<AnimationFailed />} button={t("BUTTONS.login")} buttonLink={"/"} />} />

          <Route path="/pricing" exact element={<PricingTableLoggedIn />} />

          <Route path="/checkout" exact element={<Checkout />} />

          <Route path="/test" exact element={<Test />} />


          <Route path="*" element={<Dashboard />} />
        </Routes>
      }

      {/* Redirect directly after Signup || Login, without having verified email */}
      {loggedInAtom && !userAtom.data.verified && !userAtom.data.signupCompleted &&
        <Routes>
          <Route path="/" exact element={<Feedback h1={t("FEEDBACK.verification.email.h1")} text={t("FEEDBACK.verification.email.text")} icon={<AnimationLetter />} />} />

          <Route path="/verify-email" exact element={<Feedback h1={t("FEEDBACK.verification.email.h1")} text={t("FEEDBACK.verification.email.text")} icon={<AnimationLetter />} />} />
          <Route path="/success-confirmed-email" exact element={<Feedback h1={t("FEEDBACK.success.email.h1")} text={t("FEEDBACK.success.email.text")} icon={<AnimationSuccess />} button={t("BUTTONS.login")} buttonLink={"/"} />} />
          <Route path="/failed" exact element={<Feedback h1={t("FEEDBACK.fail.default.h1")} text={t("FEEDBACK.fail.default.text")} icon={<AnimationFailed />} button={t("BUTTONS.login")} buttonLink={"/"} />} />

          <Route path="*" element={<Feedback h1={t("FEEDBACK.verification.email.h1")} text={t("FEEDBACK.verification.email.text")} icon={<AnimationLetter />} />} />
        </Routes>
      }

        {/* Redirect directly after Signup || Login, having verified email and not having completed user profile */}
        {loggedInAtom && userAtom.data.verified && !userAtom.data.signupCompleted &&
        <Routes>
          <Route path="/" exact element={<Welcome />} />

          <Route path="*" element={<Welcome />} />
        </Routes>
      }

      {/* Not logged in */}
      {!loggedInAtom &&
        <Routes>
          <Route path="/" exact element={<Login />} />
          <Route path="/forgot-password" exact element={<ForgotPassword />} />
          <Route path="/reset-password" exact element={<ResetPassword />} />
          <Route path="/register" exact element={<Register />} />

          <Route path="/verify-email" exact element={<Feedback h1={t("FEEDBACK.verification.email.h1")} text={t("FEEDBACK.verification.email.text")} icon={<AnimationLetter />} />} />
          <Route path="/success-confirmed-email" exact element={<Feedback h1={t("FEEDBACK.success.email.h1")} text={t("FEEDBACK.success.email.text")} icon={<AnimationSuccess />} button={t("BUTTONS.login")} buttonLink={"/"} />} />

          <Route path="/verify-password" exact element={<Feedback h1={t("FEEDBACK.verification.password.h1")} text={t("FEEDBACK.verification.password.text")} icon={<AnimationLetter />} />} />
          <Route path="/success-reset-password" exact element={<Feedback h1={t("FEEDBACK.success.password.h1")} text={t("FEEDBACK.success.password.text")} icon={<AnimationSuccess />} button={t("BUTTONS.login")} buttonLink={"/"} />} />

          <Route path="/failed" exact element={<Feedback h1={t("FEEDBACK.fail.default.h1")} text={t("FEEDBACK.fail.default.text")} icon={<AnimationFailed />} button={t("BUTTONS.login")} buttonLink={"/"} />} />

          <Route path="/pricing" exact element={<PricingTable />} />

          <Route path="*" element={<Login />} />
        </Routes>
      }
    </React.Fragment>
  );
};

export default AppRoutes;

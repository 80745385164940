import React, { useState, useEffect, useContext } from 'react';
import {
  useRecoilState,
} from 'recoil';
import settingsAtomState from "../../../_atoms/settingsAtom";
import { useTranslation } from "react-i18next";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/auth-context";
import { useAuth } from "../../../shared/hooks/auth-hook";
import { useSnackbar } from 'notistack';

import {
  Grid,
  Typography,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Collapse,
  Checkbox,
} from '@material-ui/core';
import {
  AddCircle as AddCircleIcon,
  ExpandMore,
  ExpandLess,
  CompareArrows as CompareArrowsIcon,
  MoreVert as MoreVertIcon,
  Today as TodayIcon,
  TextFields as TextFieldsIcon,
} from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';
import SettingsIcon from '@material-ui/icons/Settings';
import { RETURN_YEAR, RETURN_MONTH, RETURN_DAY } from '../../../_functions';
import { useTheme } from '@material-ui/core/styles';
import { DB_PATCH_SETTINGS } from '../../../_functions/DB_SETTINGS';

const ClientNumber = ({
  values,
  setValues,
  openClientNrMenuDots,
  anchorEl,
  handleClick,
  handleClose,
  changeContinuousNumber,
  addElementOpen,
  setAddElementOpen,
  setOpenClientNumberEdit,
  saveNewClientSchema,
  setSaveNewClientSchema,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, error, sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const [settingsAtom, setSettingsAtom] = useRecoilState(settingsAtomState);

  const [edit, setEdit] = useState(false);
  const [hasClientNumberChanged, setHasClientNumberChanged] = useState(false);

  const [initialClientNumber, setInitialClient] = useState(
    (values.clientNumber.state ? values.clientNumber.state : []).filter(
      (item) => item.type !== 'continuousNumber'
    )
  );

  useEffect(() => {
    const currentClientNumber = (values.clientNumber.state ? values.clientNumber.state : []).filter(
      (item) => item.type !== 'continuousNumber'
    );

    const elementsChanged = currentClientNumber.length !== initialClientNumber.length;

    const textElementsChanged = currentClientNumber.some((item, index) => {
      return (
        item.type === 'text' &&
        item.value !== (initialClientNumber[index] ? initialClientNumber[index].value : undefined)
      );
    });

    if (elementsChanged || textElementsChanged) {
      setHasClientNumberChanged(true);
    }
  }, [values.clientNumber, initialClientNumber]);



  const invoiceLabels = {
    invoiceNumber: 'Rechnungsnummer',
    estimateNumber: 'Kostenvoranschlag Nr.',
    offerNumber: 'Angebot Nr.',
  };

  const tooltips = {
    continuousNumber: 'Fortlaufende Nummer',
    fixedText: 'Fixer Text',
  };

  const menuLabels = {
    addElement: 'Element hinzufügen',
    addElementText: 'Fixer Text (Text, Zahl oder Sonderzeichen)',
    yearLabelInvoice: 'Jahr des Rechnungsdatums',
    yearLabelEstimate: 'Datum (Jahr)',
    monthLabelInvoice: 'Monat des Rechnungsdatums',
    monthLabelEstimate: 'Datum (Monat)',
    dayLabelInvoice: 'Tag des Rechnungsdatums',
    dayLabelEstimate: 'Datum (Tag)',
    arrangeRemoveElements: 'Elemente anordnen / entfernen',
  };

  return (
    <>
      <Grid
        item
        container
        xs={12}
        md={12}
        style={{
          border: '2px solid rgb(240, 243, 255)',
          background: "rgb(240, 243, 255)",
          minHeight: '56px',
          borderRadius: '4px',
          paddingTop: "10px",
          paddingBottom: '10px',
          margin: "0px 0px 0px 0px",
        }}
      >
        <Grid item xs={12} md={12} >
          <Grid
            item
            style={{ marginLeft: '14px', wordBreak: 'break-all', borderBottom: edit ? "1px solid #e0ebf7" : "1px solid transparent"}}
          >
            <Typography>
                <div style={{display: "flex", flexDirection: "row"}}>
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <span
                      className="invoiceNrHeadline"
                      style={{ fontSize: '10px', marginRight: 5, display: "block" }}
                    >
                    Kundennummer
                    </span>

                    <span style={{
                      // opacity: disabledEditing ? 0.2 : 1,
                      fontSize: '14px', fontWeight: 300,
                      }}>
                      {(values.clientNumber.state ? values.clientNumber.state : []).map(
                        (item) => item.value
                      ).join('')}
                    </span>
                  </div>
                  
                  <span style={{ marginLeft: 15, fontSize: 10 }}
                    onClick={() => setEdit(!edit)}
                  >
                    <IconButton
                      style={{
                        marginTop: 4,
                        padding: 7, // Reduce padding for smaller size
                        background: !edit ? "#ffffff" : theme.palette.primary.main
                      }}
                      onClick={() => setEdit(!edit)} // Replace with your edit handler function
                    >
                      <EditIcon style={{
                        fontSize: '15px',
                        color: !edit ? theme.palette.primary.main : "#ffffff"
                        }} />
                    </IconButton>
                  </span>
                </div>
            </Typography>
          </Grid>


          {edit &&
            <Grid
              container
              style={{
                marginBottom: '5px',
                paddingLeft: '11px',
                borderRadius: '4px',
                display: "flex",
                // justifyContent: "space-between",
                marginTop: 15
              }}
            >
              <Grid container item style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                {(values.clientNumber.state ? values.clientNumber.state : []).map((item, i) => (
                  <Grid key={item.id} item className="invoiceNrItem--v2" style={{ marginRight: 1, marginBottom: 2 }}>
                    {item.type === 'continuousNumber' && (
                      <Tooltip title={tooltips.continuousNumber} aria-label="Continuous Number">
                        <Grid
                          container
                          item
                          direction="row"
                          justifyContent="space-between"
                          style={{ background: 'rgb(240, 243, 255)', border: "1px solid rgb(240, 243, 255)", borderRadius: 2 }}
                        >
                          <Grid item style={{ textAlign: 'center', width: '100%' }}>
                            <Typography>
                              <input
                                className="badge-input--invoicenr"
                                defaultValue={item.value}
                                placeholder="Nr. ..."
                                onChange={(e) => {
                                  e.persist();
                                  e.target.value.length !== 0 &&
                                    e.target.setAttribute('size', e.target.value.length);
                                    changeContinuousNumber(e, values, (newValues) => {
                                      setValues({
                                        ...values, // Keep other values unchanged
                                        clientNumber: {
                                          ...values.clientNumber, // Preserve existing fields
                                          state: newValues.clientNumber.state || newValues.clientNumber // Update only the state
                                        },
                                      });
                                    });
                                }}
                                style={{
                                  width: "100%",
                                  fontSize: '14px',
                                  textAlign: 'center',
                                  paddingTop: '4px',
                                  paddingBottom: '2px',
                                  color: "rgba(0, 0, 0, 0.87)",
                                  fontFamily: "Inter, sans-serif",
                                  fontWeight: 300,
                                  background: "#ffffff"
                                }}
                                size={JSON.stringify(item.value).length}
                              />
                            </Typography>
                          </Grid>
                        </Grid>
                      </Tooltip>
                    )}

                    {item.type === 'text' && (
                      <Tooltip title={tooltips.fixedText} aria-label="Text">
                        <Grid
                          container
                          item
                          direction="row"
                          justifyContent="space-between"
                          style={{ background: 'rgb(240, 243, 255)', border: "1px solid rgb(240, 243, 255)", borderRadius: 2 }}
                        >
                          <Grid item style={{ width: '100%', textAlign: 'center' }}>
                            <Typography style={{ textAlign: 'center' }}>
                              <input
                                className="badge-input--invoicenr"
                                defaultValue={item.value || ''}
                                placeholder="Text ..."
                                onChange={(e) => {
                                  e.persist();
                                  e.target.value.length !== 0 &&
                                    e.target.setAttribute('size', e.target.value.length);
                                  setValues((prevValues) => {
                                    const parsedClientNr = prevValues.clientNumber.state
                                      ? prevValues.clientNumber.state
                                      : [];
                                    return {
                                      ...prevValues,
                                      clientNumber: {
                                        ...values.clientNumber,
                                        state: parsedClientNr.map((oldItem, oldIndex) =>
                                          oldIndex === i
                                            ? { ...oldItem, value: e.target.value }
                                            : oldItem
                                        )
                                      }
                                    };
                                  });
                                }}
                                style={{
                                  width: '100%',
                                  fontSize: '14px',
                                  textAlign: 'center',
                                  paddingTop: '4px',
                                  paddingBottom: '2px',
                                  color: "rgba(0, 0, 0, 0.87)",
                                  fontFamily: "Inter, sans-serif",
                                  fontWeight: 300,
                                }}
                                size={JSON.stringify(item.value).length}
                              />
                            </Typography>
                          </Grid>
                        </Grid>
                      </Tooltip>
                    )}
                  </Grid>
                ))}
              </Grid>

                <Grid container item alignContent="center">
                  <span
                    style={{
                      display: "block",
                      paddingTop: 15,
                      // paddingBottom: 5,
                      fontSize: '14px',
                      color: theme.palette.primary.main,
                      fontWeight: 300,
                      alignItems: 'center',
                      whiteSpace: 'nowrap',
                      cursor: 'pointer',
                      marginRight: 20
                    }}
                    onClick={handleClick}
                  >
                    <IconButton
                      disabled={isLoading}
                      aria-label="settings"
                      size="small"
                      style={{
                        marginTop: -3,
                        zoom: 0.6,
                        color: theme.palette.primary.main,
                        marginRight: 5,
                      }}
                    >
                      <SettingsIcon />
                    </IconButton>
                    Schema ändern
                  </span>
                </Grid>

              {(hasClientNumberChanged && !values.recurring) &&
                <Grid container alignContent="center">
                  <div
                    onClick={() => setSaveNewClientSchema(!saveNewClientSchema)}
                    style={{
                      display: 'flex',
                      alignItems: 'center', // Vertically center the items
                      cursor: 'pointer',
                      paddingTop: 5,
                      paddingBottom: 5,
                      fontSize: '14px',
                      fontWeight: 'bold',
                      whiteSpace: 'nowrap',
                      marginTop: -5,
                    }}
                  >
                    <Checkbox
                      checked={saveNewClientSchema}
                      onChange={() => setSaveNewClientSchema(!saveNewClientSchema)}
                      disabled={isLoading}
                      color="primary"
                      inputProps={{ 'aria-label': 'Apply schema to future invoices' }}
                      style={{
                        marginRight: 5,
                        marginLeft: -8,
                        marginTop: 4,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      onClick={(e) => e.stopPropagation()} // Prevents the parent div from also triggering
                    />
                    <span style={{ fontSize: 14, fontWeight: 300, lineHeight: 'normal', marginLeft: -2, marginTop: 5 }}>Neues Schema auf alle Kunden anwenden, die neu angelegt werden.</span>
                  </div>
                </Grid>
              }
            </Grid>
          }

        </Grid>
      </Grid>

      <Menu
        id="invoiceNr-menuDots"
        anchorEl={anchorEl}
        open={openClientNrMenuDots}
        onClose={handleClose}
        PaperProps={{
          elevation: 1,
        }}
      >
        <Typography
          style={{
            marginLeft: '15px',
            fontWeight: 'bold',
            fontSize: '15px',
            marginRight: '5px',
          }}
        >
          Kundennummer Schema
        </Typography>
        <MenuItem
          style={{ fontSize: '13px', marginRight: '5px' }}
          onClick={() => setAddElementOpen(!addElementOpen)}
        >
          <AddCircleIcon fontSize="small" style={{ color: theme.palette.primary.main, marginRight: '5px' }} />
          {menuLabels.addElement}
          {!addElementOpen ? (
            <ExpandMore fontSize="small" style={{ color: theme.palette.primary.main, marginLeft: '5px' }} />
          ) : (
            <ExpandLess fontSize="small" style={{ color: theme.palette.primary.main, marginLeft: '5px' }} />
          )}
        </MenuItem>

        <Collapse in={addElementOpen} timeout="auto" unmountOnExit>
          <div style={{ marginLeft: '25px' }}>
            <MenuItem
              style={{ fontSize: '13px', marginRight: '5px' }}
              onClick={() => {
                try {
                  const parsedState = values.clientNumber.state
                    ? values.clientNumber.state
                    : []; // Default to an empty array if the state is null or undefined
              
                  const newId = parsedState.reduce((acc, item) => Math.max(acc, item.id), 0) + 1;
              
                  setValues({
                    ...values,
                    clientNumber: {
                      ...values.clientNumber,
                      state: [
                        ...parsedState,
                        {
                          id: newId,
                          type: 'text',
                          value: '',
                        },
                      ],
                    },
                  });
              
                  handleClose();
                  setAddElementOpen(false);
                } catch (error) {
                  console.error("Failed to update clientNumber state:", error);
                }
              }}
            >
              <TextFieldsIcon fontSize="small" style={{ color: theme.palette.primary.main, marginRight: '5px' }} />
              {menuLabels.addElementText}
            </MenuItem>
          </div>
        </Collapse>

        <MenuItem
          style={{ fontSize: '13px', marginRight: '5px' }}
          onClick={() => {
            setOpenClientNumberEdit(true);
            setValues((prevValues) => ({
              ...prevValues,
              clientNumber:{
                ...values.clientNumber,
                state: prevValues.clientNumber.state
              },
            }));
            handleClose();
          }}
        >
          <CompareArrowsIcon fontSize="small" style={{ color: theme.palette.primary.main, marginRight: '5px' }} />
          {menuLabels.arrangeRemoveElements}
        </MenuItem>
      </Menu>
    </>
  );
};

export default ClientNumber;

import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

const mobileOpenAtom = atom({
  key: "mobileOpen",
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export default mobileOpenAtom

export const de_PAGE_INVOICES = {
  h1: "Rechnungen",
  createInvoice: "Rechnung anlegen",
  editInvoice: "Rechnung bearbeiten",
  search: "Suche",
  searchPlaceholder: "Suchen nach Rechnungsnummer oder Kundenname ...",
  noResult: "Kein Ergebnis",
  noInvoicesCreated: "Du hast keine Rechnungen angelegt",
  settings: "Einstellungen",
  settingsCreation: "Erstellung von Rechnungsnummern",
  settingsCreationAutomatic: "Automatisch",
  settingsCreationManual: "Manuell (Rechnungsnummern selbst eingeben)",
  settingsCreationSchema: "Schema definieren",
  addElement: "Element hinzufügen",
  elementText: "Fixer Text",
  elementTextPlaceholder: "Text, Zahl oder Sonderzeichen",
  elementContinuousNumber: "Fortlaufende Nummer",
  elementContinuousNumberDescribed: "Fortlaufende Nummer beginnend bei",
  elementYear: "Jahr (des Rechnungsdatums)",
  elementMonth: "Monat (des Rechnungsdatums)",
  elementDay: "Tag (des Rechnungsdatums)",
  elementDatePlaceholder: "Zum Zeitpunkt der Erstellung einer Rechnung",
  invoiceNumberPreview: "Vorschau der nächsten Rechnungsnummer",
  filter: "Filter",
  filterStatus: "Rechnungen mit folgendem Status anzeigen",
  filterStatusDraft: "Entwurf",
  filterStatusScheduled: "Versand geplant",
  filterStatusSent: "Gesendet",
  filterStatusPayed: "Bezahlt",
  filterStatusOverdue: "Überfällig",
  appliedFilters: "Angewendete Filter",
  sorted: "Sortiert nach",
  sortCreationChronologically: "Erstellungszeitpunkt (chronologisch)",
  sortCreationChronologicallyReverse: "Erstellungszeitpunkt (umgekehrt chronologisch)",
  sortInvoiceDateChronologically: "Rechnungsdatum (chronologisch)",
  sortInvoiceDateChronologicallyReverse: "Rechnungsdatum (umgekehrt chronologisch)",
  sortDueDateChronologically: "Fälligkeitsdatum (chronologisch)",
  sortDueDateChronologicallyReverse: "Fälligkeitsdatum (umgekehrt chronologisch)",
  sortPayDateChronologically: "Bezahldatum (chronologisch)",
  sortPayDateChronologicallyReverse: "Bezahldatum (umgekehrt chronologisch)",
  sortInvoiceNumbersAsc: "Rechnungsnummer (aufsteigend)",
  sortInvoiceNumbersDesc: "Rechnungsnummer (absteigend)",
  sortCompanyAsc: "Kundenname (A-Z)",
  sortCompanyDesc: "Kundenname (Z-A)",
  invoiceNumber: "Rechnungsnummer",
  draftInvoice: "Entwurf",
  scheduledInvoice: "Versand geplant",
  sentInvoice: "Gesendet",
  payedInvoice: "Bezahlt",
  overdueInvoice: "Überfällig",
  tooltipCannotEditExistingInvoice: "Rechnungsnummern bestehender Rechnungen können nicht geändert werden.",
  tooltipChangeInvoiceNumberToManual: 'Um Rechnungsnummern selbst einzugeben, wechseln Sie bitte in Ihren Einstellungen auf "manuell".',
  profileIncomplete: "Bitte vervollständige dein Profil um Rechnungen anlegen zu können.",
  noClients: "Bitte lege zunächst einen Kunden an um eine Rechnung zu erstellen.",
  invoiceNumber: "Rechnungsnummer",
  invoiceDate: "Rechnungsdatum",
  dueDate: "Fälligkeitsdatum",
  payDate: "Bezahldatum",
  total: "Rechnungsbetrag",
  dateRange: "Zeitraum eingrenzen",
  dateRangeSlim: "Zeitraum",
  settingsDateRangeNone: "Keine Eingrenzung",
  settingsDateRangeInvoiceDate: "Rechnungsdatum",
  settingsDateRangeDueDate: "Fälligkeitsdatum",
  settingsDateRangePayDate: "Bezahldatum",
  invoicesText: "Einleitungstext auf Rechnungen",
  general: {
    h2: "Allgemein"
  },
  person: {
    h2: "Kontaktperson"
  },
  logo: {
    h2: "Logo"
  },
  status: {
    h2: "Status"
  },
  note: {
    h2: "Notizen"
  },
  items: {
    h2: "Leistungen"
  },
  invoiceDelete: "Bist du sicher, dass du diese Rechnung endgültig löschen möchtest? Das Löschen kann nicht mehr rückgängig gemacht werden. Wir empfehlen dir, die Rechnung zu stornieren und nicht zu löschen, damit die fortlaufenden Rechnungsnummern korrekt bleiben.",
  exportInvoicesList: "Rechnungsliste exportieren",
  exportInvoicesListIncludeFields: "Folgende Felder der Rechnungsliste im Export inkludieren",
}

import { useEffect } from 'react';
import { DB_GET_INVOICE } from '../../../_functions/DB_INVOICES';
import { DB_GET_INVOICERECURRING } from '../../../_functions/DB_INVOICESRECURRING';

import {
  DB_GET_CLIENT,
  DB_GET_CLIENTS_LIST
} from '../../../_functions/DB_CLIENTS';

import { useHttpClient } from "../../../shared/hooks/http-hook";

const useMount = (
  invoiceId,
  auth,
  values,
  setValues,
  setSelectedClient,
  setClientList,
  setLoadingClients,
  setItems,
  type
) => {

  const { sendRequest } = useHttpClient(); // Destructure only sendRequest since it's the only needed part

  useEffect(() => {
    const fetchData = async () => {
      try {
          let invoiceData;
          switch (type) {
            case "invoice":
              invoiceData = await DB_GET_INVOICE(invoiceId, auth, sendRequest);
              break;
            case "invoicerecurring":
              invoiceData = await DB_GET_INVOICERECURRING(invoiceId, auth, sendRequest);
              break;
            default:
              throw new Error("Unsupported fetch type");
          }
        if (invoiceData) {
          // Parse the items if they exist
          const parsedInvoiceData = {
            ...invoiceData,
            items: invoiceData.items ? JSON.parse(invoiceData.items) : [], // Parse items or set as empty array if not present
            clientData: invoiceData.clientData ? JSON.parse(invoiceData.clientData) : {}
          };
    
          setValues(prevValues => ({
            ...prevValues,
            ...parsedInvoiceData,
          }));
          setItems(JSON.parse(invoiceData.items));
          setSelectedClient(invoiceData.clientData ? JSON.parse(invoiceData.clientData) : {});
    
          // Fetch client details if clientId exists in the invoice
          if (parsedInvoiceData.clientData) {
            const clientId = JSON.parse(parsedInvoiceData.clientData).id;
            const clientDetails = await DB_GET_CLIENT(clientId, auth, sendRequest);
            if (clientDetails) {
              setSelectedClient(clientDetails);
            }
          }
        }

        // Fetch clients list for the user
        setLoadingClients(true); // Ensure spinner is shown immediately
        const clientsList = await DB_GET_CLIENTS_LIST(auth, sendRequest);
        setClientList(clientsList);
        setLoadingClients(false); // Stop spinner after fetching
      } catch (err) {
        console.log(err);
        setLoadingClients(false); // Stop loading spinner on error
      } finally {
        setValues(prevValues => ({
          ...prevValues,
          loading: false,
        }));
      }
    };

    if (invoiceId) {
      fetchData();
    } else {
      // Fetch Settings to get the current (next) continuous Number (important to take into account the automatically sent recurring invoices)
      const fetchSettingsAndUpdateInvoice = async () => {
        try {
          // Fetch settings
          const resSettings = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL_API}/settings/user/${auth.userId}`,
            "GET",
            null,
            {
              "Content-Type": "application/json",
              Authorization: "Bearer " + auth.token,
            }
          );
    
          // Get invoiceNumberItems from settings
          let invoiceNumberItems = resSettings.settings[0].invoicesNumberItems || "[]"; // Fallback to empty array string
    
          // Parse the invoiceNumberItems array
          let parsedInvoiceNumberItems = JSON.parse(invoiceNumberItems);
        
          // Prepare current date values
          const currentYear = new Date().getFullYear();
          const currentMonth = String(new Date().getMonth() + 1).padStart(2, '0'); // Month is 0-based, so add 1
          const currentDay = String(new Date().getDate()).padStart(2, '0');
    
          // Loop through each item and update year, month, or day as needed
          parsedInvoiceNumberItems = parsedInvoiceNumberItems.map(item => {
            if (item.type === 'continuousNumber') {
              item.value = ""; // Use the fetched continuousNumber value
            }
    
            if (item.type === 'year') {
              item.value = currentYear; // Set current year
            }
            if (item.type === 'month') {
              item.value = currentMonth; // Set current month
            }
            if (item.type === 'day') {
              item.value = currentDay; // Set current day
            }
            return item; // Return the updated item
          });
    
          // Stringify the updated array and set it in `values`
          setValues({ ...values, invoiceNr: JSON.stringify(parsedInvoiceNumberItems), loading: false });
    
        } catch (error) {
          console.error("Failed to fetch settings", error);
        }
      };
    
      // Call the function to fetch settings and update the invoice number
      fetchSettingsAndUpdateInvoice();
    }
  }, [invoiceId, auth, sendRequest, setValues, setSelectedClient, setClientList, setLoadingClients]);
};

export default useMount;

import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, List, ListItem, ListItemText } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useHttpClient } from "../../shared/hooks/http-hook";
import { useRecoilState} from 'recoil';  // Import Recoil hook
import openInvoiceDialogAtomState from "../../_atoms/openInvoiceDialogAtom";


const RecurringRefDialog = ({ open, handleClose, auth, recurringRef }) => {
  const { isLoading, error, sendRequest } = useHttpClient();
  const [invoiceList, setInvoiceList] = useState([]);
  const [openInvoiceDialogAtom, setOpenInvoiceDialogAtom] = useRecoilState(openInvoiceDialogAtomState);

  // Fetch invoices when the dialog opens
  useEffect(() => {
    if (open) {
      fetchInvoices();
    }
  }, [open, recurringRef]);

  const fetchInvoices = async () => {
    try {
      const res = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL_API}/invoices/recurringRef`,
        "POST",  // POST request with recurringRef IDs in the body
        JSON.stringify({ ids: recurringRef }),
        {
          'Content-Type': 'application/json',
          Authorization: "Bearer " + auth.token,
        }
      );
      setInvoiceList(res);  // Set fetched invoice data in state
    } catch (err) {
      console.log(err);  // Log error to console
    }
  };

  // Function to parse and combine the stringified invoiceNr array into a single string
  const combineInvoiceNumber = (invoiceNrString) => {
    try {
      const invoiceNrArray = JSON.parse(invoiceNrString);  // Parse the stringified array
      return invoiceNrArray.map(item => item.value).join('');  // Combine the `value` fields
    } catch (error) {
      console.error('Error parsing invoiceNr:', error);
      return 'Invalid Invoice Number';
    }
  };

  // Function to format the date as DD.MM.YYYY
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('de-DE');  // Format the date as DD.MM.YYYY
  };

  // Function to handle item click and open the invoice dialog
  const handleItemClick = (invoiceId) => {
    setOpenInvoiceDialogAtom({
      ...openInvoiceDialogAtom,
      open: true,
      invoiceId: invoiceId,  // Use invoiceId from the database
      recurring: false,
    });
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      scroll="paper"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        Versendete Rechnungen
        {/* Close icon on the top-right */}
        <IconButton
          aria-label="close"
          onClick={() => handleClose(false)}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {isLoading ? (
          <p>Rechnungen werden geladen...</p>  // Loading state
        ) : invoiceList.length > 0 ? (
          <List>
            {invoiceList.map((invoice, index) => (
              <ListItem
                key={index}
                divider={index !== invoiceList.length - 1}  // Only add divider if not the last item
                onClick={() => handleItemClick(invoice._id)}  // Open dialog with the invoiceId
                button  // Make the ListItem clickable (from Material-UI)
              >
                <ListItemText
                  primary={`Rechnungsnummer: ${combineInvoiceNumber(invoice.invoiceNr)}`}  // Show "Rechnungsnummer:"
                  secondary={`Rechnungsdatum: ${formatDate(invoice.invoiceDate)}`}  // Format the date as DD.MM.YYYY
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <p>Es wurden keine Rechnungen versendet.</p>  // No invoices found
        )}
      </DialogContent>
    </Dialog>
  );
};

export default RecurringRefDialog;

export const DB_GET_INVOICESRECURRING = async (setInvoicesrecurringAtom, auth, sendRequest) => {
  try {
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL_API}/invoicesrecurring/user/${auth.userId}`,
      "GET",
      null,
      {
        Authorization: "Bearer " + auth.token,
      }
    )
    // NO ERROR
    .then((res) => setInvoicesrecurringAtom(res.invoicerecurrings))
  } catch (err) { 
    // console.log(err)
    // ERROR
  }
};

export const DB_GET_INVOICERECURRING = async (invoiceId, auth, sendRequest) => {
  try {
    const invoiceRes = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL_API}/invoicesrecurrings/${invoiceId}`,
      "GET",
      null,
      {
        Authorization: "Bearer " + auth.token,
      }
    );
    return invoiceRes ? invoiceRes.invoice : null;
  } catch (err) {
    console.log(err);
    return null;
  }
};

import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import './index.scss';
import './_styles_global/style.scss';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import CssBaseline from '@material-ui/core/CssBaseline';

import { RecoilRoot } from 'recoil';
import "./_languages/i18n";

ReactDOM.render(
    // <StrictMode>
        <RecoilRoot>
            <CssBaseline />
            <App />
        </RecoilRoot>
    // </StrictMode>
    , document.getElementById('root'));

// serviceWorkerRegistration.register();


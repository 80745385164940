import React from 'react';
import { BottomNavigation, Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import MailIcon from '@material-ui/icons/Mail';
import ButtonLoadingBottom from '../../shared/components/UIElements/ButtonLoadingBottom';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';


const ProductBottomNavigation = ({
  isLoading,
  buttonLoadingAction,
}) => {

  return (
    <>
        <BottomNavigation
          style={{ position: "fixed", width: "100%", zIndex: 999, bottom: 0, background: "transparent", margin: "0 auto" }}
          className="box-shadow--2"
        >
          <div
            style={{
              boxShadow: "rgba(118, 118, 118, 0.2) 0px -4px 8px, rgba(118, 118, 118, 0.2) 4px 0px 8px, rgba(118, 118, 118, 0.2) -4px 0px 8px",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px"
            }}
          >
            <ButtonLoadingBottom
              loading={isLoading && (buttonLoadingAction === "save")}
              disabled={isLoading}
              color="primary"
              type="submit"
              size="large"
              className="button--loading"
              borderTopLeftRadius="10px"
              style={{ background: "white", color: "inherit" }}
              borderTopRightRadius="0px"
              startIcon={<PictureAsPdfIcon />}
              // onClick={() => {
              //   setSendMode(false);
              //   setOpen(true);
              //   invoiceHandler();

              //   if (saveNewInvoiceSchema) {
              //     setSettingsAtom((prev) => ({
              //       ...prev,
              //       invoicesNumberItems: values.invoiceNr,
              //     }));
              //   }
              // }}
            >
              Abbrechen
            </ButtonLoadingBottom>

            <ButtonLoadingBottom
              loading={isLoading && (buttonLoadingAction === "save")}
              disabled={isLoading}
              color="primary"
              type="submit"
              size="large"
              className="button--loading"
              borderTopLeftRadius="0px"
              borderTopRightRadius="10px"
              startIcon={<SaveIcon />}
              // onClick={(e) => {
              //   invoiceHandler(e, false);
              //   setButtonLoadingAction("save");
              
              //   if (saveNewInvoiceSchema) {
              //   setSettingsAtom((prev) => ({
              //     ...prev,
              //     invoicesNumberItems: values.invoiceNr,
              //   }));
              // }
              // }}
            >
              Speichern
            </ButtonLoadingBottom>
          </div>
        </BottomNavigation>
    </>
  );
};

export default ProductBottomNavigation;

import React, { useState, useEffect } from "react";

import imageCompression from 'browser-image-compression';

// IMPORT components MATERIAL UI
import {
  Typography,
  Grid,
  IconButton,
} from "@material-ui/core";
import Fade from '@material-ui/core/Fade';
import PublishIcon from '@material-ui/icons/Publish';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import "./DropzoneUploader.scss"
import { useSnackbar } from 'notistack';

import { pdfjs } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker; 

const DropzoneUploaderSingle = ({
  values,
  setValues,
  buttonText,
  description,
  accept,
  logo,
  multiple,
  maxFiles,
  disabled,
  setLogosProcessing,
  setCreateExpenses,
  }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [imageBlobs, setImageBlobs] = useState([]);

  // Initial imageBlobs state
  useEffect(() => {
    if (typeof(logo) === "string" && logo !== "") {
      setImageBlobs([[logo, "image"]])
    } else {
      setImageBlobs([])
    }
  }, []);

  // specify upload params and url for your files
  const getUploadParams = ({ meta }) => {
    return { url: "https://httpbin.org/post" };
  };

  const handleChangeStatus = ({ meta, file, remove }, status) => {
    processImages(meta, file, remove, status);
  };
  
  const processImages = async (meta, file, remove, status) => {
  
    // Handle file size or type errors
    if (status === "error_file_size") {
      enqueueSnackbar(`File is too large. Maximum file size: 5 MB.`, { variant: "error" });
    } else if (status === "rejected_file_type") {
      enqueueSnackbar(`Invalid file type. Allowed formats: ${multiple ? '.jpg, .png, or .pdf' : '.jpg or .png'}`, { variant: "error" });
    } else if (status === "rejected_max_files") {
      enqueueSnackbar(multiple ? `Maximum ${maxFiles} files per upload.` : "Maximum 1 file per upload", { variant: "error" });
    }
  
    if (status === "done") {
      if (meta.type.startsWith("image/")) {
        const options = {
          maxSizeMB: 1, // (max file size in MB)
          maxWidthOrHeight: 1920, // (scales image if it's too large)
          useWebWorker: true
        };
  
        try {
          const compressedFile = await imageCompression(file, options);
          getDataBlob(compressedFile, remove); // Pass `remove` to `getDataBlob`
        } catch (error) {
          console.error("Image compression error:", error);
          enqueueSnackbar("Error compressing image", { variant: "error" });
        }
      } else if (meta.type === "application/pdf") {
        function extractImagesFromPDF(file) {
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function () {
            fetch(reader.result).then((response) => {
              // reader.result is BLOB Url-> blob:........
              response.blob().then((blob) => {
                let reader = new FileReader();
                reader.onload = (e) => {
                  const data = atob(e.target.result.replace(/.*base64,/, ""));
                  renderPage(data, reader.result);
                };
                reader.readAsDataURL(blob);
              });
            });

            const renderPage = async (data, pdfBlob) => {
              const imagesList = [];
              const canvas = document.createElement("canvas");
              canvas.setAttribute("className", "canv");
              const pdf = await pdfjs.getDocument({ data }).promise;
            
              for (let i = 1; i <= pdf.numPages; i++) {
                const page = await pdf.getPage(i);
                const viewport = page.getViewport({ scale: 1.5 });
                canvas.height = viewport.height;
                canvas.width = viewport.width;
            
                const render_context = {
                  canvasContext: canvas.getContext("2d"),
                  viewport: viewport,
                };
            
                await page.render(render_context).promise;
                let imgDataUrl = canvas.toDataURL("image/png", 1.0); // Start with best quality
                let quality = 1.0;
                let fileSize = (imgDataUrl.length * (3/4)) / (1024 * 1024); // Convert base64 length to MB
            
                // Reduce quality until the image is under 1 MB or quality drops below a reasonable level
                while (fileSize > 1 && quality > 0.1) {
                  quality -= 0.1; // Reduce quality incrementally
                  imgDataUrl = canvas.toDataURL("image/png", quality);
                  fileSize = (imgDataUrl.length * (3/4)) / (1024 * 1024); // Recalculate file size
                }
            
                imagesList.push(imgDataUrl);
              }
              let array = values.logo;
              array.push([JSON.stringify(imagesList), "pdf", file.name, pdfBlob])
              setValues(prevValues => ({
                  ...prevValues,
                  logo: array,
              }));
              // setValues({ ...values, logo: [JSON.stringify(imagesList), "pdf", file.name, pdfBlob] });
            };

            
          };
          reader.onerror = function (error) {
            console.log('Error: ', error);
          };
       }
       extractImagesFromPDF(file);
      }
    }
  };
  
  async function getDataBlob(file, remove) {
    const res = await fetch(URL.createObjectURL(file));
    const blob = await res.blob();
    const base64img = await parseURI(blob);
    setImageBlobs((items) => [
      ...items,
      [base64img, "image"]
    ]);
    if (!multiple) {
      setValues({ ...values, logo: base64img });
      remove();
    } else {
      let array = values.logo;
      array.push([base64img,"image", file.name, ""])
      setValues(prevValues => ({
          ...prevValues,
          logo: array,
      }));
        // setValues({
        //   ...values,
        //   logo: [base64img,"image", file.name, ""]
        // });
      }
  }
  
  async function parseURI(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(e.target.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  const handleSubmit = (files, allFiles) => {
    multiple && allFiles.forEach(f => f.remove())
    multiple && setLogosProcessing(prevState => prevState + allFiles.length);
    multiple && setCreateExpenses(true);
  }
  

  return (
    <>
      {(JSON.stringify(imageBlobs) !== "[]" && multiple === false) &&
      <Grid container item xs={12}>
        <div className="dropzone-preview-image-wrapper" style={{ height: multiple ? "auto" : "216px"}}>
          <Grid item container alignItems="center" style={{ flexWrap: "nowrap" }}>
          {imageBlobs.map((item, i) => (
            <>
              <img src={item[0]} alt="image" />
              <IconButton aria-label="delete" onClick={()=> {
                setImageBlobs([]);
                setValues({...values, logo: ""})
                }} >
                <DeleteForeverIcon/>
              </IconButton>
              </>
             ))}
          </Grid>
        </div>
      </Grid>
      }

      {((JSON.stringify(imageBlobs) === "[]" && multiple === false) || multiple === true) &&
      <Grid container item xs={12} style={{ height: multiple ? "auto" : "216px", alignContent: "flex-start" }}>
        <Dropzone
          classNames={{ dropzone: multiple ? "dzu-dropzone multiple" : "dzu-dropzone single" }}
          disabled={disabled}
          // getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatus}
          accept={accept}
          multiple={multiple}
          maxFiles={maxFiles}
          style={{ background: "red" }}
          maxSizeBytes={5242880} // 5 MB
          onSubmit={handleSubmit}
          inputContent={
            <>
            {((multiple === true && !disabled) || multiple === false) &&
            <Fade in={true}>
              <div className="dropzone-upload-wrapper">
                <div className="dropzone-button">
                  <Typography variant="body1" style={{ fontWeight: "500", marginRight: "10px" }}>{buttonText}</Typography>
                  <PublishIcon />
                </div>

                <Typography className="dropzone-text" variant="body2" style={{ maxWidth: "550px" }}>{description}</Typography>
              </div>
            </Fade>
            }
            {/* {(multiple === true && disabled) &&
            <Fade in={true}>
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", alignContent: "center", marginTop: "10px", marginBottom: "10px" }}>
                <ProgressBar openAiRequestLength={openAiRequestLength} expensesLength={expensesLength}/>
              </div>
            </Fade>
            } */}
            </>
          }
        >
        </Dropzone>
      </Grid>
      }
    </>
  );
};

export default DropzoneUploaderSingle;

import React, { useState, useContext, useEffect } from "react";
// IMPORT hooks & context
import { useTranslation } from "react-i18next";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from '../../shared/context/auth-context';

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import invoicesAtomState from "../../_atoms/invoicesAtom";

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';





// IMPORT components MATERIAL UI
import {
  Typography,
  Grid,
  IconButton,
  Button,
} from "@material-ui/core";

// IMPORT icons MATERIAL UI
import MailIcon from '@material-ui/icons/Mail';
import AttachmentIcon from '@material-ui/icons/Attachment';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

// IMPORT functions
import { RETURN_DATE } from '../../_functions/DATES';
import { RETURN_HOURS } from '../../_functions/DATES';

// IMPORT components
import { useSnackbar } from 'notistack';

// IMPORT own components


import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Paper from '@material-ui/core/Paper';

import { useTheme } from '@material-ui/core/styles';

import "./InvoicePdfPreview.scss"

import { saveAs } from 'file-saver'
import { Document, Page, pdfjs } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker; 

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);


export default function SentBlob({
  sentblob,
  loading
}) {
  // HOOKS & CONTEXT 
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const [pdfString, setPdfString] = useState('');
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [editorStateMessage, setEditorStateMessage] = useState("");

  // GLOBAL STATE (RECOIL)
  const [invoicesAtom, setInvoicesAtom] = useRecoilState(invoicesAtomState);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


const onDocumentLoadSuccess = ({ numPages }) => {
  setNumPages(numPages);
};

function pages() {
  let pages = []
  for (let i = 1; i <= numPages; i++) {
    pages.push(
      <Paper key={i} elevation={0} style={{margin: "20px auto", width: "fit-content", overflow: "hidden", boxShadow: "0px 3px 3px -2px #545ea51a, 0px 3px 4px 0px #545ea51a, 0px 1px 8px 0px #545ea51a"}}>
        <Page pageNumber={i} scale={2} />
      </Paper>
    );
  }
  return pages
}

// const blob = JSON.parse(sentblob).blob
// const filename= "foo.pdf"


// const downloadFile = (blob, fileName) => {
//   const link = document.createElement('a');
//   // create a blobURI pointing to our Blob
//   link.href = URL.createObjectURL(blob);
//   link.download = fileName;
//   // some browser needs the anchor to be in the doc
//   document.body.append(link);
//   link.click();
//   link.remove();
//   // in case the Blob uses a lot of memory
//   setTimeout(() => URL.revokeObjectURL(link.href), 7000);
// };

// downloadFile(new Blob(['random data'], { type: "application/octetstream" }), filename);

  const handleDownload = async () => {
    const blob = await JSON.parse(sentblob).blob;
    saveAs(blob, `${JSON.parse(sentblob).filename}`)
  }

  return (
    <div>
    <Button disabled={loading} onClick={handleClickOpen} startIcon={JSON.stringify(JSON.parse(sentblob).email) !== "[]" ? <MailIcon color="primary" /> : <VisibilityIcon color="primary" />} style={{ marginLeft: "10px", fontSize: "12px" }}>
      <span style={{ marginLeft: "-5px" }}>{JSON.stringify(JSON.parse(sentblob).email) !== "[]" ? "EMAIL" : "ANSEHEN"}</span>
    </Button>

    <Button disabled={loading} onClick={handleDownload} startIcon={<SaveAltIcon color="primary"/>} style={{ marginLeft: "2px", fontSize: "12px" }}>
      <span style={{ marginLeft: "-5px" }}>PDF</span>
    </Button>


    {/* <Button variant="contained" color="secondary" startIcon={<SendIcon />}>
      Jetzt senden
    </Button>
 */}



    {/* style={{backgroundColor: theme.palette.warning.dark, color: "white"}}  */}

    <Dialog fullScreen onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {JSON.stringify((JSON.parse(sentblob).email)) !== "[]" ? "Gesendete Email mit Anhang" : "Gesendetes Dokument"}
      </DialogTitle>
      <DialogContent dividers>
        <div style={{maxWidth: 1200, margin: "0 auto", }}>
        {JSON.stringify((JSON.parse(sentblob).email)) !== "[]" &&
        <Typography style={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
          <div style={{display: "flex", flexDirection: "row"}}>
          <MailIcon fontSize="medium" style={{ marginTop: -1, color: "rgb(33, 150, 243)", marginRight: "5px" }} />
          {`Gesendet am ${RETURN_DATE(JSON.parse(sentblob).sendDate)} um ${RETURN_HOURS(JSON.parse(sentblob).sendDate)}`}
          </div>
          <br />
          {(JSON.parse(sentblob).email) !== "" && (
            <>
              {`AN: ${((JSON.parse(sentblob).email)).replace(/,/g, "")}`}
              <br />
            </>
          )}
          {(JSON.parse(sentblob).cc) !== "" && (
            <>
              {`CC: ${((JSON.parse(sentblob).cc)).replace(/,/g, "")}`}
              <br />
            </>
          )}
          {(JSON.parse(sentblob).bcc) !== "" && (
            <>
              {`BCC: ${((JSON.parse(sentblob).bcc)).replace(/,/g, "")}`}
              <br />
            </>
          )}
          <br />
        </Typography>
        }

        {JSON.stringify((JSON.parse(sentblob).email)) !== "[]" &&
        <Typography gutterBottom>
          Betreff: <strong>{JSON.parse(sentblob).subject}</strong>
        </Typography>
        }
        
        {JSON.stringify((JSON.parse(sentblob).email)) !== "[]" && <div style={{background: "#f7f6ff", padding: 10, marginTop: 10, borderRadius: 6}}>{ReactHtmlParser(JSON.parse(sentblob).message)}</div>}
  
        {/* <Document
        file={JSON.parse(sentblob).blob}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={1} />
      </Document> */}

      <>
            <Grid container item direction="column" spacing={2}>
              {/* <Grid item>
                <TextField
                  id="email"
                  fullWidth
                  defaultValue={client.email}
                  InputProps={{
                    startAdornment: <Typography style={{ color: "rgba(0, 0, 0, 0.5)", marginRight: "10px" }}>An</Typography>,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Link style={{ marginRight: "10px" }}>CC</Link>
                        <Link>BCC</Link>
                      </InputAdornment>
                    ),
                    style: { fontWeight: "500" }
                  }}
                />
              </Grid> */}

              {/* <Grid item>
                <TextField
                  id="subject"
                  fullWidth
                  multiline
                  defaultValue={values.subject}
                  InputProps={{
                    startAdornment: <Typography style={{ color: "rgba(0, 0, 0, 0.5)", marginRight: "10px" }}>Betreff</Typography>,
                    style: { fontWeight: "500" }
                  }}
                />
              </Grid> */}

              <Grid item>
                {/* <Editor
                  toolbar={{
                    options: ['inline', 'fontSize', 'list', 'textAlign', 'link'],
                    inline: { inDropdown: true, options: ['bold', 'italic', 'underline', 'strikethrough']},
                    textAlign: {inDropdown: true},
                    link: { inDropdown: true },
                    list: { inDropdown: true },
                  }}
                  // toolbarOnFocus
                  editorState={editorStateMessage}
                  onEditorStateChange={(editorState) => {setEditorStateMessage(editorState)}}
                  localization={{
                    locale: 'de',
                  }}
                  placeholder="E-Mail Text schreiben ..."
                  wrapperClassName="editor-wrapper"
                  editorClassName="editor"
                /> */}
              </Grid>
            </Grid>

            <Grid item>
              {JSON.stringify((JSON.parse(sentblob).email)) !== "[]" &&
              <Grid container item direction="row" alignItems="center" style={{ marginTop: "20px"}}>
                <AttachmentIcon style={{ marginRight: "10px"}}/>
                <Typography component="div" variant="body1">Anhang: <span style={{ fontWeight: "500" }}>{JSON.parse(sentblob).filename}(PDF)</span></Typography>
                <IconButton onClick={handleDownload} aria-label="zoom in" style={{ marginTop: "6px", marginLeft: "5px", marginBottom: "5px", background: "#2196f3", color: "#ffffff", padding: "4px"}}>
                    <SaveAltIcon />
                </IconButton>
              </Grid>
              }

              <div  style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "flex-start"}} >
                <div className="invoice-pdf-preview">
                  <Document
                    file={JSON.parse(sentblob).blob}
                    // file={testpdf}
                    onLoadSuccess={onDocumentLoadSuccess}
                    >
                    {pages()}
                  </Document>

                </div>
              </div>
            </Grid>
            </>
            </div>
      </DialogContent>

      <DialogActions style={{ justifyContent: "center" }}>
        <Button
          onClick={handleDownload}
          variant="contained"
          color="primary"
          autoFocus
          startIcon={<SaveAltIcon />}
        >
          PDF Download
        </Button>
      </DialogActions>
    </Dialog>
  </div>
  );
}

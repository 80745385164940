import React, { useContext } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import Alert from "@material-ui/lab/Alert";
import { useTranslation } from 'react-i18next';
import { RETURN_OVERDUE_DAYS, RETURN_DATE, RETURN_HOURS } from '../../../_functions/index'; // adjust the path accordingly
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from '../../../shared/context/auth-context';
import { useAuth } from "../../../shared/hooks/auth-hook";
import { Info } from '@material-ui/icons';

import ClientsIcon from "@material-ui/icons/RecentActors";
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import FilterListIcon from '@material-ui/icons/FilterList';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import EditIcon from '@material-ui/icons/Edit';
import ScheduleIcon from '@material-ui/icons/Schedule';
import MailIcon from '@material-ui/icons/Mail';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckIcon from '@material-ui/icons/Check';
import AddIcon from '@material-ui/icons/Add';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import GavelIcon from '@material-ui/icons/Gavel';
import SearchIcon from '@material-ui/icons/Search';
import ErrorIcon from '@material-ui/icons/Error';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import RepeatIcon from '@material-ui/icons/Repeat';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import SubjectIcon from '@material-ui/icons/Subject';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import InfoIcon from '@material-ui/icons/Info';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CancelIcon from '@material-ui/icons/Cancel';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import FiberNewIcon from '@material-ui/icons/FiberNew';


const InvoiceAlerts = ({ selectedClient, settings, values, setValues, openInvoiceDialogAtom, setShowConfirmModal }) => {
  const { t } = useTranslation();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);

  const handleCancelSend = async () => {
    // Prepare FormData
    const formData = new FormData();
    formData.append("sendState", values.sentBlobs.length === 0 ? "notSent" : "sent");
    formData.append("sendDate", values.sentBlobs.length === 0 ? values.sendDate : JSON.parse(values.sentBlobs[values.sentBlobs.length - 1]).sendDate);

    let patchUrl;
    switch (values.type) {
      case "invoice":
        patchUrl = `${process.env.REACT_APP_BACKEND_URL_API}/invoices/${openInvoiceDialogAtom.invoiceId}`;
        break;
      case "invoicerecurring":
        patchUrl = `${process.env.REACT_APP_BACKEND_URL_API}/invoicesrecurrings/${openInvoiceDialogAtom.invoiceId}`;
        break;
      default:
        throw new Error("Unsupported fetch type");
    }

    try {
      // The URL for patching the specific invoice
      const url = patchUrl;

      // Send the formData to the backend using PATCH method
      const responseData = await sendRequest(
        url,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      // Handle the response if needed
      console.log("Send state updated successfully:", responseData);
    } catch (error) {
      console.error("Error updating send state:", error);
    }
  };

  return (
    <>

      {/* NEW */}
      {!selectedClient &&
        <div
          className="alert-info"
          style={{
            display: "flex",
            justifyContent: "center",
            borderRadius: 0,
            position: "fixed",
            width: "100%",
            zIndex: "9999",
            left: 0,
            boxShadow: "rgba(84, 94, 165, 0.2) 0px 1px 64px",
            backgroundColor: '#ffffff',
            padding: "5px 20px"
          }}
        >
            <div
              style={{
                background: "white",
                borderRadius: 60,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                position: "relative", // Added for badge positioning
                opacity: values.loading ? 0 : 1
              }}
            >
              {/* Main Icon with Badge */}
              <div style={{ position: "relative", marginTop: 5 }}>
                <FiberNewIcon
                  style={{
                    fontSize: "25px",
                    color: "#ffffff",
                    background: "green",
                    borderRadius: 60,
                    padding: 2,
                    marginRight: 5,
                  }}
                />
              </div>

              {/* Typography Content */}
              <Typography
                component="div"
                variant="body2"
                style={{
                  textAlign: "left",
                  color: "inherit",
                  fontSize: "13px",
                  paddingTop: "1px",
                  lineHeight: 1.2
                }}
              >
                <span
                  style={{
                    fontWeight: 500,
                  }}
                >
                  Neu erstellen
                </span>
              </Typography>
            </div>
        </div>
      }

      {/*****
       * NOT RECURRING
       * *****/}

      {/* DRAFT */}
      {(selectedClient !== "" && settings.invoicesEmailServer !== "download" && values.sendState !== "sent" && values.sendState !== "scheduled" && openInvoiceDialogAtom.invoiceId !== "") &&
        <div
          className="alert-info"
          style={{
            display: "flex",
            justifyContent: "center",
            borderRadius: 0,
            position: "fixed",
            width: "100%",
            zIndex: "9999",
            left: 0,
            boxShadow: "rgba(84, 94, 165, 0.2) 0px 1px 64px",
            backgroundColor: '#ffffff', // Custom background color
            padding: "5px 20px"
          }}
        >
          {!values.recurring &&
            <div
              style={{
                background: "white",
                borderRadius: 60,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                position: "relative", // Added for badge positioning
                opacity: values.loading ? 0 : 1
              }}
            >
                            {values.originalRef !== undefined &&
                <RepeatIcon
                  style={{
                    fontSize: "14px",
                    color: "rgb(172, 175, 197)",
                    marginRight: 5,
                  }}
                />
              }
              {/* Main Icon with Badge */}
              <div style={{ position: "relative", marginTop: 5 }}>
                <EditIcon
                  style={{
                    fontSize: "21px",
                    color: "#ffffff",
                    background: "rgb(172, 175, 197)",
                    borderRadius: 60,
                    padding: 2,
                    marginRight: 5,
                  }}
                />
                {values.cancelled &&
                  <CancelIcon
                    style={{
                      position: "absolute",
                      top: -7,
                      right: 0,
                      fontSize: 18,
                      color: "rgb(118 118 118)",
                      background: "white",
                      borderRadius: "50%",
                    }}
                  />
                }
              </div>

              {/* Typography Content */}
              <Typography
                component="div"
                variant="body2"
                style={{
                  textAlign: "left",
                  color: "inherit",
                  fontSize: "13px",
                  paddingTop: "1px",
                  lineHeight: 1.2
                }}
              >
                {values.type === "cancellation" && (
                  <span style={{ fontWeight: 700 }}>
                    <span style={{ background: "rgb(255 230 230)", color: "rgb(255, 98, 98)", fontSize: 9, padding: "2px 4px", borderRadius: 60 }}>
                      STORNORECHNUNG
                    </span>
                    <br />
                  </span>
                )}
                {values.cancelled &&
                  <span
                    style={{
                      color: "rgb(118 118 118)",
                      display: "flex",
                      alignItems: "center",
                      fontSize: 9,
                      fontWeight: 700,
                    }}
                  >
                    STORNIERT
                  </span>
                }
                <span
                  style={{
                    fontWeight: 500,
                  }}
                >
                  Entwurf
                </span>
              </Typography>
            </div>
          }

          {values.recurring &&
            <Typography style={{ lineHeight: 1 }}>Diese wiederkehrende Rechnung wird derzeit nicht versendet.</Typography>
          }
        </div>
      }

      {/* SENT */}
      {(selectedClient !== "" && settings.invoicesEmailServer !== "download" && values.sendState === "sent" && values.payState !== "payed" && values.sendState !== "scheduled" && openInvoiceDialogAtom.invoiceId !== "") &&
        <div
          className="alert-info"
          style={{
            display: "flex",
            justifyContent: "center",
            borderRadius: 0,
            position: "fixed",
            width: "100%",
            zIndex: "9999",
            left: 0,
            boxShadow: "rgba(84, 94, 165, 0.2) 0px 1px 64px",
            backgroundColor: '#ffffff', // Custom background color
            padding: "5px 20px",
            opacity: values.loading ? 0 : 1
          }}
        >
          {!values.recurring &&
            <div
              style={{
                background: "white",
                borderRadius: 60,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                position: "relative", // Added for badge positioning
              }}
            >
              {/* Main Icon with Badge */}
              {values.originalRef !== undefined &&
                <RepeatIcon
                  style={{
                    fontSize: "14px",
                    color: "rgb(26, 123, 201)",
                    marginRight: 5,
                  }}
                />
              }
              <div style={{ position: "relative", marginTop: 5 }}>
                <MailIcon
                  style={{
                    fontSize: "21px",
                    color: "#ffffff",
                    background: "rgb(26, 123, 201)",
                    borderRadius: 60,
                    padding: 3,
                    marginRight: 5,
                  }}
                />
                {values.cancelled &&
                  <CancelIcon
                    style={{
                      position: "absolute",
                      top: -7,
                      right: 0,
                      fontSize: 18,
                      color: "rgb(118 118 118)",
                      background: "white",
                      borderRadius: "50%",
                    }}
                  />
                }

                {(!values.cancelled && (RETURN_OVERDUE_DAYS(values.dueDate) > 0)) &&
                  <InfoIcon
                    style={{
                      position: "absolute",
                      top: -7,
                      right: 0,
                      fontSize: 18,
                      color: "rgb(183, 28, 28)",
                      background: "white",
                      borderRadius: "50%",
                    }}
                  />
                }
              </div>

              {/* Typography Content */}
              <Typography
                component="div"
                variant="body2"
                style={{
                  textAlign: "left",
                  color: "inherit",
                  fontSize: "13px",
                  paddingTop: "1px",
                  lineHeight: 1.2
                }}
              >
                {values.type === "cancellation" && (
                  <span style={{ fontWeight: 700 }}>
                    <span style={{ background: "rgb(255 230 230)", color: "rgb(255, 98, 98)", fontSize: 9, padding: "2px 4px", borderRadius: 60 }}>
                      STORNORECHNUNG
                    </span>
                    <br />
                  </span>
                )}
                {values.cancelled &&
                  <span
                    style={{
                      color: "rgb(118, 118, 118)",
                      display: "flex",
                      alignItems: "center",
                      fontSize: 9,
                      fontWeight: 700,
                    }}
                  >
                    STORNIERT
                  </span>
                }

                {!values.cancelled && (RETURN_OVERDUE_DAYS(values.dueDate) > 0) &&
                  <span
                    style={{
                      color: "rgb(183, 28, 28)",
                      display: "flex",
                      alignItems: "center",
                      fontSize: 9,
                      fontWeight: 700,
                    }}
                  >
                    {`${RETURN_OVERDUE_DAYS(values.dueDate)} TAG${RETURN_OVERDUE_DAYS(values.dueDate) > 1 ? "E" : ""} ÜBERFÄLLIG`}
                  </span>
                }
                <span
                  style={{
                    fontWeight: 500,
                  }}
                >
                  {`Gesendet: ${RETURN_DATE(values.sendDate)}`}
                </span>
              </Typography>
            </div>
          }
        </div>
      }

      {/* SCHEDULED */}
      {(selectedClient !== "" && settings.invoicesEmailServer !== "download" && values.sendState === "scheduled" && openInvoiceDialogAtom.invoiceId !== "") &&
        <div
          className="alert-info"
          style={{
            display: "flex",
            justifyContent: "center",
            borderRadius: 0,
            position: "fixed",
            width: "100%",
            zIndex: "9999",
            left: 0,
            boxShadow: "rgba(84, 94, 165, 0.2) 0px 1px 64px",
            backgroundColor: '#ffffff', // Custom background color
            padding: "5px 20px",
            opacity: values.loading ? 0 : 1
          }}
        >
          {!values.recurring &&
            <div
              style={{
                background: "white",
                borderRadius: 60,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                position: "relative", // Added for badge positioning
              }}
            >
              {/* Main Icon with Badge */}
              <div style={{ position: "relative", marginTop: 5 }}>
                <ScheduleIcon
                  style={{
                    fontSize: "21px",
                    color: "#ffffff",
                    background: "rgb(255, 152, 0)",
                    borderRadius: 60,
                    padding: 3,
                    marginRight: 5,
                  }}
                />
              </div>

              {/* Typography Content */}
              <Typography
                component="div"
                variant="body2"
                style={{
                  textAlign: "left",
                  color: "inherit",
                  fontSize: "13px",
                  paddingTop: "1px",
                  lineHeight: 1.2
                }}
              >
                {values.type === "cancellation" && (
                  <span style={{ fontWeight: 700 }}>
                    <span style={{ background: "rgb(255 230 230)", color: "rgb(255, 98, 98)", fontSize: 9, padding: "2px 4px", borderRadius: 60 }}>
                      STORNORECHNUNG
                    </span>
                    <br />
                  </span>
                )}
                <span
                  style={{
                    fontWeight: 500,
                  }}
                >
                  {`Geplant: ${RETURN_DATE(values.sendDate)} | ${RETURN_HOURS(values.sendDate)}`}
                </span>
              </Typography>
            </div>
          }
        </div>
      }

      {/* PAYED */}
      {(!openInvoiceDialogAtom.isEstimate && selectedClient !== "" && values.payState === "payed" && openInvoiceDialogAtom.invoiceId !== "") &&
        <div
          className="alert-info"
          style={{
            display: "flex",
            justifyContent: "center",
            borderRadius: 0,
            position: "fixed",
            width: "100%",
            zIndex: "9999",
            left: 0,
            boxShadow: "rgba(84, 94, 165, 0.2) 0px 1px 64px",
            backgroundColor: '#ffffff', // Custom background color
            padding: "5px 20px",
            opacity: values.loading ? 0 : 1
          }}
        >
          {!values.recurring &&
            <div
              style={{
                background: "white",
                borderRadius: 60,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                position: "relative", // Added for badge positioning
              }}
            >
              {/* Main Icon with Badge */}
              {values.originalRef !== undefined &&
                <RepeatIcon
                  style={{
                    fontSize: "14px",
                    color: "rgb(76, 175, 80)",
                    marginRight: 5,
                  }}
                />
              }
              <div style={{ position: "relative", marginTop: 5 }}>
                <CheckIcon
                  style={{
                    fontSize: "21px",
                    color: "#ffffff",
                    background: "rgb(76, 175, 80)",
                    borderRadius: 60,
                    padding: 2,
                    marginRight: 5,
                  }}
                />
                {values.cancelled &&
                  <CancelIcon
                    style={{
                      position: "absolute",
                      top: -7,
                      right: 0,
                      fontSize: 18,
                      color: "rgb(118 118 118)",
                      background: "white",
                      borderRadius: "50%",
                    }}
                  />
                }
              </div>

              {/* Typography Content */}
              <Typography
                component="div"
                variant="body2"
                style={{
                  textAlign: "left",
                  color: "inherit",
                  fontSize: "13px",
                  paddingTop: "1px",
                  lineHeight: 1.2
                }}
              >
                {values.type === "cancellation" && (
                  <span style={{ fontWeight: 700 }}>
                    <span style={{ background: "rgb(255 230 230)", color: "rgb(255, 98, 98)", fontSize: 9, padding: "2px 4px", borderRadius: 60 }}>
                      STORNORECHNUNG
                    </span>
                    <br />
                  </span>
                )}
                {values.cancelled &&
                  <span
                    style={{
                      color: "rgb(118 118 118)",
                      display: "flex",
                      alignItems: "center",
                      fontSize: 9,
                      fontWeight: 700,
                    }}
                  >
                    {(values.cancelledRef !== undefined && values.type !== "cancellation") ? "STORNIERT MIT STORNORECHNUNG" : "STORNIERt"}
                  </span>
                }
                <span
                  style={{
                    fontWeight: 500,
                  }}
                >
                  {`Bezahlt: ${RETURN_DATE(values.payDate)}`}
                </span>
              </Typography>
            </div>
          }
        </div>
      }


      {/*****
       * RECURRING
       * *****/}

      {/* DRAFT */}
      {(values.recurring && values.sendState !== "sent" && values.sendState !== "scheduled" && openInvoiceDialogAtom.invoiceId !== "") &&
        <div
          className="alert-info"
          style={{
            display: "flex",
            justifyContent: "center",
            borderRadius: 0,
            position: "fixed",
            width: "100%",
            zIndex: "9999",
            left: 0,
            boxShadow: "rgba(84, 94, 165, 0.2) 0px 1px 64px",
            backgroundColor: '#ffffff', // Custom background color
            padding: "5px 20px"
          }}
        >
          <div
            style={{
              background: "white",
              borderRadius: 60,
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              position: "relative", // Added for badge positioning
              opacity: values.loading ? 0 : 1
            }}
          >
            {/* Main Icon with Badge */}
            <div style={{ position: "relative", marginTop: 5 }}>
              <EditIcon
                style={{
                  fontSize: "21px",
                  color: "#ffffff",
                  background: "rgb(172, 175, 197)",
                  borderRadius: 60,
                  padding: 2,
                  marginRight: 5,
                }}
              />
            </div>
            <Typography
              component="div"
              variant="body2"
              style={{
                textAlign: "left",
                color: "inherit",
                fontSize: "13px",
                paddingTop: "1px",
                lineHeight: 1.2
              }}
            >
              <span
                style={{
                  fontWeight: 500,
                }}
              >
                Entwurf
              </span>
            </Typography>
          </div>
        </div>
      }

      {/* Sent SOME (Paused)*/}
      {(values.recurringRef.length !== values.recurringStopNumber && values.sendState === "notSent" && values.recurringRef.length !== 0 && values.payState === "notPayed") &&
        <div
          className="alert-info"
          style={{
            display: "flex",
            justifyContent: "center",
            borderRadius: 0,
            position: "fixed",
            width: "100%",
            zIndex: "9999",
            left: 0,
            boxShadow: "rgba(84, 94, 165, 0.2) 0px 1px 64px",
            backgroundColor: '#ffffff', // Custom background color
            padding: "5px 20px"
          }}
        >
          <div
            style={{
              background: "white",
              borderRadius: 60,
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              position: "relative", // Added for badge positioning
              opacity: values.loading ? 0 : 1
            }}
          >
            {/* Main Icon with Badge */}
            <div style={{ position: "relative", marginTop: 5 }}>
              <PauseCircleFilledIcon
                style={{
                  fontSize: "21px",
                  color: "#ffffff",
                  background: "rgb(172, 175, 197)",
                  borderRadius: 60,
                  padding: 2,
                  marginRight: 5,
                }}
              />
            </div>
            <Typography
              component="div"
              variant="body2"
              style={{
                textAlign: "left",
                color: "inherit",
                fontSize: "13px",
                paddingTop: "1px",
                lineHeight: 1.2
              }}
            >
              <span
                style={{
                  fontWeight: 500,
                }}
              >
                Pausiert
              </span>
            </Typography>
          </div>
        </div>
      }

      {/* SENT / INACTIVE */}
      {(values.recurring && values.sendState === "sent" && values.payState !== "payed" && values.sendState !== "scheduled" && openInvoiceDialogAtom.invoiceId !== "") &&
        <div
          className="alert-info"
          style={{
            display: "flex",
            justifyContent: "center",
            borderRadius: 0,
            position: "fixed",
            width: "100%",
            zIndex: "9999",
            left: 0,
            boxShadow: "rgba(84, 94, 165, 0.2) 0px 1px 64px",
            backgroundColor: '#ffffff', // Custom background color
            padding: "5px 20px",
            opacity: values.loading ? 0 : 1
          }}
        >
          <div
            style={{
              background: "#ffffff",
              padding: "0px",
              borderRadius: 60,
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: 5,

            }}
          >
            {/* Mail Icon with Badge */}
            <div style={{ position: "relative", display: "inline-block" }}>
              <MailIcon
                style={{
                  fontSize: "23px",
                  color: "#ffffff",
                  background: "rgb(26, 123, 201)",
                  borderRadius: 60,
                  padding: 4,
                  marginRight: 5,
                }}
              />
              {values.sendState === "sent" && (
                <CheckCircleIcon
                  style={{
                    position: "absolute",
                    top: -9,
                    right: -0,
                    fontSize: 18,
                    color: "rgb(26, 123, 201)",
                    background: "white",
                    borderRadius: "50%",
                  }}
                />
              )}
            </div>

            {/* Typography Content */}
            <Typography
              component="div"
              variant="body2"
              style={{
                textAlign: "left",
                fontSize: "13px",
                color: "inherit",
                paddingTop: "1px",
                marginTop: "-11px"
              }}
            >
              <span style={{ fontWeight: "500" }}>
                <span
                  style={{
                    color: "rgb(26, 123, 201)",
                    fontSize: 9,
                  }}
                >
                  BEENDET
                </span>
                <br />
                Versand beendet
              </span>
            </Typography>
          </div>
        </div>
      }

      {/* SCHEDULED / ACTIVE */}
      {(values.recurring && values.sendState === "scheduled" && openInvoiceDialogAtom.invoiceId !== "") &&
        <div
          className="alert-info"
          style={{
            display: "flex",
            justifyContent: "center",
            borderRadius: 0,
            position: "fixed",
            width: "100%",
            zIndex: "9999",
            left: 0,
            boxShadow: "rgba(84, 94, 165, 0.2) 0px 1px 64px",
            backgroundColor: '#ffffff', // Custom background color
            padding: "3px 20px",
            opacity: values.loading ? 0 : 1
          }}
        >
          <div
            style={{
              background: "#ffffff",
              borderRadius: 60,
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              position: "relative", // Added for badge positioning
            }}
          >
            {/* Schedule Icon with Badge */}
            <div style={{ position: "relative", display: "inline-block", marginTop: 10 }}>
              <ScheduleIcon
                style={{
                  fontSize: "22px",
                  color: "#ffffff",
                  background: "rgb(255, 183, 77)",
                  borderRadius: 60,
                  marginRight: 5,
                }}
              />
              {values.sendState === "scheduled" && (
                <PlayCircleFilledIcon
                  style={{
                    position: "absolute",
                    top: -10,
                    right: -0,
                    fontSize: 18,
                    color: "rgb(76, 175, 80)",
                    background: "white",
                    borderRadius: "50%",
                  }}
                />
              )}
            </div>

            {/* Typography Content */}
            <Typography
              component="div"
              variant="body2"
              style={{
                textAlign: "left",
                fontSize: "13px",
                color: "inherit",
                paddingTop: "1px",
              }}
            >
              <span style={{ fontWeight: "500" }}>
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <span style={{
                                        color: "rgb(76, 175, 80)",
                                        fontSize: 9,
                  }}>AKTIV</span>
                  <span>Nächster Versand: {`${RETURN_DATE(values.sendDate)} | ${RETURN_HOURS(values.sendDate)}`}</span>
                </span>
              </span>
            </Typography>
          </div>
        </div>
      }


      {/* {(!openInvoiceDialogAtom.isEstimate && selectedClient !== "" && values.payState === "payed" && openInvoiceDialogAtom.invoiceId !== "") &&
        <Alert severity="success" style={{
          justifyContent: "center",
          // minHeight: "70px",
          borderRadius: 0, position: "fixed", top: "60px", width: "100%", zIndex: "9999", left: 0, boxShadow: "rgba(84, 94, 165, 0.2) 0px 1px 64px"
        }}>
          <Typography style={{ lineHeight: 1 }}>{t("Diese Rechung wurde bezahlt.")}</Typography>
        </Alert>
      } */}

      {/* {(selectedClient !== "" && settings.invoicesEmailServer !== "download" && values.sendState === "scheduled" && openInvoiceDialogAtom.invoiceId !== "") &&
        <Alert severity="warning" style={{ justifyContent: "center", minHeight: "70px", borderRadius: 0, position: "fixed", top: "60px", width: "100%", zIndex: "9999", left: 0, boxShadow: "rgba(84, 94, 165, 0.2) 0px 1px 64px" }}>
          {!values.recurring &&
            <Typography style={{ lineHeight: 1 }}>{t("Wird am")} <span style={{ fontWeight: 500 }}>{`${RETURN_DATE(values.sendDate)} um ${RETURN_HOURS(values.sendDate)}`}</span> {t("gesendet.")}</Typography>
          }

          {values.recurring &&
            <Typography style={{ lineHeight: 1 }}>Nächster Versand am <span style={{ fontWeight: 500 }}>{`${RETURN_DATE(values.sendDate)} um ${RETURN_HOURS(values.sendDate)}`}</span></Typography>
          }

          <Button color="primary" style={{ background: "white", marginLeft: "5px", lineHeight: "1.2", fontSize: "12px" }}
            onClick={(e) => {
              handleCancelSend();
              setShowConfirmModal(true);
              setValues({ 
                ...values, 
                sendState: values.sentBlobs.length === 0 ? "notSent" : "sent", 
                sendDate: values.sentBlobs.length === 0 ? values.sendDate : JSON.parse(values.sentBlobs[values.sentBlobs.length - 1]).sendDate 
              });
            }}
          >{t("Versand abbrechen")}</Button>
        </Alert>
      } */}
    </>
  );
};

export default InvoiceAlerts;

import React, { useContext } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import Alert from "@material-ui/lab/Alert";
import { useTranslation } from 'react-i18next';
import { RETURN_OVERDUE_DAYS, RETURN_DATE, RETURN_HOURS } from '../../../_functions/index'; // adjust the path accordingly
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from '../../../shared/context/auth-context';
import { useAuth } from "../../../shared/hooks/auth-hook";
import { Info } from '@material-ui/icons';

import ClientsIcon from "@material-ui/icons/RecentActors";
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import FilterListIcon from '@material-ui/icons/FilterList';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import EditIcon from '@material-ui/icons/Edit';
import ScheduleIcon from '@material-ui/icons/Schedule';
import MailIcon from '@material-ui/icons/Mail';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckIcon from '@material-ui/icons/Check';
import AddIcon from '@material-ui/icons/Add';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import GavelIcon from '@material-ui/icons/Gavel';
import SearchIcon from '@material-ui/icons/Search';
import ErrorIcon from '@material-ui/icons/Error';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import RepeatIcon from '@material-ui/icons/Repeat';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import SubjectIcon from '@material-ui/icons/Subject';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import InfoIcon from '@material-ui/icons/Info';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CancelIcon from '@material-ui/icons/Cancel';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import FiberNewIcon from '@material-ui/icons/FiberNew';

const ClientAlerts = ({ values, openClientDialogAtom }) => {

  return (
    <>
      {/* NEW */}
      {openClientDialogAtom.clientId === "" &&
        <div
          className="alert-info"
          style={{
            display: "flex",
            justifyContent: "center",
            borderRadius: 0,
            position: "fixed",
            width: "100%",
            zIndex: "9999",
            left: 0,
            boxShadow: "rgba(84, 94, 165, 0.2) 0px 1px 64px",
            backgroundColor: '#ffffff',
            padding: "5px 20px"
          }}
        >
            <div
              style={{
                background: "white",
                borderRadius: 60,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                position: "relative", // Added for badge positioning
                opacity: values.loading ? 0 : 1
              }}
            >
              {/* Main Icon with Badge */}
              <div style={{ position: "relative", marginTop: 5 }}>
                <FiberNewIcon
                  style={{
                    fontSize: "25px",
                    color: "#ffffff",
                    background: "green",
                    borderRadius: 60,
                    padding: 2,
                    marginRight: 5,
                  }}
                />
              </div>

              {/* Typography Content */}
              <Typography
                component="div"
                variant="body2"
                style={{
                  textAlign: "left",
                  color: "inherit",
                  fontSize: "13px",
                  paddingTop: "1px",
                  lineHeight: 1.2
                }}
              >
                <span
                  style={{
                    fontWeight: 500,
                  }}
                >
                  Neu erstellen
                </span>
              </Typography>
            </div>
        </div>
      }

      {/* EXISTING */}
      {openClientDialogAtom.clientId !== "" &&
      <div
        className="alert-info"
        style={{
          display: "flex",
          justifyContent: "center",
          borderRadius: 0,
          position: "fixed",
          width: "100%",
          zIndex: "9999",
          left: 0,
          boxShadow: "rgba(84, 94, 165, 0.2) 0px 1px 64px",
          backgroundColor: '#ffffff',
          padding: "5px 20px"
        }}
      >
        <div
          style={{
            background: "white",
            borderRadius: 60,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            position: "relative", // Added for badge positioning
            opacity: values.loading ? 0 : 1
          }}
        >
          {/* Main Icon with Badge */}
          <div style={{ position: "relative", marginTop: 5 }}>
            <FiberManualRecordIcon
              style={{
                fontSize: "20px",
                color:
                  values.status.state === "active" ? "rgb(76, 175, 80)" :
                  values.status.state === "inactive" ?  "rgb(183, 28, 28)" : 
                  "rgb(255, 183, 77)",
                background:
                  values.status.state === "active" ? "rgb(76, 175, 80)" :
                  values.status.state === "inactive" ?  "rgb(183, 28, 28)" : 
                  "rgb(255, 183, 77)",
                borderRadius: 60,
                padding: 2,
                marginRight: 5,
              }}
            />
          </div>

          {/* Typography Content */}
          <Typography
            component="div"
            variant="body2"
            style={{
              textAlign: "left",
              color: "inherit",
              fontSize: "13px",
              paddingTop: "1px",
              lineHeight: 1.2
            }}
          >
            <span
              style={{
                fontWeight: 500,
              }}
            >
              {values.status.state === "active" ? "Aktiv" :
                  values.status.state === "inactive" ?  "Inaktiv" : 
                  "Möglicher Kunde (in Verhandlung)"}
            </span>
          </Typography>
        </div>
      </div>
      }
    </>
  );
};

export default ClientAlerts;

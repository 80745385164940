import React, { useEffect, useState, useContext, useRef } from "react";

// IMPORT hooks & context
import { useTranslation } from "react-i18next";
import { useHttpClient } from "../shared/hooks/http-hook";
import { AuthContext } from "../shared/context/auth-context";

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import userAtomState from "../_atoms/userAtom";
import clientsAtomState from "../_atoms/clientsAtom";
import openInvoiceDialogAtomState from "../_atoms/openInvoiceDialogAtom";
import settingsAtomState from "../_atoms/settingsAtom";
import recurringActivatedAtomState from "../_atoms/recurringActivatedAtom";
import allInvoicesByClientAtomState from "../_atoms/allInvoicesByClientAtom"
import allInvoicesByOriginalRefAtomState from "../_atoms/allInvoicesByOriginalRefAtom";

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

// IMPORT components
import { useSnackbar } from 'notistack';

// IMPORT components MATERIAL UI
import {
  Typography,
  Box,
  Container,
  Grid,
  Tooltip,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Hidden from '@material-ui/core/Hidden';

// IMPORT icons MATERIAL UI
import ClientsIcon from "@material-ui/icons/RecentActors";
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import FilterListIcon from '@material-ui/icons/FilterList';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import EditIcon from '@material-ui/icons/Edit';
import ScheduleIcon from '@material-ui/icons/Schedule';
import MailIcon from '@material-ui/icons/Mail';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AddIcon from '@material-ui/icons/Add';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import GavelIcon from '@material-ui/icons/Gavel';
import SearchIcon from '@material-ui/icons/Search';
import ErrorIcon from '@material-ui/icons/Error';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import RepeatIcon from '@material-ui/icons/Repeat';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import SubjectIcon from '@material-ui/icons/Subject';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import InfoIcon from '@material-ui/icons/Info';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CancelIcon from '@material-ui/icons/Cancel';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import CheckIcon from '@material-ui/icons/Check';


import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Popover from '@material-ui/core/Popover';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { de } from 'date-fns/locale';

// IMPORT own functions
import { SET_VALUE } from '../_functions/SET_VALUE';
import { DB_PATCH_SETTINGS } from '../_functions/DB_SETTINGS';
import { RETURN_DATE } from '../_functions/DATES';
import { RETURN_HOURS } from '../_functions/DATES';
import { RETURN_OVERDUE_DAYS } from '../_functions/DATES';

// IMPORT own components
import UserProfileIncompleteWarning from "../user/components/UserProfileIncompleteWarning";
import InvoicesSearch from "./components/InvoicesSearch";
import InvoicesFilter from "./components/InvoicesFilter";
import InvoicesSort from "./components/InvoicesSort";
import InvoicesSettings from "./components/InvoicesSettings";
import InvoicesMenuDots from "./components/InvoicesMenuDots";
import LogoMonogram from './../_assets/logo_monogram.svg';
import DateRange from "../shared/components/DateRange/DateRange";
import RecurringRefDialog from './components/RecurringRefDialog'

// IMPORT own CSS
import "./Invoices.scss"
import { Euro, FlashOnTwoTone } from "@material-ui/icons";

// Ant and moment
import { ConfigProvider, DatePicker, TimePicker, Space } from 'antd';
import deDE from 'antd/es/locale/de_DE';
import dayjs from 'dayjs';
import 'dayjs/locale/de';

dayjs.locale('de'); // Set German locale for dayjs

const useStyles = makeStyles((theme) => ({
  // necessary for content to be below app bar
  toolbar: {
    height: '0px',
    '@media (max-width: 959px)': {
      minHeight: '45px',
      height: '45px',
    },
    '@media (max-width: 599px)': {
      minHeight: '45px',
      height: '45px',
    },
    '@media (max-width: 455px)': {
      minHeight: '45px',
      height: '45px',
    },
  },
  table: {
    minWidth: 650,
  },
}));

const Invoices = ({ type }) => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { isLoading, error, sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);


  // GLOBAL STATE (RECOIL)
  const [userAtom] = useRecoilState(userAtomState);
  const [openInvoiceDialogAtom, setOpenInvoiceDialogAtom] = useRecoilState(openInvoiceDialogAtomState);
  const [clientsAtom, setClientsAtom] = useRecoilState(clientsAtomState);
  const [recurringActivatedAtom, setRecurringActivatedAtom] = useRecoilState(recurringActivatedAtomState);
  const [settingsAtom, setSettingsAtom] = useRecoilState(settingsAtomState);
  const [allInvoicesByClientAtom, setAllInvoicesByClientAtom] = useRecoilState(allInvoicesByClientAtomState);
  const [allInvoicesByOriginalRefAtom, setAllInvoicesByOriginalRefAtom] = useRecoilState(allInvoicesByOriginalRefAtomState);


  // LOCAL STATE (GLOBAL DEPENDENCY monitoring temporay CHANGES until SAVED)
  const [settings, setSettings] = useState(settingsAtom);

  // LOCAL STATE (PURE)
  const [recurring, setRecurring] = useState(false);
  const [values, setValues] = useState({
    submenuOpen: false, // values: false, search, filter, sort, settings
    invoicesFiltered: {},
    searchInput: "",
    chipDirectChange: false,
    save: false,
    loading: true,
  });
  const [recurringRef, setRecurringRef] = useState([]);
  const [recurringRefDialogOpen, setRecurringRefDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  // Data variable
  const [dataInitial, setDataInitial] = useState([]);

  // Infinite Scroll Variables
  const INITIAL_ITEMS = 20; // Load 10 items initially
  const [offset, setOffset] = useState(0); // Track the current offset for fetching
  const [hasMoreInvoices, setHasMoreInvoices] = useState(true); // To know if more invoices exist
  const [isFetching, setIsFetching] = useState(false); // Track if a fetch is in progress

  // Search Variables
  const [searchTerm, setSearchTerm] = useState(
    allInvoicesByClientAtom !== "" ? `${allInvoicesByClientAtom}` :
    allInvoicesByOriginalRefAtom !== "" ? `${allInvoicesByOriginalRefAtom}` :
    ""
  );

  /***
  FETCH
  ***/
  const fetchData = async (currentOffset, limit, isInitialLoad = false, searchTerm = "", newRecurring) => {
    try {
      const storedUserData = localStorage.getItem('userData');
      if (!storedUserData) {
        throw new Error('No user data found in local storage');
      }

      const resUser = JSON.parse(storedUserData);

      setIsFetching(true); // Set fetching flag to true

      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL_API}/invoices/user/${resUser.userId}?limit=${limit}&offset=${currentOffset}&searchTerm=${searchTerm}&sortField=${settingsAtom.invoicesSortField}&sortOrder=${settingsAtom.invoicesSortOrder}&recurring=${JSON.stringify(newRecurring)}`,
        'GET',
        null,
        { Authorization: 'Bearer ' + resUser.token }
      );

      if (response && response.invoices) {
        if (response.invoices.length < limit) {
          setHasMoreInvoices(false); // No more invoices to fetch
        }

        if (isInitialLoad) {
          setDataInitial(response.invoices); // Replace existing data for initial load
        } else {
          // Append new invoices, making sure no duplicates
          setDataInitial(prevInvoices => [
            ...prevInvoices,
            ...response.invoices,
          ]);
        }
      }

      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);
      console.error('Error fetching invoices:', err);
    }
  };

  /***
  INITIAL LOADING
  ***/
  useEffect(() => {
    // Initial load when the page loads or search term/recurring changes
    setOffset(0); // Reset offset
    fetchData(0, INITIAL_ITEMS, true, searchTerm); // Fetch the first 5 invoices with updated recurring value
  }, [searchTerm]); // Trigger on recurring or search term change

  // Store scroll position before refetching
  const [scrollPos, setScrollPos] = useState(0);

  // Save scroll position before dialog opens
  useEffect(() => {
    if (openInvoiceDialogAtom.open) {
      setScrollPos(window.scrollY); // Save current scroll position
    }
  }, [openInvoiceDialogAtom.open]);

  // Refetch all invoices and restore scroll position when the dialog closes
  useEffect(() => {
    if (!openInvoiceDialogAtom.open) {
      fetchData(0, dataInitial.length, true, searchTerm); // Refetch all data to ensure updates
      setTimeout(() => {
        window.scrollTo(0, scrollPos); // Restore scroll position
      }, 0);
    }
  }, [openInvoiceDialogAtom.open]);

  // Load more invoices when the user clicks the "Load More" button
  const loadMoreInvoices = () => {
    if (!isFetching && hasMoreInvoices) {
      const limit = INITIAL_ITEMS;
      const newOffset = offset + limit;
      setOffset(newOffset); // Update the offset
      fetchData(newOffset, limit, false, searchTerm); // Fetch more data based on new offset
    }
  };

  /***
  SEARCH
  ***/
  // Handle search input
  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    // Scroll to top when the search term is updated
    window.scrollTo({
      top: 0,
    });

    if (term !== "") {
      // Add logic here to filter invoices or trigger a new fetch based on the search term
    } else {
      handleSearchClear(); // Clear search when input is empty
    }
  };

  const handleSearchClear = () => {
    window.scrollTo({
      top: 0,
    });

    setSearchTerm("");
    setDataInitial([]);
    setOffset(0); // Reset offset for new search
    setHasMoreInvoices(true); // Allow more invoices to load

    // Use setTimeout to wait for the state updates to complete
    setTimeout(() => {
      fetchData(0, INITIAL_ITEMS, true, ""); // Fetch the first 5 invoices with cleared search term and respect recurring state
    }, 0);
  };

  // Trigger fetch with search term when the user types
  useEffect(() => {
    if (searchTerm !== '') {
      setOffset(0); // Reset offset for new search
      fetchData(0, INITIAL_ITEMS, true, searchTerm); // Pass recurring state along with search term
      setHasMoreInvoices(true); // Allow more invoices to load
    } else {
      // If search is cleared, load initial invoices again respecting the recurring state
      fetchData(0, INITIAL_ITEMS, true, "");
    }
  }, [searchTerm]); // Also listen for recurring state changes

  /***
  CHANGE SETTINGS
  ***/
  const handleChange = (e) => {
    SET_VALUE(e, setSettings);
  };

  // ON SAVE: 1) Update settingsAtom
  const updateSettingsHandler = () => {
    // GLOBAL changes
    // setSettingsAtom(settings);

    setSettingsAtom((prevSettings) => {
      const { dateRangeStartValue, dateRangeEndValue, ...restPrevSettings } = prevSettings;

      return {
        ...restPrevSettings, // Update all other fields
        ...settings,         // Apply new settings
        dateRangeStartValue, // Keep these fields unchanged, because they are not change in settings but in settingsAtom
        dateRangeEndValue,
      };
    });

    // Save variable is only needed to trigger submenuClose when NOTHING changes in settings and save button is clicked
    SET_VALUE({ target: { id: "save", value: true } }, setValues);

    setDataInitial([]);
    setOffset(0); // Reset offset for new search
    setHasMoreInvoices(true); // Allow more invoices to load on scroll
    window.scrollTo({
      top: 0,
    });
  };

  // ON SAVE: 2) DB: PATCH SETTINGS
  useEffect(() => {
    if (auth) {
      DB_PATCH_SETTINGS(settingsAtom, auth, sendRequest, enqueueSnackbar, t, false);

      SET_VALUE({
        target: {
          "submenuOpen": false,
          "chipDirectChange": false,
          "save": false,
        }
      }, setValues, "multi")
    }
  }, [auth.token, settingsAtom, values.save]);

  // ON CANCEL:
  const cancelSettingsHandler = () => {
    // Re-set original values
    setSettings(settingsAtom);
    SET_VALUE({ target: { id: "submenuOpen", value: false } }, setValues);
  };

  useEffect(() => {
    setDataInitial([]);
    setOffset(0); // Reset offset for new search
    setHasMoreInvoices(true); // Allow more invoices to load on scroll
    fetchData(0, INITIAL_ITEMS, true, searchTerm); // Fetch filtered data with cleared search term
  }, [settingsAtom]);






  const [payMenuAnchorEl, setPayMenuAnchorEl] = useState(null);
  const [payMenuValues, setPayMenuValues] = useState({
    payDate: new Date().toString(),
    payState: 'payed',
  });

  const openPayMenu = (event) => {
    event.stopPropagation();
    setPayMenuAnchorEl(event.currentTarget);
  };

  const closePayMenu = () => {
    setPayMenuAnchorEl(null);
  };

  const handlePayMenuSave = () => {
    closePayMenu();
  };

  const isPayMenuOpen = Boolean(payMenuAnchorEl);

  const activeFilterCount = [
    settings.invoicesFiltersStatusDraft,
    settings.invoicesFiltersStatusScheduled,
    settings.invoicesFiltersStatusSent,
    settings.invoicesFiltersStatusPayed,
    settings.invoicesFiltersStatusOverdue,
  ].filter(Boolean).length;


  // const onChange = (date, dateString) => {
  //   console.log(date, dateString);
  // };


  return (
    <div>
    {/* <ConfigProvider locale={deDE}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
        <label htmlFor="date-picker" style={{ fontWeight: 'bold', marginBottom: '4px' }}>
          Versanddatum und -zeit
        </label>
        <DatePicker
          id="date-picker"
          format="DD/MM/YYYY HH:mm"
          showTime={{ format: 'HH:mm' }}
          onChange={onChange}
          style={{ width: '100%' }}
        />
      </div>
    </ConfigProvider> */}


      <div className={classes.toolbar} />
      
      <div className="mobile-menu-section" style={{ borderBottom: "1px solid rgb(237, 239, 255)", position: "sticky", top: 47, zIndex: 10, width: "100%", padding: "2px 4px" }}>
        <DateRange
          type={type === "invoices" ? "invoices" : "estimates"}
          mobile={true}
        />

        <InvoicesFilter
          settings={settings}
          settingsAtom={settingsAtom}
          handleChange={handleChange}
          cancelSettingsHandler={cancelSettingsHandler}
          updateSettingsHandler={updateSettingsHandler}
          setValues={setValues}
          SET_VALUE={SET_VALUE}
          setSettings={setSettings}
          values={values}
          type={type}
          recurring={recurring}
          setOffset={setOffset}
          fetchData={fetchData}
          INITIAL_ITEMS={INITIAL_ITEMS}
          searchTerm={searchTerm}
          setHasMoreInvoices={setHasMoreInvoices}
          setSettingsAtom={setSettingsAtom}
          mobile={true}
        />

        <InvoicesSort
          settings={settings}
          handleChange={handleChange}
          cancelSettingsHandler={cancelSettingsHandler}
          updateSettingsHandler={updateSettingsHandler}
          setValues={setValues}
          SET_VALUE={SET_VALUE}
          setSettings={setSettings}
          values={values}
          type={type}
          recurring={false}
          mobile={true}
        />

        <InvoicesSettings
          settings={settings}
          handleChange={handleChange}
          cancelSettingsHandler={cancelSettingsHandler}
          updateSettingsHandler={updateSettingsHandler}
          setValues={setValues}
          SET_VALUE={SET_VALUE}
          setSettings={setSettings}
          values={values}
        />
      </div>

      <div className="not-mobile-menu-section" style={{ position: "sticky", top: 0, zIndex: 9, width: "100%" }}>
        <Grid container className="subpage-header" item direction="row" alignItems="center" alignContent="center" style={{ paddingBottom: 0 }}>
          <Grid className="main-headline-wrapper" item container direction="row" alignItems="center" style={{ marginRight: "10px", marginBottom: 10 }}>
            <Grid item >
              <Grid item container alignItems="center">
                {type === "invoices" ?
                  <LibraryBooksIcon color="primary" fontSize="large" style={{ width: 30, height: 30, background: "white", marginRight: "15px" }} />
                  : <GavelIcon color="primary" fontSize="large" style={{ width: 30, height: 30, background: "linear-gradient(to right top, rgb(38, 108, 189), rgb(36, 115, 192), rgb(33, 150, 243), #00bca7, #00bf72)", marginRight: "15px" }} className="icon--with-bg--1" />
                }
                <Typography variant="body1" component="div" style={{ fontSize: 18, fontWeight: "700" }} className="headline">Einzelrechnungen</Typography>

                {(!values.loading || !isLoading) && userAtom.data.signupCompleted && (userAtom.clientscreated || userAtom.data.clients.length !== 0) && !isFetching &&
                <Hidden smDown>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<AddCircleIcon className="MuiSvgIcon-root" style={{ fontSize: "small" }} />}
                    className="add-button" // Add the custom class
                    onClick={
                      type === "invoices"
                        ? () =>
                          setOpenInvoiceDialogAtom({
                            ...openInvoiceDialogAtom,
                            open: true,
                            isEstimate: false,
                            recurring: false,
                          })
                        : () =>
                          setOpenInvoiceDialogAtom({
                            ...openInvoiceDialogAtom,
                            open: true,
                            isEstimate: true,
                            recurring: false,
                          })
                    }
                  >
                    Erstellen
                  </Button>
                </Hidden>
                }
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            container
            alignItems="center"
            style={{ display: "flex", height: "48px" }} // Set consistent height for all children
          >
            <DateRange
              type={type === "invoices" ? "invoices" : "estimates"}
            />

            <InvoicesSearch
              handleSearchClear={handleSearchClear}
              handleSearch={handleSearch}
              type={type}
              searchTerm={searchTerm}
              allInvoicesByClientAtom={allInvoicesByClientAtom}
              setAllInvoicesByClientAtom={setAllInvoicesByClientAtom}
              allInvoicesByOriginalRefAtom={allInvoicesByOriginalRefAtomState}
              setAllInvoicesByOriginalRefAtom={setAllInvoicesByOriginalRefAtom}
            />

            <InvoicesSettings
              settings={settings}
              handleChange={handleChange}
              cancelSettingsHandler={cancelSettingsHandler}
              updateSettingsHandler={updateSettingsHandler}
              setValues={setValues}
              SET_VALUE={SET_VALUE}
              setSettings={setSettings}
              values={values}
            />
            {/* <InvoicesSettings
              settings={settings}
              handleChange={handleChange}
              cancelSettingsHandler={cancelSettingsHandler}
              updateSettingsHandler={updateSettingsHandler}
              setValues={setValues}
              SET_VALUE={SET_VALUE}
              setSettings={setSettings}
              values={values}
            /> */}
          </Grid>
        </Grid>

        <Grid container item xs={12} alignItems="center" className="chips" style={{ borderBottom: "1px solid rgb(237, 239, 255)" }}>
          <InvoicesFilter
            settings={settings}
            settingsAtom={settingsAtom}
            handleChange={handleChange}
            cancelSettingsHandler={cancelSettingsHandler}
            updateSettingsHandler={updateSettingsHandler}
            setValues={setValues}
            SET_VALUE={SET_VALUE}
            setSettings={setSettings}
            values={values}
            type={type}
            recurring={recurring}
            setOffset={setOffset}
            fetchData={fetchData}
            INITIAL_ITEMS={INITIAL_ITEMS}
            searchTerm={searchTerm}
            setHasMoreInvoices={setHasMoreInvoices}
            setSettingsAtom={setSettingsAtom}
          />

          <InvoicesSort
            settings={settings}
            handleChange={handleChange}
            cancelSettingsHandler={cancelSettingsHandler}
            updateSettingsHandler={updateSettingsHandler}
            setValues={setValues}
            SET_VALUE={SET_VALUE}
            setSettings={setSettings}
            values={values}
            type={type}
            recurring={false}
          />
        </Grid>
      </div>

      {userAtom.data.signupCompleted &&
        <React.Fragment>
          <Container maxWidth={false} disableGutters className="bg--1" style={{ paddingBottom: 70 }}>
            <Box
              display="flex"
              direction="column"
              className="list-box"
            >
              <Grid container item xs={12} >
                <div className="box-shadow--1 invoices-list">
                  <Grid
                    item
                    className="sticky-nav box-shadow--1 h-padding--5-5 tool-menu"
                    style={{ top: 149 }}
                  >
                    <Grid container>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        item
                        xs={12}
                      >
                        <Grid container item xs={12} justifyContent="space-between">

                          <Grid xs={12} lg={2} container item justifyContent="flex-start" alignItems="center" style={{ paddingLeft: 15 }}>
                            <Typography style={{marginRight: 5}}>Status</Typography>
                            {/* {activeFilterCount > 0 && (
                              <div
                                style={{
                                  // position: "absolute",
                                  border: "1px solid rgb(237, 239, 255)", // Badge background color
                                  padding: 5,
                                  borderRadius: 6,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  fontSize: "10px", // Font size
                                }}
                              >
                                <FilterListIcon style={{ borderRadius: 6, background: "rgb(237, 239, 255)", color: theme.palette.primary.main, zoom: 0.65, marginLeft: 5, marginRight: 5, marginTop: -3 }} />{activeFilterCount} Filter gesetzt
                              </div>
                            )} */}
                          </Grid>

                          <Grid xs={12} lg={2}
                            container
                            item
                            justifyContent="flex-start"
                            alignItems="center"
                            alignContent="center"
                            className="table-headline"
                            style={{ background: settings.invoicesSortField === "invoiceCompany" && "rgb(237, 239, 255)", borderRadius: 6 }}
                            onClick={() => {
                              setSettings({ ...settings, invoicesSortField: "invoiceCompany", invoicesSortOrder: settings.invoicesSortOrder === "asc" ? "desc" : "asc" });
                              setSettingsAtom({ ...settingsAtom, invoicesSortField: "invoiceCompany", invoicesSortOrder: settingsAtom.invoicesSortOrder === "asc" ? "desc" : "asc" })
                            }}
                          >
                            <div style={{ display: "flex", alignItems: "flex-start" }}>
                              <Typography style={{ paddingLeft: 15 }}>Kunde</Typography>
                              {settingsAtom.invoicesSortField === "invoiceCompany" && settings.invoicesSortOrder === "desc" ? <ArrowDownwardIcon style={{ zoom: 0.6, marginLeft: 5 }} /> : ""}
                              {settingsAtom.invoicesSortField === "invoiceCompany" && settings.invoicesSortOrder === "asc" ? <ArrowUpwardIcon style={{ zoom: 0.6, marginLeft: 5 }} /> : ""}
                            </div>
                          </Grid>

                          <Grid xs={12} lg={8} container item justifyContent="flex-start" alignItems="center" >
                            <Grid xs={4} md={2}
                              container
                              item
                              justifyContent="center"
                              alignItems="center"
                              className="table-headline"
                              style={{ background: settings.invoicesSortField === "invoiceNumber" && "rgb(237, 239, 255)" }}
                              onClick={() => {
                                setSettings({ ...settings, invoicesSortField: "invoiceNumber", invoicesSortOrder: settings.invoicesSortOrder === "asc" ? "desc" : "asc" });
                                setSettingsAtom({ ...settingsAtom, invoicesSortField: "invoiceNumber", invoicesSortOrder: settingsAtom.invoicesSortOrder === "asc" ? "desc" : "asc" })
                              }}
                            >
                              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 10px" }}>
                                <Typography
                                  style={{
                                    textAlign: "center",
                                    whiteSpace: "normal",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  Rechnungsnummer
                                </Typography>
                                {settingsAtom.invoicesSortField === "invoiceNumber" && settings.invoicesSortOrder === "desc" && (
                                  <ArrowDownwardIcon style={{ zoom: 0.6, marginLeft: 5 }} />
                                )}
                                {settingsAtom.invoicesSortField === "invoiceNumber" && settings.invoicesSortOrder === "asc" && (
                                  <ArrowUpwardIcon style={{ zoom: 0.6, marginLeft: 5 }} />
                                )}
                              </div>
                            </Grid>

                            <Grid xs={12} md={2}
                              container
                              item
                              justifyContent="center"
                              alignItems="center"
                              className="table-headline"
                              style={{ background: settings.invoicesSortField === "invoiceNet" && "rgb(237, 239, 255)", borderRadius: 6 }}
                              onClick={() => {
                                setSettings({ ...settings, invoicesSortField: "invoiceNet", invoicesSortOrder: settings.invoicesSortOrder === "asc" ? "desc" : "asc" });
                                setSettingsAtom({ ...settingsAtom, invoicesSortField: "invoiceNet", invoicesSortOrder: settingsAtom.invoicesSortOrder === "asc" ? "desc" : "asc" })
                              }}
                            >
                              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 10px" }}>
                                <Typography
                                  style={{
                                    textAlign: "center",
                                    whiteSpace: "normal",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  Netto
                                </Typography>
                                {settingsAtom.invoicesSortField === "invoiceNet" && settings.invoicesSortOrder === "desc" && (
                                  <ArrowDownwardIcon style={{ zoom: 0.6, marginLeft: 5 }} />
                                )}
                                {settingsAtom.invoicesSortField === "invoiceNet" && settings.invoicesSortOrder === "asc" && (
                                  <ArrowUpwardIcon style={{ zoom: 0.6, marginLeft: 5 }} />
                                )}
                              </div>
                            </Grid>

                            <Grid xs={12} md={2}
                              container
                              item
                              justifyContent="center"
                              alignItems="center"
                              className="table-headline"
                              style={{ background: settings.invoicesSortField === "invoiceGross" && "rgb(237, 239, 255)", borderRadius: 6 }}
                              onClick={() => {
                                setSettings({ ...settings, invoicesSortField: "invoiceGross", invoicesSortOrder: settings.invoicesSortOrder === "asc" ? "desc" : "asc" });
                                setSettingsAtom({ ...settingsAtom, invoicesSortField: "invoiceGross", invoicesSortOrder: settingsAtom.invoicesSortOrder === "asc" ? "desc" : "asc" })
                              }}
                            >
                              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 10px" }}>
                                <Typography
                                  style={{
                                    textAlign: "center",
                                    whiteSpace: "normal",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  Brutto
                                </Typography>
                                {settingsAtom.invoicesSortField === "invoiceGross" && settings.invoicesSortOrder === "desc" && (
                                  <ArrowDownwardIcon style={{ zoom: 0.6, marginLeft: 5 }} />
                                )}
                                {settingsAtom.invoicesSortField === "invoiceGross" && settings.invoicesSortOrder === "asc" && (
                                  <ArrowUpwardIcon style={{ zoom: 0.6, marginLeft: 5 }} />
                                )}
                              </div>
                            </Grid>

                            <Grid xs={4} md={2}
                              container
                              item
                              justifyContent="center"
                              alignItems="center"
                              className="table-headline"
                              style={{ background: settings.invoicesSortField === "invoiceDate" && "rgb(237, 239, 255)", borderRadius: 6 }}
                              onClick={() => {
                                setSettings({ ...settings, invoicesSortField: "invoiceDate", invoicesSortOrder: settings.invoicesSortOrder === "asc" ? "desc" : "asc" });
                                setSettingsAtom({ ...settingsAtom, invoicesSortField: "invoiceDate", invoicesSortOrder: settingsAtom.invoicesSortOrder === "asc" ? "desc" : "asc" })
                              }}
                            >
                              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 10px" }}>
                                <Typography
                                  style={{
                                    textAlign: "center",
                                    whiteSpace: "normal",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  Rechnungsdatum
                                </Typography>
                                {settingsAtom.invoicesSortField === "invoiceDate" && settings.invoicesSortOrder === "desc" && (
                                  <ArrowDownwardIcon style={{ zoom: 0.6, marginLeft: 5 }} />
                                )}
                                {settingsAtom.invoicesSortField === "invoiceDate" && settings.invoicesSortOrder === "asc" && (
                                  <ArrowUpwardIcon style={{ zoom: 0.6, marginLeft: 5 }} />
                                )}
                              </div>
                            </Grid>

                            {type === "invoices" &&
                              <Grid xs={4} md={2}
                                container
                                item
                                justifyContent="center"
                                className="table-headline"
                                style={{ background: settings.invoicesSortField === "invoiceDueDate" && "rgb(237, 239, 255)", borderRadius: 6 }}
                                onClick={() => {
                                  setSettings({ ...settings, invoicesSortField: "invoiceDueDate", invoicesSortOrder: settings.invoicesSortOrder === "asc" ? "desc" : "asc" });
                                  setSettingsAtom({ ...settingsAtom, invoicesSortField: "invoiceDueDate", invoicesSortOrder: settingsAtom.invoicesSortOrder === "asc" ? "desc" : "asc" })
                                }}
                              >
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 10px" }}>
                                  <Typography
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    Fälligkeitsdatum
                                  </Typography>
                                  {settingsAtom.invoicesSortField === "invoiceDueDate" && settings.invoicesSortOrder === "desc" && (
                                    <ArrowDownwardIcon style={{ zoom: 0.6, marginLeft: 5 }} />
                                  )}
                                  {settingsAtom.invoicesSortField === "invoiceDueDate" && settings.invoicesSortOrder === "asc" && (
                                    <ArrowUpwardIcon style={{ zoom: 0.6, marginLeft: 5 }} />
                                  )}
                                </div>
                              </Grid>
                            }

                            {/* Optionen */}
                            <Grid container item xs={12} md={2} justifyContent="center"></Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <div style={{ minHeight: "100vh" }}>
                    {/* INVOICES already created */}

                    {dataInitial.length !== 0 && (
                      <>
                        {/* APPLIED FILTER after Loading doesn't result in list of length 0 */}
                        {Object.keys(dataInitial).length !== 0 &&
                          (dataInitial)
                            .map((invoice, i) => (
                              <Grid container key={i}>
                                <Grid
                                  container
                                  alignItems="center"
                                  justifyContent="space-between"
                                  item
                                  xs={12}
                                  className="h-bg--white user-clients"
                                  style={{
                                    borderBottom: "1px solid #e2ecfd",
                                    // background: recurring ? "#fbf8ff" : "inherit",
                                    borderLeft:
                                      (invoice.sendState !== "sent" && invoice.sendState !== "scheduled" && (invoice.payState === "notPayed" || invoice.payState === undefined)) ? `5px solid rgb(172 175 197)` :
                                        (invoice.sendState === "scheduled" && invoice.payState === "notPayed") ? `5px solid ${theme.palette.warning.light}` :
                                          (invoice.sendState === "sent" && invoice.payState === "notPayed") ? `5px solid ${theme.palette.primary.dark}` :
                                            `5px solid ${theme.palette.success.light}`
                                  }}
                                >

                                  <Grid container item xs={12} justifyContent="space-between" className="table-section"
                                    onClick={() => setOpenInvoiceDialogAtom({
                                      ...openInvoiceDialogAtom,
                                      recurring: false,
                                      open: true,
                                      invoiceId: invoice.id,
                                      isEstimate: (invoice.type === "estimate" || invoice.type === "offer") ? true : false
                                    })}
                                  >
                                    {/* First Part using lg 4 */}
                                    {/* Status */}
                                    <Grid xs={12} lg={2}
                                      container
                                      item
                                      alignItems="center"
                                      justifyContent="flex-start"
                                      className="invoice-status"
                                      style={{ padding: "5px 0" }}
                                    >

                                      {/* Draft */}
                                      {(invoice.sendState !== "sent" && invoice.sendState !== "scheduled" &&
                                        (invoice.payState === "notPayed" || invoice.payState === undefined)) && (
                                          <div
                                            style={{
                                              position: "relative",
                                              display: "inline-block",
                                              marginLeft: 5,
                                              marginRight: 5,
                                              width: "100%"
                                            }}
                                          >
                                            {/* Draft Tooltip */}
                                            <Tooltip title="Rechnungsentwurf" arrow>
                                              <div
                                                style={{
                                                  background: "white",
                                                  padding: "10px 10px",
                                                  borderRadius: 60,
                                                  display: "flex",
                                                  justifyContent: "flex-start",
                                                  alignItems: "center",
                                                  position: "relative", // Added for badge positioning
                                                }}
                                              >
                                              {(invoice.originalRef !== undefined) &&
                                                <Tooltip title="Diese Rechnung wurde aufgrund einer wiederkehrenden Rechnung erstellt. Klicken Sie auf diesen Button um die wiederkehrende Rechnung anzuzeigen.">
                                                  <div
                                                    onClick={(e) => {
                                                      e.stopPropagation();
                                                      setOpenInvoiceDialogAtom({ ...openInvoiceDialogAtom, recurring: true, open: true, invoiceId: invoice.originalRef })
                                                    }}
                                                  >
                                                    <div className="recurring-info" style={{ borderRadius: "60px", display: "inline-grid", textAlign: "center", padding: "6.5px 6.5px", fontSize: "12px", marginRight: 2, marginTop: -1 }}>
                                                      <RepeatIcon className="recurring-info-icon" style={{ fontSize: 12 }} />
                                                    </div>
                                                  </div>
                                                </Tooltip>
                                              }

                                                {/* Main Icon with Badge */}
                                                <div style={{ position: "relative", marginTop: 5 }}>
                                                  <EditIcon
                                                    style={{
                                                      fontSize: "21px",
                                                      color: "#ffffff",
                                                      background: "rgb(172, 175, 197)",
                                                      borderRadius: 60,
                                                      padding: 2,
                                                      marginRight: 5,
                                                      opacity: invoice.cancelled ? 0.2 : 1,
                                                    }}
                                                  />
                                                  {invoice.cancelled && (
                                                    <CancelIcon
                                                      style={{
                                                        position: "absolute",
                                                        top: -7,
                                                        right: 0,
                                                        fontSize: 18,
                                                        color: "rgb(118 118 118)",
                                                        background: "white",
                                                        borderRadius: "50%",
                                                      }}
                                                    />
                                                  )}
                                                </div>

                                                {/* Typography Content */}
                                                <Typography
                                                  component="div"
                                                  variant="body2"
                                                  style={{
                                                    textAlign: "left",
                                                    color: "inherit",
                                                    fontSize: "13px",
                                                    paddingTop: "1px",
                                                    lineHeight: 1.2
                                                  }}
                                                >
                                                  {invoice.type === "cancellation" && (
                                                    <span style={{ fontWeight: 700 }}>
                                                      <span style={{ background: "rgb(255 230 230)", color: "rgb(255, 98, 98)", fontSize: 9, padding: "2px 4px", borderRadius: 60 }}>
                                                        STORNORECHNUNG
                                                      </span>
                                                      <br />
                                                      <span>
                                                        <span
                                                          style={{
                                                            marginLeft: 2,
                                                            display: "inline-block",
                                                            width: "100px",
                                                            overflow: "hidden",
                                                            whiteSpace: "nowrap",
                                                            textOverflow: "ellipsis",
                                                            verticalAlign: "middle",
                                                            marginTop: -1,
                                                            fontSize: 9
                                                          }}
                                                        >
                                                          zu R-Nr.: {invoice.cancelledRefInvoiceNr}
                                                        </span>
                                                      </span>
                                                      <br />
                                                    </span>
                                                  )}

                                                  {invoice.cancelled && (
                                                    <>
                                                      <span
                                                        style={{
                                                          color: "rgb(118 118 118)",
                                                          display: "flex",
                                                          alignItems: "center",
                                                          fontSize: 9,
                                                          fontWeight: 700
                                                        }}
                                                      >
                                                        STORNIERT
                                                      </span>
                                                    </>
                                                  )}

                                                  <span style={{
                                                    fontSize: 11,
                                                    fontWeight: invoice.cancelled && invoice.payState === "notPayed"
                                                      ? 500
                                                      : 500,
                                                    opacity: invoice.cancelled && invoice.payState === "notPayed"
                                                      ? 0.2
                                                      : 1,
                                                  }}>
                                                    Entwurf
                                                  </span>
                                                </Typography>
                                              </div>
                                            </Tooltip>
                                          </div>
                                        )}

                                      {/* Scheduled */}
                                      {(invoice.sendState === "scheduled" && invoice.payState === "notPayed") && (
                                        <div style={{ position: "relative", display: "inline-block", marginLeft: 5, marginRight: 5, width: "100%" }}
                                        >
                                          <Tooltip title={`Wird am ${RETURN_DATE(invoice.sendDate)} um ${RETURN_HOURS(invoice.sendDate)} gesendet`} arrow>
                                            <div
                                              style={{
                                                background: "#ffffff",
                                                padding: "10px 10px",
                                                borderRadius: 60,
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                              }}
                                            >
                                              <ScheduleIcon style={{ fontSize: "22px", color: "#ffffff", background: "rgb(255, 183, 77)", borderRadius: 60, marginRight: 5 }} />
                                              <Typography
                                                component="div"
                                                variant="body2"
                                                style={{ textAlign: "left", fontSize: "13px", color: "inherit", paddingTop: "1px" }}
                                              >
                                                {invoice.type === "cancellation" && (
                                                  <span style={{ fontWeight: 700 }}>
                                                    <span style={{ background: "rgb(255 230 230)", color: "rgb(255, 98, 98)", fontSize: 9, padding: "2px 4px", borderRadius: 60 }}>
                                                      STORNORECHNUNG
                                                    </span>
                                                    <br />
                                                    <span>
                                                      <span
                                                        style={{
                                                          marginLeft: 2,
                                                          display: "inline-block",
                                                          width: "100px",
                                                          overflow: "hidden",
                                                          whiteSpace: "nowrap",
                                                          textOverflow: "ellipsis",
                                                          verticalAlign: "middle",
                                                          marginTop: -1,
                                                          fontSize: 9
                                                        }}
                                                      >
                                                        zu R-Nr.: {invoice.cancelledRefInvoiceNr}
                                                      </span>
                                                    </span>
                                                    <br />
                                                  </span>
                                                )}
                                                <span style={{ fontWeight: "500", fontSize: 11 }}>{`Geplant: ${RETURN_DATE(invoice.sendDate)} | ${RETURN_HOURS(invoice.sendDate)}`}</span>
                                              </Typography>
                                            </div>
                                          </Tooltip>
                                        </div>
                                      )}

                                      {/* Sent */}
                                      {(invoice.sendState === "sent" && invoice.payState === "notPayed") && (
                                        <div style={{ position: "relative", display: "inline-block", marginLeft: 5, marginRight: 5, width: "100%" }}>

                                          <Tooltip title={`Wurde am ${RETURN_DATE(invoice.sendDate)} gesendet`} arrow>
                                            <div
                                              style={{
                                                background: "#ffffff",
                                                padding: "10px 10px",
                                                borderRadius: 60,
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                              }}
                                            >

                                              {(invoice.originalRef !== undefined) &&
                                                <Tooltip title="Diese Rechnung wurde aufgrund einer wiederkehrenden Rechnung versendet. Klicken Sie auf diesen Button um die wiederkehrende Rechnung anzuzeigen.">
                                                  <div
                                                    onClick={(e) => {
                                                      e.stopPropagation();
                                                      setOpenInvoiceDialogAtom({ ...openInvoiceDialogAtom, recurring: true, open: true, invoiceId: invoice.originalRef })
                                                    }}
                                                  >
                                                    <div className="recurring-info" style={{ borderRadius: "60px", display: "inline-grid", textAlign: "center", padding: "6.5px 6.5px", fontSize: "12px", marginRight: 2, marginTop: -1 }}>
                                                      <RepeatIcon className="recurring-info-icon" style={{ fontSize: 12 }} />
                                                    </div>
                                                  </div>
                                                </Tooltip>
                                              }
                                              {/* 
                                              {invoice.originalRef !== undefined && (
                                                <RepeatIcon
                                                  style={{
                                                    fontSize: "14px",
                                                    color: "rgb(26, 123, 201)",
                                                    marginRight: 5,
                                                    opacity: invoice.cancelled ? 0.2 : 1,
                                                  }}
                                                />
                                              )} */}

                                              <div style={{ position: "relative", marginTop: 5 }}>
                                                <MailIcon
                                                  style={{
                                                    fontSize: "23px",
                                                    color: "#ffffff",
                                                    background: "rgb(26, 123, 201)",
                                                    borderRadius: 60,
                                                    padding: 4,
                                                    marginRight: 5,
                                                    opacity: invoice.cancelled ? 0.2 : 1,
                                                  }}
                                                />
                                                {invoice.cancelled && (
                                                  <CancelIcon
                                                    style={{
                                                      position: "absolute",
                                                      top: -7,
                                                      right: 0,
                                                      fontSize: 18,
                                                      color: "rgb(118 118 118)",
                                                      background: "white",
                                                      borderRadius: "50%",
                                                      boxShadow: "0 0 4px rgba(0, 0, 0, 0.2)",
                                                    }}
                                                  />
                                                )}
                                                {!invoice.cancelled && (RETURN_OVERDUE_DAYS(invoice.dueDate) > 0 && invoice.payState === "notPayed") && (
                                                  <ErrorIcon
                                                    style={{
                                                      position: "absolute",
                                                      top: -7,
                                                      right: 0,
                                                      fontSize: 18,
                                                      color: "rgb(183, 28, 28)",
                                                      background: "white",
                                                      borderRadius: "50%",
                                                      boxShadow: "0 0 4px rgba(0, 0, 0, 0.2)",
                                                    }}
                                                  />
                                                )}

                                              </div>

                                              {/* Typography Content */}
                                              <Typography
                                                component="div"
                                                variant="body2"
                                                style={{
                                                  textAlign: "left",
                                                  fontSize: "13px",
                                                  color: "inherit",
                                                  paddingTop: "1px",
                                                  lineHeight: 1.2
                                                }}
                                              >

                                                {!invoice.cancelled && (RETURN_OVERDUE_DAYS(invoice.dueDate) > 0 && invoice.payState === "notPayed") && (
                                                  <>
                                                    <span
                                                      style={{
                                                        color: "rgb(183, 28, 28)",
                                                        display: "block",
                                                        fontWeight: 700,
                                                        fontSize: 9,
                                                        textTransform: "uppercase",
                                                        borderRadius: 60,
                                                        marginTop: "-1.5px",
                                                      }}
                                                    >
                                                      {`${RETURN_OVERDUE_DAYS(invoice.dueDate)} Tag${RETURN_OVERDUE_DAYS(invoice.dueDate) > 1 ? "e" : ""} überfällig`}
                                                    </span>
                                                  </>
                                                )}

                                                {invoice.type === "cancellation" && (
                                                  <span style={{ fontWeight: 700 }}>
                                                    <span style={{ background: "rgb(255 230 230)", color: "rgb(255, 98, 98)", fontSize: 9, padding: "2px 4px", borderRadius: 60 }}>
                                                      STORNORECHNUNG
                                                    </span>
                                                    <br />
                                                    <span>
                                                      <span
                                                        style={{
                                                          marginLeft: 2,
                                                          display: "inline-block",
                                                          width: "100px",
                                                          overflow: "hidden",
                                                          whiteSpace: "nowrap",
                                                          textOverflow: "ellipsis",
                                                          verticalAlign: "middle",
                                                          marginTop: -1,
                                                          fontSize: 9
                                                        }}
                                                      >
                                                        zu R-Nr.: {invoice.cancelledRefInvoiceNr}
                                                      </span>
                                                    </span>
                                                    <br />
                                                  </span>
                                                )}
                                                {invoice.cancelled && (
                                                  <>
                                                    <span
                                                      style={{
                                                        color: "rgb(118 118 118)",
                                                        display: "block",
                                                        fontWeight: 700,
                                                        fontSize: 9,
                                                      }}
                                                    >
                                                      STORNIERT
                                                    </span>
                                                  </>
                                                )}



                                                <span
                                                  style={{
                                                    opacity: invoice.cancelled && invoice.payState === "notPayed" ? 0.2 : 1,
                                                    fontWeight: "500",
                                                    fontSize: 11,
                                                  }}
                                                >
                                                  {`Gesendet: ${RETURN_DATE(invoice.sendDate)}`}
                                                </span>
                                              </Typography>
                                            </div>
                                          </Tooltip>
                                        </div>
                                      )}

                                      {/* Payed */}
                                      {invoice.payState === "payed" && (
                                        <div
                                          style={{
                                            position: "relative",
                                            display: "inline-block",
                                            marginLeft: 5,
                                            marginRight: 5,
                                            width: "100%",
                                          }}
                                        >
                                          <Tooltip title={`Wurde bezahlt am ${RETURN_DATE(invoice.payDate)}`} arrow>
                                            <div
                                              style={{
                                                background: "#ffffff",
                                                padding: "10px 10px",
                                                borderRadius: 60,
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                                position: "relative",
                                              }}
                                            >
                                              {invoice.originalRef !== undefined && (
                                                <RepeatIcon
                                                  style={{
                                                    fontSize: "14px",
                                                    color: "rgb(76, 175, 80)",
                                                    marginRight: 5,
                                                  }}
                                                />
                                              )}

                                              <div style={{ position: "relative", marginTop: 5 }}>
                                                <CheckCircleIcon
                                                  style={{
                                                    fontSize: "24px",
                                                    color: "rgb(76, 175, 80)",
                                                    marginRight: 5,
                                                  }}
                                                />
                                                {/* Badge for Cancelled State */}
                                                {invoice.cancelledRef !== undefined && invoice.type !== "cancellation" && (
                                                  <CancelIcon
                                                    style={{
                                                      position: "absolute",
                                                      top: -6,
                                                      right: 0,
                                                      fontSize: 18,
                                                      color: "rgb(118 118 118)",
                                                      background: "white",
                                                      borderRadius: "50%",
                                                      boxShadow: "0 0 4px rgba(0, 0, 0, 0.2)",
                                                    }}
                                                  />
                                                )}

                                              </div>

                                              {/* Typography Content */}
                                              <Typography
                                                component="div"
                                                variant="body2"
                                                style={{
                                                  textAlign: "left",
                                                  fontSize: "13px",
                                                  color: "inherit",
                                                  paddingTop: "1px",
                                                  lineHeight: 1.2
                                                }}
                                              >
                                                {/* STORNIERT MIT STORNORECHNUNG Above "Bezahlt" */}
                                                {(invoice.cancelledRef !== undefined && invoice.type !== "cancellation") && (
                                                  <span
                                                    style={{
                                                      color: "rgb(118 118 118)",
                                                      display: "block",
                                                      fontWeight: 700,
                                                      fontSize: 9,
                                                    }}
                                                  >
                                                    STORNIERT MIT STORNORECHNUNG
                                                  </span>
                                                )}

                                                {invoice.type === "cancellation" && (
                                                  <span style={{ fontWeight: 700 }}>
                                                    <span style={{ background: "rgb(255 230 230)", color: "rgb(255, 98, 98)", fontSize: 9, padding: "2px 4px", borderRadius: 60 }}>
                                                      STORNORECHNUNG
                                                    </span>
                                                    <br />
                                                    <span>
                                                      <span
                                                        style={{
                                                          marginLeft: 2,
                                                          display: "inline-block",
                                                          width: "100px",
                                                          overflow: "hidden",
                                                          whiteSpace: "nowrap",
                                                          textOverflow: "ellipsis",
                                                          verticalAlign: "middle",
                                                          marginTop: -1,
                                                          fontSize: 9
                                                        }}
                                                      >
                                                        zu R-Nr.: {invoice.cancelledRefInvoiceNr}
                                                      </span>
                                                    </span>
                                                    <br />
                                                  </span>
                                                )}

                                                {/* "Bezahlt" Text */}
                                                <span
                                                  style={{
                                                    fontWeight: "500",
                                                    fontSize: 11
                                                  }}
                                                >
                                                  {`Bezahlt: ${RETURN_DATE(invoice.payDate)}`}
                                                </span>
                                              </Typography>
                                            </div>
                                          </Tooltip>
                                        </div>
                                      )}

                                    </Grid>

                                    {/* Client */}
                                    <Grid xs={12} lg={2}
                                      container
                                      item
                                      alignItems="center"
                                      className="image-name-company"
                                      style={{ padding: "5px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", flexWrap: "nowrap" }}
                                      onClick={() => setOpenInvoiceDialogAtom({
                                        ...openInvoiceDialogAtom,
                                        recurring: false,
                                        open: true,
                                        invoiceId: invoice.id,
                                        isEstimate: (invoice.type === "estimate" || invoice.type === "offer") ? true : false
                                      })}
                                    >

                                      <div className="client-image" style={{ borderRadius: 6, display: "flex", justifyContent: "center", alignItems: "center", background: "white", borderRadius: 6, height: "70px", width: "70px", minWidth: "70px", marginRight: "10px", position: "relative" }}>
                                        {invoice.clientLogo !== "" ? (
                                          <img
                                            src={`${invoice.clientLogo}`}
                                            alt="logo"
                                            style={{
                                              maxWidth: "65px",
                                              maxHeight: "65px",
                                              marginRight: "10px",
                                              position: "absolute",
                                              top: "50%",
                                              left: "50%",
                                              transform: "translate(-50%, -50%)",
                                              padding: 2,
                                              opacity: invoice.cancelled ? 0.2 : 1,
                                            }}
                                          />
                                        ) :
                                          <img src={LogoMonogram} height={25} width={25} alt={`${t("APP.name")} Logo`} style={{ width: "50px", minWidth: "50px", marginRight: "10px", opacity: invoice.cancelled ? 0.1 : 0.3, margin: "0 auto" }} />
                                        }
                                      </div>

                                      <Typography component="div" variant="body2" style={{ opacity: invoice.cancelled && invoice.payState === "notPayed" ? 0.2 : 1, fontSize: "13px", fontWeight: "500", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{JSON.parse(invoice.clientData).company}</Typography>
                                    </Grid>

                                    {/* Second Part using lg 8*/}
                                    <Grid xs={12} lg={8}
                                      container
                                      item
                                      justifyContent="flex-start"
                                      alignItems="center"
                                      className="invoice-meta"
                                      style={{ opacity: invoice.cancelled && invoice.payState === "notPayed" ? 0.2 : 1, padding: "15px 0px", }}
                                      onClick={() => setOpenInvoiceDialogAtom({
                                        ...openInvoiceDialogAtom,
                                        recurring: false,
                                        open: true,
                                        invoiceId: invoice.id,
                                        isEstimate: (invoice.type === "estimate" || invoice.type === "offer") ? true : false
                                      })}
                                    >

                                      {/* Invoice Number */}
                                      <Grid xs={12} lg={2}
                                        container
                                        item
                                        justifyContent="center"
                                        className="invoice-meta-item-container"
                                      >
                                        <Typography className="invoice-meta-item" style={{ textAlign: "center", fontSize: "12px" }} component="div" variant="body2">
                                          <span className="menu-heading-title" style={{ paddingTop: 4 }}>Rechnungsnummer:</span>
                                          <Tooltip title={
                                            <span>
                                              {JSON.parse(invoice.invoiceNr).map((item, i) => (
                                                <span key={i}>
                                                  {item.value}
                                                </span>
                                              ))}
                                            </span>
                                          } arrow>
                                            <div style={{
                                              display: "inline-block",
                                              maxWidth: "100px",
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis"
                                            }}>
                                              <span style={{ fontWeight: "300" }}>
                                                {JSON.parse(invoice.invoiceNr).map((item, i) => (
                                                  <span key={i} style={{ fontSize: "13px", fontWeight: "300" }}>
                                                    {item.type === "continuousNumber" ? (
                                                      <span style={{
                                                        display: "inline-block",
                                                        background: "rgb(237, 239, 255)",
                                                        height: "17px",
                                                        color: "inherit",
                                                        paddingLeft: 3,
                                                        paddingRight: 3,
                                                        borderRadius: "3px",
                                                        marginTop: 3.3,
                                                        marginLeft: "1px",
                                                        marginRight: "1px",
                                                        fontSize: "13px",
                                                        fontWeight: "300"
                                                      }}>{item.value}</span>
                                                    ) : (
                                                      <span>{item.value}</span>
                                                    )}
                                                  </span>
                                                ))}
                                              </span>
                                            </div>
                                          </Tooltip>
                                        </Typography>
                                      </Grid>

                                      {/* Invoice Net */}
                                      <Grid xs={12} lg={2}
                                        container
                                        item
                                        justifyContent="center"
                                        className="invoice-meta-item-container"
                                      >
                                        <Typography className="invoice-meta-item" style={{ textAlign: "center", fontSize: "12px" }} component="div" variant="body2">
                                          <span className="menu-heading-title">Nettobetrag:</span>
                                          <Grid item container justifyContent="center">
                                            <div style={{ fontWeight: "300", fontSize: "12px", color: invoice.type === "cancellation" ? "red" : "inherit" }}>{invoice.type === "cancellation" && "-"}{parseFloat(invoice.subtotal).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €</div>
                                          </Grid>
                                        </Typography>
                                      </Grid>

                                      {/* Total Gross */}
                                      <Grid xs={12} lg={2}
                                        container
                                        item
                                        justifyContent="center"
                                        className="invoice-meta-item-container"
                                      >
                                        <Typography className="invoice-meta-item" style={{ textAlign: "center", fontSize: "12px" }} component="div" variant="body2">
                                          <span className="menu-heading-title">Bruttobetrag:</span>
                                          <Grid item container justifyContent="center">
                                            <div style={{ fontWeight: "300", fontSize: "12px", color: invoice.type === "cancellation" ? "red" : "inherit" }}>{invoice.type === "cancellation" && "-"}{parseFloat(invoice.total).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €</div>
                                          </Grid>
                                        </Typography>
                                      </Grid>

                                      {/* Invoice Date */}
                                      <Grid xs={4} lg={2}
                                        container
                                        item
                                        justifyContent="center"
                                        className="invoice-meta-item-container"
                                      >
                                        <Typography className="invoice-meta-item" style={{ textAlign: "center", fontSize: "12px" }} component="div" variant="body2">
                                          <span className="menu-heading-title">Rechnungsdatum:</span>
                                          <Grid item container justifyContent="center">
                                            <div style={{ fontWeight: "300", fontSize: "12px" }}>{RETURN_DATE(invoice.invoiceDate)}</div>
                                          </Grid>
                                        </Typography>
                                      </Grid>

                                      {/* Due Date */}
                                      {type === "invoices" &&
                                        <Grid xs={4} lg={2}
                                          container
                                          item
                                          justifyContent="center"
                                          className="invoice-meta-item-container"
                                        >
                                          <Typography className="invoice-meta-item" style={{ textAlign: "center", fontSize: "12px" }} component="div" variant="body2">
                                            <span className="menu-heading-title">Fälligkeitsdatum:</span>
                                            <Grid item container justifyContent="center">
                                              <div style={{ fontWeight: "300", fontSize: "12px" }}>{RETURN_DATE(invoice.dueDate)}</div>
                                            </Grid>
                                          </Typography>
                                        </Grid>
                                      }

                                      {/* Set Payment */}
                                      <Grid xs={1}
                                        container
                                        item
                                        justifyContent="center"
                                        alignItems="center"
                                        className="invoice-ref"
                                      >
                                      {/* {!invoice.cancelled && invoice.payState !== "payed" && (
                                        <>
                                          <Tooltip title="Als bezahlt markieren">
                                            <div
                                              className={"chip--pay"}
                                              style={{ transform: "scale(1.3)", display: "inline-block" }}
                                              onClick={(e) => {
                                                e.stopPropagation(); // Prevent parent click event
                                                openPayMenu(e);
                                              }}
                                            >
                                              <div
                                                className="payed-info"
                                                style={{
                                                  borderRadius: "60px",
                                                  display: "inline-grid",
                                                  textAlign: "center",
                                                  padding: "3px 4px 3px 3px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <EuroSymbolIcon
                                                  className="payed-info-icon"
                                                  style={{ fontSize: 12 }}
                                                />
                                              </div>
                                            </div>
                                          </Tooltip>

                                          <Popover
                                            open={isPayMenuOpen}
                                            anchorEl={payMenuAnchorEl}
                                            onClose={(e) => {
                                              e.stopPropagation(); // Prevent parent click event
                                              closePayMenu();
                                            }}
                                            anchorOrigin={{
                                              vertical: "bottom",
                                              horizontal: "center",
                                            }}
                                            transformOrigin={{
                                              vertical: "top",
                                              horizontal: "center",
                                            }}
                                            PaperProps={{
                                              onClick: (e) => {
                                                e.stopPropagation(); // Prevent parent click events from propagating to the invoice
                                              },
                                            }}
                                          >
                                            <div style={{ padding: 16, maxWidth: 400 }}>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "space-between",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <Typography variant="h6" style={{ fontSize: "14px" }}>
                                                  Als bezahlt markieren
                                                </Typography>
                                                <IconButton
                                                  onClick={(e) => {
                                                    e.stopPropagation(); // Prevent parent click event
                                                    closePayMenu();
                                                  }}
                                                  size="small"
                                                >
                                                  <CloseIcon />
                                                </IconButton>
                                              </div>
                                              <Typography
                                                variant="body2"
                                                style={{ fontSize: 12, margin: "16px 0" }}
                                              >
                                                Falls diese Rechnung bereits bezahlt wurde, kann hiermit direkt der
                                                Bezahlstatus geändert werden.
                                              </Typography>

                                              <MuiPickersUtilsProvider locale={de} utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                  style={{
                                                    border:
                                                      payMenuValues.payState === "sent"
                                                        ? "2px solid rgb(26, 123, 201)"
                                                        : "inherit",
                                                  }}
                                                  fullWidth
                                                  inputVariant="filled"
                                                  id="payDate"
                                                  format="dd.MM.yyyy"
                                                  label="Bezahldatum"
                                                  value={payMenuValues.payDate}
                                                  onChange={(date) => {
                                                    // Update payMenuValues but do not update dataInitial yet
                                                    setPayMenuValues((prev) => ({ ...prev, payDate: date }));
                                                  }}
                                                  KeyboardButtonProps={{
                                                    "aria-label": "change date",
                                                  }}
                                                  okLabel="OK"
                                                  cancelLabel="Abbrechen"
                                                  PopoverProps={{
                                                    onClick: (e) => e.stopPropagation(), // Prevent clicks inside datepicker from propagating
                                                  }}
                                                />
                                              </MuiPickersUtilsProvider>

                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "space-between",
                                                  marginTop: 16,
                                                }}
                                              >
                                                <Button
                                                  size="small"
                                                  variant="outlined"
                                                  color="default"
                                                  onClick={(e) => {
                                                    e.stopPropagation(); // Prevent parent click event
                                                    closePayMenu();
                                                  }}
                                                >
                                                  Abbrechen
                                                </Button>
                                                <Button
                                                  size="small"
                                                  variant="contained"
                                                  color="primary"
                                                  onClick={(e) => {
                                                    e.stopPropagation(); // Prevent parent click event

                                                    // Apply changes to dataInitial when "Speichern" is clicked
                                                    setDataInitial((prevData) =>
                                                      prevData.map((item) =>
                                                        item._id === invoice._id
                                                          ? {
                                                              ...item,
                                                              payState: "payed", // Update payState
                                                              payDate: payMenuValues.payDate.toString(), // Apply selected date
                                                            }
                                                          : item
                                                      )
                                                    );

                                                    // Close the menu
                                                    closePayMenu();
                                                  }}
                                                >
                                                  Speichern
                                                </Button>
                                              </div>
                                            </div>
                                          </Popover>
                                        </>
                                      )} */}
                                      {!invoice.cancelled && invoice.payState !== "payed" && (
  <>
    <Tooltip title="Als bezahlt markieren">
      <div
        className={"chip--pay"}
        style={{ transform: "scale(1.3)", display: "inline-block" }}
        onClick={(e) => {
          e.stopPropagation(); // Prevent parent click event
          openPayMenu(e);
        }}
      >
        <div
          className="payed-info"
          style={{
            borderRadius: "60px",
            display: "inline-grid",
            textAlign: "center",
            padding: "3px 4px 3px 3px",
            fontSize: "12px",
          }}
        >
          <EuroSymbolIcon
            className="payed-info-icon"
            style={{ fontSize: 12 }}
          />
        </div>
      </div>
    </Tooltip>

    <Popover
      open={isPayMenuOpen}
      anchorEl={payMenuAnchorEl}
      onClose={(e) => {
        e.stopPropagation(); // Prevent parent click event
        closePayMenu();
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      PaperProps={{
        onClick: (e) => {
          e.stopPropagation(); // Prevent parent click events from propagating to the invoice
        },
      }}
    >
      <div style={{ padding: 16, maxWidth: 400 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" style={{ fontSize: "14px" }}>
            Als bezahlt markieren
          </Typography>
          <IconButton
            onClick={(e) => {
              e.stopPropagation(); // Prevent parent click event
              closePayMenu();
            }}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </div>
        <Typography
          variant="body2"
          style={{ fontSize: 12, margin: "16px 0" }}
        >
          Falls diese Rechnung bereits bezahlt wurde, kann hiermit direkt der
          Bezahlstatus geändert werden.
        </Typography>

        <MuiPickersUtilsProvider locale={de} utils={DateFnsUtils}>
          <KeyboardDatePicker
            style={{
              border:
                payMenuValues.payState === "sent"
                  ? "2px solid rgb(26, 123, 201)"
                  : "inherit",
            }}
            fullWidth
            inputVariant="filled"
            id="payDate"
            format="dd.MM.yyyy"
            label="Bezahldatum"
            value={payMenuValues.payDate}
            onChange={(date) => {
              // Update payMenuValues but do not update dataInitial yet
              setPayMenuValues((prev) => ({ ...prev, payDate: date }));
            }}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            okLabel="OK"
            cancelLabel="Abbrechen"
            PopoverProps={{
              onClick: (e) => e.stopPropagation(), // Prevent clicks inside datepicker from propagating
            }}
          />
        </MuiPickersUtilsProvider>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 16,
          }}
        >
          <Button
            size="small"
            variant="outlined"
            color="default"
            onClick={(e) => {
              e.stopPropagation(); // Prevent parent click event
              closePayMenu();
            }}
          >
            Abbrechen
          </Button>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={async (e) => {
              e.stopPropagation(); // Prevent parent click event

              if (!payMenuValues.payDate) {
                alert("Bitte wählen Sie ein Bezahldatum aus.");
                return;
              }

              // Apply changes to dataInitial when "Speichern" is clicked
              setDataInitial((prevData) =>
                prevData.map((item) =>
                  item._id === invoice._id
                    ? {
                        ...item,
                        payState: "payed", // Update payState
                        payDate: payMenuValues.payDate.toString(), // Apply selected date
                      }
                    : item
                )
              );

              // Prepare FormData for the PATCH request
              const formData = new FormData();
              formData.append("payState", "payed");
              formData.append("payDate", payMenuValues.payDate.toString());

              try {
                // Send PATCH request to update the backend
                const url = `${process.env.REACT_APP_BACKEND_URL_API}/invoices/${invoice._id}`;
                const method = "PATCH";

                await sendRequest(
                  url,
                  method,
                  formData,
                  {
                    Authorization: "Bearer " + auth.token,
                  }
                );

                console.log("Invoice successfully patched in the database.");
              } catch (error) {
                console.error("Failed to patch the invoice:", error);
              }

              // Close the menu
              closePayMenu();
            }}
          >
            Speichern
          </Button>
        </div>
      </div>
    </Popover>
  </>
)}

                                      </Grid>

                                      {/* Dots */}
                                      <Grid xs={12} lg={1}
                                        container
                                        item
                                        justifyContent="flex-end"
                                        alignItems="flex-end"
                                      >
                                        <InvoicesMenuDots
                                          invoice={invoice}
                                          setOpenInvoiceDialogAtom={setOpenInvoiceDialogAtom}
                                          openInvoiceDialogAtom={openInvoiceDialogAtom}
                                          recurring={recurring}
                                          fetchData={fetchData}
                                          handleSearchClear={handleSearchClear}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))
                        }

                        {/* APPLIED FILTER after Loading does result in list of length 0 */}
                        {!values.loading && !isLoading && Object.keys(values.invoicesFiltered).length === 0 &&
                          <Typography component="div" style={{ fontSize: "12px", padding: "10px 20px" }}>{t("PAGE_INVOICES.noResult")}</Typography>
                        }
                      </>
                    )
                    }
                    {(isLoading && searchTerm === "") &&
                      <div style={{ padding: 15 }}>Wird geladen ...</div>
                    }


                    {/* NO INVOICES created yet */}
                    {!values.loading && !isLoading && dataInitial.length === 0 && type === "invoices" &&
                      <Typography component="div" style={{ fontSize: "12px", padding: "10px 20px" }}>{t("PAGE_INVOICES.noInvoicesCreated")}</Typography>
                    }


                    {hasMoreInvoices && !isFetching && dataInitial.length !== 0 && (
                      <Grid container justifyContent="center">
                        <Button
                          onClick={loadMoreInvoices}
                          style={{
                            backgroundColor: theme.palette.primary.main, // Primary color
                            color: "#fff",
                            margin: "15px 0", // Smaller margin
                            padding: "8px 16px", // Smaller padding
                            fontSize: "14px", // Smaller font size
                            borderRadius: "6px", // Slightly smaller border radius
                            boxShadow: "0px 3px 6px rgba(0,0,0,0.2)",
                          }}
                          onMouseOver={(e) =>
                            (e.currentTarget.style.backgroundColor = theme.palette.primary.dark)
                          } // Darker shade on hover
                          onMouseOut={(e) =>
                            (e.currentTarget.style.backgroundColor = theme.palette.primary.main)
                          } // Reset background color when mouse leaves
                          startIcon={<RotateLeftIcon />} // Add an icon here
                        >
                          Mehr laden
                        </Button>
                      </Grid>
                    )}

                  {(!values.loading || !isLoading) && (!userAtom.clientscreated && userAtom.data.clients.length === 0)  &&
                    <UserProfileIncompleteWarning
                      headline={t("PAGE_INVOICES.h1")}
                      text={"Bitte erstelle zunächst einen Kunden."}
                      icon={<ClientsIcon color="primary" fontSize="large" style={{ marginRight: "5px" }} />}
                      link={"/clients"}
                      linkText={t("BUTTONS.back_to_clients")}
                    />
                  }

                    {(!values.loading || !isLoading) && userAtom.data.signupCompleted && (userAtom.clientscreated || userAtom.data.clients.length !== 0) && dataInitial.length === 0 && !isFetching &&
                      <Grid container style={{ padding: "20px 15px" }}>
                        <Typography variant="body2" style={{ color: "gray" }}>
                          Es wurden keine Rechnungen mit den vorgenommenen Einstellungen gefunden.
                        </Typography>
                      </Grid>
                    }
                  </div>

                  {(!isLoading) &&
                    <>
                      {/* INVOICES already created */}

                      {/* NO INVOICES created yet */}
                      {!values.loading && !isLoading && dataInitial.length === 0 && type === "invoices" &&
                        <Typography component="div" style={{ fontSize: "12px", padding: "10px 20px" }}>{t("PAGE_INVOICES.noInvoicesCreated")}</Typography>
                      }

                      {/* NO ESTIMATES created yet */}
                      {!values.loading && !isLoading && dataInitial.length === 0 && type === "estimates" &&
                        <Typography component="div" style={{ fontSize: "12px", padding: "10px 20px" }}>Du hast noch keine Kostenvoranschläge oder Angebote angelegt</Typography>
                      }
                    </>
                  }
                </div>
              </Grid>
            </Box>
          </Container>
        </React.Fragment>
      }

      {/* {(!values.loading || !isLoading) && !userAtom.data.signupCompleted &&
        <UserProfileIncompleteWarning
          headline={t("PAGE_INVOICES.h1")}
          text={t("PAGE_INVOICES.profileIncomplete")}
          icon={<ClientsIcon color="primary" fontSize="large" style={{ marginRight: "5px" }} />}
        />
      } */}

      {(!values.loading || !isLoading) && userAtom.data.signupCompleted && (userAtom.clientscreated || userAtom.data.clients.length !== 0) && !isFetching &&

      <Fab color="primary" aria-label="add" className="add-invoice-fab" style={{ position: "fixed", bottom: "0", right: "0", margin: "20px", zIndex: 9 }}
        onClick={
          type === "invoices" ?
            () => setOpenInvoiceDialogAtom({
              ...openInvoiceDialogAtom,
              open: true,
              isEstimate: false,
              recurring: false
            })
            : () => setOpenInvoiceDialogAtom({
              ...openInvoiceDialogAtom,
              open: true,
              isEstimate: true,
              recurring: false,
            })
        }
      >
        <AddIcon />
      </Fab>
      }
    </div>
  );
};

export default Invoices;

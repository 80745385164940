// import React, { useState, useEffect } from 'react';
// import { useTranslation } from "react-i18next";
// import {
//   Typography,
//   Button,
//   Grid,
//   FormControlLabel,
//   Radio,
//   FormControl,
//   RadioGroup,
//   FormGroup,
//   Checkbox,
//   IconButton,
//   Menu,
//   Box,
//   ButtonBase,
//   makeStyles
// } from "@material-ui/core";
// import {
//   ImportExport as ImportExportIcon,
//   RadioButtonUnchecked as RadioButtonUncheckedIcon,
//   RadioButtonChecked as RadioButtonCheckedIcon,
//   Save as SaveIcon,
//   Close as CloseIcon // Import Close icon
// } from '@material-ui/icons';

// const useStyles = makeStyles(() => ({
//   hoverBox: {
//     width: "100%",
//     cursor: "pointer",
//     "&:hover": {
//       backgroundColor: "rgba(0, 0, 0, 0.04)",
//     },
//   },
//   subRadioHover: {
//     borderRadius: 4,
//     "&:hover": {
//       backgroundColor: "rgba(100, 100, 100, 0.1)", // Adjust to your desired hover color
//     },
//   },
// }));

// export default function InvoicesSortRecurring({
//   settings,
//   handleChange,
//   cancelSettingsHandler,
//   updateSettingsHandler,
//   SET_VALUE,
//   setSettings,
//   setValues,
//   values,
//   type,
//   recurring,
//   mobile
// }) {
//   const { t } = useTranslation();
//   const classes = useStyles();
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [hasChanges, setHasChanges] = useState(false);
//   const [initialSettings, setInitialSettings] = useState({ ...settings });

//   const labels = {
//     title: "Sortieren nach ...",
//     creationPoint: "Erstellungszeitpunkt",
//     companyName: "Kundenname",
//     invoiceNet: "Nettobetrag",
//     invoiceGross: "Bruttobetrag",
//     asc: {
//       creationPointInTime: "Chronologisch",
//       invoiceCompany: "Kundennamen (A - Z)",
//       invoiceNet: "Aufsteigend",
//       invoiceGross: "Aufsteigend"
//     },
//     desc: {
//       creationPointInTime: "Umgekehrt chronologisch",
//       invoiceCompany: "Kundennamen (Z - A)",
//       invoiceNet: "Absteigend",
//       invoiceGross: "Absteigend"
//     },
//     paidInvoicesWarning: "Bei Sortierung nach Bezahldatum werden ausschließlich Rechnungen mit dem Status 'bezahlt' angezeigt.",
//     cancel: t("BUTTONS.cancel"),
//     save: "Sortieren"
//   };

//   const handleClickOpen = (event) => {
//     setAnchorEl(event.currentTarget);
//     SET_VALUE({ target: { id: "submenuOpen", value: "sort" } }, setValues);

//     setInitialSettings({ ...settings });
//     setHasChanges(false);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   const handleSettingChange = (e) => {
//     handleChange(e);
//     setHasChanges(true);
//   };

//   useEffect(() => {
//     const isDifferent = Object.keys(initialSettings).some(
//       (key) => initialSettings[key] !== settings[key]
//     );
//     setHasChanges(isDifferent);
//   }, [settings, initialSettings]);

//   const handleSave = () => {
//     updateSettingsHandler();
//     setInitialSettings({ ...settings });
//     setHasChanges(false);
//     handleClose();
//   };

//   const handleCancel = () => {
//     cancelSettingsHandler();
//     setHasChanges(false);
//     handleClose();
//   };

//   const getLabelOpacity = (isChecked) => ({
//     opacity: isChecked ? 1 : 0.3,
//   });

//   const getSortLabel = () => {
//     if (!settings || !settings.invoicesRecurringsSortField || !settings.invoicesRecurringsSortOrder) {
//       return 'Unknown Status'; // Safety check for undefined settings or fields
//     }

//     switch (settings.invoicesRecurringsSortField) {
//       case 'creationPointInTime':
//         return settings.invoicesRecurringsSortOrder === 'asc' ? 'Erstellungszeitpunkt (Chronologisch)' : 'Erstellungszeitpunkt (Umgekehrt chronologisch)';
//       case 'invoiceCompany':
//         return settings.invoicesRecurringsSortOrder === 'asc' ? 'Kundenname (A-Z)' : 'Kundenname (Z-A)';
//       case 'invoiceNet':
//         return settings.invoicesRecurringsSortOrder === 'asc' ? 'Netto (Aufsteigend)' : 'Netto (Absteigend)';
//       case 'invoiceGross':
//         return settings.invoicesRecurringsSortOrder === 'asc' ? 'Brutto (Aufsteigend)' : 'Brutto (Absteigend)';
//       default:
//         return 'Unknown Status'; // Fallback for unhandled cases
//     }
//   };

//   const renderSortOption = (field, label, extraRadioOptions) => (
//     <ButtonBase
//       onClick={() => SET_VALUE({ target: { invoicesRecurringsSortField: field } }, setSettings, "multi")}
//       className={classes.hoverBox}
//       disableRipple
//       style={{ paddingLeft: 16, paddingRight: 16, borderRadius: 6 }}
//     >
//       <FormControl component="fieldset" style={{ width: "100%" }}>
//         <Grid container alignItems="center">
//           <FormGroup style={{ padding: 0, width: "100%" }}>
//             <FormControlLabel
//               control={
//                 <Checkbox
//                   icon={<RadioButtonUncheckedIcon />}
//                   checkedIcon={<RadioButtonCheckedIcon />}
//                   checked={settings.invoicesRecurringsSortField === field}
//                   color="primary"
//                   disableRipple
//                 />
//               }
//               label={
//                 <Typography
//                   variant="body2"
//                   style={{ fontWeight: 500, ...getLabelOpacity(settings.invoicesRecurringsSortField === field) }}
//                 >
//                   {label}
//                 </Typography>
//               }
//               style={{ marginBottom: "3px", width: "100%" }}
//             />
//           </FormGroup>
//         </Grid>
//         {settings.invoicesRecurringsSortField === field && (
//           <RadioGroup
//             name="invoicesRecurringsSortOrder"
//             value={settings.invoicesRecurringsSortOrder}
//             onChange={handleSettingChange}
//             style={{ marginLeft: "32px", marginBottom: "10px" }}
//           >
//             {extraRadioOptions}
//             <FormControlLabel
//               value="asc"
//               control={<Radio size="small" color="primary" />}
//               label={<Typography variant="body2">{labels.asc[field]}</Typography>}
//               className={classes.subRadioHover}
//             />
//             <FormControlLabel
//               value="desc"
//               control={<Radio size="small" color="primary" />}
//               label={<Typography variant="body2">{labels.desc[field]}</Typography>}
//               className={classes.subRadioHover}
//             />
//           </RadioGroup>
//         )}
//       </FormControl>
//     </ButtonBase>
//   );

//   return (
//     <>
//       {mobile &&
//       <IconButton aria-label="settings" onClick={handleClickOpen} style={{ color: "inherit"}}>
//         <ImportExportIcon fontSize="small" style={{ color: "rgb(44, 83, 125)" }} />
//       </IconButton>
//       }

//       {!mobile &&
//       <IconButton aria-label="settings" onClick={handleClickOpen} style={{ color: "inherit", borderRadius: 6, height: 45 }} disableRipple>
//         <ImportExportIcon fontSize="small" style={{ color: "rgb(44, 83, 125)" }} />
//         <Typography variant="body2" component="div" style={{ marginRight: "5px", fontSize: "12px", color: "rgba(0, 0, 0, 0.87)" }}>
//           <span style={{marginRight: 5}}>Sortierung:</span><span style={{fontWeight: 500, color: "rgb(44, 83, 125)", padding: "6px 8px", border: "1px solid rgb(237, 239, 255)", background: "#ffffff", borderRadius: 60}}>{getSortLabel()}</span>
//         </Typography>
//       </IconButton>
//       }
//       <Menu
//         anchorEl={anchorEl}
//         open={Boolean(anchorEl)}
//         onClose={handleCancel}
//         keepMounted
//         PaperProps={{
//           style: {
//             width: "300px",
//           },
//         }}
//       >
//         <Box display="flex" alignItems="center" justifyContent="space-between" px={2} py={1}>
//           <Typography
//             variant="h6"
//             color="primary"
//             style={{
//               display: "flex",
//               alignItems: "center",
//               fontWeight: 600,
//               fontSize: 16,
//             }}
//           >
//             <ImportExportIcon
//               style={{
//                 marginRight: 10,
//                 color: "rgb(44, 83, 125)",
//                 boxShadow: "rgba(108, 116, 173, 0.12) 0px 0px 20px 0px inset",
//                 borderRadius: 60,
//                 padding: 5,
//                 zoom: 1.5,
//               }}
//             />
//             {labels.title}
//           </Typography>
//           <IconButton onClick={handleCancel} size="small">
//             <CloseIcon />
//           </IconButton>
//         </Box>
//         <Box>
//           {renderSortOption("creationPointInTime", labels.creationPoint)}
//           {renderSortOption("invoiceCompany", labels.companyName)}
//           {renderSortOption("invoiceNet", labels.invoiceNet)}
//           {renderSortOption("invoiceGross", labels.invoiceGross)}
//         </Box>
//         <Box display="flex" justifyContent="space-between" px={2} py={1} style={{ marginTop: 10 }}>
//           <Button variant="outlined" size="small" onClick={handleCancel} disableRipple>
//             {labels.cancel}
//           </Button>
//           <Button
//             variant="contained"
//             color="primary"
//             size="small"
//             onClick={handleSave}
//             startIcon={<ImportExportIcon />}
//             disabled={!hasChanges}
//             disableRipple
//           >
//             {labels.save}
//           </Button>
//         </Box>
//       </Menu>
//     </>
//   );
// }


import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import {
  Typography,
  Button,
  Grid,
  FormControlLabel,
  Radio,
  FormControl,
  RadioGroup,
  FormGroup,
  Switch,
  IconButton,
  Menu,
  Box,
  ButtonBase,
  makeStyles
} from "@material-ui/core";
import {
  ImportExport as ImportExportIcon,
  Save as SaveIcon,
  Close as CloseIcon
} from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  hoverBox: {
    width: "100%",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  subRadioHover: {
    borderRadius: 4,
    "&:hover": {
      backgroundColor: "rgba(100, 100, 100, 0.1)",
    },
  },
}));

export default function InvoicesSortRecurring({
  settings,
  handleChange,
  cancelSettingsHandler,
  updateSettingsHandler,
  SET_VALUE,
  setSettings,
  setValues,
  values,
  type,
  recurring,
  mobile
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [initialSettings, setInitialSettings] = useState({ ...settings });

  const labels = {
    title: "Sortieren nach ...",
    creationPoint: "Erstellungszeitpunkt",
    companyName: "Kundenname",
    invoiceNet: "Nettobetrag",
    invoiceGross: "Bruttobetrag",
    asc: {
      creationPointInTime: "Chronologisch",
      invoiceCompany: "A - Z",
      invoiceNet: "Aufsteigend",
      invoiceGross: "Aufsteigend"
    },
    desc: {
      creationPointInTime: "Umgekehrt chronologisch",
      invoiceCompany: "Z - A",
      invoiceNet: "Absteigend",
      invoiceGross: "Absteigend"
    },
    paidInvoicesWarning: "Bei Sortierung nach Bezahldatum werden ausschließlich Rechnungen mit dem Status 'bezahlt' angezeigt.",
    cancel: t("BUTTONS.cancel"),
    save: "Sortieren"
  };

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
    SET_VALUE({ target: { id: "submenuOpen", value: "sort" } }, setValues);

    setInitialSettings({ ...settings });
    setHasChanges(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSettingChange = (e) => {
    handleChange(e);
    setHasChanges(true);
  };

  useEffect(() => {
    const isDifferent = Object.keys(initialSettings).some(
      (key) => initialSettings[key] !== settings[key]
    );
    setHasChanges(isDifferent);
  }, [settings, initialSettings]);

  const handleSave = () => {
    updateSettingsHandler();
    setInitialSettings({ ...settings });
    setHasChanges(false);
    handleClose();
  };

  const handleCancel = () => {
    cancelSettingsHandler();
    setHasChanges(false);
    handleClose();
  };

  const getLabelOpacity = (isChecked) => ({
    opacity: isChecked ? 1 : 0.3,
  });

  const getSortLabel = () => {
    if (!settings || !settings.invoicesRecurringsSortField || !settings.invoicesRecurringsSortOrder) {
      return 'Unknown Status'; // Safety check for undefined settings or fields
    }

    switch (settings.invoicesRecurringsSortField) {
      case 'creationPointInTime':
        return settings.invoicesRecurringsSortOrder === 'asc' ? 'Erstellungszeitpunkt (Chronologisch)' : 'Erstellungszeitpunkt (Umgekehrt chronologisch)';
      case 'invoiceCompany':
        return settings.invoicesRecurringsSortOrder === 'asc' ? 'Kundenname (A-Z)' : 'Kundenname (Z-A)';
      case 'invoiceNet':
        return settings.invoicesRecurringsSortOrder === 'asc' ? 'Netto (Aufsteigend)' : 'Netto (Absteigend)';
      case 'invoiceGross':
        return settings.invoicesRecurringsSortOrder === 'asc' ? 'Brutto (Aufsteigend)' : 'Brutto (Absteigend)';
      default:
        return 'Unknown Status'; // Fallback for unhandled cases
    }
  };

  const renderSortOption = (field, label, extraRadioOptions) => {
    const isChecked = settings.invoicesRecurringsSortField === field;

    return (
      <ButtonBase
        onClick={() => SET_VALUE({ target: { invoicesRecurringsSortField: field } }, setSettings, "multi")}
        className={classes.hoverBox}
        disableRipple
        style={{ paddingLeft: 16, paddingRight: 16, borderRadius: 6 }}
      >
        <FormControl component="fieldset" style={{ width: "100%" }}>
          <Grid container alignItems="center">
            <FormGroup style={{ padding: 0, width: "100%" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isChecked}
                    onChange={() => SET_VALUE({ target: { invoicesRecurringsSortField: field } }, setSettings, "multi")}
                    color="primary"
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    style={{ fontWeight: 500, ...getLabelOpacity(isChecked) }}
                  >
                    {label}
                  </Typography>
                }
                style={{ marginBottom: "3px", width: "100%" }}
              />
            </FormGroup>
          </Grid>
          {isChecked && (
            <RadioGroup
              name="invoicesRecurringsSortOrder"
              value={settings.invoicesRecurringsSortOrder}
              onChange={handleSettingChange}
              style={{ marginLeft: "32px", marginBottom: "10px" }}
            >
              {extraRadioOptions}
              <FormControlLabel
                value="asc"
                control={<Radio size="small" color="primary" />}
                label={<Typography variant="body2">{labels.asc[field]}</Typography>}
                className={classes.subRadioHover}
              />
              <FormControlLabel
                value="desc"
                control={<Radio size="small" color="primary" />}
                label={<Typography variant="body2">{labels.desc[field]}</Typography>}
                className={classes.subRadioHover}
              />
            </RadioGroup>
          )}
        </FormControl>
      </ButtonBase>
    );
  };

  return (
    <>
      {mobile &&
      <IconButton aria-label="settings" onClick={handleClickOpen} style={{ color: "inherit"}}>
        <ImportExportIcon fontSize="small" style={{ color: "rgb(44, 83, 125)" }} />
      </IconButton>
      }

      {!mobile &&
      <IconButton aria-label="settings" onClick={handleClickOpen} style={{ color: "inherit", borderRadius: 6, height: 45 }} disableRipple>
        <ImportExportIcon fontSize="small" style={{ color: "rgb(44, 83, 125)" }} />
        <Typography variant="body2" component="div" style={{ marginRight: "5px", fontSize: "12px", color: "rgba(0, 0, 0, 0.87)" }}>
          <span style={{marginRight: 5}}>Sortierung:</span><span style={{fontWeight: 500, color: "rgb(44, 83, 125)", padding: "6px 8px", border: "1px solid rgb(237, 239, 255)", background: "#ffffff", borderRadius: 60}}>{getSortLabel()}</span>
        </Typography>
      </IconButton>
      }
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCancel}
        keepMounted
        PaperProps={{
          style: {
            width: "300px",
          },
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between" px={2} py={1}>
          <Typography
            variant="h6"
            color="primary"
            style={{
              display: "flex",
              alignItems: "center",
              fontWeight: 600,
              fontSize: 16,
            }}
          >
            <ImportExportIcon
              style={{
                marginRight: 10,
                color: "rgb(44, 83, 125)",
                boxShadow: "rgba(108, 116, 173, 0.12) 0px 0px 20px 0px inset",
                borderRadius: 60,
                padding: 5,
                zoom: 1.5,
              }}
            />
            {labels.title}
          </Typography>
          <IconButton onClick={handleCancel} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
        <Box>
          {renderSortOption("creationPointInTime", labels.creationPoint)}
          {renderSortOption("invoiceCompany", labels.companyName)}
          {renderSortOption("invoiceNet", labels.invoiceNet)}
          {renderSortOption("invoiceGross", labels.invoiceGross)}
        </Box>
        <Box display="flex" justifyContent="space-between" px={2} py={1} style={{ marginTop: 10 }}>
          <Button variant="outlined" size="small" onClick={handleCancel} disableRipple>
            {labels.cancel}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleSave}
            startIcon={<ImportExportIcon />}
            disabled={!hasChanges}
            disableRipple
          >
            {labels.save}
          </Button>
        </Box>
      </Menu>
    </>
  );
}

import React, {
  useState,
  useContext,
  useEffect,
} from "react";

// IMPORT hooks & context
import { useTranslation } from "react-i18next";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from '../../shared/context/auth-context';
import { useAuth } from "../../shared/hooks/auth-hook";

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import expensesAtomState from "../../_atoms/expensesAtom";
import openExpenseDialogAtomState from "../../_atoms/openExpenseDialogAtom";

// IMPORT components
import { useSnackbar } from 'notistack';
import { NumericFormat } from "react-number-format";
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';

// import InvoiceLivePreview from "../components/InvoiceLivePreview";

// IMPORT components MATERIAL UI
import {
  Typography,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  Grid,
  Box,
  InputAdornment,
  TextField,
  FormControlLabel,
  Switch,
  Backdrop,
  CircularProgress
} from "@material-ui/core";
import Fade from '@material-ui/core/Fade';
import Grow from '@material-ui/core/Grow';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import DateFnsUtils from '@date-io/date-fns';
import { de } from "date-fns/locale";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

// IMPORT icons MATERIAL UI
import InvoicesIcon from "@material-ui/icons/RecentActors";
import SaveIcon from '@material-ui/icons/Save';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddCircleIcon from '@material-ui/icons/AddCircle';

// IMPORT own components
import LogoMonogram from './../../_assets/logo_monogram.svg';
import DropzoneUploader from './../../shared/components/DropzoneUploader/DropzoneUploader'
import ImageStepper from "../components/ImageStepper";
import ButtonLoadingBottom from "../../shared/components/UIElements/ButtonLoadingBottom";

// IMPORT own css
import "./ExpenseDialog.scss"

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);



const useStyles = makeStyles((theme) => {
  return {
    toolbar: theme.mixins.toolbar,
    stepContent: {
      display: "flex",
      justifyContent: "center"
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },

    // cssLabel: {
    //   color : 'green'
    // },

    cssOutlinedInput: {
      '&$cssFocused $notchedOutline': {
        borderColor: `${theme.palette.primary.main} !important`,
      }
    },

    cssFocused: {},

    notchedOutline: {
      borderWidth: '2px',
      borderColor: "#f0f3ff !important"
    },

    backdrop: {
      zIndex: 999999,
      color: '#fff',
      backgroundColor: "rgba(0,0,0,0.7)"
    },
  };
});



const ExpenseDialog = (props) => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const { isLoading, sendRequest } = useHttpClient();
  const classes = useStyles();
  const { userId } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  // GLOBAL STATE (RECOIL)
  const [openExpenseDialogAtom, setOpenExpenseDialogAtom] = useRecoilState(openExpenseDialogAtomState);
  const [expensesAtom, setExpensesAtom] = useRecoilState(expensesAtomState);


  // LOCAL
  const [selectedClient, setSelectedClient] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [expenses, setExpenses] = useState([]);
  const [errorFiles, setErrorFiles] = useState([]);
  const [errorSum, setErrorSum] = useState(false);
  const [openAiRequestLength, setOpenAiRequestLength] = useState(0);
  const [overallExpensesLength, setOverallExpensesLength] = useState(0);

  const [receipt, setReceipt] = useState(true);
  const [errors, setErrors] = useState([]);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [values, setValues] = useState({
    readData: true,
    loading: false,
    isLoading: true,
    logo: [],
    logoType: "",
  });

  const [logosProcessing, setLogosProcessing] = useState(0);
  const [createExpenses, setCreateExpenses] = useState(false);

  const handleChangeAccordion = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  console.log(expenses)

  /* 
    Functions
  */
  useEffect(() => {
    if (openExpenseDialogAtom.expenseId !== "") {
      // If is EDITING MODE of existing expense OR DUPLICATE of expense
      let expense = (expensesAtom.filter((expense) => { return expense.id === openExpenseDialogAtom.expenseId }))[0];
      setExpenses((items) => [
        ...items,
        expense
      ]);
    }
  }, []);

  const addExpense = () => {
    setOverallExpensesLength(prevOverallExpensesLength => prevOverallExpensesLength + 1);
    setExpenses((items) => [
      ...items,
      {
        vendor_company: "",
        invoice_number: "",
        description: "",
        invoice_type: "",
        invoice_date: new Date(),
        pay_date: new Date(),
        net_value: 0,
        vat_amount: 0,
        gross_value: 0,
        charge: 100,
        fileType: "none"
      },
    ]);
  }

  const changeExpense = (event, index) => {
    const target = event.target.id !== undefined ? event.target.id : event.target.name;
    const newItems = expenses.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          [target]: event.target.value,
        };
      }
      return item;
    });

    setExpenses(newItems);
  };

  const deleteExpense = (index) => {
    // let foo = openAiRequestLength;
    // let foo1 = foo - 1
    // setOpenAiRequestLength(foo1)
    setOverallExpensesLength(prevOverallExpensesLength => prevOverallExpensesLength - 1);

    setExpenses((current) =>
      current.filter((item, i) => {
        return i !== index;
      })
    );
  };

 


const expenseHandler = async (event) => {
  event.preventDefault();
  setOpenBackdrop(true); // Open the backdrop

  let errorItems = [];

  setErrors(errorItems);

  if (errorItems.length > 0) {
      enqueueSnackbar("Bitte Beträge überprüfen", { variant: "error" });
      setOpenBackdrop(false); // Close the backdrop if there's an error before attempting saves
      return;
  }

  if (!userId || !auth.token) {
      enqueueSnackbar(t("ERRORS.save_form"), { variant: "error" });
      setOpenBackdrop(false); // Ensure to close the backdrop on errors
      return;
  }

  let allExpensesSaved = true; // Flag to track the overall success of saving expenses


  if (openExpenseDialogAtom.expenseId === "" || openExpenseDialogAtom.duplicate === true) {
    // CREATE NEW EXPENSE  
    // Map `expenses` to remove `pdfFile` from each expense object
    const expensesWithoutPdf = expenses.map(({ pdfFile, ...rest }) => rest);
  
    for (const expense of expensesWithoutPdf) {
      try {
        const formData = new FormData();
        formData.append("expenses", JSON.stringify([expense])); // Send each expense as a single object in an array
  
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL_API}/expenses`,
          "POST",
          formData,
          { Authorization: "Bearer " + auth.token }
        );
      } catch (err) {
        console.log(err);
        allExpensesSaved = false; // Indicate a failure in saving expenses
        break; // Stop processing further expenses if one fails
      }
    }

    

    // Final feedback based on the outcome of all save attempts
    if (allExpensesSaved) {
        try {
            const resExpenses = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL_API}/expenses/user/${userId}`,
                "GET",
                null,
                { Authorization: "Bearer " + auth.token }
            );
            setExpensesAtom(resExpenses.expenses);
            enqueueSnackbar("All expenses processed successfully", { variant: "success" });

            // Close the dialog and reset its state if all expenses are successfully saved
            setOpenExpenseDialogAtom({
                open: false,
                expenseId: "",
                duplicate: false
            });
        } catch (err) {
            console.log(err);
            enqueueSnackbar(t("ERRORS.fetch_server"), { variant: "error" });
        }
    } else {
        enqueueSnackbar(t("ERRORS.save_server"), { variant: "error" });
    }
  } else if (openExpenseDialogAtom.expenseId !== "" && openExpenseDialogAtom.duplicate === false) {
    // UPDATE EXISTING EXPENSES
      for (const expense of expenses) {
        try {
            const formData = new FormData();
            formData.append("expenses", JSON.stringify([expense]));
  
            const res = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL_API}/expenses/${openExpenseDialogAtom.expenseId}`,
                "PATCH",
                formData,
                { Authorization: "Bearer " + auth.token }
            )
            // .then((res)=>console.log(res))
        } catch (err) {
            console.log(err);
            allExpensesSaved = false; // Indicate a failure in saving expenses
            break; // Stop processing further expenses if one fails
        }
      }

      if (allExpensesSaved) {
        try {
            const resExpenses = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL_API}/expenses/user/${userId}`,
                "GET",
                null,
                { Authorization: "Bearer " + auth.token }
            );
            setExpensesAtom(resExpenses.expenses);
            enqueueSnackbar("All expenses processed successfully", { variant: "success" });

            // Close the dialog and reset its state if all expenses are successfully saved
            setOpenExpenseDialogAtom({
                open: false,
                expenseId: "",
                duplicate: false
            });
        } catch (err) {
            console.log(err);
            enqueueSnackbar(t("ERRORS.fetch_server"), { variant: "error" });
        }
    } else {
        enqueueSnackbar(t("ERRORS.save_server"), { variant: "error" });
    }
    }
  

  setOpenBackdrop(false); // Close the backdrop after processing is complete
};

  const validate = (field) => {
    // ONLY validate invoiceNumber if it is NEW INVOICE
    // openInvoiceDialogAtom.invoiceId === "" ?
    //   VALIDATE([
    //     "company",
    //     "country",
    //     "street",
    //     "streetNr",
    //     "zip",
    //     "city",
    //     "firstName",
    //     "lastName",
    //     "vat",
    //     "invoiceNumber"
    //   ],
    //     field, values, setValues)
    //   :
    //   VALIDATE([
    //     "company",
    //     "country",
    //     "street",
    //     "streetNr",
    //     "zip",
    //     "city",
    //     "firstName",
    //     "lastName",
    //     "vat",
    //   ],
    //     field, values, setValues)
  };


  useEffect(() => {
      if(createExpenses) {
        if(values.readData && auth.token && userId) {
          (values.logo).forEach (function (item, i) {
            // let foo = openAiRequestLength;
            // let foo1 = foo + 1;
            // setOverallExpensesLength(foo1)
            setErrorFiles([])
            getExpenseDateFromImage(item, i);
          });
        }
        else if(!values.readData && auth.token && userId) {
          (values.logo).forEach (function (item, i) {
            // setLogosProcessing(prevState => prevState - 1);
            setErrorFiles([]);
            setExpenses((items) => [
              ...items,
              {
                vendor_company: "",
                invoice_number: "",
                invoice_date: new Date (),
                pay_date: new Date (),
                net_value: 0,
                gross_value: 0,
                vat_percent: 0,
                vat: [[0, 0]],
                vat_amount: 0,
                invoice_type: "",
                description: "",
                url: item[0],
                fileType: item[1],
                fileName: item[2],
                pdfFile: item[3],
                charge: 100
              },
            ]);
            setOverallExpensesLength(prevOverallExpensesLength => prevOverallExpensesLength + 1);
            if((i + 1) === (values.logo).length) {
              setCreateExpenses(false);
              setValues({...values, logo: []})
            }
          });
        }
      }
    }, [createExpenses]);


    // useEffect(() => {
    //   if(expenses.length === (values.logo).length) {
    //     setCreateExpenses(false);
    //     // setValues({...values, logo: []})
    //   }
    // }, [expenses]);


  useEffect(() => {
    if(JSON.stringify(errorFiles) !== "[]") {
      let foo = openAiRequestLength;
      let foo1 = foo - 1;
      setOpenAiRequestLength(foo1)
    }
  }, [errorFiles]);

 
  const getExpenseDateFromImage = async (item, i) => {
    setValues(v => ({...v, loading: true})); // Set loading to true when starting the process

    const itemWithoutPdf = item.filter((_, index) => index !== 3);

    const formData = new FormData();
    formData.append("logo", JSON.stringify(itemWithoutPdf));

    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL_API}/expenses/expense-image-data`,
        "POST",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      if ('error' in response.expenseDataFromImage) {
        setErrorFiles((items) => [...items, [response.fileName]]);
        // enqueueSnackbar((`Datei konnte nicht ausgelesen werden: ${response.fileName}`), { variant: "error" });
      } else {
        setOverallExpensesLength(prevOverallExpensesLength => prevOverallExpensesLength + 1);
        setExpenses((items) => [
          ...items,
          {...response.expenseDataFromImage, url: response.url, fileType: response.fileType, charge: 100, fileName: response.fileName, pdfFile: response.pdfFile}
        ]);
      }
    } catch (err) { 
      console.log(err);
      enqueueSnackbar((`Es ist ein Fehler aufgetreten`), { variant: "error" });
    } finally {
      setValues(v => ({...v, logo: [], loading: false})); // Reset loading to false when done
      setLogosProcessing(prevState => prevState - 1);
      if((i + 1) === (values.logo).length) {
        setCreateExpenses(false);
        setValues({...values, logo: []})
      }
    }
};


  return (
    <React.Fragment>
      <AppBar style={{ background: "#263238", zIndex: "1500" }}>
        <Toolbar style={{ justifyContent: "space-between" }}>
          <>
            <IconButton
              edge="start"
              color="inherit"
              style={{ margin: "0", display: "flex", width: "40px", height: "40px", border: "1px solid white" }}
              onClick={() => {
                setOpenExpenseDialogAtom({
                  ...openExpenseDialogAtom,
                  open: false,
                  expenseId: "",
                  duplicate: false
                });
                setExpenses([])
              }}
              aria-label={`${t("BUTTONS.AL_close")}`}
            >
              <ArrowBackIcon />
            </IconButton>
          </>

          <Grid container item direction="row" alignItems="center" justifyContent="center" >
            <InvoicesIcon color="primary" fontSize="large" style={{ marginRight: "5px" }} />
            <Typography variant="body1" component="h1" style={{ textTransform: "none", fontWeight: 600 }}>{openExpenseDialogAtom.expenseId !== "" ? "Ausgabe bearbeiten" : "Ausgaben erfassen"}</Typography>
          </Grid>
        </Toolbar>
      </AppBar>

        {JSON.stringify(expenses) !== "[]" &&
        <Fade in={true}>
        {/* <BottomNavigation
          showLabels
          style={{ position: "fixed", width: "100%", zIndex: 999, bottom: 0, background: "transparent", margin: "0 auto" }}
          className="box-shadow--2"

        > */}
          {/* <BottomNavigationAction
            label="Abbrechen"
            color="default"
            className="bottom-navigation-element cancel"
            icon={<CloseIcon />}
            onClick={() => {
              setOpenExpenseDialogAtom({
                ...openExpenseDialogAtom,
                open: false,
                expenseId: "",
                duplicate: false
              });
              setExpenses([])
            }}
          />

          <BottomNavigationAction
            label="Speichern"
            color="primary"
            className="bottom-navigation-element primary"
            icon={<SaveIcon />}
            onClick={(e) => expenseHandler(e)}
          />
        </BottomNavigation> */}

        <BottomNavigation
          showLabels
          style={{ position: "fixed", left: 0, width: "100%", zIndex: 999, bottom: 0, background: "transparent", margin: "0 auto" }}
          className="box-shadow--2"
        >
          <ButtonLoadingBottom
            loading={isLoading}
            disabled={isLoading}
            color="primary"
            type="submit"
            size="large"
            className="button--loading"
            borderTopLeftRadius={"10px"}
            borderTopRightRadius="10px"
            startIcon={<SaveIcon />}
            onClick={(e) => expenseHandler(e)}
          >
            Speichern
          </ButtonLoadingBottom>
        </BottomNavigation>
        </Fade>
      }
      {selectedClient &&
        <div>
          <Grid
            container
            justifyContent="center"
            spacing={1}
            className="h-borderRadius--10 box-shadow--2 h-padding--5-5 tool-menu bg--1"
          >

          </Grid>
        </div>
      }

      <React.Fragment>
        <div>
          <div className="invoice-edit">
            <div className={classes.toolbar} />
            <Fade in={true}>
            <Container maxWidth={false} disableGutters className="bg--1">
              <Container maxWidth="lg" style={{ paddingTop: "25px", paddingBottom: "100px" }}>
                  <>
                    <Box display="flex"
                      flexDirection="column"
                      // alignItems="center"
                      margin="0 25px"
                      className="h-gap--25"
                      style={{ minHeight: "100vh" }}
                    >
                      {openExpenseDialogAtom.expenseId === "" &&
                      <Box
                        display="flex"
                        flexDirection="column"
                        // padding="25px 0px"
                        className="h-gap--20"
                      >
                        <Grid
                          container
                          spacing={1}
                          direction="row"
                          alignContent="flex-start"
                          alignItems="flex-start"
                          className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                        >
                          <Box sx={{ display: "flex", margin: "0 auto" }}>
                            <Box className="mask-box">
                              <Box
                                className="mask"
                                style={{
                                  transform: `translateX(${receipt === true ? 0 : "120px"})`
                                }}
                              />
                              <Button
                                disableRipple
                                variant="text"
                                style={{ textTransform: "none", color: receipt === true ? "#ffffff" : "#919191" }}
                                onClick={() => {setReceipt(true); setExpanded(false)}}
                              >
                                Mit Beleg
                              </Button>
                              <Button
                                disableRipple
                                variant="text"
                                style={{ textTransform: "none", color: receipt === false ? "#ffffff" : "#919191" }}
                                onClick={() => {setReceipt(false); setExpanded(false)}}
                              >
                                Ohne Beleg
                              </Button>
                            </Box>
                          </Box>
                          
                          <Grid item xs={12} lg={12} style={{ textAlign: "center" }}>
                              {receipt &&
                              <>
                              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div
                                  onClick={()=>setValues({ ...values, readData: !values.readData })}
                                  style={{ textAlign: "center", marginRight: "20px", opacity: values.readData ? 1 : 0.5 }}>
                                  <div style={{ borderRadius: "4px", display: "inline", textAlign: "center", padding: "7px 7px", background: "linear-gradient(to right top, #2276f4, #6b6bf4, #9b5bed, #c243df, #e312ca)", color: "#ffffff", fontSize: "12px" }}>
                                    <strong>AI</strong>
                                  </div>
                                </div>
                                <FormControlLabel
                                  className="switch"
                                  style={{ marginTop: "10px", marginBottom: "10px", color: values.readData ? "inherit" : "#919191" }}
                                  control={
                                    <Switch
                                      checked={values.readData}
                                      onChange={()=>setValues({ ...values, readData: !values.readData })}
                                      name="readData"
                                      color="primary"
                                    />
                                  }
                                  label={<Typography>Beleg-Daten auslesen und Ausgaben automatisch erstellen</Typography>}
                                />
                              </div>
                                                     
                              <DropzoneUploader
                                values={values}
                                setValues={setValues}
                                buttonText="Beleg(e) hochladen"
                                description="Hier klicken oder Datei(en) in den Rahmen ziehen um den Upload zu starten. Mögliche Dateiformate: JPG, PNG und PDF. Maximal 10 Belege pro Upload."
                                accept="image/jpg, image/jpeg, image/png, application/pdf"
                                logo={values.logo}
                                multiple={true}
                                maxFiles={10}
                                disabled={values.loading}
                                readData={values.readData}
                                openAiRequestLength={openAiRequestLength}
                                expensesLength={expenses.length}
                                setLogosProcessing={setLogosProcessing}
                                createExpenses={createExpenses}
                                setCreateExpenses={setCreateExpenses}
                                setExpenses={setExpenses}
                                errorFiles={errorFiles}
                                setErrorFiles={setErrorFiles}
                                overallExpensesLength={overallExpensesLength}
                              />
                              </>
                              }

                              {!receipt &&
                              <Grid item xs={12} lg={12} style={{ textAlign: "center", marginTop: "20px", marginBottom: "20px" }}>
                                <Button
                                  variant="outlined"
                                  size="large"
                                  color="primary"
                                  style={{ textTransform: "none" }}
                                  startIcon={<AddCircleIcon />}
                                  onClick={()=> {addExpense(); setExpanded(expenses.length)}}
                                >
                                  Ausgabe hinzfügen
                                </Button>
                              </Grid>
                              }

                          </Grid>
                        </Grid>
                      </Box>
                      }

                      {(openExpenseDialogAtom.expenseId === "" && expenses.length > 0) && 
                      <Fade in={true}>
                      <Box
                        display="flex"
                        flexDirection="column"
                        // padding="25px 0px"
                        className="h-gap--20"
                      >
                        <Grid
                          container
                          spacing={1}
                          direction="row"
                          alignContent="flex-start"
                          alignItems="flex-start"
                          style={{ minHeight: "100vh" }}
                          className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                        >
                              {!values.loading &&
                              <>
                              <Typography variant="h6" style={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}>{JSON.stringify(expenses) !== "[]" && "Ausgaben"}</Typography>
                              {/* {(JSON.stringify(expenses) !== "[]" && values.readData) &&
                              <Typography variant="body2" style={{ width: "100%", marginTop: "20px", marginBottom: "20px"}}>Bitte Daten vor dem Speichern kontrollieren und gegebenenfalls anpassen.</Typography>
                              } */}
                              </>
                              }
                              {expenses.map((item, i) => (
                              <>
                              <Grow
                                in={true}
                                style={{ transformOrigin: '0 0 0' }}
                                // {...(true ? { timeout: 1000 } : {})}
                              >
                              <Accordion square expanded={expanded === i} onChange={handleChangeAccordion(i)} style={{ marginTop: "10px", marginBottom: "10px" }}>
                                <AccordionSummary aria-controls="panel1d-content" id={i}>
                                  <Typography>Ausgabe {i + 1}: <span style={{ color: "red" }}>{errors.indexOf(i) !== -1 ? "Bitte Beträge überprüfen" : ""}</span><strong>{item.vendor_company}</strong></Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                  <Grid item container xs={12} spacing={1} style={{ marginTop: "10px", marginBottom: "10px" }}>
                                    <Grid item xs={12} md={6} >
                                      {/* <img style={{ maxWidth: "100% "}} src={item[1]} /> */}
                                      {item.fileType === "image" &&
                                      <InnerImageZoom src={item.url} zoomSrc={item.url} />
                                      }
                                      {item.fileType === "pdf" && 
                                      <ImageStepper imageItems={JSON.parse(item.url)}/>
                                      }

                                      {item.fileType === "none" &&
                                      <img src={LogoMonogram} alt={`${t("APP.name")} Logo`} style={{ maxWidth: "100%", opacity: 0.2 }} />
                                      }
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                      <Grid item xs={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
                                        <TextField
                                          disabled={values.loading}
                                          autoComplete="off"
                                          defaultValue={item.vendor_company}
                                          id="vendor_company"
                                          label={`Lieferant`}
                                          variant="outlined"
                                          type="text"
                                          onChange={(e) => changeExpense(e,i)}
                                          fullWidth
                                        />
                                      </Grid>

                                      <Grid item xs={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
                                        <TextField
                                          disabled={values.loading}
                                          autoComplete="off"
                                          defaultValue={item.invoice_number}
                                          id="invoice_number"
                                          label={`Rechnungsnummer`}
                                          variant="outlined"
                                          type="text"
                                          onChange={(e) => changeExpense(e,i)}
                                          fullWidth
                                        />
                                      </Grid>

                                      <Grid item xs={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
                                        <TextField
                                          disabled={values.loading}
                                          autoComplete="off"
                                          defaultValue={item.description}
                                          id="description"
                                          label={`Beschreibung`}
                                          variant="outlined"
                                          type="text"
                                          onChange={(e) => changeExpense(e,i)}
                                          fullWidth
                                        />
                                      </Grid>

                                      <Grid item xs={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
                                        <TextField
                                          disabled={values.loading}
                                          autoComplete="off"
                                          defaultValue={item.invoice_type}
                                          id="invoice_type"
                                          label={`Ausgabenkategorie`}
                                          variant="outlined"
                                          type="text"
                                          onChange={(e) => changeExpense(e,i)}
                                          fullWidth
                                        />
                                      </Grid>

                                      <Grid item xs={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
                                        <MuiPickersUtilsProvider locale={de} utils={DateFnsUtils}>
                                          <KeyboardDatePicker
                                            disabled={values.loading}
                                            fullWidth
                                            inputVariant="filled"
                                            id="invoice_date"
                                            format="dd.MM.yyyy"
                                            label="Rechnungsdatum"
                                            value={item.invoice_date}
                                            onChange={(date) => {
                                              changeExpense({target: {id: "invoice_date", value: date}}, i)
                                            }}
                                            KeyboardButtonProps={{
                                              'aria-label': 'change date',
                                            }}
                                            okLabel="OK"
                                            cancelLabel="Abbrechen"
                                          />
                                        </MuiPickersUtilsProvider>
                                      </Grid>

                                      <Grid item xs={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
                                        <MuiPickersUtilsProvider locale={de} utils={DateFnsUtils}>
                                          <KeyboardDatePicker
                                            disabled={values.loading}
                                            fullWidth
                                            inputVariant="filled"
                                            id="pay_date"
                                            format="dd.MM.yyyy"
                                            label="Zahldatum"
                                            value={item.pay_date}
                                            onChange={(date) => {
                                              changeExpense({target: {id: "pay_date", value: date}}, i)
                                            }}
                                            KeyboardButtonProps={{
                                              'aria-label': 'change date',
                                            }}
                                            okLabel="OK"
                                            cancelLabel="Abbrechen"
                                          />
                                        </MuiPickersUtilsProvider>
                                      </Grid>

                                      <Grid item xs={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
                                        <NumericFormat
                                          disabled={values.loading}
                                          fullWidth
                                          className="input-white"
                                          id="net_value"
                                          label="Preis (netto)"
                                          variant="outlined"
                                          value={item.net_value}
                                          prefix="€ "
                                          thousandSeparator="."
                                          decimalSeparator=","
                                          decimalScale={2}
                                          valueIsNumericString
                                          error={errors.indexOf(i) !== -1 ? true : false}
                                          customInput={TextField}
                                          onValueChange={({ value: v }) => {
                                            changeExpense({target: {id: "net_value", value: v}}, i)
                                          }}
                                        />
                                      </Grid>

                                      <Grid item xs={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
                                        <NumericFormat
                                          disabled={values.loading}
                                          fullWidth
                                          className="input-white"
                                          id="vat_amount"
                                          label="USt. Betrag"
                                          variant="outlined"
                                          value={item.vat_amount}
                                          prefix="€ "
                                          thousandSeparator="."
                                          decimalSeparator=","
                                          decimalScale={2}
                                          valueIsNumericString
                                          error={errors.indexOf(i) !== -1 ? true : false}
                                          customInput={TextField}
                                          onValueChange={({ value: v }) => {
                                            changeExpense({target: {id: "vat_amount", value: v}}, i)
                                          }}
                                        />
                                      </Grid>

                                      <Grid item xs={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
                                        <NumericFormat
                                          disabled={values.loading}
                                          fullWidth
                                          className="input-white"
                                          id="gross_value"
                                          label="Preis (brutto)"
                                          variant="outlined"
                                          value={item.gross_value}
                                          prefix="€ "
                                          thousandSeparator="."
                                          decimalSeparator=","
                                          error={errors.indexOf(i) !== -1 ? true : false}
                                          decimalScale={2}
                                          valueIsNumericString
                                          customInput={TextField}
                                          onValueChange={({ value: v }) => {
                                            changeExpense({target: {id: "gross_value", value: v}}, i);
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
                                        <NumericFormat
                                          fullWidth
                                          className="input-white"
                                          id="charge"
                                          label="Anrechenbarkeit"
                                          variant="outlined"
                                          value={item.charge}
                                          decimalScale={2}
                                          thousandSeparator="."
                                          decimalSeparator=","
                                          valueIsNumericString
                                          customInput={TextField}
                                          InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                              <span style={{ color: "inherit" }}>%</span>
                                            </InputAdornment>,
                                            classes: {
                                              root: classes.cssOutlinedInput,
                                              focused: classes.cssFocused,
                                              notchedOutline: classes.notchedOutline,
                                            },
                                          }}
                                          onValueChange={({ value: v }) => {
                                            changeExpense({target: {id: "charge", value: v}}, i)
                                          }}
                                        />
                                      </Grid>

                                      <Grid item xs={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
                                        <Button
                                          size="small"
                                          className={classes.button}
                                          startIcon={<DeleteForeverIcon />}
                                          style={{ marginTop: "10px", marginBottom: "5px" }}
                                          onClick={() => {
                                            deleteExpense(i);
                                            setExpanded(false)
                                          }}
                                        >
                                          Ausgabe löschen
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </AccordionDetails>
                              </Accordion>
                              </Grow>
                              </>
                              ))}
                        </Grid>
                      </Box>
                      </Fade>
                      }

                      {(expenses.length !== 0 && openExpenseDialogAtom.expenseId !== "") &&
                      <Fade in={true}>
                      <Box
                        display="flex"
                        flexDirection="column"
                        // padding="25px 0px"
                        className="h-gap--20"
                      >
                        <Grid
                          container
                          spacing={1}
                          direction="row"
                          alignContent="flex-start"
                          alignItems="flex-start"
                          className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                        >
                          <Fade in={true}>
                            <Grid item container xs={12} spacing={1} style={{ marginTop: "10px", marginBottom: "10px" }}>
                              <Grid item xs={12} md={6} >
                                {/* <img style={{ maxWidth: "100% "}} src={item[1]} /> */}
                                {expenses[0].fileType === "image" &&
                                <InnerImageZoom src={expenses[0].url} zoomSrc={expenses[0].url} />
                                }
                                {expenses[0].fileType === "pdf" && 
                                <ImageStepper imageItems={JSON.parse(expenses[0].url)}/>
                                }

                                {expenses[0].fileType === "none" &&
                                <img src={LogoMonogram} alt={`${t("APP.name")} Logo`} style={{ maxWidth: "100%", opacity: 0.2 }} />
                                }
                              </Grid>

                              <Grid item xs={12} md={6}>
                                <Grid item xs={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
                                  <TextField
                                    disabled={values.loading}
                                    autoComplete="off"
                                    defaultValue={expenses[0].vendor_company}
                                    id="vendor_company"
                                    label={`Lieferant`}
                                    variant="outlined"
                                    type="text"
                                    onChange={(e) => changeExpense(e,0)}
                                    fullWidth
                                  />
                                </Grid>

                                <Grid item xs={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
                                  <TextField
                                    disabled={values.loading}
                                    autoComplete="off"
                                    defaultValue={expenses[0].invoice_number}
                                    id="invoice_number"
                                    label={`Rechnungsnummer`}
                                    variant="outlined"
                                    type="text"
                                    onChange={(e) => changeExpense(e,0)}
                                    fullWidth
                                  />
                                </Grid>

                                <Grid item xs={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
                                  <TextField
                                    disabled={values.loading}
                                    autoComplete="off"
                                    defaultValue={expenses[0].description}
                                    id="description"
                                    label={`Beschreibung`}
                                    variant="outlined"
                                    type="text"
                                    onChange={(e) => changeExpense(e,0)}
                                    fullWidth
                                  />
                                </Grid>

                                <Grid item xs={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
                                  <TextField
                                    disabled={values.loading}
                                    autoComplete="off"
                                    defaultValue={expenses[0].invoice_type}
                                    id="invoice_type"
                                    label={`Ausgabenkategorie`}
                                    variant="outlined"
                                    type="text"
                                    onChange={(e) => changeExpense(e,0)}
                                    fullWidth
                                  />
                                </Grid>

                                <Grid item xs={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
                                  <MuiPickersUtilsProvider locale={de} utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                      disabled={values.loading}
                                      fullWidth
                                      inputVariant="filled"
                                      id="invoice_date"
                                      format="dd.MM.yyyy"
                                      label="Rechnungsdatum"
                                      value={expenses[0].invoice_date}
                                      onChange={(date) => {
                                        changeExpense({target: {id: "invoice_date", value: date}}, 0)
                                      }}
                                      KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                      }}
                                      okLabel="OK"
                                      cancelLabel="Abbrechen"
                                    />
                                  </MuiPickersUtilsProvider>
                                </Grid>

                                <Grid item xs={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
                                  <MuiPickersUtilsProvider locale={de} utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                      disabled={values.loading}
                                      fullWidth
                                      inputVariant="filled"
                                      id="pay_date"
                                      format="dd.MM.yyyy"
                                      label="Zahldatum"
                                      value={expenses[0].pay_date}
                                      onChange={(date) => {
                                        changeExpense({target: {id: "pay_date", value: date}}, 0)
                                      }}
                                      KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                      }}
                                      okLabel="OK"
                                      cancelLabel="Abbrechen"
                                    />
                                  </MuiPickersUtilsProvider>
                                </Grid>

                                <Grid item xs={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
                                  <NumericFormat
                                    disabled={values.loading}
                                    fullWidth
                                    className="input-white"
                                    id="net_value"
                                    label="Preis (netto)"
                                    variant="outlined"
                                    value={expenses[0].net_value}
                                    prefix="€ "
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    decimalScale={2}
                                    valueIsNumericString
                                    customInput={TextField}
                                    onValueChange={({ value: v }) => {
                                      changeExpense({target: {id: "net_value", value: v}}, 0)
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
                                  <NumericFormat
                                    disabled={values.loading}
                                    fullWidth
                                    className="input-white"
                                    id="vat_amount"
                                    label="USt. Betrag"
                                    variant="outlined"
                                    value={expenses[0].vat_amount}
                                    prefix="€ "
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    decimalScale={2}
                                    valueIsNumericString
                                    customInput={TextField}
                                    onValueChange={({ value: v }) => {
                                      changeExpense({target: {id: "vat_amount", value: v}}, 0)
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
                                  <NumericFormat
                                    disabled={values.loading}
                                    fullWidth
                                    className="input-white"
                                    id="gross_value"
                                    label="Preis (brutto)"
                                    variant="outlined"
                                    value={expenses[0].gross_value}
                                    prefix="€ "
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    error={errorSum}
                                    decimalScale={2}
                                    valueIsNumericString
                                    customInput={TextField}
                                    onValueChange={({ value: v }) => {
                                      changeExpense({target: {id: "gross_value", value: v}}, 0);
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
                                  <NumericFormat
                                    fullWidth
                                    className="input-white"
                                    id="charge"
                                    label="Anrechenbarkeit"
                                    variant="outlined"
                                    value={expenses[0].charge}
                                    decimalScale={2}
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    valueIsNumericString
                                    customInput={TextField}
                                    InputProps={{
                                      endAdornment: <InputAdornment position="end">
                                        <span style={{ color: "inherit" }}>%</span>
                                      </InputAdornment>,
                                      classes: {
                                        root: classes.cssOutlinedInput,
                                        focused: classes.cssFocused,
                                        notchedOutline: classes.notchedOutline,
                                      },
                                    }}
                                    onValueChange={({ value: v }) => {
                                      changeExpense({target: {id: "charge", value: v}}, 0)
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Fade>
                        </Grid>
                      </Box>
                      </Fade>
                      }
                    </Box>
                  </>
              </Container>
            </Container>
            </Fade>
          </div>
        </div>
      </React.Fragment>


      {/* <Backdrop className={classes.backdrop} open={createExpenses}>
        <CircularProgress color="inherit" />
      </Backdrop> */}


    </React.Fragment>
  );
};

export default ExpenseDialog;
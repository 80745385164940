import { useEffect } from 'react';

const useInvoiceCalculations = (items, values, setValues, selectedClient) => {
  const discountAbsoluteValue = JSON.parse(parseFloat(values.discountAbsolute));
  const discountPercentValue = JSON.parse(values.discountPercent);
  const discountValueValue = JSON.parse(values.discountValue);
  const discountedTotalValue = JSON.parse(parseFloat(values.discountedTotal));

  useEffect(() => {
    if (selectedClient) {
      const calculateSubtotal = () => {
        let total = 0;
        items.forEach(function (item) {
          total += item.discountedTotal;
        });
        return total;
      };

      const calculateDiscountValue = () => {
        const discountValue = values.discount === "absolute" ? values.discountAbsolute : (calculateSubtotal() * discountPercentValue / 100);
        return discountValue;
      };

      const calculateDiscountedSubtotal = () => {
        const discountedSubtotal = calculateSubtotal() - discountValueValue;
        return discountedSubtotal;
      };

      let discountedTotal = discountedTotalValue;

      const vatGroups = items.reduce((vatGroups, item) => {
        item["vatPercent"] = item.vatPercent !== "" ? item.vatPercent : selectedClient.vatPercent !== "" ? selectedClient.vatPercent : 0;
        const group = (vatGroups[item.vatPercent] || ["", 0, 0]); // i=0 -> vat percentage, i=1 -> vat absolute, i=2 -> net value
        group[0] = item.vatPercent;

        if (values.discount === "none") {
          group[1] += (parseInt(item.vatPercent) / 100 * item.discountedTotal);
          discountedTotal += (1 + (parseInt(item.vatPercent) / 100)) * item.discountedTotal;
          group[2] += item.discountedTotal;
        } else {
          if (values.discount === "absolute") {
            group[1] += parseInt(item.vatPercent) / 100 * (item.discountedTotal - (item.discountedTotal / calculateSubtotal() * parseFloat(discountValueValue)));
            discountedTotal += (1 + (parseInt(item.vatPercent) / 100)) * (item.discountedTotal - (item.discountedTotal / calculateSubtotal() * parseFloat(discountValueValue)));
            group[2] += item.discountedTotal - (item.discountedTotal / calculateSubtotal() * parseFloat(discountValueValue));
          } else if (values.discount === "percent") {
            group[1] += (parseInt(item.vatPercent) / 100 * item.discountedTotal * (1 - parseFloat(discountPercentValue) / 100));
            discountedTotal += (1 + (parseInt(item.vatPercent) / 100)) * item.discountedTotal * (1 - parseFloat(discountPercentValue) / 100);
            group[2] += item.discountedTotal * (1 - parseFloat(discountPercentValue) / 100);
          }
        }

        vatGroups[item.vatPercent] = group;
        return vatGroups;
      }, {});

      // Add reminderRequestCharge only once, outside the loop
      if (values.reminderRequestCharge !== 0 && values.reminderRequestCharge !== 0.00 && values.reminderRequestCharge !== "0.00" && values.reminderRequestCharge !== "") {
        discountedTotal += parseFloat(values.reminderRequestCharge);
      }

      setValues({
        ...values,
        vatValues: JSON.stringify(vatGroups),
        subtotal: calculateSubtotal(),
        total: discountedTotal,
        discountValue: calculateDiscountValue(),
        discountedSubtotal: calculateDiscountedSubtotal(),
      });
    }
  }, [items, values.discount, values.discountValue, values.discountAbsolute, values.discountPercent, values.reminderRequestCharge, selectedClient]);
};

export default useInvoiceCalculations;

import React from 'react';
import { BottomNavigation, Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import MailIcon from '@material-ui/icons/Mail';
import ButtonLoadingBottom from '../../../shared/components/UIElements/ButtonLoadingBottom';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';


const InvoiceBottomNavigation = ({
  selectedClient,
  settings,
  settingsAtom,
  setSettingsAtom,
  values,
  isLoading,
  buttonLoadingAction,
  invoiceHandler,
  setButtonLoadingAction,
  openInvoiceDialogAtom,
  setOpen,
  setSendMode,
  saveNewInvoiceSchema,
  disabledEditing
}) => {

  return (
    <>
      {(selectedClient && values.sendState.state !== "scheduled") &&
        <BottomNavigation
          showLabels
          style={{ position: "fixed", width: "100%", zIndex: 999, bottom: 0, background: "transparent", margin: "0 auto" }}
          className="box-shadow--2"
        >
          <div
            style={{
              boxShadow: "rgba(118, 118, 118, 0.2) 0px -4px 8px, rgba(118, 118, 118, 0.2) 4px 0px 8px, rgba(118, 118, 118, 0.2) -4px 0px 8px",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px"
            }}
          >
            <ButtonLoadingBottom
              loading={isLoading && (buttonLoadingAction === "save")}
              disabled={isLoading}
              color="primary"
              type="submit"
              size="large"
              className="button--loading"
              borderTopLeftRadius="10px"
              style={{ background: "white", color: "inherit" }}
              borderTopRightRadius={(selectedClient !== "" && settings.invoicesEmailServer !== "download" && openInvoiceDialogAtom.invoiceId !== "" && !values.cancelled && !disabledEditing) ? "0px" : "10px"}
              startIcon={<PictureAsPdfIcon />}
              onClick={() => {
                setSendMode(false);
                setOpen(true);
                invoiceHandler(false);

                if (saveNewInvoiceSchema) {
                  setSettingsAtom((prev) => ({
                    ...prev,
                    invoicesNumberItems: values.invoiceNr,
                  }));
                }
              }}
            >
              <span style={{display: "flex", flexDirection: "column", alignItems: "flex-start", lineHeight: 1.05}}>
                <span style={{ fontSize: 9}}>Speichern und</span>
                <span>Vorschau</span>
              </span>
            </ButtonLoadingBottom>
            

            <ButtonLoadingBottom
              loading={isLoading && (buttonLoadingAction === "save")}
              disabled={isLoading}
              color="primary"
              type="submit"
              size="large"
              className="button--loading button-save"
              borderTopLeftRadius="0px"
              borderTopRightRadius={(selectedClient !== "" && settings.invoicesEmailServer !== "download" && openInvoiceDialogAtom.invoiceId !== "" && values.sendState !== "scheduled") ? "0px" : "10px"}
              startIcon={<SaveIcon />}
              onClick={(e) => {
                invoiceHandler(true);
                setButtonLoadingAction("save");
              
                if (saveNewInvoiceSchema) {
                setSettingsAtom((prev) => ({
                  ...prev,
                  invoicesNumberItems: values.invoiceNr,
                }));
              }
              }}
            >
              Speichern
            </ButtonLoadingBottom>

            {(!values.cancelled && selectedClient !== "" && settings.invoicesEmailServer !== "download" && openInvoiceDialogAtom.invoiceId !== "" && values.sendState !== "scheduled") &&
              <ButtonLoadingBottom
                loading={isLoading && (buttonLoadingAction === "save and send")}
                disabled={isLoading}
                color="primaryold"
                type="submit"
                size="large"
                className="button--loading button-send"
                borderTopLeftRadius={(selectedClient !== "" && settings.invoicesEmailServer !== "download" && openInvoiceDialogAtom.invoiceId !== "") ? "0px" : "10px"}
                borderTopRightRadius="10px"
                startIcon={<MailIcon />}
                onClick={(e) => {
                  invoiceHandler(false);
                  setButtonLoadingAction("save and send");
                  setOpen(true);
                  setSendMode(true)

                  if (saveNewInvoiceSchema) {
                    setSettingsAtom((prev) => ({
                      ...prev,
                      invoicesNumberItems: values.invoiceNr,
                    }));
                  }
                }}
              >
                <span style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                  {/* <span style={{ fontSize: 9}}>Speichern und planen:</span> */}
                  <span>{!values.recurring ? "Versand" : "Versand"}</span>
                </span>
              </ButtonLoadingBottom>
            }
          </div>
        </BottomNavigation>
      }
    </>
  );
};

export default InvoiceBottomNavigation;

import React, { useState, useEffect, useContext } from "react";

// IMPORT recoil
import {
    useRecoilState,
} from 'recoil';
import settingsAtomState from "../../_atoms/settingsAtom";
import userAtomState from "../../_atoms/userAtom";


import { useTranslation } from "react-i18next";

// IMPORT components MATERIAL UI
import {
    Typography,
    Button,
    Box,
    TextField,
    Container,
    Grid,
    InputAdornment,
    makeStyles,
    Chip,
    FormHelperText
} from "@material-ui/core";
import Fade from '@material-ui/core/Fade';


import MailOutlineIcon from '@material-ui/icons/MailOutline';

import { DB_PATCH_SETTINGS } from '../../_functions/DB_SETTINGS';

// IMPORT components
import { useSnackbar } from 'notistack';

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";

import { useAuth } from "../../shared/hooks/auth-hook";

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import InputLabel from '@material-ui/core/InputLabel';


import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import SettingsIcon from '@material-ui/icons/Settings';
import SaveIcon from '@material-ui/icons/Save';

import ButtonLoading from "../../shared/components/UIElements/ButtonLoading";
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 250,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    toolbar: {
        height: '0px',
        '@media (max-width: 959px)': {
          height: '62px',
        },
        '@media (max-width: 599px)': {
          height: '45px',
        },
        '@media (max-width: 455px)': {
          height: '55px',
        },
      },
}));

export default function Settings() {
    // GLOBAL STATE (RECOIL)
    const [settingsAtom, setSettingsAtom] = useRecoilState(settingsAtomState);
    const [userAtom, setUserAtom] = useRecoilState(userAtomState);


    // HOOKS & CONTEXT
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const auth = useContext(AuthContext);

    const [showPassword, setShowPassword] = React.useState(false);

    const [values, setValues] = useState("");
    const [cc, setCc] = useState({
        state: "",
        isEmail: false,
    });
    const [ccItems, setCcItems] = useState([]);
    const [bcc, setBcc] = useState({
        state: "",
        isEmail: false,
    });
    const [bccItems, setBccItems] = useState([]);

    const [smtpHostError, setSmtpHostError] = useState(false);
    const [smtpUserError, setSmtpUserError] = useState(false);
    const [smtpPassError, setSmtpPassError] = useState(false);
    const [smtpFromError, setSmtpFromError] = useState(false);
    const [smtpEmailError, setSmtpEmailError] = useState(false);
    const [smtpPortError, setSmtpPortError] = useState(false);

    
    const [testEmail, setTestEmail] = useState("neutral");

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        let passInfo = settingsAtom.invoicesEmailPassInfo ? "password_is_already_set" : ""
        setValues({...values, ...settingsAtom, invoicesEmailPass: passInfo})
        setCcItems(JSON.parse(settingsAtom.cc))
        setBccItems(JSON.parse(settingsAtom.bcc))
        testSMTP()
    }, [settingsAtom]);

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
        testSMTP();
    };


    const handleChangeCc = (event) => {
        setCc({
            state: event.target.value,
            isEmail: (/^\S+@\S+\.\S+$/).test(event.target.value) ? true : false
        })
    };

    const handleChangeBcc = (event) => {
        setBcc({
            state: event.target.value,
            isEmail: (/^\S+@\S+\.\S+$/).test(event.target.value) ? true : false
        })
    };

    const addCc = () => {
        setCcItems((items) => [
            ...items,
            cc.state
        ]);
        setCc({
            state: "",
            isEmail: false,
        });
    };

    const addBcc = () => {
        setBccItems((items) => [
            ...items,
            bcc.state
        ]);
        setBcc({
            state: "",
            isEmail: false,
        });
    };

    const handleDeleteCc = (index) => {
        setCcItems((current) =>
            current.filter((item, i) => {
                return i !== index;
            })
        );
    };

    const handleDeleteBcc = (index) => {
        setBccItems((current) =>
            current.filter((item, i) => {
                return i !== index;
            })
        );
    };

    const testSMTP = () => {
        if (
        ((/^\S+@\S+\.\S+$/).test(values.invoicesEmailAddress) && values.invoicesEmailAddress !== undefined) &&
        (values.invoicesEmailHost !== "" && values.invoicesEmailHost !== undefined) &&
        (values.invoicesEmailUser !== "" && values.invoicesEmailUser !== undefined) &&
        (values.invoicesEmailPass !== "" && values.invoicesEmailPass !== undefined) &&
        (values.invoicesEmailAddress !== "" && values.invoicesEmailAddress !== undefined) &&
        (values.invoicesEmailFrom !== "" && values.invoicesEmailFrom !== undefined)
        ) {
            return true
        } else {
            return false
        }
    };


    const save = async () => {
        if ((values.invoicesEmailServer === "own" && testSMTP())
            || values.invoicesEmailServer === "download"
            || values.invoicesEmailServer === "dagowert"
            ) {
            setSettingsAtom({...values, cc: JSON.stringify(ccItems), bcc: JSON.stringify(bccItems), invoicesEmailPassInfo: values.invoicesEmailPass === "" ? false : true});

            if (auth.token) {
                DB_PATCH_SETTINGS({...values, cc: JSON.stringify(ccItems), bcc: JSON.stringify(bccItems)}, auth, sendRequest, enqueueSnackbar, t, true);
            }

            setSmtpHostError(false)
            setSmtpUserError(false)
            setSmtpPassError(false)
            setSmtpEmailError(false)
            setSmtpFromError(false)
            setSmtpPortError(false)
            setTestEmail("neutral")
        } else {
            if (values.invoicesEmailHost === "" || values.invoicesEmailHost === undefined) {
                setSmtpHostError(true)
            }
            if (values.invoicesEmailUser === "" || values.invoicesEmailUser === undefined) {
                setSmtpUserError(true)
            }
            if (values.invoicesEmailPass === "" || values.invoicesEmailPass === undefined) {
                setSmtpPassError(true)
            }
            if (values.invoicesEmailAddress === "" || values.invoicesEmailAddress === undefined || !(/^\S+@\S+\.\S+$/).test(values.invoicesEmailAddress)) {
                setSmtpEmailError(true)
            }
            if (values.invoicesEmailFrom === "" || values.invoicesEmailFrom === undefined) {
                setSmtpFromError(true)
            }
            if (values.invoicesEmailPort === "" || values.invoicesEmailPort === undefined) {
                setSmtpPortError(true)
            }
            return
        } 
    };

    const sendSmtpTest = () => {
        (async () => {
            try {
            
              const formData = new FormData();
              formData.append("host", values.invoicesEmailHost);
              formData.append("user", values.invoicesEmailUser);
              formData.append("pass", values.invoicesEmailPass);
              formData.append("from", values.invoicesEmailFrom);
              formData.append("email", values.invoicesEmailAddress);
              formData.append("port", values.invoicesEmailPort);
              formData.append("emailto", userAtom.data.email);
              formData.append("id", values._id);

              
            const res = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL_API}/invoices/testsmtp`,
                "POST",
                formData,
                {
                // "Content-Type": "application/json",
                Authorization: "Bearer " + auth.token,
                }
            )
                .then((res) => setTestEmail("success"))
                // .then(() => setEmailSent(true))
                // .then(() => setZoom(!zoom))
                // .then(() => invoiceHandler("sent", sendDate, jsonString))
             

            } catch (err) {
              setTestEmail("error")
            }
          })();
        
    }

    return (
        <div>
            <div className={classes.toolbar} />
            <Fade in={true}>
                <Grid container className="subpage-header" maxWidth={false} item direction="row" alignItems="center" alignContent="center">
                <Grid item container alignItems="center" style={{ marginRight: "10px" }}>
                    <SettingsIcon color="primary" fontSize="large" style={{ background: "linear-gradient(to right top, rgb(38, 108, 189), rgb(36, 115, 192), rgb(33, 150, 243), #00bca7, #00bf72)", marginRight: "15px" }} className="icon--with-bg--1" />
                    <Typography variant="body1" component="div" style={{ fontWeight: "500" }} className="headline">Einstellungen</Typography>
                </Grid>
                </Grid>
            </Fade>

            <Container maxWidth={false} disableGutters className="bg--1" >
            <Box
              display="flex"
              flexDirection="column"
              className="list-box"
            >

                    <Box display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        margin="0 25px"
                        className="h-gap--25"
                    >

                        <Box
                            display="flex"
                            flexDirection="column"
                            padding="25px 0px"
                            className="h-gap--20"
                        >
                            <Grid
                                container
                                spacing={5}
                                direction="row"
                                alignContent="flex-start"
                                alignItems="flex-start"
                                className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                            >

                                {values !== "" &&
                                    <Grid container spacing={3}>
                                        <Grid container item lg={12}>
                                            <Grid container item lg={12}>


                                                <Grid container item lg={12}>
                                                    <Typography variant="body1" component="div" style={{ fontWeight: "bold" }}>
                                                        E-Mail-Versand von Kostenvoranschlägen, Angeboten, Rechnungen, Zahlungserinnerungen, etc.
                                                    </Typography>
                                                </Grid>

                                                <Grid container item lg={12} style={{ marginBottom: "20px" }}>
                                                    <FormControl component="fieldset">
                                                        <RadioGroup aria-label="invoicesEmailServer" name="invoicesEmailServer" value={values.invoicesEmailServer} onChange={handleChange}>
                                                            <FormControlLabel value="download" control={<Radio color="primary" style={{ paddingTop: "5px", paddingBottom: "2px" }} />} label="PDF herunterladen und selbst versenden" />
                                                            <FormControlLabel value="dagowert" control={<Radio color="primary" style={{ paddingTop: "5px", paddingBottom: "2px" }} />} label="Über DagoWert-Server versenden" />
                                                            <FormControlLabel value="own" control={<Radio color="primary" style={{ paddingTop: "5px", paddingBottom: "2px" }} />} label="Über eigenen SMTP Server versenden (empfohlen)" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>



                                                {values.invoicesEmailServer === "own" &&
                                                    <Grid container item lg={12} spacing={1}>
                                                        <Grid container item xs={12} lg={6}>
                                                            <TextField
                                                                required
                                                                id="invoicesEmailHost"
                                                                name="invoicesEmailHost"
                                                                label="SMTP Host"
                                                                variant="outlined"
                                                                type="text"
                                                                defaultValue={values.invoicesEmailHost}
                                                                error={smtpHostError}
                                                                helperText={
                                                                    smtpHostError ? "Bitte ausfüllen" : ""
                                                                }
                                                                onChange={handleChange}
                                                                // onBlur={() => validate("company")}
                                                                fullWidth
                                                            />
                                                        </Grid>

                                                        <Grid container item xs={12} lg={6}>
                                                            <TextField
                                                                required
                                                                id="invoicesEmailUser"
                                                                name="invoicesEmailUser"
                                                                label="SMTP Benutzername"
                                                                variant="outlined"
                                                                type="text"
                                                                defaultValue={values.invoicesEmailUser}
                                                                error={smtpUserError}
                                                                helperText={
                                                                    smtpUserError ? "Bitte ausfüllen" : ""
                                                                }
                                                                onChange={handleChange}
                                                                // onBlur={() => validate("company")}
                                                                fullWidth
                                                            />
                                                        </Grid>


                                                        <Grid container item xs={12} lg={6}>
                                                            <FormControl variant="outlined" fullWidth>
                                                                <InputLabel htmlFor="outlined-adornment-password">
                                                                    SMTP Passwort *
                                                                </InputLabel>
                                                                <OutlinedInput
                                                                    disabled={values.invoicesEmailPassInfo === true} // A password has already been set
                                                                    required
                                                                    id="invoicesEmailPass"
                                                                    name="invoicesEmailPass"
                                                                    type={showPassword ? "text" : "password"}
                                                                    defaultValue={values.invoicesEmailPassInfo !== true ? values.invoicesEmailPass : "password_is_already_set"}
                                                                    value={values.invoicesEmailPass}
                                                                    onChange={handleChange}
                                                                    // onBlur={() => validate("password")}
                                                                    autoComplete="current-password"
                                                                    endAdornment={
                                                                        <InputAdornment position="end">
                                                                            {values.invoicesEmailPassInfo !== true && 
                                                                            <IconButton
                                                                                aria-label="toggle password visibility"
                                                                                onClick={handleClickShowPassword}
                                                                                onMouseDown={handleMouseDownPassword}
                                                                            >
                                                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                                            </IconButton>
                                                                            }
                                                                            {values.invoicesEmailPassInfo === true &&
                                                                            <Button size="small" color="primary" onClick={()=>{setValues({...values, invoicesEmailPassInfo: false, invoicesEmailPass: ""})}}>
                                                                                Ändern
                                                                            </Button>
                                                                            }
                                                                        </InputAdornment>
                                                                    }
                                                                    labelWidth={135}
                                                                />
                                                                <FormHelperText
                                                                                    error={smtpPassError}
                                                                                    id="form-helper-text-password"
                                                                                >
                                                                                    {smtpPassError ? "Bitte ausfüllen" : ""}
                                                                                </FormHelperText>
                                                            </FormControl>
                                                        </Grid>

                                                        <Grid container item xs={12} lg={6}>
                                                            <TextField
                                                                required
                                                                id="invoicesEmailPort"
                                                                name="invoicesEmailPort"
                                                                label="Port"
                                                                variant="outlined"
                                                                type="text"
                                                                defaultValue={values.invoicesEmailPort}
                                                                error={smtpPortError}
                                                                helperText={
                                                                    smtpPortError ? "Bitte ausfüllen" : ""
                                                                }
                                                                onChange={handleChange}
                                                                // onBlur={() => validate("company")}
                                                                fullWidth
                                                            />
                                                        </Grid>


                                                        <Grid container item xs={12} lg={6}>
                                                            <TextField
                                                                required
                                                                id="invoicesEmailFrom"
                                                                name="invoicesEmailFrom"
                                                                label="Absender-Name für Emails"
                                                                variant="outlined"
                                                                type="text"
                                                                defaultValue={values.invoicesEmailFrom}
                                                                error={smtpFromError}
                                                                helperText={
                                                                    smtpFromError ? "Bitte ausfüllen" : ""
                                                                }
                                                                onChange={handleChange}
                                                                // onBlur={() => validate("company")}
                                                                fullWidth
                                                            />
                                                        </Grid>

                                                        <Grid container item xs={12} lg={6}>
                                                            <TextField
                                                                required
                                                                id="invoicesEmailAddress"
                                                                name="invoicesEmailAddress"
                                                                label="Absender E-Mail-Adresse"
                                                                variant="outlined"
                                                                type="text"
                                                                defaultValue={values.invoicesEmailAddress}
                                                                error={smtpEmailError}
                                                                helperText={
                                                                    smtpEmailError ? "Bitte eine gültige Email-Adresse eingeben" : ""
                                                                }
                                                                onChange={handleChange}
                                                                // onBlur={() => validate("company")}
                                                                fullWidth
                                                            />
                                                        </Grid>

                                                        {!values.invoicesEmailPassInfo &&
                                                        <Grid container item xs={12}>
                                                            <Grid container item xs={12} lg={6}>
                                                                <Button style={{ width: "100%"}} startIcon={<MailOutlineIcon />} disabled={!testSMTP()} size="small" variant="contained" color="primary" onClick={()=>sendSmtpTest()}>Test-Email an <span style={{ textTransform: "none", fontWeight: "700", paddingLeft: "5px", paddingRight: "5px" }}>{userAtom.data.email}</span> senden</Button>
                                                            </Grid>
                                                        </Grid>
                                                        }

                                                        <Grid container item xs={12}>
                                                            <Grid container item xs={12} lg={6}>
                                                                {testEmail === "success" &&
                                                                <Alert severity="success" style={{ width: "100%"}}>
                                                                    Die Test-Email wurde erfolgreich versendet.
                                                                </Alert>
                                                                }

                                                                {testEmail === "error" &&
                                                                <Alert severity="error" style={{ width: "100%"}}>
                                                                    Die Test-Email konnte nicht versendet werden. Bitte Angaben überprüfen!
                                                                </Alert>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                }

                                                {values.invoicesEmailServer !== "download" &&
                                                <Grid container item lg={12} spacing={1} style={{ marginTop: "20px"}}>
                                                    <Grid container item xs={12}>
                                                        <Typography variant="body1" component="div" style={{ fontWeight: "bold" }}>
                                                                E-Mails in Kopie (CC) an folgende Adressen schicken 
                                                        </Typography>
                                                    </Grid>
                                                    <Grid container item xs={12}>
                                                        <Typography variant="body1" component="div" style={{ fontSize: "13px" }}>
                                                                Hier können Email-Adressen hinterlegt werden, an die jede Email als KOPIE versendet wird. Wenn eine Rechnungs-Email nicht nur an den Kunden sondern auch zum Beispiel an die eigene E-Mail-Adresse geschickt werden soll, bitte hier diese E-Mail-Adresse hinterlegen.
                                                        </Typography>
                                                    </Grid>
                                                    <Grid container item xs={12}>
                                                        <Grid container item xs={12} style={{ marginBottom: "10px" }} alignItems="center">
                                                            <Typography variant="body1" component="div" style={{ fontSize: "13px", marginRight: "5px" }}>
                                                                    <strong>CC-Adressen:</strong>
                                                            </Typography>
                                                            {ccItems.length !== 0 && ccItems.map((item, i) => (
                                                                <Chip key={i} variant="outlined" label={item} onDelete={()=>handleDeleteCc(i)} style={{ marginRight: "5px", marginTop: "5px", marginBottom: "5px"}}/>
                                                            ))}
                                                        </Grid>
                                                        <Grid container item xs={12} lg={6}>
                                                            <TextField
                                                                id="cc"
                                                                name="cc"
                                                                label="Email-Adresse ..."
                                                                variant="outlined"
                                                                type="email"
                                                                value={cc.state}
                                                                InputProps={{
                                                                    endAdornment: <InputAdornment position="end">
                                                                    <Button disabled={!cc.isEmail} size="small" variant="contained" color="secondary" onClick={()=>addCc()}>hinzufügen</Button>
                                                                    </InputAdornment>,
                                                                }}
                                                                onChange={(e)=>
                                                                    handleChangeCc(e) 
                                                                }
                                                                onKeyDown={(e) => {
                                                                    // On Spacebar or Enter
                                                                    if ((e.keyCode === 32 || e.key === "Enter") && cc.isEmail) {
                                                                      addCc();
                                                                    }
                                                                }}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                }

                                                {values.invoicesEmailServer !== "download" &&
                                                <Grid container item lg={12} spacing={1} style={{ marginTop: "20px"}}>
                                                    <Grid container item xs={12}>
                                                        <Typography variant="body1" component="div" style={{ fontWeight: "bold" }}>
                                                                E-Mails in Blind-Kopie (BCC) an folgende Adressen schicken 
                                                        </Typography>
                                                    </Grid>
                                                    <Grid container item xs={12}>
                                                        <Typography variant="body1" component="div" style={{ fontSize: "13px" }}>
                                                                Hier können Email-Adressen hinterlegt werden, an die jede Email als BLIND-KOPIE versendet wird. Wenn eine Rechnungs-Email nicht nur an den Kunden sondern auch zum Beispiel an die eigene E-Mail-Adresse geschickt werden soll, bitte hier diese E-Mail-Adresse hinterlegen.
                                                        </Typography>
                                                    </Grid>
                                                    <Grid container item xs={12}>
                                                        <Grid container item xs={12} style={{ marginBottom: "10px" }} alignItems="center">
                                                            <Typography variant="body1" component="div" style={{ fontSize: "13px", marginRight: "5px" }}>
                                                                    <strong>BCC-Adressen:</strong>
                                                            </Typography>
                                                            {bccItems.length !== 0 && bccItems.map((item, i) => (
                                                                <Chip key={i} variant="outlined" label={item} onDelete={()=>handleDeleteBcc(i)} style={{ marginRight: "5px", marginTop: "5px", marginBottom: "5px"}}/>
                                                            ))}
                                                        </Grid>
                                                        <Grid container item xs={12} lg={6}>
                                                            <TextField
                                                                id="bcc"
                                                                name="bcc"
                                                                label="Email-Adresse ..."
                                                                variant="outlined"
                                                                type="email"
                                                                value={bcc.state}
                                                                InputProps={{
                                                                    endAdornment: <InputAdornment position="end">
                                                                    <Button disabled={!bcc.isEmail} size="small" variant="contained" color="secondary" onClick={()=>addBcc()}>hinzufügen</Button>
                                                                    </InputAdornment>,
                                                                }}
                                                                onChange={(e)=>
                                                                    handleChangeBcc(e) 
                                                                }
                                                                onKeyDown={(e) => {
                                                                    // On Spacebar or Enter
                                                                    if ((e.keyCode === 32 || e.key === "Enter") && bcc.isEmail) {
                                                                      addBcc();
                                                                    }
                                                                }}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }


                            </Grid>
                        </Box>



                        <Grid container item lg={12} spacing={2} direction="column" alignItems="center">

                                <Grid item>
                                    <ButtonLoading
                                        loading={isLoading}
                                        disabled={isLoading}
                                        type="submit"
                                        onClick={() => save()}
                                        fullWidth
                                        size="large"
                                        className="button--loading"
                                        startIcon={<SaveIcon style={{ marginRight: "5px" }} />}
                                    >
                                        {t("BUTTONS.save")}
                                    </ButtonLoading>
                                </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </div>
    );
}

import React, {
  useState,
  useContext,
  useEffect,
} from "react";

// IMPORT hooks & context
import { useTranslation } from "react-i18next";
import { countryToFlag } from "../../shared/functions/countryToFlag";
import { COUNTRIES } from "../../shared/util/countries_de";
import { useForm } from "../../shared/hooks/form-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from '../../shared/context/auth-context';
import { useAuth } from "../../shared/hooks/auth-hook";

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import settingsAtomState from "../../_atoms/settingsAtom";
import openClientDialogAtomState from "../../_atoms/openClientDialogAtom";
import clientsAtomState from "../../_atoms/clientsAtom";
import clientsFilteredAtomState from "../../_atoms/clientsFilteredAtom";
import userAtomState from "../../_atoms/userAtom";


// IMPORT functions
import { VALIDATE } from '../../_functions/VALIDATE';
import { SET_VALUES } from '../../_functions/SET_VALUES';
import { VAT } from '../../_functions/VAT';
import { DB_PATCH_SETTINGS } from '../../_functions/DB_SETTINGS';
import { DB_DELETE_CLIENT } from '../../_functions/DB_CLIENTS';
import { DB_GET_CLIENT } from '../../_functions/DB_CLIENTS';


// IMPORT components
import { useSnackbar } from 'notistack';
import DropzoneUploaderSingle from "../../shared/components/DropzoneUploader/DropzoneUploaderSingle";
import ButtonLoadingBottom from "../../shared/components/UIElements/ButtonLoadingBottom";
import ClientNumber from "./components/ClientNumber";
import ClientAppBar from "../client_dialog/components/ClientAppBar";
import ClientAlerts from "../client_dialog/components/ClientAlerts";
import ClientNumberEdit from "./components/ClientNumberEdit";

// IMPORT components MATERIAL UI
import {
  Typography,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  Grid,
  Box,
  InputAdornment,
  TextField,
  CircularProgress,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  ListItemIcon,
  ListItemText,
  BottomNavigation,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import Autocomplete from "@material-ui/lab/Autocomplete";

// IMPORT icons MATERIAL UI
import CloseIcon from "@material-ui/icons/Close";
import ClientsIcon from "@material-ui/icons/RecentActors";
import SaveIcon from '@material-ui/icons/Save';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

// IMPORT own components
import Logo from "../../_assets/logo_colored.svg";
import ButtonLoading from "../../shared/components/UIElements/ButtonLoading";
import ConfirmModal from "../../shared/components/UIElements/Modal";
import changeContinuousNumber from "./utils/CHANGE_CONTINUOUS_NUMBER";

// IMPORT own css
import "./ClientDialog.scss"

const useStyles = makeStyles((theme) => {
  return {
    toolbar: theme.mixins.toolbar,
    stepContent: {
      display: "flex",
      justifyContent: "center"
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  };
});

const ClientDialog = (props) => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const classes = useStyles();
  const { userId } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  // GLOBAL STATE (RECOIL)
  const [userAtom, setUserAtom] = useRecoilState(userAtomState);
  const [openClientDialogAtom, setOpenClientDialogAtom] = useRecoilState(openClientDialogAtomState);
  const [clientsAtom, setClientsAtom] = useRecoilState(clientsAtomState);
  const [, setClientsFilteredAtom] = useRecoilState(clientsFilteredAtomState);
  const [settingsAtom, setSettingsAtom] = useRecoilState(settingsAtomState);

  // LOCAL
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [settings, setSettings] = useState(settingsAtom);
  const [values, setValues] = useState({
    email: {
      state: "",
      error: false,
      errorMsg: t("FIELDS.email.error"),
    },
    firstName: {
      state: "",
      error: false,
      errorMsg: t("FIELDS.firstName.error"),
    },
    lastName: {
      state: "",
      error: false,
      errorMsg: t("FIELDS.lastName.error"),
    },
    company: {
      state: "",
      error: false,
      errorMsg: t("FIELDS.company.error"),
    },
    street: {
      state: "",
      error: false,
      errorMsg: t("FIELDS.street.error"),
    },
    streetNr: {
      state: "",
      error: false,
      errorMsg: t("FIELDS.streetNr.error"),
    },
    zip: {
      state: "",
      error: false,
      errorMsg: t("FIELDS.zip.error"),
    },
    city: {
      state: "",
      error: false,
      errorMsg: t("FIELDS.city.error"),
    },
    country: {
      state: "",
      error: false,
      errorMsg: t("FIELDS.country.error"),
    },
    precedingTitle: {
      // optional
      state: "",
    },
    followingTitle: {
      // optional
      state: ""
    },
    bank: {
      // optional
      state: "",
    },
    phone: {
      // optional
      state: "",
    },
    vat: {
      // optional
      state: "",
      digits: 0, // e.g. Austria: 8, Germany: 9 -> see https://www.finanz.at/steuern/umsatzsteuer/uid-nummer/
      code: "", // e.g. ATU, DE, etc ...
      error: false,
      errorMsg: t("FIELDS.vat.error"),
    },
    vatPercent: {
      // optional
      state: "",
    },
    image: {
      // optional
      state: "",
    },
    logo: "",
    showClientNumber: settingsAtom.clientsNumber === "manual" ? true : false,
    clientNumber: {
      state: [],
      error: false,
      errorMsg: t("FIELDS.clientNumber.errorMissing"),
    },
    clientNumberValues: {
      clientsLength: 0,
      arrClientNumbers: [0], // Array of all client numbers in use. 0 has to be default state to make client numbers start at 1.
      missingClientNumbers: [] // Client numbers that are "free" due to client deletion
    },
    status: {
      state: "",
    },
    category: "company",
    salutation: "ms",
    politeform: "formal",
    loading: true
  });

  const [formState, inputHandler] = useForm(
    {
      image: {
        value: null,
        isValid: false,
      },
    },
    false
  );



  // NEW FETCHING
  // useEffect(() => {
  //   const fetchClientDetails = async () => {
  //     try {
  //       const clientDetails = await DB_GET_CLIENT(openClientDialogAtom.clientId, auth, sendRequest);
  //       console.log(clientDetails); // Handle the client details as needed
  //     } catch (error) {
  //       console.error('Error fetching client details:', error);
  //     }
  //   };

  //   fetchClientDetails();
  // }, [openClientDialogAtom.clientId, auth, sendRequest]); // Add dependencies if necessary


  /********************* 
    Component Functions
  **********************/

  // INVOICE NUMBER MENU DOTS
  const [addElementOpen, setAddElementOpen] = useState(false);
  const [openClientNumberEdit, setOpenClientNumberEdit] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openClientNrMenuDots = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [saveNewClientSchema, setSaveNewClientSchema] = useState(false)



  useEffect(() => {
    // UPDATE SETTINGS: CONTINUOUS NUMBER IN SCHEMA when client is created (but only update it if clioent is saved => see clientHandler)
    let clientNumberItems = (typeof (settingsAtom.clientsNumberItems) === "string" ? JSON.parse(settingsAtom.clientsNumberItems) : settingsAtom.clientsNumberItems)
    const currentIndex = clientNumberItems.findIndex((elements) => elements.type === "continuousNumber");
    if (currentIndex !== -1) {
      let currentId = clientNumberItems[currentIndex].id;
      let currentValue = clientNumberItems[currentIndex].value;

      let objClientNumber = values.clientNumber.state.find(key => key.type === "continuousNumber");
      let objClientNumberContinuousNumber = objClientNumber !== undefined ? objClientNumber.value : "";

      const updatedItem = { ...clientNumberItems[currentIndex], id: currentId, type: "continuousNumber", value: objClientNumberContinuousNumber >= parseFloat(currentValue) ? parseFloat(objClientNumberContinuousNumber) + 1 : parseFloat(currentValue) };
      const newItems = [...clientNumberItems];
      newItems[currentIndex] = updatedItem;
      setSettings({ ...settings, clientsNumberItems: newItems });
    }
  }, [values.clientNumber]);

  // const changeContinuousNumber = (e) => {

  //   const onlyContainsNumbers = (str) => /^\d+$/.test(str);
  //   if (!onlyContainsNumbers(e.target.value)) {
  //     setValues({
  //       ...values,
  //       clientNumber: {
  //         ...values.clientNumber,
  //         error: true,
  //         errorMsg: "Bitte eine ganzzahlige positive Nummer eintragen"
  //       }
  //     });
  //     return;
  //   }

  //   else {
  //     let clientNumberItems = (typeof (values.clientNumber.state) === "string" ? JSON.parse(values.clientNumber.state) : values.clientNumber.state)
  //     const currentIndex = clientNumberItems.findIndex((elements) => elements.type === "continuousNumber");
  //     if (currentIndex !== -1) {
  //       let currentId = clientNumberItems[currentIndex].id;

  //       const updatedItem = { ...clientNumberItems[currentIndex], id: currentId, type: "continuousNumber", value: parseFloat(e.target.value) };
  //       const newItems = [...clientNumberItems];
  //       newItems[currentIndex] = updatedItem;
  //       setValues({
  //         ...values,
  //         clientNumber: {
  //           ...values.clientNumber,
  //           error: false,
  //           errorMsg: "",
  //           state: newItems
  //         }
  //       });

  //       // Generic function: Get one client number out of client number items
  //       const streamlinedClientNr = (clientNumber) => {
  //         let nr = ""
  //         for (const [key, value] of Object.entries(clientNumber)) {
  //           nr += value
  //         }
  //         return nr;
  //       }


  //       // Get current (streamlined) invoice number
  //       // const currentInvoiceNumber = streamlinedInvoiceNr(values.invoiceNumber.state.map((item, i) => (item.value)))

  //       if (openClientDialogAtom.clientId === "" || (openClientDialogAtom.clientId !== "" && (streamlinedClientNr(JSON.parse(((clientsAtom.filter((client) => { return client.id === openClientDialogAtom.clientId }))[0]).clientNumber).map((item, i) => (item.value))) !== streamlinedClientNr(newItems.map((item, i) => (item.value)))))) {
  //         // If is NEW INVOICE
  //         // OR is UPDATE of EXISTING INVOICE AND invoice number (new) doesn't equal invoice number (old)
  //         let allClientNumbers = []

  //         const getAllClientNumbers = () => {
  //           // console.log(Object.entries(invoicesAtom[0]))

  //           for (var key in clientsAtom) {
  //             // skip loop if the property is from prototype
  //             if (!clientsAtom.hasOwnProperty(key)) continue;

  //             var obj = clientsAtom[key];
  //             for (var prop in obj) {
  //               // skip loop if the property is from prototype
  //               if (!obj.hasOwnProperty(prop)) continue;

  //               // your code
  //               if (prop === "clientNumber") {
  //                 // allInvoiceNumbers.push(streamlinedInvoiceNr(JSON.parse(value).map((item, i) => (item.value))))
  //                 allClientNumbers.push(streamlinedClientNr(JSON.parse(obj[prop]).map((item, i) => (item.value))));
  //               }
  //             }
  //           }
  //         }
  //         getAllClientNumbers()


  //         if (allClientNumbers.indexOf(streamlinedClientNr(newItems.map((item, i) => (item.value)))) !== -1) {
  //           setValues({
  //             ...values,
  //             clientNumber: {
  //               ...values.clientNumber,
  //               error: true,
  //               errorMsg: "Diese Kundennummer existiert bereits",
  //             }
  //           });
  //         }
  //       }
  //     }
  //   }
  // }

  useEffect(() => {
    const streamlinedClientNr = (clientNumber) => {
      let nr = ""
      for (const [key, value] of Object.entries(clientNumber)) {
        nr += value
      }
      return nr;
    }

    const currentClientNumber = streamlinedClientNr(values.clientNumber.state.map((item, i) => (item.value)))

    // if(selectedClient && openClientDialogAtom.clientId === "") {

    let allClientNumbers = []

    const getAllClientNumbers = () => {
      // console.log(Object.entries(invoicesAtom[0]))

      for (var key in clientsAtom) {
        // skip loop if the property is from prototype
        if (!clientsAtom.hasOwnProperty(key)) continue;

        var obj = clientsAtom[key];
        for (var prop in obj) {
          // skip loop if the property is from prototype
          if (!obj.hasOwnProperty(prop)) continue;

          // your code
          if (prop === "clientNumber") {
            // allInvoiceNumbers.push(streamlinedInvoiceNr(JSON.parse(value).map((item, i) => (item.value))))
            allClientNumbers.push(streamlinedClientNr(JSON.parse(obj[prop]).map((item, i) => (item.value))));
          }
        }
      }
    }
    getAllClientNumbers()

    if (allClientNumbers.indexOf(currentClientNumber) !== -1) {
      setValues({
        ...values,
        clientNumber: {
          ...values.clientNumber,
          error: true,
          errorMsg: "Diese Rechnungsnummer existiert bereits",
        }
      });
    } else {
      setValues({
        ...values,
        clientNumber: {
          ...values.clientNumber,
          error: false,
          errorMsg: "",
        }
      });
    }
    // }
  }, []);

  const showDeleteWarningHandler = () => {
    setShowConfirmModal(true);
  };

  const cancelDeleteHandler = () => {
    setShowConfirmModal(false);
  };

  const confirmDeleteHandler = (client) => {
    DB_DELETE_CLIENT(
      client,
      auth,
      sendRequest,
      enqueueSnackbar,
      t,
      clientsAtom,
      setClientsAtom,
      openClientDialogAtom,
      setOpenClientDialogAtom,
      setShowConfirmModal
    );
  };

  const clientHandler = async (event) => {
    event.preventDefault();

    if (
      userId &&
      auth.token &&
      !values.company.error &&
      !values.country.error &&
      !values.street.error &&
      !values.streetNr.error &&
      !values.zip.error &&
      !values.city.error &&
      !values.email.error &&
      !values.firstName.error &&
      !values.lastName.error &&
      !values.vat.error &&
      !values.clientNumber.error
    ) {
      try {
        const formData = new FormData();
        formData.append("company", values.company.state);
        formData.append("email", values.email.state);
        formData.append("phone", values.phone.state);
        formData.append("vatPercent", values.vatPercent.state);
        formData.append("vat", values.vat.state);
        formData.append("street", values.street.state);
        formData.append("streetNr", values.streetNr.state);
        formData.append("zip", values.zip.state);
        formData.append("city", values.city.state);
        formData.append("country", JSON.stringify(values.country.state));
        formData.append("precedingTitle", values.precedingTitle.state);
        formData.append("firstName", values.firstName.state);
        formData.append("lastName", values.lastName.state);
        formData.append("followingTitle", values.followingTitle.state);
        formData.append("clientNumber", JSON.stringify(values.clientNumber.state));
        formData.append("status", values.status.state);
        formData.append("category", values.category);
        formData.append("salutation", values.salutation);
        formData.append("politeform", values.politeform);
        if (openClientDialogAtom.clientId !== "" && (formState.inputs.image.value === "/#" || formState.inputs.image.value === null)) {
          // Editing: image was not changed
          formData.append("image", values.image.state);
        } else if (openClientDialogAtom.clientId !== "" && (formState.inputs.image.value !== "/#" && formState.inputs.image.value !== null)) {
          // Editing: image was changed
          formData.append("image", formState.inputs.image.value);
        } else if (openClientDialogAtom.clientId === "" && (formState.inputs.image.value === "/#" || formState.inputs.image.value === null)) {
          // Creation: image was not uploaded
          formData.append("image", "/#");
        } else if (openClientDialogAtom.clientId === "" && (formState.inputs.image.value !== "/#" || formState.inputs.image.value !== null)) {
          // Creation: image was uploaded
          formData.append("image", formState.inputs.image.value);
        }
        formData.append("logo", values.logo);

        if (openClientDialogAtom.clientId === "") {
          // CREATE NEW CLIENT

          await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL_API}/clients`,
            "POST",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          )
          
            .then(() => DB_PATCH_SETTINGS(settings, auth, sendRequest, enqueueSnackbar, t, false))
            .then(() => setSettingsAtom(settings))
            .then(() => setUserAtom({...userAtom, clientscreated: true}))
        } else if (openClientDialogAtom.clientId !== "") {
          // UPDATE EXISTING CLIENT
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL_API}/clients/${openClientDialogAtom.clientId}`,
            "PATCH",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          )

            // If saveNewInvoiceSchema is true, update settings with invoiceNr changes
            // const updatedSettings = {
            //   ...settingsAtom,
            //   clientsNumberItems: JSON.stringify(values.clientNumber.state ? values.clientNumber.state : [])
            // };
            // // Call DB_PATCH_SETTINGS with the updatedSettings object
            // await DB_PATCH_SETTINGS(updatedSettings, auth, sendRequest, enqueueSnackbar, t);
        }

        // If saveNewInvoiceSchema is true, update settings with invoiceNr changes
        if(saveNewClientSchema) {
          const updatedSettings = {
            ...settingsAtom,
            clientsNumberItems: JSON.stringify(values.clientNumber.state ? values.clientNumber.state : [])
          };
          // Call DB_PATCH_SETTINGS with the updatedSettings object
          await DB_PATCH_SETTINGS(updatedSettings, auth, sendRequest, enqueueSnackbar, t);
        }
    
        // FETCH updated Client List
        try {
          const resClients = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL_API}/clients/user/all/${userId}`,
            "GET",
            null,
            {
              Authorization: `Bearer ${auth.token}`
            }
          );
          setClientsAtom(resClients.clients);
          setClientsFilteredAtom(resClients.clients);
          setOpenClientDialogAtom({ ...openClientDialogAtom, open: false, clientId: "" });
          enqueueSnackbar(t("FIELDS.saved"), { variant: "success" });
        } catch (err) {
          // SERVER ERROR
          enqueueSnackbar(t("ERRORS.save_server"), { variant: "error" });
        }
      } catch (err) {
        // SERVER ERROR
        enqueueSnackbar(t("ERRORS.save_server"), { variant: "error" });
      }
    } else {
      enqueueSnackbar(t("ERRORS.save_form"), { variant: "error" });
    }
  };







  // CURRENT FETCHING

  useEffect(() => {
    const fetchClientNumber = async () => {
      console.log("here")
      try {
        if (openClientDialogAtom.clientId === "" && auth) {
          // Fetch new clientNumber for NEW Client
          const formData = new FormData();
          formData.append("userId", auth.userId);
          const response = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL_API}/clients/getclientnumber`, // Endpoint to fetch the client number
            "POST", // HTTP method
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
          console.log(response)
  
          // Set values for a new client with the fetched client number
          setValues({
            ...values,
            clientNumberValues: {
              ...values.clientNumberValues,
            },
            clientNumber: {
              ...values.clientNumber,
              state: JSON.parse(response.clientNumber), // Set the fetched client number
            },
            status: {
              ...values.status,
              state: "active",
            },
            loading: false,
          });
        } else if (openClientDialogAtom.clientId !== "") {
          // If is EDITING MODE of existing client
          let client = clientsAtom.filter((client) => {
            return client._id === openClientDialogAtom.clientId;
          });
  
          // Set values for an existing client
          setValues({
            ...values,
            company: { ...values.company, state: client[0].company },
            country: { ...values.country, state: JSON.parse(client[0].country) },
            street: { ...values.street, state: client[0].street },
            streetNr: { ...values.streetNr, state: client[0].streetNr },
            zip: { ...values.zip, state: client[0].zip },
            city: { ...values.city, state: client[0].city },
            phone: { ...values.phone, state: client[0].phone },
            email: { ...values.email, state: client[0].email },
            vat: { ...values.vat, state: client[0].vat },
            vatPercent: { ...values.vatPercent, state: client[0].vatPercent },
            precedingTitle: { ...values.precedingTitle, state: client[0].precedingTitle },
            firstName: { ...values.firstName, state: client[0].firstName },
            lastName: { ...values.lastName, state: client[0].lastName },
            followingTitle: { ...values.followingTitle, state: client[0].followingTitle },
            status: { ...values.status, state: client[0].status },
            image: { ...values.image, state: client[0].image },
            logo: client[0].logo,
            category: client[0].category || "company",
            salutation: client[0].salutation || "ms",
            politeform: client[0].politeform || "formal",
  
            clientNumberValues: {
              ...values.clientNumberValues,
            },
            clientNumber: {
              ...values.clientNumber,
              state: JSON.parse(client[0].clientNumber),
            },
  
            loading: false,
          });
        }
      } catch (error) {
        console.error("Error fetching client number:", error);
        setValues((prevValues) => ({
          ...prevValues,
          loading: false,
        }));
      }
    };
  
    fetchClientNumber();
  }, [auth]); // Ensure this only runs once when the component mounts
  

















  const handleChange = async (e) => {
    clearError();
    SET_VALUES(e, values, setValues);
  };

  useEffect(() => {
    const vatData = VAT(values.country.state.code)

    if (values.country.state !== "") {
      // If statement prevents rendering when country is not set yet
      setValues({
        ...values,
        vat: {
          ...values.vat,
          code: vatData.vatCode,
          digits: vatData.vatDigits,
        }
      });
    }
  }, [values.country]);

  const validate = (field) => {
    // ONLY validate clientNumber if it is NEW CLIENT
    openClientDialogAtom.clientId === "" ?
      VALIDATE([
        "company",
        "country",
        "street",
        "streetNr",
        "zip",
        "city",
        "firstName",
        "lastName",
        "vat",
        "clientNumber",
        "email"
      ],
        field, values, setValues)
      :
      VALIDATE([
        "company",
        "country",
        "street",
        "streetNr",
        "zip",
        "city",
        "firstName",
        "lastName",
        "vat",
        "email"
      ],
        field, values, setValues)
  };


  useEffect(() => {
    if (values.category === "person") {
      setValues({ ...values, company: { ...values.company, state: `${values.precedingTitle.state !== "" ? values.precedingTitle.state + " " : ""}${values.firstName.state + " "}${values.lastName.state}${values.followingTitle.state !== "" ? ", " + values.followingTitle.state : ""}` } })
    }
  }, [values.precedingTitle, values.firstName, values.lastName, values.followingTitle]);


  return (
    <React.Fragment>
      <ClientAppBar
        openClientDialogAtom={openClientDialogAtom}
        setOpenClientDialogAtom={setOpenClientDialogAtom}
      />

      <ClientAlerts
        values={values}
        openClientDialogAtom={openClientDialogAtom}
      />

      <React.Fragment>
        <div>
          <div className={classes.toolbar} />
          <Container maxWidth={false} disableGutters className="bg--1">
            <Container maxWidth="lg" spacing={1} style={{ paddingTop: "25px", paddingBottom: "100px" }}>
              {!values.loading &&
                <>
                  <form onSubmit={clientHandler}>
                    <Box display="flex"
                      flexDirection="column"
                      margin="0 25px"
                      className="h-gap--15"
                      style={{ minHeight: "100vh", margin: 0 }}
                    >

                      {/* STATUS and CATEGORY and CLIENT NUMBEr*/}
                      <Box
                        display="flex"
                        flexDirection="column"
                        // padding="25px 0px"
                        className="h-gap--20"
                        style={{ marginTop: 30 }}
                      >
                        <Grid
                          container
                          item
                          spacing={2}
                          style={{ margin: 0, maxWidth: "100%" }}
                          direction="row"
                          alignContent="flex-start"
                          alignItems="flex-start"
                          className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                        >

                          {/* STATUS */}
                          <Grid container item lg={6} spacing={2}>
                            <FormControl variant="filled" id="status" className={classes.formControl} fullWidth>
                              <InputLabel id="status">{t("FIELDS.clientStatus.label")}</InputLabel>
                              <Select
                                labelId="status"
                                id="status"
                                name="status"
                                defaultValue={values.status.state}
                                onChange={handleChange}
                                className="select-status"
                              >
                                <MenuItem value={"active"}>
                                  <ListItemIcon style={{ minWidth: "auto", marginRight: "5px" }}>
                                    <FiberManualRecordIcon style={{ color: theme.palette.success.light }} />
                                  </ListItemIcon>
                                  <ListItemText primary={t("FIELDS.clientStatus.optionActive")} />
                                </MenuItem>

                                <MenuItem value={"inactive"}>
                                  <ListItemIcon style={{ minWidth: "auto", marginRight: "5px" }}>
                                    <FiberManualRecordIcon style={{ color: theme.palette.error.main }} />
                                  </ListItemIcon>
                                  <ListItemText primary={t("FIELDS.clientStatus.optionInactive")} />
                                </MenuItem>

                                <MenuItem value={"prospect"}>
                                  <ListItemIcon style={{ minWidth: "auto", marginRight: "5px" }}>
                                    <FiberManualRecordIcon style={{ color: theme.palette.warning.light }} />
                                  </ListItemIcon>
                                  <ListItemText primary={t("FIELDS.clientStatus.optionProspect")} />
                                </MenuItem>
                                {/* <MenuItem value={"active"}><FiberManualRecordIcon color={theme.palette.success.light}/>Aktiver Kunde</MenuItem> */}
                                {/* <MenuItem value={"inactive"}>Inaktiver Kunde</MenuItem>
                              <MenuItem value={"prospect"}>Möglicher Kunde (in Verhandlung)</MenuItem> */}
                              </Select>
                            </FormControl>
                          </Grid>

                          {/* CATEGORY */}
                          <Grid container item lg={6} spacing={2} alignItems="center" justifyContent="center">
                            <FormControl component="fieldset">
                              <RadioGroup
                                row
                                aria-label="customer-type"
                                name="customerType"
                                value={values.category} // Default value
                                onChange={(e) => setValues({ ...values, category: e.target.value })}
                                style={{ marginTop: 10, marginLeft: 5 }}
                              >
                                <FormControlLabel
                                  value="company"
                                  control={<Radio color="primary" />} // Set the color to primary
                                  label="Unternehmen/Organisation"
                                />
                                <FormControlLabel
                                  value="person"
                                  control={<Radio color="primary" />} // Set the color to primary
                                  label="Privatperson"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>

                          {/* CLIENT NUMBER */}
                          <Grid item xs={12} style={{ margin: 0, paddingLeft: 0, paddingRight: 0 }}>
                            <ClientNumber
                              values={values}
                              setValues={setValues}
                              openClientNrMenuDots={openClientNrMenuDots}
                              anchorEl={anchorEl}
                              handleClick={handleClick}
                              handleClose={handleClose}
                              changeContinuousNumber={changeContinuousNumber}
                              addElementOpen={addElementOpen}
                              setOpenClientNumberEdit={setOpenClientNumberEdit}
                              setAddElementOpen={setAddElementOpen}
                              saveNewClientSchema={saveNewClientSchema}
                              setSaveNewClientSchema={setSaveNewClientSchema}
                            />

                            <ClientNumberEdit
                              openClientNumberEdit={openClientNumberEdit}
                              setOpenClientNumberEdit={setOpenClientNumberEdit}
                              values={values}
                              setValues={setValues}
                              // selectedClient={selectedClient}
                              // values={values}
                              // setValues={setValues}
                              // settingsAtom={settingsAtom}
                              // recurring={false}
                            />
                          </Grid>
                        </Grid>
                      </Box>

                      {/* COMPANY NAME */}
                      {values.category !== "person" &&
                        <Box
                          display="flex"
                          flexDirection="column"
                          // padding="25px 0px"
                          className="h-gap--20"
                        >
                          <Grid
                            container
                            item
                            style={{ margin: 0, maxWidth: "100%" }}
                            direction="row"
                            alignContent="flex-start"
                            alignItems="flex-start"
                            className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                          >
                            <Grid container item lg={12} spacing={1}>
                              <Grid container item direction="row" alignItems="center">
                                <Typography variant="body2" component="div" style={{ fontSize: 14, fontWeight: 500 }}>Kundenname</Typography>
                              </Grid>

                              <Grid item xs={12}>
                                <TextField
                                  autoComplete="off"
                                  defaultValue={values.company.state}
                                  id="company"
                                  label="Name des Unternehmens (inkl. Rechtsform z.B. GmbH)*"
                                  variant="filled"
                                  type="text"
                                  error={values.company.error}
                                  helperText={
                                    values.company.error ? values.company.errorMsg : ""
                                  }
                                  onChange={handleChange}
                                  onBlur={() => validate("company")}
                                  fullWidth
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      }

                      {/* PERSON NAME */}
                      {values.category === "person" &&
                        <Box
                          display="flex"
                          flexDirection="column"
                          // padding="25px 0px"
                          className="h-gap--20"
                        >
                          <Grid
                            container
                            item
                            style={{ margin: 0, maxWidth: "100%" }}
                            direction="row"
                            alignContent="flex-start"
                            alignItems="flex-start"
                            className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                          >
                            <Grid container item lg={12} spacing={1}>
                              <Grid container item direction="row" alignItems="center">
                                <Typography variant="body2" component="div" style={{ fontSize: 14, fontWeight: 500 }}>Kundenname: {values.company.state}</Typography>
                              </Grid>
                              {/* SALUTATION */}
                              <Grid item xs={12} md={4}>
                                <TextField
                                  id="salutation"
                                  select
                                  label={`Anrede`}
                                  value={values.salutation}
                                  onChange={(e)=>setValues({...values, salutation: e.target.value})}
                                  variant="filled"
                                  fullWidth
                                >
                                  <MenuItem value="ms">Frau</MenuItem>
                                  <MenuItem value="mr">Herr</MenuItem>
                                  <MenuItem value="diverse">Divers</MenuItem>
                                  <MenuItem value="notdisclosed">Keine Angabe</MenuItem>
                                </TextField>
                              </Grid>

                              {/* POLITEFORM */}
                              <Grid item xs={12} md={4}>
                                <TextField
                                  id="politeform"
                                  select
                                  label={`Höflichkeitsform`}
                                  value={values.politeform}
                                  onChange={(e)=>setValues({...values, politeform: e.target.value})}
                                  variant="filled"
                                  fullWidth
                                >
                                  <MenuItem value="formal">Sie (formell)</MenuItem>
                                  <MenuItem value="informal">Du (informell)</MenuItem>
                                </TextField>
                              </Grid>

                              {/* PHONE */}
                              <Grid item xs={12} md={4}>
                                <TextField
                                  autoComplete="off"
                                  defaultValue={values.phone.state}
                                  id="phone"
                                  label={`${t("FIELDS.phone.label")}`}
                                  variant="filled"
                                  type="text"
                                  onChange={handleChange}
                                  fullWidth
                                />
                              </Grid>

                              {/* PRECEDING TITLE */}
                              <Grid item xs={12} md={2}>
                                <TextField
                                  defaultValue={values.precedingTitle.state}
                                  id="precedingTitle"
                                  label={`${t("FIELDS.precedingTitle.label")}`}
                                  variant="filled"
                                  type="text"
                                  onChange={handleChange}
                                  fullWidth
                                />
                              </Grid>

                              {/* FIRST NAME */}
                              <Grid item xs={12} md={4}>
                                <TextField
                                  defaultValue={values.firstName.state}
                                  id="firstName"
                                  label={`${t("FIELDS.firstName.label")} *`}
                                  variant="filled"
                                  type="text"
                                  error={values.firstName.error}
                                  helperText={
                                    values.firstName.error ? values.firstName.errorMsg : ""
                                  }
                                  onChange={handleChange}
                                  onBlur={() => validate("firstName")}
                                  fullWidth
                                />
                              </Grid>

                              {/* LAST NAME */}
                              <Grid item xs={12} md={4}>
                                <TextField
                                  defaultValue={values.lastName.state}
                                  id="lastName"
                                  label={`${t("FIELDS.lastName.label")} *`}
                                  variant="filled"
                                  type="text"
                                  error={values.lastName.error}
                                  helperText={
                                    values.lastName.error ? values.lastName.errorMsg : ""
                                  }
                                  onChange={handleChange}
                                  onBlur={() => validate("lastName")}
                                  fullWidth
                                />
                              </Grid>

                              {/* FOLLOWING TITLE */}
                              <Grid item xs={12} md={2}>
                                <TextField
                                  defaultValue={values.followingTitle.state}
                                  id="followingTitle"
                                  label={`${t("FIELDS.followingTitle.label")}`}
                                  variant="filled"
                                  type="text"
                                  onChange={handleChange}
                                  fullWidth
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      }

                      {/* ADDRESS */}
                      <Box
                        display="flex"
                        flexDirection="column"
                        // padding="25px 0px"
                        className="h-gap--20"
                      >
                        <Grid
                          container
                          item
                          style={{ margin: 0, maxWidth: "100%" }}
                          direction="row"
                          alignContent="flex-start"
                          alignItems="flex-start"
                          className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                        >
                          <Grid container item lg={12} spacing={1} >
                            <Grid container item direction="row" alignItems="center">
                              <Typography variant="body2" component="div" style={{ fontSize: 14, fontWeight: 500 }}>Adresse</Typography>
                            </Grid>

                            {/* STREET */}
                            <Grid item xs={12} sm={8} lg={10}>
                              <TextField
                                autoComplete="off"
                                defaultValue={values.street.state}
                                id="street"
                                label={`${t("FIELDS.street.label")} *`}
                                variant="filled"
                                type="text"
                                error={values.street.error}
                                helperText={
                                  values.street.error ? values.street.errorMsg : ""
                                }
                                onChange={handleChange}
                                onBlur={() => validate("street")}
                                fullWidth
                              />
                            </Grid>

                            {/* STREET NR */}
                            <Grid item xs={12} sm={4} lg={2}>
                              <TextField
                                autoComplete="off"
                                defaultValue={values.streetNr.state}
                                id="streetNr"
                                label={`${t("FIELDS.streetNr.label")} *`}
                                variant="filled"
                                type="text"
                                error={values.streetNr.error}
                                helperText={
                                  values.streetNr.error ? values.streetNr.errorMsg : ""
                                }
                                onChange={handleChange}
                                onBlur={() => validate("streetNr")}
                                fullWidth
                              />
                            </Grid>

                            {/* ZIP */}
                            <Grid item xs={12} sm={4} lg={2}>
                              <TextField
                                autoComplete="off"
                                defaultValue={values.zip.state}
                                id="zip"
                                label={`${t("FIELDS.zip.label")} *`}
                                variant="filled"
                                type="text"
                                error={values.zip.error}
                                helperText={values.zip.error ? values.zip.errorMsg : ""}
                                onChange={handleChange}
                                onBlur={() => validate("zip")}
                                fullWidth
                              />
                            </Grid>

                            {/* CITY */}
                            <Grid item xs={12} sm={8} lg={4}>
                              <TextField
                                autoComplete="off"
                                defaultValue={values.city.state}
                                id="city"
                                label={`${t("FIELDS.city.label")} *`}
                                variant="filled"
                                type="text"
                                error={values.city.error}
                                helperText={values.city.error ? values.city.errorMsg : ""}
                                onChange={handleChange}
                                onBlur={() => validate("city")}
                                fullWidth
                              />
                            </Grid>

                            {/* COUNTRY */}
                            <Grid item xs={12} sm={12} lg={6}>
                              <Autocomplete
                                fullWidth
                                autoComplete={false}
                                defaultValue={values.country.state}
                                value={values.country.state || null}
                                getOptionSelected={(option, value) => option.label === value.label} // prevents infinite loop!!!
                                id="country"
                                onChange={(event, value) =>
                                  handleChange({
                                    target: {
                                      id: "country",
                                      value: value
                                    }
                                  })
                                }
                                disableClearable
                                options={COUNTRIES}
                                classes={{
                                  option: classes.option,
                                }}
                                autoHighlight
                                getOptionLabel={(option) => `${countryToFlag(option.code)} ${option.label}`}
                                renderOption={(option) => (
                                  <React.Fragment>
                                    <span style={{ marginRight: "5px" }}>{countryToFlag(option.code)} </span>
                                    {option.label}
                                  </React.Fragment>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={`${t("FIELDS.country.label")} *`}
                                    variant="filled"
                                    onBlur={() => validate("country")}
                                    error={values.country.error}
                                    helperText={
                                      values.country.error ? values.country.errorMsg : ""
                                    }
                                    value={values.country.state}
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: "new-password", // disable autocomplete and autofill
                                    }}
                                  />
                                )}
                              />
                            </Grid>

                            {/* EMAIL */}
                            <Grid item xs={12} lg={6}>
                              <TextField
                                autoComplete="off"
                                defaultValue={values.email.state}
                                id="email"
                                label={`${t("FIELDS.email.labelInvoice")} *`}
                                variant="filled"
                                type="text"
                                error={values.email.error}
                                helperText={values.email.error ? values.email.errorMsg : ""}
                                onBlur={() => validate("email")}
                                onChange={handleChange}
                                fullWidth
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>

                      {/* CONTACT PERSON */}
                      {values.category !== "person" && (
                        <Box
                          display="flex"
                          flexDirection="column"
                          className="h-gap--20"
                        >
                          <Grid
                            container
                            item
                            spacing={1}
                            style={{ margin: 0, maxWidth: "100%" }}
                            direction="row"
                            alignContent="flex-start"
                            alignItems="flex-start"
                            className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                          >
                              <Grid container item direction="row" alignItems="center">
                                <Typography variant="body2" component="div" style={{ fontSize: 14, fontWeight: 500 }}>
                                  {t("PAGE_CLIENTS.person.h2")}
                                </Typography>
                              </Grid>

                              {/* SALUTATION */}
                              <Grid item xs={12} md={4}>
                                <TextField
                                  id="salutation"
                                  select
                                  label={`Anrede`}
                                  value={values.salutation}
                                  onChange={(e)=>setValues({...values, salutation: e.target.value})}
                                  variant="filled"
                                  fullWidth
                                >
                                  <MenuItem value="ms">Frau</MenuItem>
                                  <MenuItem value="mr">Herr</MenuItem>
                                  <MenuItem value="diverse">Divers</MenuItem>
                                  <MenuItem value="notdisclosed">Keine Angabe</MenuItem>
                                </TextField>
                              </Grid>

                              {/* POLITEFORM */}
                              <Grid item xs={12} md={4}>
                                <TextField
                                  id="politeform"
                                  select
                                  label={`Höflichkeitsform`}
                                  value={values.politeform}
                                  onChange={(e)=>setValues({...values, politeform: e.target.value})}
                                  variant="filled"
                                  fullWidth
                                >
                                  <MenuItem value="formal">Sie (formell)</MenuItem>
                                  <MenuItem value="informal">Du (informell)</MenuItem>
                                </TextField>
                              </Grid>

                              {/* PHONE */}
                              <Grid item xs={12} md={4}>
                                <TextField
                                  autoComplete="off"
                                  defaultValue={values.phone.state}
                                  id="phone"
                                  label={`${t("FIELDS.phone.label")}`}
                                  variant="filled"
                                  type="text"
                                  onChange={handleChange}
                                  fullWidth
                                />
                              </Grid>

                              {/* PRECEDING TITLE */}
                              <Grid item xs={12} md={2}>
                                <TextField
                                  defaultValue={values.precedingTitle.state}
                                  id="precedingTitle"
                                  label={`${t("FIELDS.precedingTitle.label")}`}
                                  variant="filled"
                                  type="text"
                                  onChange={handleChange}
                                  fullWidth
                                />
                              </Grid>

                              {/* FIRST NAME */}
                              <Grid item xs={12} md={4}>
                                <TextField
                                  defaultValue={values.firstName.state}
                                  id="firstName"
                                  label={`${t("FIELDS.firstName.label")} *`}
                                  variant="filled"
                                  type="text"
                                  error={values.firstName.error}
                                  helperText={
                                    values.firstName.error ? values.firstName.errorMsg : ""
                                  }
                                  onChange={handleChange}
                                  onBlur={() => validate("firstName")}
                                  fullWidth
                                />
                              </Grid>

                              {/* LAST NAME */}
                              <Grid item xs={12} md={4}>
                                <TextField
                                  defaultValue={values.lastName.state}
                                  id="lastName"
                                  label={`${t("FIELDS.lastName.label")} *`}
                                  variant="filled"
                                  type="text"
                                  error={values.lastName.error}
                                  helperText={
                                    values.lastName.error ? values.lastName.errorMsg : ""
                                  }
                                  onChange={handleChange}
                                  onBlur={() => validate("lastName")}
                                  fullWidth
                                />
                              </Grid>

                              {/* FOLLOWING TITLE */}
                              <Grid item xs={12} md={2}>
                                <TextField
                                  defaultValue={values.followingTitle.state}
                                  id="followingTitle"
                                  label={`${t("FIELDS.followingTitle.label")}`}
                                  variant="filled"
                                  type="text"
                                  onChange={handleChange}
                                  fullWidth
                                />
                              </Grid>
                          </Grid>
                        </Box>
                      )}

                      {/* VAT */}
                      <Box
                        display="flex"
                        flexDirection="column"
                        // padding="25px 0px"
                        className="h-gap--20"
                      >
                        <Grid
                          container
                          item
                          spacing={1}
                          style={{ margin: 0, maxWidth: "100%" }}
                          direction="row"
                          alignContent="flex-start"
                          alignItems="flex-start"
                          className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                        >
                          <Grid container item lg={12} spacing={1} >
                            <Grid container item direction="row" alignItems="center">
                              <Typography variant="body2" component="div" style={{ fontSize: 14, fontWeight: 500 }}>Umsatzsteuer</Typography>
                            </Grid>

                            {/* VAT ID */}
                            {values.category !== "person" &&
                              <Grid item xs={12} lg={3}>
                                <TextField
                                  // required={values.vat.state !== ""}
                                  autoComplete="off"
                                  defaultValue={values.vat.state}
                                  disabled={values.country.state === "" ? true : false}
                                  id="vat"
                                  label={`${t("FIELDS.vat.label")}`}
                                  variant="filled"
                                  type="text"
                                  onChange={handleChange}
                                  onBlur={() => validate("vat")}
                                  error={values.vat.error}
                                  helperText={values.vat.error ? values.vat.errorMsg : ""}
                                  fullWidth
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start" style={{ backgroundColor: "#ffffff", borderRadius: "5px", padding: "0 5px", height: "100%" }}><span style={{ fontSize: "75%", padding: "4px 4px 2px 4px", borderRadius: 4, marginTop: 6, marginLeft: -6, background: "rgb(237, 239, 255)"  }}>{values.vat.code}</span></InputAdornment>,
                                  }}
                                />
                              </Grid>
                            }

                            {/* VAT % */}
                            <Grid item xs={12} lg={3}>
                              <TextField
                                label={`${t("FIELDS.defaultVatPercentage.label")}`}
                                id="vatPercent"
                                variant="filled"
                                onChange={handleChange}
                                defaultValue={values.vatPercent.state}
                                fullWidth
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">
                                    <span style={{ color: "inherit" }}>%</span>
                                  </InputAdornment>
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>

                      {/* LOGO */}
                      {values.category !== "person" &&
                        <Box
                          display="flex"
                          flexDirection="column"
                          // padding="25px 0px"
                          className="h-gap--20"
                        >
                          <Grid
                            container
                            item
                            direction="row"
                            alignContent="flex-start"
                            alignItems="flex-start"
                            className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                          >
                            <Grid container item xs={12} spacing={2}>
                              <Grid container item xs={12}>
                                <DropzoneUploaderSingle
                                  values={values}
                                  setValues={setValues}
                                  buttonText="Logo hochladen"
                                  description="Hier klicken oder Bild in den Rahmen ziehen um den Logo-Upload zu starten. Mögliche Dateiformate: JPG und PNG"
                                  accept="image/jpg, image/jpeg, image/png"
                                  logo={values.logo}
                                  multiple={false}
                                  maxFiles={1}
                                  disabled={false}
                                  readData={false}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      }

                      <Grid container item lg={12} spacing={2} direction="column" alignItems="center">
                        <Grid container item lg={12} spacing={2} justifyContent="center" alignItems="center">
                          <Grid item>
                            <BottomNavigation
                              showLabels
                              style={{ position: "fixed", left: 0, width: "100%", zIndex: 999, bottom: 0, background: "transparent", margin: "0 auto" }}
                              className="box-shadow--2"
                            >
                              <ButtonLoadingBottom
                                loading={isLoading}
                                disabled={isLoading}
                                color="primary"
                                type="submit"
                                size="large"
                                className="button--loading"
                                borderTopLeftRadius={"10px"}
                                borderTopRightRadius="10px"
                                startIcon={<SaveIcon />}
                                onClick={() => validate("all")}
                              >
                                Speichern
                              </ButtonLoadingBottom>
                            </BottomNavigation>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </form>
                </>
              }

              {values.loading &&
                <div className="center">
                  <CircularProgress />
                </div>
              }

              <ConfirmModal
                show={showConfirmModal}
                onCancel={cancelDeleteHandler}
                header={t("BUTTONS.clientDelete")}
                footerClass="client-item__modal-actions"
                style={{ zIndex: "9999999" }}
                footer={
                  <React.Fragment>
                    <Button
                      variant="outlined"
                      style={{ marginRight: "10px" }}
                      onClick={cancelDeleteHandler}
                    >
                      {t("BUTTONS.cancel")}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => confirmDeleteHandler(openClientDialogAtom.clientId)}
                    >
                      {t("BUTTONS.delete")}
                    </Button>
                  </React.Fragment>
                }
              >
                <p>
                  {t("PAGE_CLIENTS.clientDelete")}
                </p>
              </ConfirmModal>


            </Container>
          </Container>
        </div>
      </React.Fragment>


    </React.Fragment>
  );
};

export default ClientDialog;

import React from 'react';
import { AppBar, Toolbar, IconButton, Grid, Typography } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon, LibraryBooks as LibraryBooksIcon, Repeat as RepeatIcon, Gavel as GavelIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import ClientsIcon from "@material-ui/icons/RecentActors";

const ClientAppBar = ({ openClientDialogAtom, setOpenClientDialogAtom }) => {
  const { t } = useTranslation();

  return (
    <AppBar style={{ background: "rgb(44, 83, 125)", zIndex: "1500" }}>
      <Toolbar style={{ justifyContent: "space-between", position: 'relative' }}>
        <IconButton
          edge="start"
          color="inherit"
          style={{
            margin: "0",
            display: "flex",
            width: "35px",
            height: "35px",
            border: "1px solid white",
            zIndex: 1, // Bring the button to the front
          }}
          onClick={() => {
            setOpenClientDialogAtom({
              ...openClientDialogAtom,
              open: false,
              clientId: "",
            });
          }}
          aria-label={`${t("BUTTONS.AL_close")}`}
        >
          <ArrowBackIcon />
        </IconButton>

        <Grid
          container
          item
          direction="row"
          alignItems="center"
          justifyContent="center"
          style={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            pointerEvents: 'none', // Allows clicks to pass through
          }}
        >
            <ClientsIcon fontSize="large" style={{ color: "white", marginRight: "5px" }} />
          <Typography variant="body1" component="h1" style={{ textTransform: "none", fontWeight: 600 }}>
            Kunde
          </Typography>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default ClientAppBar;

import { createTheme } from '@material-ui/core/styles';

/*
// Official Breakpoints
Breakpoints(

Matieral UI:
xs: 0
sm: 600
md: 960
lg: 1280
xl: 1920

iPad Pro:
1024
)*/



let theme = createTheme({
  palette: {
    primary: {
      light: '#4dabf5',
      // main: "#2196f3",
      main: "rgb(44, 83, 125)",
      dark: "#1a7bc9",
    },
    secondary: {
      main: '#E312CA',
    },
    primaryold: {
      main: "rgb(26, 123, 201)"
    },
    standard: {
      main: "rgba(0, 0, 0, 0.54)",
      light: "#e9e9e9"
    },
    warning: {
      main: "rgb(255, 183, 77)",
    },
    error: {
      main: "#b71c1c",
    },
    success: {
      light: "#4caf50",
      main: "#2e7d32",
      dark: "#1b5e20"
    },
    premium: {
      light: "#000000",
      main: "#A8EB12",
      dark: "#000000",
      buttonText: "#004d7a",
    },
    pro: {
      light: "#000000",
      main: "#FFD700",
      dark: "#000000",
      buttonText: "#004d7a",
    },
    field: {
      main: "#f0f3ff"
    }
  },
  typography: {
    fontFamily: `Inter, sans-serif`,
    h1: {
      fontFamily: `Plus Jakarta Sans, sans-serif`,
      fontWeight: 700,
      // letterspacing: "1px"
    },
    h2: {
      fontFamily: `Plus Jakarta Sans, sans-serif`,
    },
    h3: {
      fontFamily: `Plus Jakarta Sans, sans-serif`,
    },
    h4: {
      fontFamily: `Plus Jakarta Sans, sans-serif`,
      fontWeight: 700,
    },
    h5: {
      fontFamily: `Plus Jakarta Sans, sans-serif`,
      fontWeight: 700,
    },
    h6: {
      fontFamily: `Plus Jakarta Sans, sans-serif`,
      fontWeight: 700,
    },
    body1: {
      fontWeight: 300,
      fontSize: 14,
    },
    body2: {
      fontWeight: 300,
    }
  },

  breakpoints: {
    values: {
      // xxs: 0,
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      // xxl: 1800
    }
  },
  props: {
    MuiTypography: {
      variantMapping: {
        h1: 'h1',
        h2: 'h2',
        h3: 'h2',
        h4: 'h2',
        h5: 'h2',
        h6: 'h2',
        subtitle1: 'h2',
        subtitle2: 'h2',
        body1: 'span',
        body2: 'p',
      },
    },
    MuiButton: {
      disableElevation: true,
    },
  },
});


theme = createTheme(theme, {
  overrides: {
    MuiFormControlLabel: {
      label: {
        fontSize: '14px',
      },
    },
    MuiInputBase: {
      root: {
        backgroundColor: "#ffffff", // Set background to white
        // fontSize: '14px !important',
      },
      input: {
        fontSize: "14px", // Sets font size for all input components

        '&::placeholder': {
          fontSize: '14px !important',
        },
      },
    },
    MuiFilledInput: {
      root: {
        background: "#ffffff",
        backgroundColor: "#ffffff",
        borderRadius: "4px",
        "&:hover": {
          background: "#ffffff",
          backgroundColor: "#ffffff",
        },
        "&.Mui-focused": {
          background: "#ffffff",
          backgroundColor: "#ffffff",
        },
        "&.Mui-disabled": {
          background: "rgb(177 177 177 / 12%)",
          backgroundColor: "rgb(177 177 177 / 12%)",
        }
      },
      input: {
        borderRadius: "4px",
        fontSize: "14px", // Sets font size for all input components
      },
      underline: {
        '&:after': {
          display: "none"
        },
        '&:focused::after': {
          display: "none"
        },
        '&:error::after': {
          display: "none"
        },
        '&:before': {
          display: "none"
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          display: "none"
        },
        '&$disabled:before': {
          display: "none"
        },
      }
    },
    MuiOutlinedInput: {
      root: {
        "& .MuiOutlinedInput-notchedOutline": {
          borderWidth: "1px 1px 0 1px", // Remove bottom border
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderWidth: "1px 1px 0 1px", // Ensure no bottom border when focused
          borderColor: theme.palette.primary.main, // Focus color for other borders
        },
      },
      notchedOutline: {
        borderColor: "rgba(0, 0, 0, 0.23)", // Default border color
      },
    },
    MuiFilledInput: {
      input: {
        fontSize: "14px", // For filled input variant
      },
    },
    MuiInput: {
      input: {
        fontSize: "14px", // For standard input variant
      },
    },

    MuiButton: {
      label: {
        // fontFamily: `Plus Jakarta Sans 700, sans-serif`,
        // letterSpacing: "1px",
      },
      contained: {
        '&.Mui-disabled': {
          backgroundColor: '#e3e3e3',
        },
      },
    },
    MuiListItemText: {
      primary: {
        fontFamily: `Plus Jakarta Sans, sans-serif`,
        fontSize: "14px"
      }
    },

    MuiListItemIcon: {
      root: {
        minWidth: "40px",
      }
    },

    MuiAutocomplete: {
      inputRoot: {
        background: "#f0f3ff !important",


      },
    },

    MuiFormControl: {
      root: {
        borderRadius: "4px",
      }
    },

    MuiBadge: {
      badge: {
        padding: 0
      }
    },
    MuiAutocomplete: {
      listbox: {
        fontSize: "14px", // Sets font size for autocomplete options
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: "14px", // Sets font size for select options
      },
    },
    MuiSelect: {
      root: {
        backgroundColor: "#ffffff", // Set background to white for selection inputs
      },
      outlined: {
        "& .MuiOutlinedInput-notchedOutline": {
          borderWidth: "1px 1px 0 1px", // Remove bottom border for selection fields
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderWidth: "1px 1px 0 1px", // Ensure no bottom border when focused
          borderColor: theme.palette.primary.main, // Focus color for other borders
        },
      },
    },
  }
});

export default theme;

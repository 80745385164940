import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import SentBlob from '../../components/SentBlob';
import { RETURN_DATE, RETURN_HOURS } from '../../../_functions';

const SentBlobsHistory = ({ selectedClient, settings, values, openInvoiceDialogAtom, isLoading, openSentBlob, setOpenSentBlob }) => {
  return (
    (selectedClient !== "" && settings.invoicesEmailServer !== "download" && values.sentBlobs.length > 0) &&
    <Box
      display="flex"
      flexDirection="column"
      className="h-gap--20"
    >
      <Grid
        container
        spacing={1}
        direction="row"
        alignContent="flex-start"
        alignItems="flex-start"
        className="h-bg--white h-borderRadius--10 h-padding--25-16 box-shadow--2"
      >
        <Grid item container xs={12} sm={12} direction="column" className="sent-info">
          <Grid item container >
            <Grid item container spacing={1}>
              <Typography style={{ marginBottom: "10px", fontWeight: 500 }}>Versandhistorie</Typography>
            </Grid>
            {(openInvoiceDialogAtom.invoiceId !== "" && openInvoiceDialogAtom.duplicate !== true) && (values.sentBlobs).map((sentblob, i) => (
              <Grid item container alignItems="center" spacing={1} key={i}>
                <Typography style={{ fontSize: "12px" }}>
                  <span style={{ fontWeight: 500 }}>{RETURN_DATE(JSON.parse(sentblob).sendDate)}</span> um {RETURN_HOURS(JSON.parse(sentblob).sendDate)} ({
                    (JSON.parse(sentblob).type === "invoice" && (JSON.parse(sentblob).isCorrection === "false" || JSON.parse(sentblob).isCorrection === undefined)) ? "Rechnung"
                    : (JSON.parse(sentblob).type === "invoice" && JSON.parse(sentblob).isCorrection === "true") ? "Korrektur"
                      : JSON.parse(sentblob).type === "reminder" ? "Zahlungserinnerung"
                        : JSON.parse(sentblob).type === "reminderRequest" ? "Mahnung"
                          : JSON.parse(sentblob).type === "estimate" ? "Kostenvoranschlag"
                            : JSON.parse(sentblob).type === "cancellation" ? "Stornorechnung"
                              : "Angebot"
                  })
                </Typography>
                <SentBlob sentblob={sentblob} loading={isLoading} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SentBlobsHistory;

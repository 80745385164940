// import React, { useState,   useContext,
// } from "react";
// // IMPORT hooks & context
// import { useTranslation } from "react-i18next";
// import { countryToFlag } from "../../shared/functions/countryToFlag";
// import { COUNTRIES } from "../../shared/util/countries_de";
// import { useForm } from "../../shared/hooks/form-hook";
// import { useHttpClient } from "../../shared/hooks/http-hook";
// import { AuthContext } from '../../shared/context/auth-context';

// // IMPORT recoil
// import {
//   useRecoilState,
// } from 'recoil';
// import invoicesAtomState from "../../_atoms/invoicesAtom";

// // IMPORT components MATERIAL UI
// import {
//   Typography,
//   Box,
//   Container,
//   Grid,
//   Tooltip,
//   Chip,
//   Collapse,
//   IconButton,
//   Menu,
//   MenuItem,
//   Button
// } from "@material-ui/core";

// // IMPORT icons MATERIAL UI
// import ClientsIcon from "@material-ui/icons/RecentActors";
// import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
// import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
// import FilterListIcon from '@material-ui/icons/FilterList';
// import ImportExportIcon from '@material-ui/icons/ImportExport';
// import EditIcon from '@material-ui/icons/Edit';
// import ScheduleIcon from '@material-ui/icons/Schedule';
// import MailIcon from '@material-ui/icons/Mail';
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// import WarningIcon from '@material-ui/icons/Warning';
// import DateRangeIcon from '@material-ui/icons/DateRange';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
// import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
// import FileCopyIcon from '@material-ui/icons/FileCopy';
// import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
// import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
// import VisibilityIcon from '@material-ui/icons/Visibility';

// // IMPORT functions
// import { DB_DELETE_INVOICE } from './../../_functions/DB_INVOICES';

// // IMPORT components
// import { useSnackbar } from 'notistack';

// // IMPORT own components
// import InvoicesCsv from "../components/InvoicesCsv";
// import InvoicesFilter from "./InvoicesFilter";
// import InvoicesSort from "./InvoicesSort";
// import InvoicesDateRange from "./InvoicesDateRange";
// import InvoicesSettings from "./InvoicesSettings";
// import ConfirmModal from "../../shared/components/UIElements/Modal";
// import InvoiceLivePreview from "./InvoiceLivePreview";

// const ITEM_HEIGHT = 48;

// export default function InvoicesMenuDots({
//   invoice,
//   setOpenInvoiceDialogAtom,
//   openInvoiceDialogAtom,
//   recurring,
//   fetchData,
//   handleSearchClear,
// }) {
//   // HOOKS & CONTEXT 
//   const { t } = useTranslation();
//   const auth = useContext(AuthContext);
//   const { isLoading, error, sendRequest, clearError } = useHttpClient();
//   const { enqueueSnackbar } = useSnackbar();

//   // GLOBAL STATE (RECOIL)
//   const [invoicesAtom, setInvoicesAtom] = useRecoilState(invoicesAtomState);

//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const open = Boolean(anchorEl);
//   const handleClick = (event) => {
//     event.stopPropagation();
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = (event) => {
//     event.stopPropagation();
//     setAnchorEl(null);
//   };

//   const [showConfirmModal, setShowConfirmModal] = useState(false);

//   const showDeleteWarningHandler = () => {
//     setShowConfirmModal(true);
//   };

//   const cancelDeleteHandler = (event) => {
//     event.stopPropagation();
//     setShowConfirmModal(false);
//   };

//   const confirmDeleteHandler = (invoice, event) => {
//     event.stopPropagation();
//     DB_DELETE_INVOICE(
//       invoice,
//       auth,
//       sendRequest,
//       enqueueSnackbar,
//       t,
//       invoicesAtom,
//       setInvoicesAtom,
//       openInvoiceDialogAtom,
//       setOpenInvoiceDialogAtom,
//       setShowConfirmModal,
//       handleSearchClear,
//     );
//   };


//   const duplicateInvoice = async (invoiceId) => {
//     try {
//       const formData = new FormData();
//       formData.append("invoiceId", invoiceId); // Assuming invoiceId is required in formData
  
//       const url = recurring
//   ? `${process.env.REACT_APP_BACKEND_URL_API}/invoicesrecurrings/duplicate`
//   : `${process.env.REACT_APP_BACKEND_URL_API}/invoices/duplicate`;

// const response = await sendRequest(
//   url, // Dynamically set the URL based on isRecurring
//   "POST",
//   formData,
//   {
//     Authorization: "Bearer " + auth.token,
//   }
// );
  
  
//       // Set the dialog state with the response data
//       setOpenInvoiceDialogAtom((prevState) => ({
//         ...prevState,
//         open: true,
//         invoiceId: response.invoiceId, // assuming `invoiceNr` is the identifier returned in the response
//         recurring: recurring,
//         duplicate: true,
//         type: "invoice",
//       }));
//       handleSearchClear(); // Necessary to reload invoices list
  
//       // Optionally handle other success feedback here
//     } catch (error) {
//       console.error("Failed to copy invoice:", error); // Log error details
//       // Optionally handle error feedback to the user here
//     }
//   };


//   const cancelInvoice = async (invoiceId, payState) => {
//     try {
//       const formData = new FormData();
//       formData.append("invoiceId", invoiceId); // Assuming invoiceId is required in formData
  
//       const response = await sendRequest(
//         `${process.env.REACT_APP_BACKEND_URL_API}/invoices/cancel`, // URL for copying
//         "POST",
//         formData,
//         {
//           Authorization: "Bearer " + auth.token,
//         }
//       );
    
//       // Set the dialog state with the response data
//         setOpenInvoiceDialogAtom((prevState) => ({
//           ...prevState,
//           open: true,
//           invoiceId: response.invoiceId, // assuming `invoiceNr` is the identifier returned in the response
//           recurring: false,
//           type: "cancellation",
//         }));
//       handleSearchClear(); // Necessary to reload invoices list
  
//       // Optionally handle other success feedback here
//     } catch (error) {
//       console.error("Failed to copy invoice:", error); // Log error details
//       // Optionally handle error feedback to the user here
//     }
//   };
  

//   return (
//     <>
//       <IconButton
//         aria-label="more"
//         aria-controls={invoice.id}
//         aria-haspopup="true"
//         onClick={(e) => handleClick(e)}
//         style= {{ marginRight: "5px" }}
//       >
//         <MoreHorizIcon />
//       </IconButton>
//       <Menu
//         id={invoice.id}
//         anchorEl={anchorEl}
//         open={open}
//         onClose={(e) => handleClose(e)}
//         PaperProps={{
//           elevation: 1,
//           style: {
//             maxHeight: ITEM_HEIGHT * 4.5,
//             width: 'auto',
//           },
//         }}
//       >

//           <MenuItem
//             style={{ fontSize: "13px" }}
//             onClick={(e) => {
//               setOpenInvoiceDialogAtom({
//                 ...openInvoiceDialogAtom,
//                 open: true,
//                 invoiceId: invoice.id,
//                 ...(invoice.recurring ? { recurring: true } : { recurring: false }),
//               });
//               handleClose(e);
//             }}
//           >
//             {!invoice.cancelled && <EditIcon fontSize="small" style={{ marginRight: "5px" }} />}
//             {invoice.cancelled && <VisibilityIcon fontSize="small" style={{ marginRight: "5px" }} />}
//             {invoice.cancelled ? "Ansehen" : "Bearbeiten"}
//           </MenuItem>

//           {!invoice.cancelled && invoice.type !== "cancellation" &&
//           <MenuItem
//             style={{ fontSize: "13px" }}
//             onClick={
//               (e) => {
//                 duplicateInvoice(invoice.id);
//                 handleClose(e)
//               }
//             }
//             >
//             <FileCopyIcon fontSize="small" style={{ marginRight: "5px" }} />Duplizieren
//           </MenuItem>
//           }

//           {!invoice.recurring && !invoice.cancelled && invoice.type !== "cancellation" && invoice.sendState !== "scheduled" &&
//           <MenuItem 
//             style={{ fontSize: "13px" }}
//             onClick={
//               (e) => {
//                 cancelInvoice(invoice.id, invoice.payState); // cancelled: true
//                 handleClose(e)
//               }
//             }
//             >
//             <CancelPresentationIcon fontSize="small" style={{ marginRight: "5px" }} />{invoice.payState === "payed" ? "Storno-Rechnung erstellen" : "Stornieren"}
//           </MenuItem>
//           }
         

//           {invoice.sendState !== "sent" && invoice.sendState !== "scheduled" && recurring &&
//           <MenuItem 
//             style={{ fontSize: "13px" }}
//             onClick={
//               (e) => {
//                 showDeleteWarningHandler(invoice.id);
//                 handleClose(e)
//               }
//             }
//             >
//             <DeleteForeverIcon fontSize="small" style={{ marginRight: "5px" }} />Löschen
//           </MenuItem>
//           }
//       </Menu>

//       <ConfirmModal
//         show={showConfirmModal}
//         onCancel={cancelDeleteHandler}
//         header={t("BUTTONS.invoiceDelete")}
//         footerClass="invoice-item__modal-actions"
//         style={{ zIndex: "9999999" }}
//         footer={
//           <React.Fragment>
//             <Button
//               variant="outlined"
//               style={{ marginRight: "10px" }}
//               onClick={(e) => cancelDeleteHandler(e)}
//             >
//               {t("BUTTONS.cancel")}
//             </Button>
//             <Button
//               variant="contained"
//               color="primary"
//               onClick={(e) => confirmDeleteHandler(invoice.id, e)}
//             >
//               {t("BUTTONS.delete")}
//             </Button>
//           </React.Fragment>
//         }
//       >
//         <p>
//           {t("PAGE_INVOICES.invoiceDelete")}
//         </p>
//       </ConfirmModal>
//     </>
//   );
// }

// import React, { useState, useContext } from "react";
// // IMPORT hooks & context
// import { useTranslation } from "react-i18next";
// import { useHttpClient } from "../../shared/hooks/http-hook";
// import { AuthContext } from "../../shared/context/auth-context";

// // IMPORT recoil
// import { useRecoilState } from "recoil";
// import invoicesAtomState from "../../_atoms/invoicesAtom";

// // IMPORT components MATERIAL UI
// import {
//   IconButton,
//   Menu,
//   MenuItem,
//   Button,
// } from "@material-ui/core";

// // IMPORT icons MATERIAL UI
// import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
// import EditIcon from "@material-ui/icons/Edit";
// import VisibilityIcon from "@material-ui/icons/Visibility";
// import FileCopyIcon from "@material-ui/icons/FileCopy";
// import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
// import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
// import CloseIcon from "@material-ui/icons/Close";

// // IMPORT functions
// import { DB_DELETE_INVOICE } from "./../../_functions/DB_INVOICES";

// // IMPORT components
// import { useSnackbar } from "notistack";
// import ConfirmModal from "../../shared/components/UIElements/Modal";

// const ITEM_HEIGHT = 48;

// export default function InvoicesMenuDots({
//   invoice,
//   setOpenInvoiceDialogAtom,
//   openInvoiceDialogAtom,
//   recurring,
//   handleSearchClear,
// }) {
//   // HOOKS & CONTEXT
//   const { t } = useTranslation();
//   const auth = useContext(AuthContext);
//   const { sendRequest } = useHttpClient();
//   const { enqueueSnackbar } = useSnackbar();

//   // GLOBAL STATE (RECOIL)
//   const [invoicesAtom, setInvoicesAtom] = useRecoilState(invoicesAtomState);

//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const open = Boolean(anchorEl);

//   const handleClick = (event) => {
//     event.stopPropagation();
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = (event) => {
//     event.stopPropagation();
//     setAnchorEl(null);
//   };

//   const [showConfirmModal, setShowConfirmModal] = useState(false);

//   const showDeleteWarningHandler = () => {
//     setShowConfirmModal(true);
//   };

//   const cancelDeleteHandler = (event) => {
//     event.stopPropagation();
//     setShowConfirmModal(false);
//   };

//   const confirmDeleteHandler = (invoice, event) => {
//     event.stopPropagation();
//     DB_DELETE_INVOICE(
//       invoice,
//       auth,
//       sendRequest,
//       enqueueSnackbar,
//       t,
//       invoicesAtom,
//       setInvoicesAtom,
//       openInvoiceDialogAtom,
//       setOpenInvoiceDialogAtom,
//       setShowConfirmModal,
//       handleSearchClear
//     );
//   };

//   const duplicateInvoice = async (invoiceId) => {
//     try {
//       const formData = new FormData();
//       formData.append("invoiceId", invoiceId);

//       const url = recurring
//         ? `${process.env.REACT_APP_BACKEND_URL_API}/invoicesrecurrings/duplicate`
//         : `${process.env.REACT_APP_BACKEND_URL_API}/invoices/duplicate`;

//       const response = await sendRequest(url, "POST", formData, {
//         Authorization: "Bearer " + auth.token,
//       });

//       setOpenInvoiceDialogAtom((prevState) => ({
//         ...prevState,
//         open: true,
//         invoiceId: response.invoiceId,
//         recurring: recurring,
//         duplicate: true,
//         type: "invoice",
//       }));
//       handleSearchClear();
//     } catch (error) {
//       console.error("Failed to copy invoice:", error);
//     }
//   };

//   const cancelInvoice = async (invoiceId, payState) => {
//     try {
//       const formData = new FormData();
//       formData.append("invoiceId", invoiceId);

//       const response = await sendRequest(
//         `${process.env.REACT_APP_BACKEND_URL_API}/invoices/cancel`,
//         "POST",
//         formData,
//         {
//           Authorization: "Bearer " + auth.token,
//         }
//       );

//       setOpenInvoiceDialogAtom((prevState) => ({
//         ...prevState,
//         open: true,
//         invoiceId: response.invoiceId,
//         recurring: false,
//         type: "cancellation",
//       }));
//       handleSearchClear();
//     } catch (error) {
//       console.error("Failed to cancel invoice:", error);
//     }
//   };

//   return (
//     <>
//       <IconButton
//         aria-label="more"
//         aria-controls={invoice.id}
//         aria-haspopup="true"
//         onClick={(e) => handleClick(e)}
//         style={{ marginRight: "5px" }}
//       >
//         <MoreHorizIcon />
//       </IconButton>
//       <Menu
//         id={invoice.id}
//         anchorEl={anchorEl}
//         open={open}
//         onClose={(e) => handleClose(e)}
//         PaperProps={{
//           elevation: 1,
//           style: {
//             maxHeight: ITEM_HEIGHT * 4.5,
//             width: "auto",
//           },
//         }}
//       >
//         {/* Header with MoreHorizIcon and CloseIcon */}
//         <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "space-between",
//             padding: "5px 10px",
//             borderBottom: "1px solid #ddd",
//           }}
//         >
//           <IconButton
//             style={{ marginRight: "auto" }}
//             size="small"
//             disabled
//           >
//             <MoreHorizIcon fontSize="small" />
//           </IconButton>
//           <IconButton
//             onClick={(e) => handleClose(e)}
//             size="small"
//           >
//             <CloseIcon fontSize="small" />
//           </IconButton>
//         </div>

//         {/* Menu Items */}
//         <MenuItem
//           style={{ fontSize: "13px" }}
//           onClick={(e) => {
//             setOpenInvoiceDialogAtom({
//               ...openInvoiceDialogAtom,
//               open: true,
//               invoiceId: invoice.id,
//               ...(invoice.recurring ? { recurring: true } : { recurring: false }),
//             });
//             handleClose(e);
//           }}
//         >
//           {!invoice.cancelled && <EditIcon fontSize="small" style={{ marginRight: "5px" }} />}
//           {invoice.cancelled && <VisibilityIcon fontSize="small" style={{ marginRight: "5px" }} />}
//           {invoice.cancelled ? "Ansehen" : "Bearbeiten"}
//         </MenuItem>

//         {!invoice.cancelled && invoice.type !== "cancellation" && (
//           <MenuItem
//             style={{ fontSize: "13px" }}
//             onClick={(e) => {
//               duplicateInvoice(invoice.id);
//               handleClose(e);
//             }}
//           >
//             <FileCopyIcon fontSize="small" style={{ marginRight: "5px" }} />
//             Duplizieren
//           </MenuItem>
//         )}

//         {!invoice.recurring &&
//           !invoice.cancelled &&
//           invoice.type !== "cancellation" &&
//           invoice.sendState !== "scheduled" && (
//             <MenuItem
//               style={{ fontSize: "13px" }}
//               onClick={(e) => {
//                 cancelInvoice(invoice.id, invoice.payState);
//                 handleClose(e);
//               }}
//             >
//               <CancelPresentationIcon fontSize="small" style={{ marginRight: "5px" }} />
//               {invoice.payState === "payed" ? "Storno-Rechnung erstellen" : "Stornieren"}
//             </MenuItem>
//           )}

//         {invoice.sendState !== "sent" &&
//           invoice.sendState !== "scheduled" &&
//           recurring && (
//             <MenuItem
//               style={{ fontSize: "13px" }}
//               onClick={(e) => {
//                 showDeleteWarningHandler(invoice.id);
//                 handleClose(e);
//               }}
//             >
//               <DeleteForeverIcon fontSize="small" style={{ marginRight: "5px" }} />
//               Löschen
//             </MenuItem>
//           )}
//       </Menu>

//       <ConfirmModal
//         show={showConfirmModal}
//         onCancel={cancelDeleteHandler}
//         header={t("BUTTONS.invoiceDelete")}
//         footerClass="invoice-item__modal-actions"
//         style={{ zIndex: "9999999" }}
//         footer={
//           <React.Fragment>
//             <Button
//               variant="outlined"
//               style={{ marginRight: "10px" }}
//               onClick={(e) => cancelDeleteHandler(e)}
//             >
//               {t("BUTTONS.cancel")}
//             </Button>
//             <Button
//               variant="contained"
//               color="primary"
//               onClick={(e) => confirmDeleteHandler(invoice.id, e)}
//             >
//               {t("BUTTONS.delete")}
//             </Button>
//           </React.Fragment>
//         }
//       >
//         <p>{t("PAGE_INVOICES.invoiceDelete")}</p>
//       </ConfirmModal>
//     </>
//   );
// }


import React, { useState, useContext } from "react";
// IMPORT hooks & context
import { useTranslation } from "react-i18next";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";

// IMPORT recoil
import { useRecoilState } from "recoil";
import invoicesAtomState from "../../_atoms/invoicesAtom";

// IMPORT components MATERIAL UI
import {
  IconButton,
  Menu,
  MenuItem,
  Button,
} from "@material-ui/core";

// IMPORT icons MATERIAL UI
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import CloseIcon from "@material-ui/icons/Close";

// IMPORT functions
import { DB_DELETE_INVOICE } from "./../../_functions/DB_INVOICES";

// IMPORT components
import { useSnackbar } from "notistack";
import ConfirmModal from "../../shared/components/UIElements/Modal";

const ITEM_HEIGHT = 48;

export default function InvoicesMenuDots({
  invoice,
  setOpenInvoiceDialogAtom,
  openInvoiceDialogAtom,
  recurring,
  handleSearchClear,
}) {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const { sendRequest } = useHttpClient();
  const { enqueueSnackbar } = useSnackbar();

  // GLOBAL STATE (RECOIL)
  const [invoicesAtom, setInvoicesAtom] = useRecoilState(invoicesAtomState);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const showDeleteWarningHandler = () => {
    setShowConfirmModal(true);
  };

  const cancelDeleteHandler = (event) => {
    event.stopPropagation();
    setShowConfirmModal(false);
  };

  const confirmDeleteHandler = (invoice, event) => {
    event.stopPropagation();
    DB_DELETE_INVOICE(
      invoice,
      auth,
      sendRequest,
      enqueueSnackbar,
      t,
      invoicesAtom,
      setInvoicesAtom,
      openInvoiceDialogAtom,
      setOpenInvoiceDialogAtom,
      setShowConfirmModal,
      handleSearchClear
    );
  };

  const duplicateInvoice = async (invoiceId) => {
    try {
      const formData = new FormData();
      formData.append("invoiceId", invoiceId);

      const url = recurring
        ? `${process.env.REACT_APP_BACKEND_URL_API}/invoicesrecurrings/duplicate`
        : `${process.env.REACT_APP_BACKEND_URL_API}/invoices/duplicate`;

      const response = await sendRequest(url, "POST", formData, {
        Authorization: "Bearer " + auth.token,
      });

      setOpenInvoiceDialogAtom((prevState) => ({
        ...prevState,
        open: true,
        invoiceId: response.invoiceId,
        recurring: recurring,
        duplicate: true,
        type: "invoice",
      }));
      handleSearchClear();
    } catch (error) {
      console.error("Failed to copy invoice:", error);
    }
  };

  const cancelInvoice = async (invoiceId, payState) => {
    try {
      const formData = new FormData();
      formData.append("invoiceId", invoiceId);

      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL_API}/invoices/cancel`,
        "POST",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      setOpenInvoiceDialogAtom((prevState) => ({
        ...prevState,
        open: true,
        invoiceId: response.invoiceId,
        recurring: false,
        type: "cancellation",
      }));
      handleSearchClear();
    } catch (error) {
      console.error("Failed to cancel invoice:", error);
    }
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls={invoice.id}
        aria-haspopup="true"
        onClick={(e) => handleClick(e)}
        style={{ marginRight: "5px" }}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id={invoice.id}
        anchorEl={anchorEl}
        open={open}
        onClose={(e) => handleClose(e)}
        PaperProps={{
          elevation: 1,
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "auto",
          },
        }}
      >
        {/* Header with MoreHorizIcon and CloseIcon */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "5px 10px",
            borderBottom: "1px solid #ddd",
            cursor: "default", // Ensure the cursor does not indicate a clickable area
          }}
          onClick={(e) => e.stopPropagation()} // Prevent event propagation in the header
        >
          <IconButton
            style={{ marginRight: "auto" }}
            size="small"
            disabled
          >
            <MoreHorizIcon fontSize="small" />
          </IconButton>
          <IconButton
            onClick={(e) => handleClose(e)}
            size="small"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>

        {/* Menu Items */}
        <MenuItem
          style={{ fontSize: "13px" }}
          onClick={(e) => {
            setOpenInvoiceDialogAtom({
              ...openInvoiceDialogAtom,
              open: true,
              invoiceId: invoice.id,
              ...(invoice.recurring ? { recurring: true } : { recurring: false }),
            });
            handleClose(e);
          }}
        >
          {!invoice.cancelled && <EditIcon fontSize="small" style={{ marginRight: "5px" }} />}
          {invoice.cancelled && <VisibilityIcon fontSize="small" style={{ marginRight: "5px" }} />}
          {invoice.cancelled ? "Ansehen" : "Bearbeiten"}
        </MenuItem>

        {!invoice.cancelled && invoice.type !== "cancellation" && (
          <MenuItem
            style={{ fontSize: "13px" }}
            onClick={(e) => {
              duplicateInvoice(invoice.id);
              handleClose(e);
            }}
          >
            <FileCopyIcon fontSize="small" style={{ marginRight: "5px" }} />
            Duplizieren
          </MenuItem>
        )}

        {!invoice.recurring &&
          !invoice.cancelled &&
          invoice.type !== "cancellation" &&
          invoice.sendState !== "scheduled" && (
            <MenuItem
              style={{ fontSize: "13px" }}
              onClick={(e) => {
                cancelInvoice(invoice.id, invoice.payState);
                handleClose(e);
              }}
            >
              <CancelPresentationIcon fontSize="small" style={{ marginRight: "5px" }} />
              {invoice.payState === "payed" ? "Storno-Rechnung erstellen" : "Stornieren"}
            </MenuItem>
          )}

        {invoice.sendState !== "sent" &&
          invoice.sendState !== "scheduled" &&
          recurring && (
            <MenuItem
              style={{ fontSize: "13px" }}
              onClick={(e) => {
                showDeleteWarningHandler(invoice.id);
                handleClose(e);
              }}
            >
              <DeleteForeverIcon fontSize="small" style={{ marginRight: "5px" }} />
              Löschen
            </MenuItem>
          )}
      </Menu>

      <ConfirmModal
        show={showConfirmModal}
        onCancel={cancelDeleteHandler}
        header={t("BUTTONS.invoiceDelete")}
        footerClass="invoice-item__modal-actions"
        style={{ zIndex: "9999999" }}
        footer={
          <React.Fragment>
            <Button
              variant="outlined"
              style={{ marginRight: "10px" }}
              onClick={(e) => cancelDeleteHandler(e)}
            >
              {t("BUTTONS.cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => confirmDeleteHandler(invoice.id, e)}
            >
              {t("BUTTONS.delete")}
            </Button>
          </React.Fragment>
        }
      >
        <p>{t("PAGE_INVOICES.invoiceDelete")}</p>
      </ConfirmModal>
    </>
  );
}

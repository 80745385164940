import React, { useEffect, useState, useContext } from 'react';

import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from '../../../shared/context/auth-context';
import { useAuth } from "../../../shared/hooks/auth-hook";

// IMPORT recoil
import { useRecoilState } from 'recoil';
import invoicesAtomState from "../../../_atoms/invoicesAtom";
import expensesAtomState from "../../../_atoms/expensesAtom";
import settingsAtomState from "../../../_atoms/settingsAtom";
import userAtomState from "../../../_atoms/userAtom";

// IMPORT hooks & context
import { useTranslation } from "react-i18next";
import { useSnackbar } from 'notistack';
import { useTheme } from '@material-ui/core/styles';

// IMPORT components MATERIAL UI
import {
  Typography,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Box,
  Divider
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

import DateFnsUtils from '@date-io/date-fns';
import { format, startOfDay, endOfDay } from 'date-fns';
import { de } from "date-fns/locale";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

// IMPORT icons MATERIAL UI
import DateRangeIcon from '@material-ui/icons/DateRange';
import CloseIcon from '@material-ui/icons/Close';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import ErrorIcon from '@material-ui/icons/Error';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { DB_PATCH_SETTINGS } from '../../../_functions/DB_SETTINGS';

// IMPORT own CSS
import "./DateRange.scss";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});





export default function DateRange({ type, mobile }) {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const [userAtom] = useRecoilState(userAtomState);
  const { userId, token } = useAuth();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const [fullWidth] = useState(true);
  const [maxWidth] = useState('sm');

  // GLOBAL STATE (RECOIL)
  const [invoicesAtom] = useRecoilState(invoicesAtomState);
  const [expensesAtom] = useRecoilState(expensesAtomState);
  const [settingsAtom, setSettingsAtom] = useRecoilState(settingsAtomState);

  // LOCAL STATE (PURE)
  const [types, setTypes] = useState(() => []);
  const [selectedType, setSelectedType] = useState(null);
  const [years, setYears] = useState(() => []);
  const [selectedYear, setSelectedYear] = useState(null);
  const quarters = ["first", "second", "third", "fourth"];
  const months = [
    "Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"
  ];

  const [noDateRestriction, setNoDateRestriction] = useState(false);


  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  



  





 // LOCAL STATE
 const [values, setValues] = useState({
  type: "",
  year: null,
  completeYear: false,
  month: null,
  quarter: null,
  startDate: null,
  endDate: null,
});
const [originalValues, setOriginalValues] = useState({});
const [hasChanges, setHasChanges] = useState(false);

const [menuOpen, setMenuOpen] = useState(false);

// Handle menu open
const handleMenuOpen = () => {
  setMenuOpen(true);
  // Initialize state with current values from settingsAtom
  const currentType = settingsAtom.invoicesDateRange || "invoiceDate";
  const startDate = settingsAtom.dateRangeStartValue || new Date(new Date().getFullYear(), 0, 1);
  const endDate = settingsAtom.dateRangeEndValue || new Date(new Date().getFullYear(), 11, 31);

  const initialValues = {
    type: currentType,
    year: new Date(startDate).getFullYear(),
    completeYear: isCompleteYear(startDate, endDate),
    month: new Date(startDate).getMonth(),
    quarter: getQuarter(startDate),
    startDate: startOfDay(new Date(startDate)),
    endDate: endOfDay(new Date(endDate)),
  };

  setValues(initialValues);
  setOriginalValues(initialValues);
};

// Check for changes
useEffect(() => {
  const changesDetected = Object.keys(values).some(
    (key) => values[key]?.toString() !== originalValues[key]?.toString()
  );
  setHasChanges(changesDetected);
}, [values, originalValues]);


// Helper Functions
const isCompleteYear = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  return (
    start.getMonth() === 0 &&
    start.getDate() === 1 &&
    end.getMonth() === 11 &&
    end.getDate() === 31
  );
};

const getQuarter = (date) => {
  const month = new Date(date).getMonth();
  if (month <= 2) return 1;
  if (month <= 5) return 2;
  if (month <= 8) return 3;
  return 4;
};

const handleCancel = () => {
  setValues(originalValues); // Reset to original values
  setMenuOpen(false);
};

const handleChange = (key, value) => {
  setValues((prev) => ({ ...prev, [key]: value }));
};


  useEffect(() => {
    if (auth.token) {
      fetchInvoiceYears();
    }

    // Set initial values and store a backup when the dialog is opened
    const initialStartDate = settingsAtom.dateRangeStartValue || new Date(new Date().getUTCFullYear(), 0, 1);
    const initialEndDate = settingsAtom.dateRangeEndValue || new Date(new Date().getFullYear(), 11, 31);

    setValues({
      ...values,
      startDate: initialStartDate,
      endDate: initialEndDate,
    });

    // Save original values to restore on cancel
    setOriginalValues({
      startDate: initialStartDate,
      endDate: initialEndDate,
    });
  }, [auth]);

  const fetchInvoiceYears = async () => {
    try {
      const res = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL_API}/invoices/years`,
        "POST",
        JSON.stringify({ userId: auth.userId }),
        {
          'Content-Type': 'application/json',
          Authorization: "Bearer " + auth.token,
        }
      );
      setYears(res);
    } catch (err) {
      console.log(err);
    }
  };

  const getFormattedDateStringForYearBasedOnString = (dateString, newYear) => {
    let originalDate = new Date(dateString);
    if (isNaN(originalDate)) return "Invalid date string";

    let month = originalDate.getMonth();
    let day = originalDate.getDate();
    if (month === 1 && day === 29 && !((newYear % 4 === 0 && newYear % 100 !== 0) || (newYear % 400 === 0))) {
      day = 28;
    }
    return new Date(newYear, month, day, originalDate.getHours(), originalDate.getMinutes(), originalDate.getSeconds()).toString();
  };

  const handleYears = (year) => {
    const isLeapYear = (year) => {
      return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
    };
  
    const newStartDate = getFormattedDateStringForYearBasedOnString(values.startDate, year);
    let newEndDate = getFormattedDateStringForYearBasedOnString(values.endDate, year);
  
    // Adjust February for leap year if February is selected
    const month = new Date(values.endDate).getMonth();
    const day = new Date(values.endDate).getDate();
  
    if (month === 1 && day === 28 && isLeapYear(year)) {
      newEndDate = new Date(year, 1, 29, 23, 59, 59).toString(); // Set endDate to Feb 29 for leap year
    }
  
    if (new Date(newEndDate) <= new Date(newStartDate)) {
      setValues({
        startDate: newStartDate,
        endDate: values.endDate,
        endDateError: true,
      });
    } else {
      setValues({
        startDate: newStartDate,
        endDate: newEndDate,
        endDateError: false,
      });
    }
  };  

  const handleQuarters = (quarter) => {
    const quarterDates = {
      first: [0, 1, 2],
      second: [3, 4, 5],
      third: [6, 7, 8],
      fourth: [9, 10, 11]
    };
  
    const startMonth = quarterDates[quarter][0];
    const endMonth = quarterDates[quarter][2];
  
    const startDateYear = new Date(values.startDate).getFullYear();
  
    setValues({
      startDate: new Date(startDateYear, startMonth, 1, 0, 0, 0).toString(),
      endDate: new Date(startDateYear, endMonth, new Date(startDateYear, endMonth + 1, 0).getDate(), 23, 59, 59).toString(),
    });
  };

  const handleMonths = (month) => {
    const isLeapYear = (year) => {
      return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    };
  
    // Use the current year from startDate
    const selectedYear = new Date(values.startDate).getFullYear();
  
    // Adjust February for leap year
    const daysInMonth = [31, isLeapYear(selectedYear) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  
    setValues({
      startDate: new Date(selectedYear, month, 1, 0, 0, 0).toString(), // Set start date to 1st of the selected month
      endDate: new Date(selectedYear, month, daysInMonth[month], 23, 59, 59).toString(), // Set end date to the last day of the selected month
    });
  };

  const isFullMonthSelected = () => {
    const startDate = new Date(values.startDate);
    const endDate = new Date(values.endDate);
    return (
      startDate.getDate() === 1 &&
      endDate.getDate() === new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0).getDate() &&
      startDate.getMonth() === endDate.getMonth() &&
      startDate.getFullYear() === endDate.getFullYear()
    );
  };

  const renderMonthLabel = () => {
    if (isFullMonthSelected()) {
      return (
        <span style={{ fontWeight: 500 }}>
          {months[new Date(values.startDate).getMonth()]}
        </span>
      );
    } else {
      return "Monat";
    }
  };

  const handleTypes = (type) => {
    setSelectedType(type);
    handleChange("type", type)
  }

  useEffect(() => {
    if (type === "invoices") {
      setTypes(["invoiceDate", "payDate", "dueDate"]);
      setSelectedType(settingsAtom.invoicesDateRange === "none" ? "invoiceDate" : settingsAtom.invoicesDateRange);
    } else {
      setSelectedType(settingsAtom.invoicesDateRange);
    }
  }, []);

  const [anchorElType, setAnchorElType] = useState(null);
  const handleClickType = (event) => setAnchorElType(event.currentTarget);
  const handleCloseType = () => setAnchorElType(null);

  const [anchorElYear, setAnchorElYear] = useState(null);
  const handleClickYear = (event) => setAnchorElYear(event.currentTarget);
  const handleCloseYear = () => setAnchorElYear(null);

  const [anchorElQuarter, setAnchorElQuarter] = useState(null);
  const handleClickQuarter = (event) => setAnchorElQuarter(event.currentTarget);
  const handleCloseQuarter = () => setAnchorElQuarter(null);

  const [anchorElMonth, setAnchorElMonth] = useState(null);
  const handleClickMonth = (event) => setAnchorElMonth(event.currentTarget);
  const handleCloseMonth = () => setAnchorElMonth(null);

  const isFullYearSelected = () => new Date(values.startDate).getFullYear() === new Date(values.endDate).getFullYear();

  const renderQuarterLabel = () => {
    const startDate = new Date(values.startDate);
    const endDate = new Date(values.endDate);
    const year = startDate.getFullYear();
  
    let quarterLabel = "Quartal";
    let isQuarterSelected = false;
  
    if (startDate.getMonth() === 0 && startDate.getDate() === 1 &&
      endDate.getMonth() === 2 && endDate.getDate() === 31 && startDate.getFullYear() === endDate.getFullYear()) {
      quarterLabel = "1. Quartal";
      isQuarterSelected = true;
    }
    if (startDate.getMonth() === 3 && startDate.getDate() === 1 &&
      endDate.getMonth() === 5 && endDate.getDate() === 30 && startDate.getFullYear() === endDate.getFullYear()) {
      quarterLabel = "2. Quartal";
      isQuarterSelected = true;
    }
    if (startDate.getMonth() === 6 && startDate.getDate() === 1 &&
      endDate.getMonth() === 8 && endDate.getDate() === 30 && startDate.getFullYear() === endDate.getFullYear()) {
      quarterLabel = "3. Quartal";
      isQuarterSelected = true;
    }
    if (startDate.getMonth() === 9 && startDate.getDate() === 1 &&
      endDate.getMonth() === 11 && endDate.getDate() === 31 && startDate.getFullYear() === endDate.getFullYear()) {
      quarterLabel = "4. Quartal";
      isQuarterSelected = true;
    }

    return (
      <span style={{ 
        fontWeight: isQuarterSelected ? 500 : 'normal', 
      }}>
        {quarterLabel}
      </span>
    );
  };

  const renderYearLabel = () => {
    if (isFullYearSelected() && years.includes(new Date(values.startDate).getFullYear())) {
      return (
        <span style={{ fontWeight: 500 }}>
          {new Date(values.startDate).getFullYear()}
        </span>
      );
    } else {
      return "Jahr";
    }
  };

  const handleCompleteYear = () => {
    const startDateYear = new Date(values.startDate).getFullYear();
  
    setValues({
      startDate: new Date(startDateYear, 0, 1, 0, 0, 0).toString(),
      endDate: new Date(startDateYear, 11, 31, 23, 59, 59).toString(),
    });
  };

  const isFullYearFromJanToDec = () => {
    const startDate = new Date(values.startDate);
    const endDate = new Date(values.endDate);
    return startDate.getMonth() === 0 && startDate.getDate() === 1 &&
      endDate.getMonth() === 11 && endDate.getDate() === 31 &&
      isFullYearSelected();
  };

  return (
    <>
    {mobile &&
    <IconButton
      onClick={(e) => {setOpen(!open); setAnchorEl(e.currentTarget); handleMenuOpen();}}
      aria-label="date range"
      style={{ color: theme.palette.primary.main}}
      >
        <DateRangeIcon />
    </IconButton>
    }

    {!mobile &&
    <div style={{ height: "100%", marginRight: "8px"}}>
      <div style={{ height: "100%" }} className="date" onClick={(e) => {setOpen(!open); setAnchorEl(e.currentTarget); handleMenuOpen()}}>
      <IconButton aria-label="date range" style={{ zoom: "0.8", color: theme.palette.primary.main, marginRight: "-10px" }}>
        <DateRangeIcon />
      </IconButton>
      <Typography className="date-text" style={{ width: "100%", color: "rgb(44, 83, 125)", fontWeight: 500, borderRadius: "4px", marginLeft: "8px", padding: "2px", fontSize: "12px", marginRight: "5px" }}>
        <span>
          <span className="date-type">
            {type === "invoices" ? `${settingsAtom.invoicesDateRange === "payDate" ? "Zahldatum" : settingsAtom.invoicesDateRange === "invoiceDate" ? "Rechnungsdatum" : "Fälligkeitsdatum"} ` : type === "estimates" ? "Datum" + " " : "Zahldatum" + " "}
          </span>

          <span style={{ marginLeft: 5, fontWeight: 500, color: "rgb(44, 83, 125)" }}>
            {`${settingsAtom.dateRangeStartValue === undefined ? format(new Date(new Date().getFullYear(), 0, 1), 'dd.MM.yyyy') : format(new Date(settingsAtom.dateRangeStartValue), 'dd.MM.yyyy')}
            - 
            ${settingsAtom.dateRangeEndValue === undefined ? format((new Date(new Date().getFullYear(), 11, 31)).setHours(23, 59, 59, 999), 'dd.MM.yyyy') : format(new Date(settingsAtom.dateRangeEndValue), 'dd.MM.yyyy')}
            `}
          </span>
        </span>
        {/* Define startDate and endDate for quarter and month checks */}
        {(() => {
          const startDate = new Date(values.startDate);
          const endDate = new Date(values.endDate);

          // Check for full year display
          if (isFullYearFromJanToDec()) {
            return (
              <span className="date-range-hint" style={{ border: "1px solid rgb(237, 239, 255)", fontWeight: "bold", borderRadius: 60, padding: "5px 10px" }}>
                Gesamtes Jahr {startDate.getFullYear()}
              </span>
            );
          }

          // Check for each quarter
          if (new Date(values.startDate).getFullYear() === new Date(values.endDate).getFullYear() && startDate.getMonth() === 0 && startDate.getDate() === 1 && endDate.getMonth() === 2 && endDate.getDate() === 31) {
            return (
              <span className="date-range-hint" style={{ border: "1px solid rgb(237, 239, 255)",fontWeight: "bold", borderRadius: 60, padding: "5px 10px" }}>
                1. Quartal {startDate.getFullYear()}
              </span>
            );
          }
          if (new Date(values.startDate).getFullYear() === new Date(values.endDate).getFullYear() && startDate.getMonth() === 3 && startDate.getDate() === 1 && endDate.getMonth() === 5 && endDate.getDate() === 30) {
            return (
              <span className="date-range-hint" style={{ border: "1px solid rgb(237, 239, 255)", fontWeight: "bold", borderRadius: 60, padding: "5px 10px" }}>
                2. Quartal {startDate.getFullYear()}
              </span>
            );
          }
          if (new Date(values.startDate).getFullYear() === new Date(values.endDate).getFullYear() && startDate.getMonth() === 6 && startDate.getDate() === 1 && endDate.getMonth() === 8 && endDate.getDate() === 30) {
            return (
              <span className="date-range-hint" style={{ border: "1px solid rgb(237, 239, 255)", fontWeight: "bold", borderRadius: 60, padding: "5px 10px" }}>
                3. Quartal {startDate.getFullYear()}
              </span>
            );
          }
          if (new Date(values.startDate).getFullYear() === new Date(values.endDate).getFullYear() && startDate.getMonth() === 9 && startDate.getDate() === 1 && endDate.getMonth() === 11 && endDate.getDate() === 31) {
            return (
              <span className="date-range-hint" style={{ border: "1px solid rgb(237, 239, 255)", fontWeight: "bold", borderRadius: 60, padding: "5px 10px" }}>
                4. Quartal {startDate.getFullYear()}
              </span>
            );
          }

          // Check for full month display
          if (isFullMonthSelected()) {
            const monthNames = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];
            const monthLabel = monthNames[startDate.getMonth()];

            return (
              <span className="date-range-hint" style={{ border: "1px solid rgb(237, 239, 255)", fontWeight: "bold", borderRadius: 60, padding: "5px 10px" }}>
                {monthLabel} {startDate.getFullYear()}
              </span>
            );
          }

          return null;
        })()}
      </Typography>
    </div>
    </div>
    }
          <Menu
        anchorEl={anchorEl}
        keepMounted
        onClose={() => {
          setOpen(!open);

          // Reset to the original values on cancel
          setValues({
            ...values,
            startDate: originalValues.startDate,
            endDate: originalValues.endDate,
            startDateError: false,
            endDateError: false,
          });
          setSelectedType(settingsAtom.invoicesDateRange);
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          style: {
            width: '350px', // Set fixed width
            maxWidth: 'calc(100% - 32px)', // Ensure it does not exceed 900px
          },
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between" px={2} py={1}>
          <Typography
            variant="h6"
            color="primary"
            style={{
              display: "flex",
              alignItems: "center",
              fontWeight: 600,
              fontSize: 16,
            }}
          >
            <DateRangeIcon
              style={{
                marginRight: 10,
                color: "rgb(44, 83, 125)",
                boxShadow: "rgba(108, 116, 173, 0.12) 0px 0px 20px 0px inset",
                borderRadius: 60,
                padding: 5,
                zoom: 1.5,
              }}
            />
            Zeitraum
          </Typography>
          <IconButton
            onClick={() => {
              setOpen(!open);

              // Reset to the original values on cancel
              setValues({
                ...values,
                startDate: originalValues.startDate,
                endDate: originalValues.endDate,
                startDateError: false,
                endDateError: false,
              });
              setSelectedType(settingsAtom.invoicesDateRange);
            }}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </Box>


          <Grid item container spacing={1} style={{paddingLeft: 16, paddingRight: 16}}>

            {type === "invoices" &&
              <Grid item container xs={12} md={12}>
                <List style={{ width: "100%", display: "flex", background: "rgb(240, 243, 255)", borderRadius: "4px", padding: 0, marginTop: "8px" }}>
                  <ListItem
                    button
                    onClick={handleClickType}
                  >
                    <ListItemText primary={<Typography variant="body2" style={{ fontWeight: 500 }}>
                      {selectedType === "payDate" ? "Zahldatum" : selectedType === "invoiceDate" ? "Rechnungsdatum" : "Falligkeitsdatum"}
                    </Typography>} />
                    <ArrowDropDownIcon />
                  </ListItem>
                  <Menu
                    id="type-menu"
                    anchorEl={anchorElType}
                    keepMounted
                    open={Boolean(anchorElType)}
                    onClose={handleCloseType}
                  >
                    {types.map(type => (
                      <MenuItem
                        key={type}
                        style={{ fontSize: "12px" }}
                        onClick={() => {
                          handleTypes(type);
                          handleCloseType();
                        }}
                      >
                        <span style={{ paddingRight: "5px" }}>{type === "payDate" ? "Zahldatum" : type === "invoiceDate" ? "Rechungsdatum" : "Fälligkeitsdatum"}</span>
                      </MenuItem>
                    ))}
                  </Menu>
                </List>

                {selectedType === "payDate" &&
                  <Typography style={{ fontSize: "10px", marginTop: 5, marginBottom: "7px", display: "flex", alignItems: "center", padding: "5px", background: "rgb(234, 242, 234)", borderRadius: "4px" }}>
                    <ErrorIcon style={{ zoom: 0.9, marginRight: "6px" }} />
                    <span>Bei Datumseingrenzung nach Zahldatum werden auschließlich Rechnungen mit dem Status 'bezahlt' angezeigt</span>
                  </Typography>
                }
              </Grid>
            }

            <Grid item container xs={12} md={12}>
              <List className="daterange-dropdowns" style={{ width: "100%", display: "flex", flexDirection: "column",  borderRadius: "4px", padding: 0, marginTop: "8px", marginBottom: "8px" }}>
                <ListItem
                  button
                  onClick={handleClickYear}
                  style={{textAlign: "center", padding: 0, borderRadius: 4}}
                >
                  <ListItemText style={{opacity: isFullYearSelected() ? 1 : 0.3, fontWeight: 300, background: "rgb(240, 243, 255)", borderRadius: 20, padding: "5px 10px"}} primary={<Typography variant="body2" component="div">{renderYearLabel()}</Typography>} />
                  <ArrowDropDownIcon />
                </ListItem>

                <Menu
  id="year-menu"
  anchorEl={anchorElYear}
  keepMounted
  open={Boolean(anchorElYear)}
  onClose={handleCloseYear}
  PaperProps={{
    style: {
      maxHeight: '60vh',
      overflowY: 'auto',
    },
  }}
>
  {/* Header with headline and close icon */}
  <Box
    display="flex"
    alignItems="center"
    justifyContent="space-between"
    px={2}
    py={1}
    borderBottom="1px solid rgba(0, 0, 0, 0.1)" // Optional divider for clarity
  >
    <Typography variant="body2" style={{ fontWeight: 500 }}>
      Jahr auswählen
    </Typography>
    <IconButton
      size="small"
      onClick={handleCloseYear}
      aria-label="close"
      style={{marginLeft: 5}}
    >
      <CloseIcon />
    </IconButton>
  </Box>

  {/* Year options */}
  {years.map((year) => (
    <MenuItem
      key={year}
      style={{ fontSize: "12px" }}
      onClick={() => {
        handleYears(year);
        handleCloseYear();
      }}
    >
      <span style={{ paddingRight: "5px" }}>{year}</span>
    </MenuItem>
  ))}
</Menu>


                  <Tooltip title="Gesamtes Jahr (01.01. bis 31.12.)">
                    <ListItem button onClick={handleCompleteYear} style={{textAlign: "center", padding: 0}}>
                    <ListItemText primary={<Typography variant="body2" component="div" style={{display: "flex", justifyContent: "center", marginRight: 25, alignItems: "center", background: isFullYearFromJanToDec() ? "rgb(240, 243, 255)" : "rgb(240, 243, 255)", borderRadius: 20, padding: "5px 10px", fontWeight: isFullYearFromJanToDec() ? 500 : 300, opacity: isFullYearFromJanToDec() ? 1 : 0.3,}}>
                    <SettingsEthernetIcon
                        style={{
                          marginRight: 5,
                        }}
                      />Gesamtes Jahr
                    </Typography>} />
                     
                    </ListItem>
                  </Tooltip>

                <ListItem
                  button
                  onClick={handleClickQuarter}
                  style={{textAlign: "center", padding: 0, borderRadius: 4}}
                >
                <ListItemText
                  style={{
                    background: "rgb(240, 243, 255)",
                    opacity:
                    (new Date(values.startDate).getFullYear() === new Date(values.endDate).getFullYear() && new Date(values.startDate).getMonth() === 0 && new Date(values.startDate).getDate() === 1 && new Date(values.endDate).getMonth() === 2 && new Date(values.endDate).getDate() === 31) ||
                    (new Date(values.startDate).getFullYear() === new Date(values.endDate).getFullYear() && new Date(values.startDate).getMonth() === 3 && new Date(values.startDate).getDate() === 1 && new Date(values.endDate).getMonth() === 5 && new Date(values.endDate).getDate() === 30) ||
                    (new Date(values.startDate).getFullYear() === new Date(values.endDate).getFullYear() && new Date(values.startDate).getMonth() === 6 && new Date(values.startDate).getDate() === 1 && new Date(values.endDate).getMonth() === 8 && new Date(values.endDate).getDate() === 30) ||
                    (new Date(values.startDate).getFullYear() === new Date(values.endDate).getFullYear() && new Date(values.startDate).getMonth() === 9 && new Date(values.startDate).getDate() === 1 && new Date(values.endDate).getMonth() === 11 && new Date(values.endDate).getDate() === 31)
                    ? 1
                    : 0.3,
                    borderRadius: 20,
                    padding: "5px 10px"
                  }}
                  primary={<Typography variant="body2" component="div">{renderQuarterLabel()}</Typography>}
                />
                  <ArrowDropDownIcon />
                </ListItem>

                <Menu
  id="quarter-menu"
  anchorEl={anchorElQuarter}
  keepMounted
  open={Boolean(anchorElQuarter)}
  onClose={handleCloseQuarter}
  PaperProps={{
    style: {
      maxHeight: '60vh',
      overflowY: 'auto',
    },
  }}
>
  {/* Header with headline and close icon */}
  <Box
    display="flex"
    alignItems="center"
    justifyContent="space-between"
    px={2}
    py={1}
    borderBottom="1px solid rgba(0, 0, 0, 0.1)" // Optional divider for clarity
  >
    <Typography variant="body2" style={{ fontWeight: 500 }}>
      Quartal auswählen
    </Typography>
    <IconButton
      size="small"
      onClick={handleCloseQuarter}
      aria-label="close"
      style={{marginLeft: 5}}
    >
      <CloseIcon />
    </IconButton>
  </Box>

  {/* Quarter options */}
  {quarters.map((quarter) => (
    <MenuItem
      key={quarter}
      style={{ fontSize: "12px" }}
      onClick={() => {
        handleQuarters(quarter);
        handleCloseQuarter();
      }}
    >
      <span style={{ paddingRight: "5px" }}>
        {quarter === "first"
          ? "1. Quartal"
          : quarter === "second"
          ? "2. Quartal"
          : quarter === "third"
          ? "3. Quartal"
          : "4. Quartal"}
      </span>
    </MenuItem>
  ))}
</Menu>


                <ListItem
                  button
                  onClick={handleClickMonth}
                  style={{textAlign: "center", padding: 0, borderRadius: 4}}
                >
                <ListItemText
                  style={{
                    background: "rgb(240, 243, 255)",
                    opacity:
                    isFullMonthSelected()
                      ? 1
                      : 0.3,
                    borderRadius: 20,
                    padding: "5px 10px",
                  }}
                  primary={<Typography variant="body2" component="div">{renderMonthLabel()}</Typography>}
                />
                <ArrowDropDownIcon />
                </ListItem>

                <Menu
  id="month-menu"
  anchorEl={anchorElMonth}
  keepMounted
  open={Boolean(anchorElMonth)}
  onClose={handleCloseMonth}
  PaperProps={{
    style: {
      maxHeight: '60vh',
      overflowY: 'auto',
    },
  }}
>
  {/* Header with headline and close icon */}
  <Box
    display="flex"
    alignItems="center"
    justifyContent="space-between"
    px={2}
    py={1}
    borderBottom="1px solid rgba(0, 0, 0, 0.1)" // Optional divider for clarity
  >
    <Typography variant="body2" style={{ fontWeight: 500 }}>
      Monat auswählen
    </Typography>
    <IconButton
      size="small"
      onClick={handleCloseMonth}
      aria-label="close"
      style={{marginLeft: 5}}
    >
      <CloseIcon />
    </IconButton>
  </Box>

  {/* Month options */}
  {months.map((month, i) => (
    <MenuItem
      key={month}
      style={{ fontSize: "12px" }}
      onClick={() => {
        handleMonths(i);
        handleCloseMonth();
      }}
    >
      <span style={{ paddingRight: "5px" }}>{month}</span>
    </MenuItem>
  ))}
</Menu>
              </List>
            </Grid>



            <Grid item container xs={12} md={12}>
                <Divider style={{background: "rgb(240, 243, 255)", width: "100%", height: 1, marginBottom: 20}}/>
                <MuiPickersUtilsProvider locale={de} utils={DateFnsUtils}>
                  <Grid item xs={12} md={12} className="datepicker" style={{ marginBottom: "5px" }}>
                    <KeyboardDatePicker
                      inputVariant="filled"
                      id="dateRangeStartValue"
                      format="dd.MM.yyyy"
                      label="Von"
                      value={values.startDate}
                      onChange={(date) => {
                        if (new Date(date) <= new Date(values.endDate)) {
                          setValues({
                            ...values,
                            startDate: startOfDay(date),
                            startDateError: false
                          });
                        } else {
                          setValues({
                            ...values,
                            startDateError: true
                          });
                        }
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      okLabel="OK"
                      cancelLabel="Abbrechen"
                      error={values.startDateError}
                      helperText={values.startDateError ? "Das Startdatum muss kleiner oder gleich dem Enddatum sein." : ""}
                    />
                  </Grid>

                  <Grid item xs={12} md={12} className="datepicker">
                    <KeyboardDatePicker
                      inputVariant="filled"
                      id="dateRangeEndValue"
                      format="dd.MM.yyyy"
                      label="Bis"
                      value={values.endDate}
                      onChange={(date) => {
                        if (new Date(date) >= new Date(values.startDate)) {
                          setValues({
                            ...values,
                            endDate: endOfDay(date),
                            endDateError: false
                          });
                        } else {
                          setValues({
                            ...values,
                            endDateError: true
                          });
                        }
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      okLabel="OK"
                      cancelLabel="Abbrechen"
                      error={values.endDateError}
                      helperText={values.endDateError ? "Das Enddatum muss größer oder gleich dem Startdatum sein." : ""}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
            </Grid>
          </Grid>

<Box display="flex" justifyContent="space-between" px={2} py={1} style={{marginTop: 15}}>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              setOpen(!open);

              // Reset to the original values on cancel
              setValues({
                ...values,
                startDate: originalValues.startDate,
                endDate: originalValues.endDate,
                startDateError: false,
                endDateError: false,
              });
              setSelectedType(settingsAtom.invoicesDateRange);
            }}
          >
            {t("BUTTONS.cancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              // Save new values
              setOriginalValues({ ...values }); // Update the backup to new values
              setSettingsAtom({
                ...settingsAtom,
                dateRangeStartValue: values.startDate.toString(),
                dateRangeEndValue: values.endDate,
                invoicesDateRange: selectedType,
              });
              DB_PATCH_SETTINGS(
                {
                  ...settingsAtom,
                  invoicesDateRange: selectedType,
                  dateRangeStartValue: values.startDate.toString(),
                  dateRangeEndValue: values.endDate,
                },
                auth,
                sendRequest,
                enqueueSnackbar,
                t,
                false
              );
              setOpen(!open);
              setValues({
                ...values,
                startDateError: false,
                endDateError: false,
              });
            }}
            startIcon={<DateRangeIcon />}
            disabled={!hasChanges || values.endDateError}
          >
            Zeitraum ändern
          </Button>
        </Box>
      </Menu>
    </>
  );
}


import React, { useEffect, useState, useContext } from "react";

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import userAtomState from "./../../_atoms/userAtom";
import openInvoiceDialogAtomState from "../../_atoms/openInvoiceDialogAtom";
import designerAtomState from "../../_atoms/designerAtom";
import settingsAtomState from "../../_atoms/settingsAtom";

// IMPORT hooks & context
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from 'notistack';


import { useHttpClient } from "./../../shared/hooks/http-hook";
import { AuthContext } from "./../../shared/context/auth-context";

import {
  TextField,
  Button,
  Grid,
  InputAdornment,
  SvgIcon,
  Chip,
  Backdrop,
  FormControlLabel,
  Switch,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  List,
  ListItem,
  Box,
  Container
} from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTheme } from "@material-ui/core/styles";

import DateFnsUtils from '@date-io/date-fns';
import { de } from "date-fns/locale";
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';


// IMPORT components
import InvoicePdfPreview from "./InvoicePdfPreview";
import AnimationSuccess from "./../../shared/components/UIElements/animations/AnimationSuccess"
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';

import "./InvoiceLivePreview.scss"

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import Typography from '@material-ui/core/Typography';

import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import AttachmentIcon from '@material-ui/icons/Attachment';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import EditIcon from '@material-ui/icons/Edit';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EmailIcon from '@material-ui/icons/Email';

import InvoicesScheduleSend from "./InvoicesScheduleSend";
import { PictureAsPdf } from "@material-ui/icons";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);


const InvoiceLivePreview = ({
  openPdfPreview,
  setOpen,
  sendMode,
  client,
  items,
  invoiceId,
  invoiceNr,
  invoiceDate,
  invoiceDueDate,
  invoiceSubtotal,
  invoiceDiscount,
  invoiceDiscountAbsolute,
  invoiceDiscountPercent,
  invoiceDiscountValue,
  invoiceDiscountedSubtotal,
  invoiceVatValues,
  invoiceTotal,
  designer,
  headline,
  text,
  clientNr,
  loading,
  directDownload,
  handleClose,
  invoiceValues,
  selectedClient,
  userId,
  setInvoicesAtom,
  setInvoicesFilteredAtom,
  sendState,
  reminderRequestCharge,
  isEstimate,
  type,
  showVat,
  showSignature,
  showAdditionalText,
  additionalText,
  recurring,
  isCorrection,
  status,
  setSendMode
}) => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  // const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const theme = useTheme();


  // GLOBAL STATE (RECOIL)
  const [userAtom] = useRecoilState(userAtomState);
  const [openInvoiceDialogAtom, setOpenInvoiceDialogAtom] = useRecoilState(openInvoiceDialogAtomState);
  const [designerAtom] = useRecoilState(designerAtomState);
  const [settingsAtom, setSettingsAtom] = useRecoilState(settingsAtomState);
  const [settingsAtomInitial] = useRecoilState(settingsAtomState);
  const [editorStateMessage, setEditorStateMessage] = useState(null);

  const [cssZoom] = useState("100%");

  const [emailSent, setEmailSent] = useState(false);
  const [emailScheduled, setEmailScheduled] = useState(false);
  const [zoom, setZoom] = useState(false);

  const [, setEditorState] = useState(null);
  const [editRecipients, setEditRecipients] = useState(false);


  const [values, setValues] = useState({
    country: "",
    countryCode: "",
    items: [],
    headline: "",
    text: "",
    clientNr: "",
    invoiceNr: "",
    invoiceDate: "",
    dueDate: "",
    sendState: "",
    sendDate: new Date(),
    discount: "",
    discountAbsolute: "",
    discountPercent: "",
    discountValue: 0,
    reminderRequestCharge: 0,
    subject: "",
    recurring: false,
    isLoading: true,
  });

  // RECIPIENT(S)
  const [recipients, setRecipients] = useState([]);
  const [recipient, setRecipient] = useState({
    state: "",
    isEmail: false,
    error: false,
  });

  // RECIPIENT(S) CC
  const [recipientsCc, setRecipientsCc] = useState([]);
  const [recipientCc, setRecipientCc] = useState({
    state: "",
    isEmail: false,
    error: false,
  });

  // RECIPIENT(S) BCC
  const [recipientsBcc, setRecipientsBcc] = useState([]);
  const [recipientBcc, setRecipientBcc] = useState({
    state: "",
    isEmail: false,
    error: false,
  });

  const [emailEnterState, setEmailEnterState] = useState("recipient"); // recipient, cc, bcc

  useEffect(() => {
    try {
      const country = JSON.parse(client.country);
      setValues({
        ...values,
        country: country.label,
        countryCode: country.code,
        headline: headline,
        text: text,
        items: items,
        clientNr: clientNr,
        invoiceNr: invoiceNr,
        invoiceDate: invoiceDate,
        dueDate: invoiceDueDate,
        sendState: sendState,
        discount: invoiceDiscount,
        discountAbsolute: invoiceDiscountAbsolute,
        discountPercent: invoiceDiscountPercent,
        discountValue: invoiceDiscountValue,
        reminderRequestCharge: reminderRequestCharge,
        subject: `${userAtom.data.company} Rechnung Nr. ${invoiceNrForDownload(invoiceNr)}`,
        type: type,
        showVat: showVat,
        showSignature: showSignature,
        showAdditonalText: showAdditionalText,
        additionalText: additionalText,
        recurring: recurring,
        isLoading: false,
        isCorrection: isCorrection,
        status: status
      })

    } catch (err) {
      // 👇️ This runs
      // console.log('Error: ', err.message);
    }
  }, [
    client,
    items,
    headline,
    text,
    invoiceDate,
    invoiceDueDate,
    clientNr,
    invoiceNr,
    sendState,
    invoiceDiscount,
    invoiceDiscountAbsolute,
    invoiceDiscountPercent,
    invoiceDiscountValue,
    reminderRequestCharge,
    type,
    showVat,
    showSignature,
    isCorrection
  ]);


  const [logo, setLogo] = React.useState(null);


  useEffect(() => {
    // console.log(userAtom.data.logo)

    let blob = new Blob([userAtom.data.logo], { type: 'image' });
    let link = URL.createObjectURL(blob);

    setLogo(userAtom.data.logo)

  }, [userAtom])


  // SEND immediately
  const send = async (scheduled, sendDate, sendOption, manualSendSave) => {
    if (manualSendSave) {
      handleManualSendSaveAndClose();
      invoiceHandler("sent", values.sendDate, sendOption); // Only necessary to update the invoices list with the new state send
    } else
      if (JSON.stringify(recipients) !== "[]") {
        try {
          const formData = new FormData();
          formData.append("sendOption", sendOption);
          formData.append("status", isCorrection ? "correction" : "draft");
          formData.append("userId", auth.userId);
          formData.append("settingsId", settingsAtom._id);
          formData.append("clientId", client.id);
          formData.append("invoiceId", invoiceId);
          formData.append("subject", values.subject);
          formData.append("filename", `Rechnung_${userAtom.data.company}_${invoiceNrForDownload(values.invoiceNr)}_${client.company}.pdf`)
          formData.append("email", JSON.stringify(recipients.map(item => item.email)));
          formData.append("cc", JSON.stringify(recipientsCc.map(item => item.email)));
          formData.append("bcc", JSON.stringify(recipientsBcc.map(item => item.email)));
          formData.append("type", invoiceValues.type);
          formData.append("isCorrection", isCorrection);

          editorStateMessage !== null
            ? formData.append("message", draftToHtml(convertToRaw(editorStateMessage.getCurrentContent())))
            : formData.append("message", "")

          if (scheduled !== "scheduled") {
            await sendRequest(
              `${process.env.REACT_APP_BACKEND_URL_API}/invoices/sendinvoice`,
              "POST",
              formData,
              {
                // "Content-Type": "application/json",
                Authorization: "Bearer " + auth.token,
              }
            )
              .then(() => setEmailSent(true))
              .then(() => setZoom(!zoom))
            // .then(() => invoiceHandler("sent", sendDate))
          } else {
            invoiceHandler("scheduled", sendDate, sendOption);
            setEmailScheduled(true);
          }

        } catch (err) {
          console.log(err)
        }
      } else {
        setRecipient({
          ...recipient,
          error: true
        })
      }
  };


  // SCHEDULE send
  const invoiceHandler = async (sendState, sendDate, sendOption) => {
    // event.preventDefault();
    if (
      JSON.stringify(recipients) !== "[]"
    ) {

      try {
        const formData = new FormData();
        formData.append("sendOption", sendOption);
        formData.append("status", isCorrection ? "correction" : "draft");
        formData.append("clientId", client.id);
        formData.append("sendState", sendState);
        formData.append("sendDate", sendDate);
        formData.append("type", invoiceValues.type);
        formData.append("blob", sendState === "scheduled" ? JSON.stringify({
          userId: auth.userId,
          settingsId: settingsAtom._id,
          clientId: client.id,
          invoiceId: invoiceId,
          subject: values.subject,
          message: draftToHtml(convertToRaw(editorStateMessage.getCurrentContent())),
          email: JSON.stringify(recipients.filter(item => item.source === "userinput").map(item => item.email)), //only add the emails that are added manually
          cc: JSON.stringify(recipientsCc.filter(item => item.source === "userinput").map(item => item.email)), //only add the emails that are added manually
          bcc: JSON.stringify(recipientsBcc.filter(item => item.source === "userinput").map(item => item.email)), //only add the emails that are added manually
          type: invoiceValues.type,
          isCorrection: JSON.stringify(isCorrection),
          sendOption: sendOption // dagowert or manual
        }) : JSON.stringify({ blob: "" }));


        let patchUrl;
        let fetchUrl;
        switch (invoiceValues.type) {
          case "invoice":
            patchUrl = `${process.env.REACT_APP_BACKEND_URL_API}/invoices/${openInvoiceDialogAtom.invoiceId}`;
            fetchUrl = `${process.env.REACT_APP_BACKEND_URL_API}/invoices/user/${userId}`;
            break;
          case "cancellation":
            patchUrl = `${process.env.REACT_APP_BACKEND_URL_API}/invoices/${openInvoiceDialogAtom.invoiceId}`;
            fetchUrl = `${process.env.REACT_APP_BACKEND_URL_API}/invoices/user/${userId}`;
            break;
          case "invoicerecurring":
            patchUrl = `${process.env.REACT_APP_BACKEND_URL_API}/invoicesrecurrings/${openInvoiceDialogAtom.invoiceId}`;
            fetchUrl = `${process.env.REACT_APP_BACKEND_URL_API}/invoicesrecurrings/user/${userId}`;
            break;
          default:
            throw new Error("Unsupported fetch type");
        }


        // UPDATE EXISTING INVOICE
        await sendRequest(
          patchUrl,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        )

        // FETCH updated Invoices List
        try {
          const resInvoices = await sendRequest(
            fetchUrl,
            "GET",
            null,
            {
              Authorization: `Bearer ${auth.token}`
            }
          );
          setInvoicesAtom(resInvoices.invoices);
          setInvoicesFilteredAtom(resInvoices.invoices);
          // enqueueSnackbar(t("FIELDS.saved"), { variant: "success" });
        } catch (err) {
          // SERVER ERROR
          // enqueueSnackbar(t("ERRORS.save_server"), { variant: "error" });
        }
      } catch (err) {
        // SERVER ERROR
        console.log(err)
        // enqueueSnackbar(t("ERRORS.save_server"), { variant: "error" });
      }
    } else {
      // enqueueSnackbar(t("ERRORS.save_form"), { variant: "error" });
    }
  };

  const invoiceNrForDownload = (invoiceNumber) => {
    let nr = ""
    for (const [key, value] of Object.entries(invoiceNumber)) {
      nr += value
    }
    return nr;
  }

  useEffect(() => {
    if (settingsAtomInitial.invoicesEmailText === undefined) {
      setEditorStateMessage(EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(`
          <p>Sehr geehrte Damen und Herren!</p>
          <p>Anbei senden wir Ihnen die Rechnung für die erbrachte Leistung und freuen uns auf erfolgreiche weitere Zusammenarbeit.</p>
          <p>Mit freundlichen Grüßen</br>${userAtom.data.company}</p>
          <p>Bitte antworten Sie nicht direkt auf diese automatisch generierte Nachricht. Bei Fragen sind wir unter den auf der Rechnung angegebenen Kontaktdaten gerne für Sie da.</p>
          `)
        )))
    } else {
      setEditorStateMessage(EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(settingsAtomInitial.invoicesEmailText)
        )))
    }
  }, [settingsAtomInitial]);

  // Recipients
  // useEffect(() => {
  //   setRecipients(() => [
  //     client.email
  //   ]);
  // }, [client.email]);


  // Recipients coming from settings -> are not deletable
  useEffect(() => {
    setRecipients(() => [
      { email: client.email, source: "settings" }
    ]);
  }, [client.email]);


  const handleChangeRecipient = (event, type) => {
    setRecipient({
      ...recipient,
      state: event.target.value,
      isEmail: (/^\S+@\S+\.\S+$/).test(event.target.value) ? true : false
    })
  };

  // const addRecipient = () => {
  //   setRecipients((items) => [
  //     ...items,
  //     recipient.state
  //   ]);
  //   setRecipient({
  //     ...recipient,
  //     state: "",
  //     isEmail: false,
  //     error: false,
  //   });
  // };

  // Recipients coming from userinput -> are deletable
  const addRecipient = () => {
    setRecipients((items) => [
      ...items,
      { email: recipient.state, source: "userinput" }
    ]);
    setRecipient({
      ...recipient,
      state: "",
      isEmail: false,
      error: false,
    });
  };

  const handleDeleteRecipient = (index) => {
    setRecipients((current) =>
      current.filter((item, i) => {
        return i !== index;
      })
    );
  };

  // CC
  useEffect(() => {
    setRecipientsCc(() =>
      JSON.parse(settingsAtom.cc).map(email => ({ email, source: "settings" }))
    );
  }, [settingsAtom]);

  const handleChangeRecipientCc = (event, type) => {
    setRecipientCc({
      ...recipientCc,
      state: event.target.value,
      isEmail: (/^\S+@\S+\.\S+$/).test(event.target.value) ? true : false
    })
  };

  const addRecipientCc = () => {
    setRecipientsCc((items) => [
      ...items,
      { email: recipientCc.state, source: "userinput" }
    ]);
    setRecipientCc({
      ...recipientCc,
      state: "",
      isEmail: false,
      error: false,
    });
  };

  const handleDeleteRecipientCc = (index) => {
    setRecipientsCc((current) =>
      current.filter((item, i) => {
        return i !== index;
      })
    );
  };

  // BCC
  useEffect(() => {
    setRecipientsBcc(() =>
      JSON.parse(settingsAtom.bcc).map(email => ({ email, source: "settings" }))
    );
  }, [settingsAtom]);

  const handleChangeRecipientBcc = (event, type) => {
    setRecipientBcc({
      ...recipientBcc,
      state: event.target.value,
      isEmail: (/^\S+@\S+\.\S+$/).test(event.target.value) ? true : false
    })
  };

  const addRecipientBcc = () => {
    setRecipientsBcc((items) => [
      ...items,
      { email: recipientBcc.state, source: "userinput" }
    ]);
    setRecipientBcc({
      ...recipientBcc,
      state: "",
      isEmail: false,
      error: false,
    });
  };

  const handleDeleteRecipientBcc = (index) => {
    setRecipientsBcc((current) =>
      current.filter((item, i) => {
        return i !== index;
      })
    );
  };

  const [pdfString, setPdfString] = useState('');
  const [downloadLink, setDownloadLink] = useState(null);

  useEffect(() => {
    if (openPdfPreview === true && invoiceId) {
      const fetchInvoicePDF = async () => {
        try {
          const formData = new FormData();
          formData.append("userId", auth.userId);
          formData.append("invoiceId", invoiceId);
          formData.append("clientId", client.id);
          formData.append("type", type);
          formData.append("isCorrection", (status === "correction" || isCorrection) ? "true" : "false");

          const response = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL_API}/invoices/previewinvoice`,
            "POST",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );

          const base64String = response.pdfBase64;
          setPdfString(base64String);

          // Convert base64 to Blob and create download link
          const byteCharacters = atob(base64String);
          const byteNumbers = new Array(byteCharacters.length).fill().map((_, i) => byteCharacters.charCodeAt(i));
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: 'application/pdf' });

          const blobUrl = URL.createObjectURL(blob);
          setDownloadLink(blobUrl);
        } catch (error) {
          console.error('Error fetching the PDF:', error);
        }
      };

      fetchInvoicePDF();
    }
  }, [openPdfPreview, invoiceId]);


  const [sendOption, setSendOption] = useState("dagowert"); // Default value

  const handleChangeSendOption = (event) => {
    setSendOption(event.target.value);
    setSendOptionManualSent(false)
  };

  const [sendOptionManualSent, setSendOptionManualSent] = useState(false); // Default value

  const handleManualSendSaveAndClose = async () => {
    try {
      const formData = new FormData();
      formData.append("userId", auth.userId);
      formData.append("settingsId", settingsAtom._id);
      formData.append("clientId", client.id);
      formData.append("invoiceId", invoiceId);
      formData.append("subject", values.subject);
      formData.append("filename", `Rechnung_${userAtom.data.company}_${invoiceNrForDownload(values.invoiceNr)}_${client.company}.pdf`)
      formData.append("email", JSON.stringify(recipients.map(item => item.email)));
      formData.append("cc", JSON.stringify(recipientsCc.map(item => item.email)));
      formData.append("bcc", JSON.stringify(recipientsBcc.map(item => item.email)));
      formData.append("isCorrection", isCorrection);

      formData.append("sendState", "sent"); // Only sending 'sendState'
      formData.append("sendOption", sendOption);
      formData.append("status", isCorrection ? "correction" : "draft");
      formData.append("type", invoiceValues.type);
      formData.append("blob", sendState === "scheduled" ? JSON.stringify({
        userId: auth.userId,
        settingsId: settingsAtom._id,
        clientId: client.id,
        invoiceId: invoiceId,
        subject: values.subject,
        message: draftToHtml(convertToRaw(editorStateMessage.getCurrentContent())),
        email: JSON.stringify(recipients.filter(item => item.source === "userinput").map(item => item.email)), //only add the emails that are added manually
        cc: JSON.stringify(recipientsCc.filter(item => item.source === "userinput").map(item => item.email)), //only add the emails that are added manually
        bcc: JSON.stringify(recipientsBcc.filter(item => item.source === "userinput").map(item => item.email)), //only add the emails that are added manually
        type: invoiceValues.type,
        isCorrection: JSON.stringify(isCorrection),
        sendOption: sendOption // dagowert or manual
      }) : JSON.stringify({ blob: "" }));

      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL_API}/invoices/manualsend/${invoiceId}`,
        "PATCH", // Assuming it's a PATCH request
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      // enqueueSnackbar(t("FIELDS.saved"), { variant: "success" });
      // Handle success (e.g., close the dialog, show a notification, etc.)
    } catch (error) {
      console.error("Error saving invoice:", error);
      // Handle error (e.g., show an error message)
    }
  };
  return (
    <div>

      {directDownload === false &&
        <div>
<Dialog
  fullScreen
  onClose={() => {
    setOpen(false);
    setEmailSent(false);
    setZoom(!zoom);
    setEditorState(null);
  }}
  className="invoice-preview-dialog"
  aria-labelledby="customized-dialog-title"
  open={openPdfPreview}
>
  <DialogTitle
    id="customized-dialog-title"
    style={{
      background: "rgb(26, 123, 201)",
      color: "#ffffff",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "8px 16px", // Adjust padding if needed
    }}
  >
    {/* Back Icon */}
    {!emailSent &&
    <IconButton
      edge="start"
      color="inherit"
      style={{
        margin: "0 16px 0 0", // Adds space to the right of the back button
        display: "flex",
        width: "35px",
        height: "35px",
        border: "1px solid white",
        zIndex: 1,
        padding: 5
      }}
      onClick={() => {
        setOpen(false);
        setEmailSent(false);
        setZoom(!zoom);
        setEditorState(null);
      }}
      aria-label={`${t("BUTTONS.AL_close")}`}
    >
      <ArrowBackIcon style={{ margin: 0 }}/>
    </IconButton>
    }

{/* INVISIBLE BUTTON TO KEEP POSITION */}
{emailSent &&
    <IconButton
      edge="start"
      color="inherit"
      style={{
        opacity: 0,
        cursor: "initial",
        margin: "0 16px 0 0", // Adds space to the right of the back button
        display: "flex",
        width: "35px",
        height: "35px",
        border: "1px solid white",
        zIndex: 1,
        padding: 5
      }}
      aria-label={`${t("BUTTONS.AL_close")}`}
    >
      <ArrowBackIcon style={{ margin: 0 }}/>
    </IconButton>
    }

    {/* Title */}
    <div style={{ fontSize: 14, padding: "0 16px", flexGrow: 1, textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
    {sendMode ? <EmailIcon style={{ fontSize: 25 }}/> : <PictureAsPdf style={{ fontSize: 25 }}/>}
      {!sendMode
        ? "PDF Vorschau"
        : !emailSent && !emailScheduled
        ? "Versand"
        : emailSent
        ? "Wurde gesendet"
        : "Versand wurde geplant"}
    </div>

    {/* Close Icon */}
    {sendMode &&
    <IconButton
      onClick={() => {
        setOpenInvoiceDialogAtom({
          ...openInvoiceDialogAtom,
          open: false,
          invoiceId: "",
          duplicate: false,
        });
      }}
      style={{
        color: "#ffffff",
        marginLeft: "16px", // Adds space to the left of the close button
      }}
    >
      <CloseIcon />
    </IconButton>
    }

{/* INVISIBLE BUTTON TO KEEP POSITION */}
{!sendMode &&
    <IconButton
      style={{
        opacity: 0,
        cursor: "initial",
        color: "#ffffff",
        marginLeft: "16px", // Adds space to the left of the close button
      }}
    >
      <CloseIcon />
    </IconButton>
    }
  </DialogTitle>


            <DialogContent dividers className="bg--1">
              {/* {isLoading && sendMode &&
                <CircularProgress style={{ color: theme.palette.primary.main }} />
              } */}





              {(sendMode && !emailSent && !emailScheduled) &&

                <Container maxWidth="lg" style={{ paddingTop: "25px", paddingBottom: 8 }}>
                  <Grid container direction="column" item spacing={1}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      // padding="25px 0px"
                      className="h-gap--20"
                    >
                      <Grid
                        container
                        item
                        direction="row"
                        alignContent="flex-start"
                        alignItems="flex-start"
                        className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                        spacing={1}
                      >
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel id="email-selection-label">Versandoption</InputLabel>
                          <Select
                            labelId="email-selection-label"
                            id="email-selection"
                            value={sendOption}
                            onChange={handleChangeSendOption}
                            label="Versandoption"
                          >
                            <MenuItem value="dagowert">Per Email mit DagoWert</MenuItem>
                            <MenuItem value="manual">Per Post oder Email</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Box>
                  </Grid>
                </Container>
              }


              {(sendMode && !emailSent && !emailScheduled && sendOption === "manual") &&
                <Container maxWidth="lg" style={{ paddingTop: "25px", paddingBottom: "100px" }}>
                  <Grid container direction="column" item spacing={1}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      // padding="25px 0px"
                      className="h-gap--20"
                      style={{ marginBottom: 25 }}
                    >
                      <Grid
                        container
                        item
                        direction="row"
                        alignContent="flex-start"
                        alignItems="flex-start"
                        className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                        spacing={1}
                      >

                        {/* Additional Sentence */}
                        {!recurring &&
                          <Typography variant="body2">
                            Mit der gewählten Versandoption wird das Dokument <strong>nicht</strong> über DagoWert versendet.
                          </Typography>
                        }

                        {recurring &&
                          <Typography variant="body2">
                            Mit der gewählten Versandoption werden Einzelrechnungen dieser wiederkehrenden Rechnung <strong>nicht</strong> automatisch über DagoWert versendet. Entsprechend dem gewählten Intervall werden Einzelrechnungen automatisiert als Entwurf erstellt, die per Post oder anderem Email-Programm versendet werden müssen.
                          </Typography>
                        }

                        {!recurring &&
                          <ol style={{ paddingLeft: 15 }}>
                            <li style={{ fontWeight: 700 }}>Dokument als PDF-Datei herunterladen</li>
                            <Typography variant="body2" style={{ color: "gray" }}>
                              Dokument herunterladen und per Post oder anderem Email Programm senden.
                            </Typography>


                            <div style={{ marginBottom: 20, width: zoom ? "100%" : "200px", display: "flex", flexDirection: "row", alignItems: "flex-start" }}>

                              <div>
                                {isLoading && <CircularProgress />}
                                {!isLoading && pdfString && (
                                  <InvoicePdfPreview pdfString={pdfString} />
                                )}

                                {downloadLink && (
                                  <Button
                                    className="email-pdf-preview"
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    href={downloadLink}
                                    download={`Rechnung_${userAtom.data.company}_${invoiceNrForDownload(values.invoiceNr)}_${client.company}.pdf`}
                                    startIcon={<SaveAltIcon />}
                                  >
                                    PDF
                                  </Button>
                                )}

                                {error && <p>PDF konnte nicht geladen werden.</p>}
                              </div>
                              <IconButton onClick={() => setZoom(!zoom)} aria-label="zoom in" style={{ marginTop: "6px", marginLeft: "-18px", background: theme.palette.primary.main, color: "#ffffff", padding: "4px" }}>
                                {!zoom ? <ZoomInIcon /> : <ZoomOutIcon />}
                              </IconButton>
                            </div>


                            <li style={{ fontWeight: 700 }}>Als "gesendet" speichern: Erfassung in der Versandhistorie</li>
                            <Typography variant="body2" style={{ color: "gray" }}>
                              Bei Speicherung als "gesendet", wird die aktuelle Version in der Versandhistorie mit Versandzeitpunkt erfasst. Dies unterstützt die Dokumentation und Nachvollziehbarkeit.
                            </Typography>

                            <FormControlLabel style={{ marginBottom: 5 }}
                              control={
                                <Switch
                                  disabled={isLoading}
                                  checked={sendOptionManualSent}
                                  onChange={() => setSendOptionManualSent(!sendOptionManualSent)}
                                  name="notSendViaDagowert"
                                  color="primary"
                                />
                              }
                              label={
                                <div>
                                  <span style={{ marginRight: 5, fontSize: 14 }}>Gesendet</span>
                                  {/* <span style={{ fontSize: '11px', color: 'gray' }}>  
        Rechnungsversand auf dem Postweg oder als Email ohne DagoWert
      </span> */}
                                </div>
                              }
                            />


                            {sendOptionManualSent &&
                              <MuiPickersUtilsProvider locale={de} utils={DateFnsUtils}>
                                <Grid item xs={12} md={3} className="datepicker" >
                                  <KeyboardDatePicker
                                    disabled={isLoading}
                                    style={{ border: values.sendState === "sent" ? "2px solid rgb(26, 123, 201)" : "inherit" }}
                                    fullWidth
                                    inputVariant="filled"
                                    id="sendDate"
                                    format="dd.MM.yyyy"
                                    label="Versanddatum"
                                    value={values.sendDate}
                                    onChange={(date) =>
                                      setValues({
                                        ...values,
                                        sendDate: date.toString(),
                                      })
                                    }
                                    KeyboardButtonProps={{
                                      'aria-label': 'change date',
                                    }}
                                    okLabel="OK"
                                    cancelLabel="Abbrechen"
                                  />

                                  <KeyboardTimePicker
                                    style={{ marginTop: 5 }}
                                    fullWidth
                                    inputVariant="filled"
                                    margin="normal"
                                    id="time-picker"
                                    label="Versandzeit"
                                    value={values.sendDate}
                                    onChange={(date) =>
                                      setValues({
                                        ...values,
                                        sendDate: date,
                                      })
                                    }
                                    KeyboardButtonProps={{
                                      'aria-label': 'change time',
                                    }}
                                    okLabel="OK"
                                    cancelLabel="Abbrechen"
                                  />
                                </Grid>
                              </MuiPickersUtilsProvider>
                            }


                          </ol>
                        }

                        {recurring &&
                          <ol style={{ paddingLeft: 15 }}>
                            <li style={{ fontWeight: 700 }}>Vorschau</li>

                            <div style={{ marginBottom: 20, width: zoom ? "100%" : "200px", display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
                              <div>
                                {isLoading && <CircularProgress />}
                                {!isLoading && pdfString && (
                                  <InvoicePdfPreview pdfString={pdfString} />
                                )}
                                {error && <p>PDF konnte nicht geladen werden.</p>}
                              </div>
                              <IconButton onClick={() => setZoom(!zoom)} aria-label="zoom in" style={{ marginTop: "6px", marginLeft: "-18px", background: theme.palette.primary.main, color: "#ffffff", padding: "4px" }}>
                                {!zoom ? <ZoomInIcon /> : <ZoomOutIcon />}
                              </IconButton>
                            </div>

                            <li style={{ fontWeight: 700 }}>Erstellung als Entwurf (wiederkehrend)</li>
                            <Typography variant="body2" style={{ color: "gray" }}>
                              Definieren Sie den ersten Erstellungszeitpunkt um diese wiederkehrende Rechnung zu aktivieren. Abhängig vom Intervall werden Einzelrechnungen als Entwurf erstellt.
                            </Typography>

                            {sendOptionManualSent &&
                              <MuiPickersUtilsProvider locale={de} utils={DateFnsUtils}>
                                <Grid item xs={12} md={3} className="datepicker" >
                                  <KeyboardDatePicker
                                    disabled={isLoading}
                                    style={{ border: values.sendState === "sent" ? "2px solid rgb(26, 123, 201)" : "inherit" }}
                                    fullWidth
                                    inputVariant="filled"
                                    id="sendDate"
                                    format="dd.MM.yyyy"
                                    label="Versanddatum"
                                    value={values.sendDate}
                                    onChange={(date) =>
                                      setValues({
                                        ...values,
                                        sendDate: date.toString(),
                                      })
                                    }
                                    KeyboardButtonProps={{
                                      'aria-label': 'change date',
                                    }}
                                    okLabel="OK"
                                    cancelLabel="Abbrechen"
                                  />

                                  <KeyboardTimePicker
                                    style={{ marginTop: 5 }}
                                    fullWidth
                                    inputVariant="filled"
                                    margin="normal"
                                    id="time-picker"
                                    label="Versandzeit"
                                    value={values.sendDate}
                                    onChange={(date) =>
                                      setValues({
                                        ...values,
                                        sendDate: date,
                                      })
                                    }
                                    KeyboardButtonProps={{
                                      'aria-label': 'change time',
                                    }}
                                    okLabel="OK"
                                    cancelLabel="Abbrechen"
                                  />
                                </Grid>
                              </MuiPickersUtilsProvider>
                            }


                          </ol>
                        }
                      </Grid>
                    </Box>
                  </Grid>
                </Container>

              }

              {(sendMode && !emailSent && !emailScheduled && sendOption === "dagowert") &&
                <Container maxWidth="lg" style={{ paddingTop: "25px", paddingBottom: "100px" }}>
                  <Grid container direction="column" item spacing={1}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      // padding="25px 0px"
                      className="h-gap--20"
                      style={{ marginBottom: 25 }}
                    >
                      <Grid
                        container
                        item
                        direction="row"
                        alignContent="flex-start"
                        alignItems="flex-start"
                        className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                        spacing={1}
                      >
                        <Grid item container xs={12} alignItems="center">
                          <Typography><span style={{ background: "rgb(240, 243, 255)", color: theme.palette.primary.main, borderRadius: 6, padding: 5, fontWeight: 500, marginRight: "10px", fontSize: 11 }}>Von:</span><span style={{ fontWeight: "500" }}>{settingsAtom.invoicesEmailServer === "own" ? `${settingsAtom.invoicesEmailFrom} <${settingsAtom.invoicesEmailAddress}>` : "DagoWert <accounting@dagowert.com>"}</span></Typography>
                        </Grid>

                        <Grid item container xs={12} alignItems="center">
                          {/* Optional header or label */}
                          <Typography style={{ background: "rgb(240, 243, 255)", color: theme.palette.primary.main, borderRadius: 6, padding: 5, fontWeight: 500, marginRight: "10px", fontSize: 11, marginTop: 3 }}>An:</Typography>

                          {/* Display Chips */}
                          {recipients.map((item, i) => (
                            <Chip
                              key={i}
                              label={item.email}
                              variant="outlined"
                              style={{
                                display: "flex",
                                fontSize: "14px",
                                marginRight: "10px",
                                marginTop: "5px",
                                marginBottom: "5px",
                              }}
                              onDelete={
                                item.source === "userinput" ? () => handleDeleteRecipient(i) : undefined
                              }
                            />
                          ))}
                        </Grid>


                        {JSON.stringify(recipientsCc) !== "[]" &&
                          <Grid item container xs={12} alignItems="center">
                            <Typography style={{ background: "rgb(240, 243, 255)", color: theme.palette.primary.main, borderRadius: 6, padding: 5, fontWeight: 500, marginRight: "10px", fontSize: 11 }}>CC:</Typography>

                            {recipientsCc.map((item, i) => (
                              <Chip
                                key={i}
                                label={item.email}
                                variant="outlined"
                                style={{ display: "flex", fontSize: "14px", marginRight: "10px", marginTop: "5px", marginBottom: "5px" }}
                                onDelete={item.source === "userinput" ? () => handleDeleteRecipientCc(i) : undefined}
                              />
                            ))}
                          </Grid>
                        }

                        {JSON.stringify(recipientsBcc) !== "[]" &&
                          <Grid item container xs={12} alignItems="center">
                            <Typography style={{ background: "rgb(240, 243, 255)", color: theme.palette.primary.main, borderRadius: 6, padding: 5, fontWeight: 500, marginRight: "10px", fontSize: 11 }}>BCC:</Typography>

                            {recipientsBcc.map((item, i) => (
                              <Chip
                                key={i}
                                label={item.email}
                                variant="outlined"
                                style={{ display: "flex", fontSize: "14px", marginRight: "10px", marginTop: "5px", marginBottom: "5px" }}
                                onDelete={item.source === "userinput" ? () => handleDeleteRecipientBcc(i) : undefined}
                              />
                            ))}
                          </Grid>
                        }


                        <Grid item container lg={6} alignItems="center" style={{ marginTop: 10 }}>
                          <div className="add-recipients" style={{ marginTop: -11 }} onClick={() => setEditRecipients(!editRecipients)}  >
                            <IconButton
                              style={{
                                backgroundColor: !editRecipients ? "rgb(240, 243, 255)" : theme.palette.primary.main,
                                color: !editRecipients ? theme.palette.primary.main : "#ffffff",
                                fontSize: 15, // Control the font size of the icon
                                padding: 8, // Adjust padding for smaller button size
                                marginRight: 10
                              }}
                            >
                              <EditIcon style={{ fontSize: 15 }} />
                            </IconButton>
                            {!editRecipients && <span style={{ fontSize: 11, color: theme.palette.primary.main }}>Empfänger ändern</span>}
                          </div>

                          {editRecipients &&
                            <>
                              <div style={{ display: "flex" }}>
                                <Button size="small" onClick={() => { setEmailEnterState(emailEnterState !== "recipient" ? "recipient" : "recipient") }} style={{ marginRight: "5px", marginBottom: "10px" }} variant={emailEnterState === "recipient" ? "contained" : "outlined"} color="primary">AN</Button>
                                <Button size="small" onClick={() => { setEmailEnterState(emailEnterState !== "cc" ? "cc" : "recipient") }} disabled={JSON.stringify(recipients) === "[]" ? true : false} style={{ marginRight: "5px", marginBottom: "10px" }} variant={emailEnterState === "cc" ? "contained" : "outlined"} color="primary">CC</Button>
                                <Button size="small" onClick={() => { setEmailEnterState(emailEnterState !== "bcc" ? "bcc" : "recipient") }} disabled={JSON.stringify(recipients) === "[]" ? true : false} style={{ marginBottom: "10px", marginRight: "35px" }} variant={emailEnterState === "bcc" ? "contained" : "outlined"} color="primary">BCC</Button>
                              </div>

                              {emailEnterState === "recipient" &&
                                <TextField
                                  id="email"
                                  type="email"
                                  fullWidth
                                  variant="outlined"
                                  placeholder="Empfänger hinzufügen ... "
                                  value={recipient.state}
                                  className="input-with-chips"
                                  error={recipient.error}
                                  helperText={
                                    recipient.error ? "Bitte Empfänger eingeben" : ""
                                  }
                                  onChange={(e) =>
                                    handleChangeRecipient(e, "input")
                                  }
                                  onKeyDown={(e) => {
                                    // On Spacebar or Enter
                                    if ((e.keyCode === 32 || e.key === "Enter") && recipient.isEmail) {
                                      addRecipient();
                                    }
                                  }}
                                  InputProps={{
                                    startAdornment:
                                      <InputAdornment position="start">
                                        <div style={{ marginTop: 3, color: "rgba(0, 0, 0, 0.5)" }}>An:</div>
                                      </InputAdornment>
                                    ,
                                    endAdornment: <InputAdornment position="end" style={{
                                      display: "flex",
                                      alignItems: "center", // Vertically centers the content
                                    }}>
                                      <Button disabled={!recipient.isEmail} size="small" variant="contained" color="secondary" onClick={() => addRecipient()}>hinzufügen</Button>
                                    </InputAdornment>,
                                    style: { fontWeight: "500" }
                                  }}
                                />
                              }

                              {emailEnterState === "cc" &&
                                <TextField
                                  id="cc"
                                  type="email"
                                  fullWidth
                                  variant="outlined"
                                  placeholder="Empfänger in CC hinzufügen ... "
                                  value={recipientCc.state}
                                  className="input-with-chips"
                                  error={recipientCc.error}
                                  helperText={
                                    recipientCc.error ? "Bitte Empfänger eingeben" : ""
                                  }
                                  onChange={(e) =>
                                    handleChangeRecipientCc(e, "input")
                                  }
                                  onKeyDown={(e) => {
                                    // On Spacebar or Enter
                                    if ((e.keyCode === 32 || e.key === "Enter") && recipientCc.isEmail) {
                                      addRecipientCc();
                                    }
                                  }}
                                  InputProps={{
                                    startAdornment:
                                      <InputAdornment position="start">
                                        <div style={{ marginTop: 3, color: "rgba(0, 0, 0, 0.5)" }}>CC:</div>
                                      </InputAdornment>
                                    ,
                                    endAdornment: <InputAdornment position="end" style={{
                                      display: "flex",
                                      alignItems: "center", // Vertically centers the content
                                    }}>
                                      <Button disabled={!recipientCc.isEmail} size="small" variant="contained" color="secondary" onClick={() => addRecipientCc()}>hinzufügen</Button>
                                    </InputAdornment>,
                                    style: { fontWeight: "500" }
                                  }}
                                />
                              }

                              {emailEnterState === "bcc" &&
                                <TextField
                                  id="bcc"
                                  type="email"
                                  fullWidth
                                  variant="outlined"
                                  placeholder="Empfänger in BCC hinzufügen ... "
                                  value={recipientBcc.state}
                                  className="input-with-chips"
                                  error={recipientBcc.error}
                                  helperText={
                                    recipientBcc.error ? "Bitte Empfänger eingeben" : ""
                                  }
                                  onChange={(e) =>
                                    handleChangeRecipientBcc(e, "input")
                                  }
                                  onKeyDown={(e) => {
                                    // On Spacebar or Enter
                                    if ((e.keyCode === 32 || e.key === "Enter") && recipientBcc.isEmail) {
                                      addRecipientBcc();
                                    }
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <div style={{ marginTop: 3, color: "rgba(0, 0, 0, 0.5)" }}>BCC:</div>
                                      </InputAdornment>
                                    ),
                                    endAdornment: (
                                      <InputAdornment
                                        position="end"
                                        style={{
                                          display: "flex",
                                          alignItems: "center", // Vertically centers the content
                                        }}
                                      >
                                        <Button
                                          disabled={!recipientBcc.isEmail}
                                          size="small"
                                          variant="contained"
                                          color="secondary"
                                          onClick={() => addRecipientBcc()}
                                        >
                                          hinzufügen
                                        </Button>
                                      </InputAdornment>
                                    ),
                                    style: { fontWeight: "500" }
                                  }}
                                />
                              }
                            </>
                          }

                        </Grid>




                      </Grid>
                    </Box>

                    <Box
                      display="flex"
                      flexDirection="column"
                      // padding="25px 0px"
                      className="h-gap--20"
                    >
                      <Grid
                        container
                        item
                        direction="row"
                        alignContent="flex-start"
                        alignItems="flex-start"
                        className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                        spacing={1}
                      >
                        <Grid container item>
                          <TextField
                            id="subject"
                            fullWidth
                            multiline
                            label={`Betreff`}
                            variant="outlined"
                            onChange={(e) => { setValues({ ...values, subject: e.target.value }) }}
                            defaultValue={values.subject}
                          // InputProps={{
                          //   startAdornment: <Typography style={{ color: "rgba(0, 0, 0, 0.5)", marginRight: "10px" }}>Betreff</Typography>,
                          //   style: { fontWeight: "500" }
                          // }}
                          />
                        </Grid>

                        <Grid container item>
                          <Typography variant="body1" component="p" style={{ color: "rgba(0, 0, 0, 0.5)", paddingTop: "10px", fontSize: 11, marginLeft: 14, color: "rgba(0, 0, 0, 0.54)", paddingBottom: 5 }}>Nachricht:</Typography>

                          <Editor
                            toolbar={{
                              options: ['inline', 'fontSize', 'list', 'textAlign', 'link'],
                              inline: { inDropdown: true, options: ['bold', 'italic', 'underline', 'strikethrough'] },
                              textAlign: { inDropdown: true },
                              link: { inDropdown: true },
                              list: { inDropdown: true },
                            }}
                            // toolbarOnFocus
                            editorState={editorStateMessage}
                            onEditorStateChange={(editorState) => { setEditorStateMessage(editorState) }}
                            localization={{
                              locale: 'de',
                            }}
                            placeholder="E-Mail Text schreiben ..."
                            wrapperClassName="editor-wrapper"
                            editorClassName="editor"
                          />
                        </Grid>
                      </Grid>



                      <Grid item>
                        <Grid container item direction="row" alignItems="center" style={{ marginTop: "20px" }}>
                          <AttachmentIcon style={{ marginRight: "10px" }} />
                          <Typography component="div" variant="body1">Anhang: <span style={{ fontWeight: "500" }}>PDF</span></Typography>
                        </Grid>

                        <div style={{ width: zoom ? "100%" : "200px", display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
                          <div>
                            {isLoading && <CircularProgress />}
                            {!isLoading && pdfString && (
                              <InvoicePdfPreview pdfString={pdfString} />
                            )}

                            {downloadLink && (
                              <Button
                                className="email-pdf-preview"
                                variant="contained"
                                color="primary"
                                size="small"
                                href={downloadLink}
                                download={`Rechnung_${userAtom.data.company}_${invoiceNrForDownload(values.invoiceNr)}_${client.company}.pdf`}
                                startIcon={<SaveAltIcon />}
                              >
                                PDF
                              </Button>
                            )}

                            {error && <p>PDF konnte nicht geladen werden.</p>}
                          </div>
                          <IconButton onClick={() => setZoom(!zoom)} aria-label="zoom in" style={{ marginTop: "6px", marginLeft: "-18px", background: theme.palette.primary.main, color: "#ffffff", padding: "4px" }}>
                            {!zoom ? <ZoomInIcon /> : <ZoomOutIcon />}
                          </IconButton>
                        </div>

                        {/* <a href={instance.url} download={`Rechnung_${invoiceNrForDownload(values.invoiceNr)}_${client.company}`}>
                <Button startIcon={<SaveAltIcon />}variant="contained" autoFocus color="primary">
                  PDF
                </Button>
              </a> */}
                      </Grid>
                    </Box>
                  </Grid>
                </Container>
              }

              {(!sendMode) &&
                <div>
                  {isLoading &&
                    <div style={{ display: "flex", justifyContent: "center", height: "100vh", alignItems: "center" }}>
                      <CircularProgress style={{ marginTop: "-190px" }} />
                    </div>
                  }
                  {!isLoading && pdfString && (
                    <InvoicePdfPreview pdfString={pdfString} />
                  )}
                  {error && <p>PDF konnte nicht geladen werden.</p>}
                </div>

              }
              {(sendMode && emailSent && !isLoading) &&
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                  <AnimationSuccess />
                  <Typography variant="body1" component="p" style={{ maxWidth: 1280, padding: 25, margin: "0 auto", marginTop: 20, background: "#ffffff", borderRadius: 10, boxShadow: "0 24px 64px rgba(84, 94, 165, 0.1019607843)" }}>Ihre Rechnung wurde erfolgreich per Email versendet.</Typography>
                </div>
              }

              {(sendMode && emailScheduled && !isLoading) &&
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                  <AnimationSuccess />
                  <Typography variant="body1" component="p" style={{ maxWidth: 1280, padding: 25, margin: "0 auto", marginTop: 20, background: "#ffffff", borderRadius: 10, boxShadow: "0 24px 64px rgba(84, 94, 165, 0.1019607843)" }}>Ihr Rechnungs-Versand per Email wurde erfolgreich geplant.</Typography>
                </div>
              }
            </DialogContent>

            <DialogActions style={{ justifyContent: "center" }}>
              {!sendMode && downloadLink &&
                (
                  <Button
                    variant="contained"
                    color="primary"
                    href={downloadLink}
                    download={`Rechnung_${userAtom.data.company}_${invoiceNrForDownload(values.invoiceNr)}_${client.company}.pdf`}
                    startIcon={<SaveAltIcon />}
                  >
                    Download PDF
                  </Button>
                )
              }

              {(sendMode && !emailSent) &&
                <>
                  <InvoicesScheduleSend
                    values={values}
                    setValues={setValues}
                    sendState={values.sendState}
                    sendDate={values.sendDate}
                    send={send}
                    isLoading={isLoading}
                    sendOption={sendOption}
                    setEmailSent={setEmailSent}
                    setOpenInvoiceDialogAtom={setOpenInvoiceDialogAtom}
                    openInvoiceDialogAtom={openInvoiceDialogAtom}
                    sendOptionManualSent={sendOptionManualSent}
                    recurring={recurring}
                    setSendMode={setSendMode}
                    sendMode={sendMode}
                    emailScheduled={emailScheduled}
                  />
                </>
              }

              {(sendMode && (emailSent || emailScheduled)) &&
                <Button variant="contained" autoFocus
                  onClick={() => {
                    setOpen(false);
                    setEmailSent(false);
                    setOpenInvoiceDialogAtom({
                      ...openInvoiceDialogAtom,
                      open: false,
                      invoiceId: ""
                    })
                  }}
                  color="primary">
                  Schließen
                </Button>
              }
            </DialogActions>
          </Dialog>
        </div>
      }
    </div>
  );
}

export default InvoiceLivePreview;

import React, { useState, useEffect, useContext } from "react";

// IMPORT hooks & context
import { useTranslation } from "react-i18next";
import { countryToFlag } from "../../../shared/functions/countryToFlag";
import { COUNTRIES } from "../../../shared/util/countries_de";
import { useForm } from "../../../shared/hooks/form-hook";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/auth-context";
import { useAuth } from "../../../shared/hooks/auth-hook";

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import userAtomState from "../../../_atoms/userAtom";

import { useSnackbar } from 'notistack';

// IMPORT functions
import { VALIDATE } from '../../../_functions/VALIDATE';
import { SET_VALUES } from '../../../_functions/SET_VALUES';
import { SET_USER_ATOM } from '../../../_functions/SET_USER_ATOM';
import { VAT } from '../../../_functions/VAT';

// IMPORT components
import ButtonLoading from "../../../shared/components/UIElements/ButtonLoading";
import ImageUpload from "../../../shared/components/FormElements/ImageUpload";
import DropzoneUploaderSingle from "../../../shared/components/DropzoneUploader/DropzoneUploaderSingle";

// IMPORT components MATERIAL UI
import {
  Typography,
  Button,
  Box,
  TextField,
  Container,
  Grid,
  InputAdornment,
  makeStyles,
  BottomNavigation,
  MenuItem,
  Tooltip,
  IconButton
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Fade from '@material-ui/core/Fade';


// IMPORT icons MATERIAL UI
import SaveIcon from '@material-ui/icons/Save';
import AccountCircle from "@material-ui/icons/AccountCircle";
import InfoIcon from '@material-ui/icons/Info';

import ButtonLoadingBottom from "../../../shared/components/UIElements/ButtonLoadingBottom";


const useStyles = makeStyles((theme) => ({
  // necessary for content to be below app bar
  toolbar: {
    height: '0px',
    '@media (max-width: 959px)': {
      height: '62px',
    },
    '@media (max-width: 599px)': {
      height: '45px',
    },
    '@media (max-width: 455px)': {
      height: '55px',
    },
  },
}));

const UserProfile = () => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const classes = useStyles();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const { userId } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  // GLOBAL STATE (RECOIL)
  const [userAtom, setUserAtom] = useRecoilState(userAtomState);
  

  // LOCAL 
  const [submitted, setSubmitted] = useState(null);
  const [values, setValues] = useState({
    firstName: {
      state: userAtom.data.firstName,
      error: false,
      errorMsg: t("FIELDS.firstName.error"),
    },
    lastName: {
      state: userAtom.data.lastName,
      error: false,
      errorMsg: t("FIELDS.lastName.error"),
    },
    company: {
      state: userAtom.data.company,
      error: false,
      errorMsg: t("FIELDS.company.error"),
    },
    street: {
      state: userAtom.data.street,
      error: false,
      errorMsg: t("FIELDS.street.error"),
    },
    streetNr: {
      state: userAtom.data.streetNr,
      error: false,
      errorMsg: t("FIELDS.streetNr.error"),
    },
    zip: {
      state: userAtom.data.zip,
      error: false,
      errorMsg: t("FIELDS.zip.error"),
    },
    city: {
      state: userAtom.data.city,
      error: false,
      errorMsg: t("FIELDS.city.error"),
    },
    country: {
      state: userAtom.data.country ? JSON.parse(userAtom.data.country) : "",
      error: false,
      errorMsg: t("FIELDS.country.error"),
    },
    precedingTitle: {
      // optional
      state: userAtom.data.precedingTitle,
    },
    followingTitle: {
      // optional
      state: userAtom.data.followingTitle,
    },
    bank: {
      // optional
      state: userAtom.data.bank,
    },
    bankAccount: {
      // optional
      state: userAtom.data.bankAccount,
    },
    bankAccountOwner: {
      // optional
      state: userAtom.data.bankAccountOwner,
    },
    bankZip: {
      // optional
      state: userAtom.data.bankZip,
    },
    iban: {
      // optional
      state: userAtom.data.iban,
    },
    bic: {
      // optional
      state: userAtom.data.bic,
    },
    phone: {
      // optional
      state: userAtom.data.phone,
    },
    mobile: {
      // optional
      state: userAtom.data.mobile,
    },
    companyWebsite: {
      // optional
      state: userAtom.data.companyWebsite,
    },
    companyEmail: {
      // optional
      state: userAtom.data.companyEmail,
    },
    vat: {
      // optional
      state: userAtom.data.vat,
      digits: 0, // e.g. Austria: 8, Germany: 9 -> see https://www.finanz.at/steuern/umsatzsteuer/uid-nummer/
      code: "", // e.g. ATU, DE, etc ...
      error: false,
      errorMsg:t("FIELDS.vat.error"),
    },
    fbnr: {
      // optional
      state: userAtom.data.fbnr,
    },
    image: {
      // optional
      state: userAtom.data.image,
    },
    logo: userAtom.data.logo,
    foundingYear: userAtom.data.foundingYear,
    businessYearStart: userAtom.data.businessYearStart
  });


  const [formState, inputHandler] = useForm(
    {
      image: {
        value: userAtom.data.image !== "/#" ? userAtom.data.image : null,
        isValid: false,
      },
    },
    false
  );

  /* 
    Functions
  */
  const userUpdateHandler = async (event) => {
    event.preventDefault();

    if (
      !values.company.error &&
      !values.country.error &&
      !values.street.error &&
      !values.streetNr.error &&
      !values.zip.error &&
      !values.city.error &&
      !values.firstName.error &&
      !values.lastName.error &&
      !values.vat.error
      ) {
      try {
        const formData = new FormData();
        formData.append("company", values.company.state);
        formData.append("phone", values.phone.state);
        formData.append("mobile", values.mobile.state);
        formData.append("companyWebsite", values.companyWebsite.state);
        formData.append("companyEmail", values.companyEmail.state);
        formData.append("vat", values.vat.state);
        formData.append("street", values.street.state);
        formData.append("streetNr", values.streetNr.state);
        formData.append("zip", values.zip.state);
        formData.append("city", values.city.state);
        formData.append("country", JSON.stringify(values.country.state));
        formData.append("precedingTitle", values.precedingTitle.state);
        formData.append("firstName", values.firstName.state);
        formData.append("lastName", values.lastName.state);
        formData.append("followingTitle", values.followingTitle.state);
        formData.append("bank", values.bank.state);
        formData.append("bankAccount", values.bankAccount.state);
        formData.append("bankAccountOwner", values.bankAccountOwner.state);
        formData.append("bankZip", values.bankZip.state);
        formData.append("iban", values.iban.state);
        formData.append("bic", values.bic.state);
        formData.append("fbnr", values.fbnr.state);
        formData.append("foundingYear", values.foundingYear);
        formData.append("businessYearStart", values.businessYearStart);
        formData.append("signupCompleted", true);
        formState.inputs.image.value === "/#" || formState.inputs.image.value === null
        ? formData.append("image", "/#")
        : formData.append("image", formState.inputs.image.value);
        formData.append("logo", values.logo);

        const res = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL_API}/users/${userId}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        console.log(res.user)
  
        SET_USER_ATOM(res.user, userAtom, setUserAtom);
        setSubmitted(true);
        enqueueSnackbar(t("FIELDS.saved"), { variant: "success" })
      } catch (err) {}
    } else {
      setSubmitted(false);
      enqueueSnackbar(t("FIELDS.error"), { variant: "error" })
    }
  };

  const handleChange = async (e) => {
    clearError();
    setSubmitted(null);
    SET_VALUES(e, values, setValues);
  };

  useEffect(() => {
    const vatData = VAT(values.country.state.code)

    if (values.country.state !== "") {
      // If statement prevents rendering when country is not set yet
      setValues({
        ...values,
        vat: {
          ...values.vat,
          code: vatData.vatCode,
          digits: vatData.vatDigits,
        }
      });
    }
  }, [values.country]);

  const validate = (field) => {
    VALIDATE([
      "company",
      "country",
      "street",
      "streetNr",
      "zip",
      "city",
      "firstName",
      "lastName",
      "vat"
    ],
      field, values, setValues);
  };


  return (
    <div>
      <div className={classes.toolbar} />
      <Fade in={true}>
        <Grid container className="subpage-header" maxWidth={false} item direction="row" alignItems="center" alignContent="center">
          <Grid item container alignItems="center" style={{ marginRight: "10px" }}>
            <AccountCircle color="primary" fontSize="large" style={{ background: "linear-gradient(to right top, rgb(38, 108, 189), rgb(36, 115, 192), rgb(33, 150, 243), #00bca7, #00bf72)", marginRight: "15px" }} className="icon--with-bg--1" />
            <Typography variant="body1" component="div" style={{ fontWeight: "500" }} className="headline">{t("PAGE_PROFILE.h1")}</Typography>
          </Grid>
        </Grid>
      </Fade>

      <Container maxWidth={false} disableGutters className="bg--1" >
            <Box
              display="flex"
              flexDirection="column"
              className="list-box"
            >


          <form onSubmit={userUpdateHandler}>
            <Box display="flex"
              flexDirection="column"
              justifyContent="center"
              // alignItems="center"
              margin="0 25px"
              className="h-gap--25"
            >

              <Box
                display="flex"
                flexDirection="column"
                padding="25px 0px"
                className="h-gap--20"
              >
                <Grid
                  container
                  spacing={5}
                  direction="row"
                  alignContent="flex-start"
                  alignItems="flex-start"
                  className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                >
                  <Grid container item lg={12} spacing={2}>
                    <Grid container item direction="row" alignItems="center">
                      <Typography variant="h6" component="h2">{t("PAGE_PROFILE.headlineCompany")}</Typography>
                    </Grid>

                    <Grid container item spacing={1}>
                      <Grid item xs={12} sm={8}>
                        <TextField
                          autoComplete="off"
                          defaultValue={values.company.state}
                          id="company"
                          label={`${t("FIELDS.company.label")} *`}
                          variant="outlined"
                          type="text"
                          error={values.company.error}
                          helperText={
                            values.company.error ? values.company.errorMsg : ""
                          }
                          onChange={handleChange}
                          onBlur={() => validate("company")}
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Autocomplete
                          fullWidth
                          autoComplete="off"
                          defaultValue={values.country.state}
                          value={values.country.state || null}
                          getOptionSelected={(option, value) => option.label === value.label} // prevents infinite loop!!!
                          id="country"
                          onChange={(event, value) =>
                            handleChange({
                              target: {
                                id: "country",
                                value: value
                              }
                            })
                          }
                          disableClearable
                          options={COUNTRIES}
                          classes={{
                            option: classes.option,
                          }}
                          autoHighlight
                          getOptionLabel={(option) => `${countryToFlag(option.code)} ${option.label}`}
                          renderOption={(option) => (
                            <React.Fragment>
                              <span style={{ marginRight: "5px" }}>{countryToFlag(option.code)} </span>
                              {option.label}
                            </React.Fragment>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={`${t("FIELDS.country.label")} *`}
                              variant="outlined"
                              onBlur={() => validate("country")}
                              error={values.country.error}
                              helperText={
                                values.country.error ? values.country.errorMsg : ""
                              }
                              value={values.country.state}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password", // disable autocomplete and autofill
                              }}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} sm={8} lg={4}>
                        <TextField
                          autoComplete="off"
                          defaultValue={values.street.state}
                          id="street"
                          label={`${t("FIELDS.street.label")} *`}
                          variant="outlined"
                          type="text"
                          error={values.street.error}
                          helperText={
                            values.street.error ? values.street.errorMsg : ""
                          }
                          onChange={handleChange}
                          onBlur={() => validate("street")}
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} sm={4} lg={2}>
                        <TextField
                          autoComplete="off"
                          defaultValue={values.streetNr.state}
                          id="streetNr"
                          label={`${t("FIELDS.streetNr.label")} *`}
                          variant="outlined"
                          type="text"
                          error={values.streetNr.error}
                          helperText={
                            values.streetNr.error ? values.streetNr.errorMsg : ""
                          }
                          onChange={handleChange}
                          onBlur={() => validate("streetNr")}
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} sm={4} lg={2}>
                        <TextField
                          autoComplete="off"
                          defaultValue={values.zip.state}
                          id="zip"
                          label={`${t("FIELDS.zip.label")} *`}
                          variant="outlined"
                          type="text"
                          error={values.zip.error}
                          helperText={values.zip.error ? values.zip.errorMsg : ""}
                          onChange={handleChange}
                          onBlur={() => validate("zip")}
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} sm={8} lg={4}>
                        <TextField
                          autoComplete="off"
                          defaultValue={values.city.state}
                          id="city"
                          label={`${t("FIELDS.city.label")} *`}
                          variant="outlined"
                          type="text"
                          error={values.city.error}
                          helperText={values.city.error ? values.city.errorMsg : ""}
                          onChange={handleChange}
                          onBlur={() => validate("city")}
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="subtitle2" component="div"><br />Optional: Bankverbindung für Ihre Rechnungslegung</Typography>
                      </Grid>

                      <Grid item xs={12} md={8} lg={4}>
                        <TextField
                          autoComplete="off"
                          defaultValue={values.bank.state}
                          id="bank"
                          label={`${t("FIELDS.bank.label")}`}
                          variant="outlined"
                          type="text"
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} md={8} lg={4}>
                        <TextField
                          autoComplete="off"
                          defaultValue={values.bankZip.state}
                          id="bankZip"
                          label={`${t("FIELDS.bankZip.label")}`}
                          variant="outlined"
                          type="text"
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>
                      

                      <Grid item xs={12} md={8} lg={4}>
                        <TextField
                          autoComplete="off"
                          defaultValue={values.iban.state}
                          id="iban"
                          label={`${t("FIELDS.iban.label")}`}
                          variant="outlined"
                          type="text"
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} md={8} lg={4}>
                        <TextField
                          autoComplete="off"
                          defaultValue={values.bic.state}
                          id="bic"
                          label={`${t("FIELDS.bic.label")}`}
                          variant="outlined"
                          type="text"
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} md={8} lg={4}>
                        <TextField
                          autoComplete="off"
                          defaultValue={values.bankAccount.state}
                          id="bankAccount"
                          label={`${t("FIELDS.bankAccount.label")}`}
                          variant="outlined"
                          type="text"
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} md={8} lg={4}>
                        <TextField
                          autoComplete="off"
                          defaultValue={values.bankAccountOwner.state}
                          id="bankAccountOwner"
                          label={`${t("FIELDS.bankAccountOwner.label")}`}
                          variant="outlined"
                          type="text"
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="subtitle2" component="div"><br />Optional: Weitere Angaben für Ihre Rechnungslegung und Filteroptionen</Typography>
                      </Grid>

                      <Grid item xs={12} md={4} lg={4}>
                        <TextField
                          autoComplete="off"
                          defaultValue={values.phone.state}
                          id="phone"
                          label={`${t("FIELDS.phone.label")}`}
                          variant="outlined"
                          type="text"
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>


                      <Grid item xs={12} md={4} lg={4}>
                        <TextField
                          autoComplete="off"
                          defaultValue={values.mobile.state}
                          id="mobile"
                          label={`${t("FIELDS.mobile.label")}`}
                          variant="outlined"
                          type="text"
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} md={4} lg={4}>
                        <TextField
                          autoComplete="off"
                          defaultValue={values.companyWebsite.state}
                          id="companyWebsite"
                          label={`${t("FIELDS.companyWebsite.label")}`}
                          variant="outlined"
                          type="text"
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} md={4} lg={4}>
                        <TextField
                          autoComplete="off"
                          defaultValue={values.companyEmail.state}
                          id="companyEmail"
                          label={`${t("FIELDS.companyEmail.label")}`}
                          variant="outlined"
                          type="text"
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} lg={4}>
                        <TextField
                          // required={values.vat.state !== ""}
                          autoComplete="off"
                          defaultValue={values.vat.state}
                          disabled={values.country.state === "" ? true : false}
                          id="vat"
                          label={`${t("FIELDS.vat.label")}`}
                          variant="outlined"
                          type="text"
                          onChange={handleChange}
                          onBlur={() => validate("vat")}
                          error={values.vat.error}
                          helperText={values.vat.error ? values.vat.errorMsg : ""}
                          fullWidth
                          InputProps={{
                            startAdornment: <InputAdornment position="start" style={{ backgroundColor: "#ededed", borderRadius: "5px", padding: "10px", height: "100%" }}>{values.vat.code}</InputAdornment>,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} lg={4}>
                        <TextField
                          autoComplete="off"
                          defaultValue={values.fbnr.state}
                          id="fbnr"
                          label={`${t("FIELDS.fbnr.label")}`}
                          variant="outlined"
                          type="text"
                          onChange={handleChange}
                          fullWidth
                          InputProps={{
                            startAdornment: <InputAdornment position="start" style={{ backgroundColor: "#ededed", borderRadius: "5px", padding: "10px", height: "100%" }}>FN</InputAdornment>,
                          }}
                        />
                      </Grid>

<Grid item xs={12} lg={4}>
  <TextField
    id="year-select"
    select
    label="Gründungsjahr"
    variant="outlined"
    defaultValue={values.foundingYear} // Default value for the founding year
    fullWidth
    onChange={(e) => setValues((prevValues) => ({ ...prevValues, foundingYear: e.target.value }))} // Update the state when year changes
  >
    {Array.from(new Array(new Date().getFullYear() - 1970 + 1), (_, index) => {
      const yearString = (new Date().getFullYear() - index).toString(); // Convert year to string
      return (
        <MenuItem key={index} value={yearString}>
          {yearString}
        </MenuItem>
      );
    })}
  </TextField>
</Grid>

<Grid item xs={12} lg={4}>
  <TextField
    id="businessYearStart"
    select
    label="Wirtschaftsjahr"
    variant="outlined"
    value={values.businessYearStart === 0 ? "Entspricht Kalenderjahr" : "Entspricht nicht Kalenderjahr"} // Conditional value
    onChange={(e) => setValues((prevValues) => ({ ...prevValues, businessYearStart: e.target.value === "Entspricht Kalenderjahr" ? 0 : 1 }))}
    fullWidth
  >
    <MenuItem value="Entspricht Kalenderjahr">Entspricht Kalenderjahr</MenuItem>
    <MenuItem value="Entspricht nicht Kalenderjahr">Entspricht nicht Kalenderjahr</MenuItem>
  </TextField>
</Grid>

{values.businessYearStart !== 0 && (
  <Grid item xs={12} lg={4}>
    <TextField
      id="month-select"
      select
      label="Wirtschaftsjahr beginnt im"
      variant="outlined"
      value={values.businessYearStart}  // businessYearStart holds the numeric value for the month
      onChange={(e) => setValues({ ...values, businessYearStart: e.target.value })}  // Update businessYearStart with the selected month number
      fullWidth
    >
      {/* Map numeric values to months */}
      {[
        { number: 1, month: 'Februar' },
        { number: 2, month: 'März' },
        { number: 3, month: 'April' },
        { number: 4, month: 'Mai' },
        { number: 5, month: 'Juni' },
        { number: 6, month: 'Juli' },
        { number: 7, month: 'August' },
        { number: 8, month: 'September' },
        { number: 9, month: 'Oktober' },
        { number: 10, month: 'November' },
        { number: 11, month: 'Dezember' }
      ].map(({ number, month }) => (
        <MenuItem key={number} value={number}>
          {month}
        </MenuItem>
      ))}
    </TextField>
  </Grid>
)}

    </Grid>
                    </Grid>
                  </Grid>
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                padding="25px 0px"
                className="h-gap--20"
              >
                <Grid
                  container
                  spacing={5}
                  direction="row"
                  alignContent="flex-start"
                  alignItems="flex-start"
                  className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                >
                  <Grid container item lg={12} spacing={2}>
                    <Grid container item direction="row" alignItems="center">
                      <Typography variant="h6" component="h2">{t("PAGE_PROFILE.headlinePerson")}</Typography>
                    </Grid>

                    <Grid container item spacing={1}>
                      <Grid container item xs={12} lg={6} spacing={1}>
                        <Grid item xs={12} sm={5}>
                          <TextField
                            defaultValue={values.precedingTitle.state}
                            id="precedingTitle"
                            label={`${t("FIELDS.precedingTitle.label")}`}
                            variant="outlined"
                            type="text"
                            onChange={handleChange}
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} sm={7}>
                          <TextField
                            defaultValue={values.firstName.state}
                            id="firstName"
                            label={`${t("FIELDS.firstName.label")} *`}
                            variant="outlined"
                            type="text"
                            error={values.firstName.error}
                            helperText={
                              values.firstName.error
                                ? values.firstName.errorMsg
                                : ""
                            }
                            onChange={handleChange}
                            onBlur={() => validate("firstName")}
                            fullWidth
                          />
                        </Grid>
                      </Grid>

                      <Grid container item xs={12} lg={6} spacing={1}>
                        <Grid item xs={12} sm={7}>
                          <TextField
                            defaultValue={values.lastName.state}
                            id="lastName"
                            label={`${t("FIELDS.lastName.label")} *`}
                            variant="outlined"
                            type="text"
                            error={values.lastName.error}
                            helperText={
                              values.lastName.error ? values.lastName.errorMsg : ""
                            }
                            onChange={handleChange}
                            onBlur={() => validate("lastName")}
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} sm={5}>
                          <TextField
                            defaultValue={values.followingTitle.state}
                            id="followingTitle"
                            label={`${t("FIELDS.followingTitle.label")}`}
                            variant="outlined"
                            type="text"
                            onChange={handleChange}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                padding="25px 0px"
                className="h-gap--20"
              >
                <Grid
                  container
                  spacing={5}
                  direction="row"
                  alignContent="flex-start"
                  alignItems="flex-start"
                  className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                >
                  <Grid container item lg={12} spacing={1}>
                    <Grid container item direction="row" alignItems="center">
                      <Typography variant="h6" component="h2">{t("PAGE_PROFILE.headlineImage")}</Typography>
                    </Grid>


                    {/* <Grid container item xs={12} lg={6}>
                      <br />
                      <div className="image-upload-wrapper">
                        <ImageUpload
                          id="image"
                          image={formState.inputs.image.value}
                          setUserAtom={SET_USER_ATOM}
                          onInput={inputHandler}
                        />
                      </div>
                    </Grid> */}

                    <Grid container item xs={12}>
                      <Grid item xs={12}>
                        <Typography variant="body2" component="p" style={{ marginBottom: "10px" }}>Dein Bild-Logo wird z.B. auf deinen Rechnungen angezeigt.</Typography>
                      </Grid>

                      <DropzoneUploaderSingle
                        values={values}
                        setValues={setValues}
                        buttonText="Logo hochladen"
                        description="Hier klicken oder Bild in den Rahmen ziehen um den Logo-Upload zu starten. Mögliche Dateiformate: JPG und PNG"
                        accept="image/jpg, image/jpeg, image/png"
                        logo={userAtom.data.logo}
                        multiple={false}
                        maxFiles={1}
                        disabled={false}
                        readData={false}
                      />
                      <Grid item container alignItems="center" style={{ flexWrap: "nowrap" }}>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>

              <Grid container item lg={12} spacing={2} direction="column" alignItems="center">
               
                <Grid container item lg={12} spacing={2} justifyContent="center" alignItems="center">
                  {/* <Grid item>
                    <Button
                      variant="outlined"
                      size="large"
                      style={{padding: "15px"}}
                      onClick={() => window.location.href = `/dashboard`}
                    >
                      {t("BUTTONS.cancel")}
                    </Button>
                  </Grid> */}

                  <Grid item>
                    <ButtonLoading
                      loading={isLoading}
                      disabled={isLoading}
                      type="submit"
                      onClick={() => validate("all")}
                      fullWidth
                      size="large"
                      className="button--loading"
                      startIcon={<SaveIcon style={{ marginRight: "5px" }} />}
                    >
                      {t("BUTTONS.save")}
                    </ButtonLoading>

                    {/* <BottomNavigation
                      showLabels
                      style={{ position: "fixed", left: 150, width: "100%", zIndex: 999, bottom: 0, background: "transparent", margin: "0 auto" }}
                      className="box-shadow--2"
                    >
                      <ButtonLoadingBottom
                        loading={isLoading}
                        disabled={isLoading}
                        color="primary"
                        type="submit"
                        size="large"
                        className="button--loading"
                        borderTopLeftRadius={"10px"}
                        borderTopRightRadius="10px"
                        startIcon={<SaveIcon />}
                        onClick={() => validate("all")}
                      >
                        Speichern
                      </ButtonLoadingBottom>
                    </BottomNavigation> */}
                  </Grid>
                </Grid>

                {/* FORM ERROR */}
                {/* {submitted === false &&
                    <Grid item xs={12} lg={6}>
                      <Alert severity="error">{t("ERRORS.save_form")}</Alert>
                    </Grid>
                } */}
                
                {/* SERVER ERROR */}
                {/* {error &&
                    <Grid item xs={12} lg={6}>
                      <Alert severity="error">{t("ERRORS.save_server")}</Alert>
                    </Grid>
                } */}

                {/* NO SERVER && NO FORM-ERROR */}
                {/* {!error && submitted &&
                    <Grid item xs={12} lg={6}>
                      <Alert severity="success">{t("FIELDS.saved")}</Alert>
                    </Grid>
                } */}
              </Grid>
            </Box>
          </form>
        </Box>
      </Container>
    </div>
  );
};

export default UserProfile;

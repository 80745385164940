import React, { useState, useContext, useEffect, useRef } from "react";

// IMPORT recoil
import { useRecoilState } from 'recoil';
import {
  settingsAtomState,
  openProductDialogAtomState,
  invoicesAtomState,
  clientsAtomState,
  invoicesFilteredAtomState,
} from "../_atoms";

// IMPORT global hooks & context
import { useTranslation } from "react-i18next";
import { useHttpClient } from "../shared/hooks/http-hook";
import { AuthContext } from '../shared/context/auth-context';
import { useAuth } from "../shared/hooks/auth-hook";
import { enqueueSnackbar } from 'notistack';

// IMPORT global functions
import {
  SET_VALUE,
  SET_VALUES,
  RETURN_DATE,
  RETURN_HOURS,
  RETURN_OVERDUE_DAYS,
} from '../_functions';

import {
  DB_GET_CLIENT,
  DB_GET_CLIENTS_LIST
} from '../_functions/DB_CLIENTS';

// import { DB_PATCH_SETTINGS } from '../_functions/DB_SETTINGS';


// IMPORT components
import { NumericFormat } from "react-number-format";

// IMPORT own components
import LogoMonogram from './../_assets/logo_monogram.svg';
import ProductAppBar from "./components/ProductAppBar";
import ProductBottomNavigation from "./components/ProductBottomNavigation";
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import SubjectIcon from '@material-ui/icons/Subject';

// IMPORT Material-UI components
import {
  Typography,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  Grid,
  Box,
  InputAdornment,
  TextField,
  CircularProgress,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  ListItemIcon,
  ListItemText,
  FormControlLabel,
  Switch,
  Chip,
  Backdrop,
  BottomNavigation,
  AccordionDetails,
  Tooltip,
  Divider,
  Fab
} from "@material-ui/core";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import Alert from "@material-ui/lab/Alert";

// IMPORT Material-UI icons from custom file
import {
  AddCircle as AddCircleIcon,
  ArrowBack as ArrowBackIcon,
  CheckCircle as CheckCircleIcon,
  DeleteForever as DeleteForeverIcon,
  OpenWith as DragIcon,
  Edit as EditIcon,
  Gavel as GavelIcon,
  RecentActors as InvoicesIcon,
  Mail as MailIcon,
  Save as SaveIcon,
  Warning as WarningIcon,
  Repeat as RepeatIcon,
} from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/Info';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

// IMPORT date-fns utils and localization
import DateFnsUtils from '@date-io/date-fns';
import { de } from "date-fns/locale";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

// IMPORT own css
import "./ProductsDIALOG.scss"

// IMPORT local utils and hooks


// import useMount from './invoice_dialog/hooks/useMount';


const useStyles = makeStyles((theme) => {
  return {
    toolbar: theme.mixins.toolbar,
    stepContent: {
      display: "flex",
      justifyContent: "center"
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    cssOutlinedInput: {
      '&$cssFocused $notchedOutline': {
        borderColor: `${theme.palette.primary.main} !important`,
      }
    },
    cssFocused: {},
    notchedOutline: {
      borderWidth: '2px',
      borderColor: "#f0f3ff !important"
    },
  };
});

const ProductsDIALOG = () => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { userId } = useAuth();
  const theme = useTheme();
  const classes = useStyles();

  // GLOBAL STATE (RECOIL)
  const [openProductDialogAtom, setOpenProductDialogAtom] = useRecoilState(openProductDialogAtomState);
  const [invoicesAtom, setInvoicesAtom] = useRecoilState(invoicesAtomState);
  const [, setInvoicesFilteredAtom] = useRecoilState(invoicesFilteredAtomState);
  const [settingsAtom, setSettingsAtom] = useRecoilState(settingsAtomState);
  const [clientsAtom] = useRecoilState(clientsAtomState);

  // LOCAL STATE
  const [recurring, setRecurring] = useState(false)
  const [selectedClient, setSelectedClient] = useState(null);
  const [items, setItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [sendMode, setSendMode] = useState(false);
  const [openSentBlob, setOpenSentBlob] = useState(false);
  const [openInvoiceNumberEdit, setOpenInvoiceNumberEdit] = useState(false);
  const [addElementOpen, setAddElementOpen] = useState(false);
  const [, setShowGlobalDiscount] = useState(false);
  const [currentItem, setCurrentItem] = useState({
    i: false,
    name: "",
    quantity: 1,
    price: 0,
    unit: "",
    vatPercent: "",
    discount: "none",
    discountPercent: 0,
    discountAbsolute: 0,
  });
  const [settings, setSettings] = useState(settingsAtom);
  const [values, setValues] = useState({
    additionalText: "",
    blob: "",
    clientData: "",
    discount: "none",
    discountAbsolute: "0",
    discountPercent: "0",
    discountValue: "0",
    discountedSubtotal: "0",
    discountedTotal: "0",
    dueDate: new Date().toString(),
    dueDays: -1,
    invoiceDate: new Date().toString(),
    invoiceNr: "",
    items: "",
    loading: true,
    payDate: new Date().toString(),
    payState: "notPayed",
    recurringRef: [],
    reminderRequestCharge: "0",
    sendDate: new Date().toString(),
    sendState: "notSent",
    sentBlobs: [],
    showAdditionalText: false,
    status: "draft",
    subtotal: "0",
    headline: "Rechnung",
    text: "Wir erlauben uns, Ihnen folgenden Betrag in Rechnung zu stellen und freuen uns auf weitere erfolgreiche Zusammenarbeit.",
    total: "0",
    type: "invoice",
    vatValues: "{}",
    cancelled: false,
    loading: true,
  })

  const [saveNewInvoiceSchema, setSaveNewInvoiceSchema] = useState(false)


  const [initialClientPicked, setInitialClientPicked] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [clientListFetched, setClientListFetched] = useState(false); // New state to track if clientList has been fetched
  const [loadingClients, setLoadingClients] = useState(false); // New state to track if clients are being fetched
  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms)); // Helper function to show clientsList Loading Spinner for at least one second

  const [buttonLoadingAction, setButtonLoadingAction] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [openPicker, setOpenPicker] = useState(false);
  const [disabledEditing, setDisabledEditing] = useState(false);

  const [shortname, setShortname] = useState(""); // State for select field
  const [nr, setNr] = useState(""); // State for select field
  const [productType, setProductType] = useState("dienstleistung"); // State for select field
  const [longname, setLongname] = useState(""); // State for textarea
  const [netPrice, setNetPrice] = useState(""); // State for net price
  const [unit, setUnit] = useState("Stk"); // Default selection for unit
  const [priceType, setPriceType] = useState("flat"); // Default is "Pauschalpreis"
  const [quantity, setQuantity] = useState(1); // State for quantity

  const handleQuantityChange = (event) => {
    const value = event.target.value;

    // Only allow positive numbers
    if (/^\d*$/.test(value)) {
      setQuantity(value);
    }
  };


  const handlePriceTypeChange = (event) => {
    setPriceType(event.target.value);
  };

  const handleUnitChange = (event) => {
    setUnit(event.target.value);
  };

  const handleNetPriceChange = (value) => {
    setNetPrice(value);
  };

  const handleLongnameChange = (event) => {
    setLongname(event.target.value);
  };

  const handleProductTypeChange = (event) => {
    setProductType(event.target.value);
  };

  const handleShortnameChange = (event) => {
    setShortname(event.target.value);
  };

  const handleNrChange = (event) => {
    setNr(event.target.value);
  };

  return (
    <React.Fragment>
      <ProductAppBar
        setOpenProductDialogAtom={setOpenProductDialogAtom}
        openProductDialogAtom={openProductDialogAtom}
      />

      <ProductBottomNavigation
        isLoading={isLoading}
        buttonLoadingAction={buttonLoadingAction}
      />

      <React.Fragment>
        <div>
          <div className="invoice-edit">
            <div className={classes.toolbar} />
            <Container maxWidth={false} disableGutters className="bg--1">
              <Container maxWidth="lg" style={{ paddingTop: "25px", paddingBottom: "100px" }}>
                <form
                // onSubmit={invoiceHandler}
                >
                  <Box display="flex"
                    flexDirection="column"
                    margin="0 25px"
                    className="h-gap--25"
                    style={{ minHeight: "100vh" }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      // padding="25px 0px"
                      className="h-gap--20"
                    >
                      <Grid
                        container
                        spacing={1}
                        direction="row"
                        alignContent="flex-start"
                        alignItems="flex-start"
                        className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                      >
                        <Grid item xs={12}>
                          <TextField
                            disabled={isLoading || disabledEditing}
                            multiline
                            required
                            autoComplete="off"
                            value={shortname}
                            id="shortname"
                            label="Produktname"
                            variant="outlined"
                            fullWidth
                            onChange={handleShortnameChange}
                          />
                        </Grid>

                        {/* Longname TextArea */}
                        <Grid item xs={12}>
                          <TextField
                            disabled={isLoading || disabledEditing}
                            multiline
                            minRows={4} // Minimum number of visible rows
                            maxRows={10} // Optional: Maximum number of rows before scrolling
                            autoComplete="off"
                            value={longname}
                            id="longname"
                            label="Beschreibung"
                            variant="outlined"
                            fullWidth
                            onChange={handleLongnameChange}
                          />
                        </Grid>
                      </Grid>
                    </Box>

                    <Box
                      display="flex"
                      flexDirection="column"
                      // padding="25px 0px"
                      className="h-gap--20"
                    >
                      <Grid
                        container
                        spacing={1}
                        direction="row"
                        alignContent="flex-start"
                        alignItems="flex-start"
                        className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                      >

                        <Grid item xs={12}>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            disabled={isLoading || disabledEditing}
                          >
                            <InputLabel id="product-type-label">Produkt Typ</InputLabel>
                            <Select
                              labelId="product-type-label"
                              id="product-type"
                              value={productType}
                              onChange={handleProductTypeChange}
                              label="Produkt Typ"
                            >
                              <MenuItem value="dienstleistung">Dienstleistung</MenuItem>
                              <MenuItem value="physisches-produkt">Physisches Produkt</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            disabled={isLoading || disabledEditing}
                            multiline
                            autoComplete="off"
                            value={nr}
                            id="nr"
                            label="Produktnummer"
                            variant="outlined"
                            fullWidth
                            onChange={handleNrChange}
                          />
                        </Grid>
                      </Grid>
                    </Box>

                    <Box
                      display="flex"
                      flexDirection="column"
                      // padding="25px 0px"
                      className="h-gap--20"
                    >
                      <Grid
                        container
                        spacing={1}
                        direction="row"
                        alignContent="flex-start"
                        alignItems="flex-start"
                        className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                      >

                        <Grid container item xs={12}>
                          <NumericFormat
                            fullWidth
                            className="input-white"
                            id="price"
                            label="Preis (netto)"
                            variant="outlined"
                            value={netPrice}
                            prefix="€ "
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={2}
                            valueIsNumericString
                            required
                            customInput={TextField}
                            onValueChange={(values) => handleNetPriceChange(values.value)}
                          />
                        </Grid>

                        {/* Price Type Dropdown */}
                        <Grid item xs={12}>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            disabled={isLoading || disabledEditing}
                          >
                            <InputLabel id="price-type-label">Preis Typ</InputLabel>
                            <Select
                              labelId="price-type-label"
                              id="price-type"
                              value={priceType}
                              onChange={handlePriceTypeChange}
                              label="Preis Typ"
                            >
                              <MenuItem value="flat">Pauschalpreis</MenuItem>
                              <MenuItem value="perunit">Preis pro Einheit</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>

                        {/* Conditionally Render Unit Dropdown */}
                        {priceType === "pro-einheit" && (
                          <>
                            <Grid item xs={12}>
                              <FormControl
                                variant="outlined"
                                fullWidth
                                disabled={isLoading || disabledEditing}
                              >
                                <InputLabel id="unit-label">Einheit</InputLabel>
                                <Select
                                  labelId="unit-label"
                                  id="unit"
                                  value={unit}
                                  onChange={handleUnitChange}
                                  label="Einheit"
                                >
                                  <MenuItem value="Stk">Stk</MenuItem>
                                  <MenuItem value="Std">Std</MenuItem>
                                  <MenuItem value="Tag(e)">Tag(e)</MenuItem>
                                  <MenuItem value="m2">m²</MenuItem>
                                  <MenuItem value="m">m</MenuItem>
                                  <MenuItem value="kg">kg</MenuItem>
                                  <MenuItem value="t">t</MenuItem>
                                  <MenuItem value="lfm">lfm</MenuItem>
                                  <MenuItem value="m3">m³</MenuItem>
                                  <MenuItem value="km">km</MenuItem>
                                  <MenuItem value="L">L</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>

                            {/* Quantity Field */}
                            <Grid item xs={12}>
                              <TextField
                                disabled={isLoading || disabledEditing}
                                required
                                autoComplete="off"
                                type="text" // Use text to enforce custom validation logic
                                value={quantity}
                                id="quantity"
                                label="Menge"
                                variant="outlined"
                                fullWidth
                                onChange={handleQuantityChange}
                              // helperText="Nur positive Zahlen erlaubt"
                              />
                            </Grid>
                          </>
                        )}


                      </Grid>
                    </Box>
                  </Box>
                </form>
              </Container>
            </Container>
          </div>
        </div>
      </React.Fragment>
    </React.Fragment>
  );
};

export default ProductsDIALOG;

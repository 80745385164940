import React, { useState, useEffect } from 'react';

// IMPORT hooks & context
import { useTranslation } from "react-i18next";

// IMPORT components MATERIAL UI
import {
  Typography,
  Button,
  Grid,
  IconButton,
  Dialog
} from "@material-ui/core";

// IMPORT icons MATERIAL UI
import SaveIcon from '@material-ui/icons/Save';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

// IMPORT icons MATERIAL UI
import CloseIcon from '@material-ui/icons/Close';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import openClientDialogAtom from '../../_atoms/openClientDialogAtom';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function InvoiceNumberEdit({
  openInvoiceNumberEdit,
  setOpenInvoiceNumberEdit,
  selectedClient,
  values,
  setValues,
  settingsAtom,
  recurring
}) {
  // Strings collected at the top
  const invoiceLabels = {
    invoiceNumber: 'Rechnungsnummer',
    estimateNumber: 'Kostenvoranschlag Nr.',
    offerNumber: 'Angebot Nr.',
    yearInvoice: 'Jahr des Rechnungsdatums',
    yearEstimate: 'Datum (Jahr)',
    monthInvoice: 'Monat des Rechnungsdatums',
    monthEstimate: 'Datum (Monat)',
    dayInvoice: 'Tag des Rechnungsdatums',
    dayEstimate: 'Datum (Tag)',
  };

  const buttonLabels = {
    applyChanges: 'Änderungen anwenden',
    cancel: 'Abbrechen',
  };

  // HOOKS & CONTEXT
  const { t } = useTranslation();

  const [fullWidth] = useState(true);
  const [maxWidth] = useState('sm');
  
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(JSON.parse(values.invoiceNr));
  }, [values]);


  const handleNewItems = () => {
    setValues({
      ...values,
      invoiceNr: JSON.stringify(items)
    });
  };

  const moveItemUp = index => {
    setItems(currentItems => {
      if (index > 0 && currentItems.length > 1) { 
        const newItems = [...currentItems];
        let itemAbove = newItems[index - 1];
        let currentItem = newItems[index];

        newItems[index - 1] = currentItem;
        newItems[index] = itemAbove;

        return newItems;
      }
      return currentItems;
    });
  };

  const moveItemDown = index => {
    setItems(currentItems => {
      if (index < currentItems.length - 1) {
        const newItems = [...currentItems];
        [newItems[index + 1], newItems[index]] = [newItems[index], newItems[index + 1]];
        return newItems;
      }
      return currentItems;
    });
  };
  
  return (
    <>
      <Dialog
        onClose={() => { setOpenInvoiceNumberEdit(false)}}
        aria-labelledby="customized-dialog-title"
        open={openInvoiceNumberEdit}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <DialogTitle id="customized-dialog-title" onClose={() => { setOpenInvoiceNumberEdit(false)}} style={{ display: "flex" }}>
          <CompareArrowsIcon color="primary" /> Elemente anordnen / entfernen
        </DialogTitle>
        <DialogContent dividers>
        {selectedClient &&
        <>
        <Grid item container xs={12} md={12}>
          <Grid item xs={12} md={12}>
            <Grid item style={{  marginBottom: "5px", wordBreak: "break-all"}}>
              <Typography>
                <span className="invoiceNumberHeadline" style={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.54)" }}>
                 {invoiceLabels.invoiceNumber}
                </span>
                <span style={{ fontSize: "12px" }}>
                {`
                ${recurring ? "Beispiel:" : ""}
                ${(items).map((item, i) => (
                 item.value
                )).join("")}
                `}
                </span>
              </Typography>
            </Grid>

            <Grid container style={{ marginBottom: "5px", borderRadius: "4px"}}>
                {(items).map((item, i) => (
                <Grid key={item.id} item className="invoiceNumberItem--v3">
                  {item.type === "continuousNumber" &&
                  <div style={{ paddingTop: 5, paddingBottom: 5, alignItems: "center", display: "flex", flexDirection: "row", border: "2px solid rgb(240, 243, 255)" }}>
                    <IconButton onClick={() => moveItemUp(i)} disabled={i === 0} color="primary" aria-label="move up">
                      <ArrowUpwardIcon />
                    </IconButton>

                    <IconButton onClick={() => moveItemDown(i)} disabled={i === items.length -1} color="primary" aria-label="move down">
                      <ArrowDownwardIcon />
                    </IconButton>
                    
                    <Grid container item direction="row" className="element" justifyContent="space-between"
                      // style={{ background: "rgba(33, 150, 243, 0.1)" }}
                      >
                      <Grid item style={{ textAlign: "center", width: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        {!recurring &&
                        <Typography >
                          <input
                            disabled={true}
                            className="badge-input--invoicenr"
                            defaultValue={item.value}
                            placeholder={`Nr. ...`}
                            style={{ width: "100%", fontSize: "14px", textAlign: "center", paddingTop: "4px", paddingBottom: "2px"  }}
                            size={JSON.stringify(item.value).length}
                          />
                        </Typography>
                        }

                        <Typography style={{ fontSize: "10px", margin: "0 auto", maxWidth: 300 }}>
                          Fortlaufende Nummer {`${recurring ? "(wird automatisch generiert)" : ""}`}
                          <br /><span style={{color: "grey"}}>Die fortlaufende Nummer muss immer vorhanden sein und kann daher nicht entfernt werden.</span>
                        </Typography>
                      </Grid>
                    </Grid>

                    <IconButton disabled color="primary" aria-label="delete" style={{border: "2px solid white"}}>
                      <DeleteForeverIcon />
                    </IconButton>
                  </div>
                  }

                  {item.type === "text" &&
                  <div style={{ paddingTop: 5, paddingBottom: 5, alignItems: "center", display: "flex", flexDirection: "row", border: "2px solid rgb(240, 243, 255)" }}>
                    <IconButton onClick={() => moveItemUp(i)} disabled={i === 0} color="primary" aria-label="move up">
                      <ArrowUpwardIcon />
                    </IconButton>

                    <IconButton onClick={() => moveItemDown(i)} disabled={i === items.length -1} color="primary" aria-label="move down">
                      <ArrowDownwardIcon />
                    </IconButton>

                    <Grid container item direction="row" className="element" justifyContent="space-between"
                      // style={{ background: "rgb(242 239 255)" }}
                      >
                      <Grid item style={{  width: "100%", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        <Typography style={{ textAlign: "center" }}>
                          <input
                            disabled={true}
                            className="badge-input--invoicenr"
                            defaultValue={item.value}
                            placeholder={`Text ...`}
                            style={{ width: "100%", fontSize: "14px", textAlign: "center", paddingTop: "4px", paddingBottom: "2px"   }}
                            size={JSON.stringify(item.value).length}
                          />
                        </Typography>

                        <Typography style={{ fontSize: "10px" }}>Fixer Text</Typography>
                      </Grid>
                    </Grid>

                    <IconButton onClick={()=> setItems(currentItems => currentItems.filter(element => element.id !== item.id))}  color="primary" aria-label="delete" style={{border: "2px solid white"}}>
                      <DeleteForeverIcon style={{ color: "red" }}/>
                    </IconButton>
                  </div>
                  }
                
                  {item.type === "year" &&
                  <div style={{ paddingTop: 5, paddingBottom: 5, alignItems: "center", display: "flex", flexDirection: "row", border: "2px solid rgb(240, 243, 255)" }}>
                    <IconButton onClick={() => moveItemUp(i)} disabled={i === 0} color="primary" aria-label="move up">
                      <ArrowUpwardIcon />
                    </IconButton>

                    <IconButton onClick={() => moveItemDown(i)} disabled={i === items.length -1} color="primary" aria-label="move down">
                      <ArrowDownwardIcon />
                    </IconButton>

                    <Grid container item direction="row" className="element"  justifyContent="space-between" style={{  paddingTop: 5, paddingBottom: 5, borderRadius: 6, background: "rgb(230, 229, 237)" }}>
                      <Grid item style={{  width: "100%", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        {!recurring &&
                        <Typography
                          style={{ width: "100%", fontSize: "14px", fontWeight: "400", display: "flex", alignItems: "center" }}
                          className="badge-input--date">
                          <span style={{ width: "100%", fontSize: "14px", textAlign: "center", paddingLeft: "15px", paddingRight: "15px" }}>
                              {item.value}
                          </span>
                        </Typography>
                        }

                        <Typography style={{ fontSize: "10px", maxWidth: 300, margin: "0 auto" }}>
                          {values.type === "invoice" || values.type === "reminder" || values.type === "reminderRequest"
                            ? invoiceLabels.yearInvoice
                            : invoiceLabels.yearEstimate} {`${recurring ? "(wird automatisch generiert)" : ""}`}
                            {settingsAtom.invoicesStartAtZeroAnnually &&
                            <>
                            <br /><span style={{color: "grey"}}>Das Element "Jahr",kann aufgrund der Einstellung <span style={{ fontWeight: "bold" }}>"Fortlaufende Nummer jedes Jahr mit 1 beginnen"</span>, nicht gelöscht werden. In den Einstellungen, unter dem Punkt "Rechnung", kann diese Festlegung deaktiviert werden.</span>
                            </>
                            }
                            </Typography>
                      </Grid>
                    </Grid>

                    <IconButton disabled={settingsAtom.invoicesStartAtZeroAnnually ? true : false} color="primary" aria-label="delete" style={{border: "2px solid white"}}>
                      <DeleteForeverIcon  onClick={()=> setItems(currentItems => currentItems.filter(element => element.id !== item.id))} style={{ color: !settingsAtom.invoicesStartAtZeroAnnually && "red" }}/>
                    </IconButton>
                  </div>
                  }

                  {item.type === "month" &&
                  <div style={{ paddingTop: 5, paddingBottom: 5, alignItems: "center", display: "flex", flexDirection: "row", border: "2px solid rgb(240, 243, 255)" }}>
                    <IconButton onClick={() => moveItemUp(i)} disabled={i === 0} color="primary" aria-label="move up">
                      <ArrowUpwardIcon />
                    </IconButton>

                    <IconButton onClick={() => moveItemDown(i)} disabled={i === items.length -1} color="primary" aria-label="move down">
                      <ArrowDownwardIcon />
                    </IconButton>

                    <Grid container item direction="row" className="element" justifyContent="space-between" style={{ paddingTop: 5, paddingBottom: 5, borderRadius: 6, background: "rgb(230, 229, 237)" }}>
                      <Grid item style={{  width: "100%", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        {!recurring &&
                        <Typography
                          style={{ width: "100%", fontSize: "14px", fontWeight: "400", display: "flex", alignItems: "center" }}
                          className="badge-input--date">
                          <span style={{ width: "100%", fontSize: "14px", textAlign: "center", paddingLeft: "15px", paddingRight: "15px" }}>
                              {item.value}
                          </span>
                        </Typography>
                        }

                        <Typography style={{ fontSize: "10px" }}>
                          {values.type === "invoice" || values.type === "reminder" || values.type === "reminderRequest"
                            ? invoiceLabels.monthInvoice
                            : invoiceLabels.monthEstimate} {`${recurring ? "(wird automatisch generiert)" : ""}`}
                        </Typography>
                      </Grid>
                    </Grid>

                    <IconButton color="primary" aria-label="delete" style={{border: "2px solid white"}}>
                      <DeleteForeverIcon onClick={()=> setItems(currentItems => currentItems.filter(element => element.id !== item.id))} style={{ color: "red" }}/>
                    </IconButton>
                  </div>
                  }

                  {item.type === "day" &&
                  <div style={{ paddingTop: 5, paddingBottom: 5, alignItems: "center", display: "flex", flexDirection: "row", border: "2px solid rgb(240, 243, 255)" }}>
                    <IconButton onClick={() => moveItemUp(i)} disabled={i === 0} color="primary" aria-label="move up">
                      <ArrowUpwardIcon />
                    </IconButton>

                    <IconButton onClick={() => moveItemDown(i)} disabled={i === items.length -1} color="primary" aria-label="move down">
                      <ArrowDownwardIcon />
                    </IconButton>

                    <Grid container item direction="row" className="element"  justifyContent="space-between" style={{ paddingTop: 5, paddingBottom: 5, borderRadius: 6, background: "rgb(230, 229, 237)" }}>
                      <Grid item style={{  width: "100%", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        {!recurring &&
                        <Typography
                          style={{ width: "100%", fontSize: "14px", fontWeight: "400", display: "flex", alignItems: "center" }}
                          className="badge-input--date">
                          <span style={{ width: "100%", textAlign: "center", paddingLeft: "15px", paddingRight: "15px" }}>
                              {item.value}
                          </span>
                        </Typography>
                        }

                        <Typography style={{ fontSize: "10px" }}>
                          {values.type === "invoice" || values.type === "reminder" || values.type === "reminderRequest"
                            ? invoiceLabels.dayInvoice
                            : invoiceLabels.dayEstimate} {`${recurring ? "(wird automatisch generiert)" : ""}`}
                        </Typography>
                      </Grid>
                    </Grid>

                    <IconButton color="primary" aria-label="delete" style={{border: "2px solid white"}}>
                      <DeleteForeverIcon onClick={()=> setItems(currentItems => currentItems.filter(element => element.id !== item.id))} style={{ color: "red" }}/>
                    </IconButton>
                  </div>
                  }
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        </>
        }
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            size="small"
            onClick={() => { setOpenInvoiceNumberEdit(false)} }
          >
            {t("BUTTONS.cancel")}
          </Button>

          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => { setOpenInvoiceNumberEdit(false); handleNewItems(); }}
            startIcon={<SaveIcon style={{ marginRight: "5px" }} />}
          >
            {buttonLabels.applyChanges}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

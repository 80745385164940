import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Typography,
  Button,
  Box,
  FormControlLabel,
  FormControl,
  FormGroup,
  Checkbox,
  IconButton,
  Menu,
  makeStyles,
  Chip
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import FilterListIcon from "@material-ui/icons/FilterList";
import EditIcon from "@material-ui/icons/Edit";
import MailIcon from "@material-ui/icons/Mail";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Error";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles((theme) => ({
  badge: {
    backgroundColor: "rgb(44, 83, 125)",
    color: "#fff",
    transform: "scale(0.9)",
    top: 5,
    right: 0,
    border: "1px solid #fff",
  },
  checkboxLabel: {
    transition: "background-color 0.3s, color 0.3s",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    marginRight: theme.spacing(0.75),
    color: "#fff",
    opacity: 0.3, // Default opacity
    zoom: 1.2
  },
  iconWrapperChecked: {
    opacity: 1, // Full opacity when checked
  },
  draft: {
    backgroundColor: "rgb(172, 175, 197)",
  },
  scheduled: {
    backgroundColor: "#fff",
    color: theme.palette.warning.main,
    fontSize: "22px",
  },
  sent: {
    backgroundColor: "rgb(26, 123, 201)",
  },
  paid: {
    backgroundColor: theme.palette.success.main,
  },
  overdue: {
    backgroundColor: "#fff",
    color: theme.palette.error.main,
    fontSize: "22px",
  },
}));

export default function ClientsFilter({
  settings,
  handleChange,
  cancelSettingsHandler,
  updateSettingsHandler,
  SET_VALUE,
  setValues,
  values,
  setOffset,
  fetchData,
  INITIAL_ITEMS,
  searchTerm,
  setHasMoreInvoices,
  setSettingsAtom,
  settingsAtom,
  setSettings,
  mobile
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [initialSettings, setInitialSettings] = useState({ ...settings });

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
    SET_VALUE({ target: { id: "submenuOpen", value: "filter" } }, setValues);

    setInitialSettings({ ...settings });
    setHasChanges(false);
  };

  const activeFilterCount = [
    settings.clientsFiltersStatusActive,
    settings.clientsFiltersStatusInactive,
    settings.clientsFiltersStatusProspect,
  ].filter(Boolean).length;

  const getFilterLabel = () => {
    if (activeFilterCount === 0) return t("PAGE_INVOICES.noFilters");
    const labels = [];
    if (settings.clientsFiltersStatusActive) labels.push("Aktiv");
    if (settings.clientsFiltersStatusInactive) labels.push("Inaktiv");
    if (settings.clientsFiltersStatusProspect) labels.push("In Verhandlung");
    return labels.join(", ");
  };

  const getLabelOpacity = (isChecked) => ({
    opacity: activeFilterCount === 0 || isChecked ? 1 : 0.3,
  });

  const handleSettingChange = (e) => {
    handleChange(e);
    setHasChanges(true);
  };

  useEffect(() => {
    const isDifferent = Object.keys(initialSettings).some(
      (key) => initialSettings[key] !== settings[key]
    );
    setHasChanges(isDifferent);
  }, [settings, initialSettings]);

  const handleSave = () => {
    updateSettingsHandler();
    setHasChanges(false);
    setAnchorEl(null);
  };

  const handleCancel = () => {
    cancelSettingsHandler();
    setHasChanges(false);
    setAnchorEl(null);
  };

  return (
    <>
      {mobile && (
        <IconButton
          aria-label="filters"
          onClick={handleClickOpen}
          style={{ color: "inherit", position: "relative" }}
        >
          {/* Filter Icon */}
          <FilterListIcon fontSize="small" style={{ color: "rgb(44, 83, 125)" }} />

          {/* Custom Badge */}
          {activeFilterCount > 0 && (
            <div
              style={{
                position: "absolute",
                top: "8px", // Adjust position
                right: 0, // Adjust position
                backgroundColor: "rgb(44, 83, 125)", // Badge background color
                color: "#fff", // Text color
                width: "18px", // Badge size
                height: "18px", // Badge size
                borderRadius: "50%", // Circle shape
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "12px", // Font size
                fontWeight: "bold", // Bold text
                border: "1px solid #fff", // Optional border for clarity
                zIndex: 1, // Ensure it appears above the icon
              }}
            >
              {activeFilterCount}
            </div>
          )}
        </IconButton>
      )}



      {!mobile &&
        <IconButton
          aria-label="filters"
          onClick={handleClickOpen}
          style={{ color: "inherit", borderRadius: 6, height: 45 }}
          disableRipple
        >
          <FilterListIcon fontSize="small" style={{ background: activeFilterCount > 0 ? "#E312CA" : "white", color: activeFilterCount > 0 ? "white" : "rgb(44, 83, 125)", borderRadius: 4 }} />
          <Typography
            variant="body2"
            component="div"
            style={{
              marginLeft: "5px",
              fontSize: "12px",
              color: "rgba(0, 0, 0, 0.87)",
            }}
          >
            <span style={{ marginRight: 5 }}>Statusfilter:</span>
            <span style={{ fontWeight: 500 }}>
              {activeFilterCount === 0 ? "Kein Filter gesetzt" : ""}
            </span>
          </Typography>

          {settingsAtom.clientsFiltersStatusActive &&
            <Chip
              style={{ marginRight: "5px" }}
              icon={<FiberManualRecordIcon style={{ width: 15, height: 15, color: "rgb(76, 175, 80)", background: "rgb(76, 175, 80)", borderRadius: "60px", padding: "2px" }} />}
              size="small"
              label={"Aktiv"}
              className="draggable-chip draft"
            />
          }
          {settingsAtom.clientsFiltersStatusInactive &&
            <Chip
              style={{ marginRight: "5px" }}
              icon={<FiberManualRecordIcon style={{ width: 15, height: 15, color: "rgb(183, 28, 28)", background: "rgb(183, 28, 28)", borderRadius: "60px", padding: "2px" }} />}
              size="small"
              label={"Inaktiv"}
              className="draggable-chip draft"
            />
          }
          {settingsAtom.clientsFiltersStatusProspect &&
            <Chip
              style={{ marginRight: "5px" }}
              icon={<FiberManualRecordIcon style={{ width: 15, height: 15, color: "rgb(255, 197, 112)", background: "rgb(255, 197, 112)", borderRadius: "60px", padding: "2px" }} />}
              size="small"
              label={"Möglicher Kunde"}
              className="draggable-chip draft"
            />
          }
        </IconButton>
      }

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCancel}
        keepMounted
        PaperProps={{
          style: {
            width: "300px", // Original width of the menu
          },
        }}
      >
        {/* Header with Close Icon */}
        <Box display="flex" alignItems="center" justifyContent="space-between" px={2} py={1}>
          <Typography
            variant="h6"
            color="primary"
            style={{
              display: "flex",
              alignItems: "center",
              fontWeight: 600,
              fontSize: 16,
            }}
          >
            <FilterListIcon
              style={{
                marginRight: 10,
                color: "rgb(44, 83, 125)",
                boxShadow: "rgba(108, 116, 173, 0.12) 0px 0px 20px 0px inset",
                borderRadius: 60,
                padding: 5,
                zoom: 1.5,
              }}
            />
            {t("PAGE_INVOICES.filter")}
          </Typography>
          <IconButton onClick={handleCancel} size="small">
            <CloseIcon />
          </IconButton>
        </Box>

        <Box>
          <FormControl component="fieldset">
            <FormGroup
              style={{ marginTop: 10, marginBottom: 10 }}
              aria-label="filterInvoicesStatus"
              name="filterInvoicesStatus"
              onChange={handleSettingChange}
            >
              {/* Active */}
              <FormControlLabel
                className={classes.checkboxLabel}
                style={{ marginRight: 0, marginLeft: 0, paddingLeft: 6, paddingRight: 20 }}
                name="clientsFiltersStatusActive"
                control={<Checkbox checked={settings.clientsFiltersStatusActive} color="primary" />}
                label={
                  <Box
                    display="flex"
                    alignItems="center"
                    style={getLabelOpacity(settings.clientsFiltersStatusActive)}
                  >
                    <Box
                      className={`${classes.iconWrapper} ${settings.clientsFiltersStatusActive
                          ? classes.iconWrapperChecked
                          : ""
                        } ${classes.draft}`}
                    >
                      <FiberManualRecordIcon style={{ fontSize: 15, borderRadius: 60, color: "rgb(76, 175, 80)", background: "rgb(76, 175, 80)" }} />
                    </Box>
                    <Typography variant="body2">
                      Aktiv
                    </Typography>
                  </Box>
                }
              />
              {/* Inactive */}
              <FormControlLabel
                className={classes.checkboxLabel}
                style={{ marginRight: 0, marginLeft: 0, paddingLeft: 6, paddingRight: 20 }}
                name="clientsFiltersStatusInactive"
                control={<Checkbox checked={settings.clientsFiltersStatusInactive} color="primary" />}
                label={
                  <Box
                    display="flex"
                    alignItems="center"
                    style={getLabelOpacity(settings.clientsFiltersStatusInactive)}
                  >
                    <Box
                      className={`${classes.iconWrapper} ${settings.clientsFiltersStatusInactive
                          ? classes.iconWrapperChecked
                          : ""
                        } ${classes.draft}`}
                    >
                      <FiberManualRecordIcon style={{ fontSize: 15, borderRadius: 60, color: "rgb(183, 28, 28)", background: "rgb(183, 28, 28)" }} />
                    </Box>
                    <Typography variant="body2">
                      Inaktiv
                    </Typography>
                  </Box>
                }
              />
              {/* Prospect */}
              <FormControlLabel
                className={classes.checkboxLabel}
                style={{ marginRight: 0, marginLeft: 0, paddingLeft: 6, paddingRight: 20 }}
                name="clientsFiltersStatusProspect"
                control={<Checkbox checked={settings.clientsFiltersStatusProspect} color="primary" />}
                label={
                  <Box
                    display="flex"
                    alignItems="center"
                    style={getLabelOpacity(settings.clientsFiltersStatusProspect)}
                  >
                    <Box
                      className={`${classes.iconWrapper} ${settings.clientsFiltersStatusProspect
                          ? classes.iconWrapperChecked
                          : ""
                        } ${classes.draft}`}
                    >
                      <FiberManualRecordIcon style={{ fontSize: 15, borderRadius: 60, color: "rgb(255, 197, 112)", background: "rgb(255, 197, 112)" }} />
                    </Box>
                    <Typography variant="body2">
                      Möglicher Kunde (in Verhandlung)
                    </Typography>
                  </Box>
                }
              />
            </FormGroup>
          </FormControl>
        </Box>

        <Box display="flex" justifyContent="space-between" px={2} py={1}>
          <Button variant="outlined" size="small" onClick={handleCancel}>
            {t("BUTTONS.cancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleSave}
            startIcon={<FilterListIcon />}
            disabled={!hasChanges}
          >
            Filtern
          </Button>
        </Box>
      </Menu>
    </>
  );
}

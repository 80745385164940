import React, { useState, useEffect } from "react";

// KEEP!!!! even it is greyed out!!!!
import _ from "lodash";
import filter from "lodash";
import groupBy from "lodash";
import map from "lodash";
import sumBy from "lodash";
// KEEP !!!!

// IMPORT hooks & context
import {
  NavLink
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { saveAs } from 'file-saver';
import { jsPDF } from "jspdf";
import 'jspdf-autotable';  // This imports the autotable plugin

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import userAtomState from "../../_atoms/userAtom";
import selectedMenuItemAtomState from "../../_atoms/selectedMenuItemAtom";
import subMenuOpenAtomState from "../../_atoms/subMenuOpenAtom";
import invoicesAtomState from "../../_atoms/invoicesAtom";
import expensesAtomState from "../../_atoms/expensesAtom";
import openInvoiceDialogAtomState from "../../_atoms/openInvoiceDialogAtom";
import openExpenseDialogAtomState from "../../_atoms/openExpenseDialogAtom";
import settingsAtomState from "../../_atoms/settingsAtom";

import { useHttpClient } from "../../shared/hooks/http-hook";

// IMPORT components MATERIAL UI
import {
  Typography,
  Box,
  Container,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  SvgIcon,
  useTheme,
  useMediaQuery,
  Modal,
  makeStyles,
  Divider,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";

// IMPORT icons MATERIAL UI
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import CloseIcon from '@material-ui/icons/Close';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import { RETURN_DATE } from '../../_functions/DATES';
import DOWNLOAD_ZIP from "../../_functions/DOWNLOAD_ZIP";
import DOWNLOAD_SINGLEPDF from "../../_functions/DOWNLOAD_SINGLEPDF";

import DateRange from "../../shared/components/DateRange/DateRange";

import "./Balance.scss"

function PdfPreviewIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d=" M 18.5 10.5 L 17 10.5 L 17 11.5 L 18.5 11.5 L 18.5 13 L 17 13 L 17 15 L 15.5 15 L 15.5 9 L 18.5 9 L 18.5 10.5 L 18.5 10.5 L 18.5 10.5 L 18.5 10.5 Z  M 7 11.5 L 8 11.5 L 8 10.5 L 7 10.5 L 7 11.5 L 7 11.5 L 7 11.5 L 7 11.5 Z  M 9.5 11.5 C 9.5 12.33 8.83 13 8 13 L 7 13 L 7 15 L 5.5 15 L 5.5 9 L 8 9 C 8.83 9 9.5 9.67 9.5 10.5 L 9.5 11.5 L 9.5 11.5 L 9.5 11.5 L 9.5 11.5 Z  M 18 4 L 6 4 C 4.9 4 4 4.9 4 6 L 4 18 C 4 19.1 4.9 20 6 20 L 18 20 C 19.1 20 20 19.1 20 18 L 20 6 C 20 4.9 19.1 4 18 4 L 18 4 L 18 4 Z  M 12 13.5 L 13 13.5 L 13 10.5 L 12 10.5 L 12 13.5 L 12 13.5 L 12 13.5 Z  M 14.5 13.5 C 14.5 14.33 13.83 15 13 15 L 10.5 15 L 10.5 9 L 13 9 C 13.83 9 14.5 9.67 14.5 10.5 L 14.5 13.5 L 14.5 13.5 L 14.5 13.5 Z " />
    </SvgIcon>
  );
}


const useStyles = makeStyles((theme) => ({
  // necessary for content to be below app bar
  toolbar: {
    height: '0px',
    '@media (max-width: 959px)': {
      height: '64px', 
    },
    '@media (max-width: 599px)': {
      height: '40px', 
    },
  },
  table: {
    // Setting the font size for all text within the table
    '& th, & td': {
      fontSize: '12px', // Set your desired font size here
    },
  },
  content: {
    padding: theme.spacing(2),
    // You can add more styles to your content here
  },
  modal: {
    position: 'fixed',  // Fixed position to stay in place
    top: 0,
    left: 0,
    width: '100vw',  // Full viewport width
    height: '100%',  // Use 100% height for auto-adjusting to content length
    overflowY: 'auto',  // Enables vertical scrolling
    display: 'flex',
    alignItems: 'flex-start',  // Aligns content to the top
    justifyContent: 'center',
  },
  paper: {
    width: '100%',  // Full width inside the modal
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    boxSizing: 'border-box',  // Includes padding in width calculation
  },

}));


function FinancialData({ sums }) {
  return (
    <div className="sums">
            <div className="sums-element" style={{ marginRight: "5px", background: "rgb(226 236 253 / 38%)", borderRadius: "6px", display: "flex", alignItems: "center", padding: "5px", borderLeft: "5px solid rgb(0, 191, 114)"}}>
              <div className="sums-element-headline" style={{ display: "inline-block", marginRight: "10px" }}>
                <Typography style={{ textAlign: "center", fontSize: "12px", color: "rgba(0, 0, 0, 0.87)", fontWeight: "500", padding: "0 5px" }}>Einnahmen</Typography>
              </div>

              <div style={{ display: "inline-block", marginRight: "10px" }}>
                <Typography style={{ display: "block", textAlign: "center", fontSize: "12px", color: "rgba(0,0,0,0.40)", fontWeight: "500", padding: "0 5px" }}>netto</Typography>
                <Typography style={{ textAlign: "center", fontSize: "12px", color: "rgba(0, 0, 0, 0.87)", fontWeight: "500", padding: "0 5px" }}>€ {sums.netRevenue.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
              </div>

              <div style={{ display: "inline-block", marginRight: "10px" }}>
                <Typography style={{ display: "block", textAlign: "center", fontSize: "12px", color: "rgba(0,0,0,0.40)", fontWeight: "500", padding: "0 5px" }}>USt.</Typography>
                <Typography style={{ textAlign: "center", fontSize: "12px", color: "rgba(0, 0, 0, 0.87)", fontWeight: "500", padding: "0 5px" }}>€ {sums.vatRevenue.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
              </div>

              <div style={{ display: "inline-block", marginRight: "10px" }}>
                <Typography style={{ display: "block", textAlign: "center", fontSize: "12px", color: "rgba(0,0,0,0.40)", fontWeight: "500", padding: "0 5px" }}>brutto</Typography>
                <Typography style={{ textAlign: "center", fontSize: "12px", color: "rgba(0, 0, 0, 0.87)", fontWeight: "500", padding: "0 5px" }}>€ {sums.grossRevenue.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
              </div>
            </div>

            <div className="sums-element" style={{ marginRight: "5px", background: "rgb(226 236 253 / 38%)", borderRadius: "6px", display: "flex", alignItems: "center", padding: "5px", borderLeft: "5px solid rgb(255, 121, 121)"}}>
              <div className="sums-element-headline" style={{ display: "inline-block", marginRight: "10px",  }}>
                <Typography style={{ textAlign: "center", fontSize: "12px", color: "rgba(0, 0, 0, 0.87)", fontWeight: "500",  padding: "0 5px" }}>Ausgaben</Typography>
              </div>

              <div style={{ display: "inline-block", marginRight: "10px" }}>
                <div style={{ textAlign: "center", fontSize: "12px", color: "rgba(0,0,0,0.40)", fontWeight: "500", padding: "0 5px" }}>netto</div>
                <Typography style={{ textAlign: "center", fontSize: "12px", color: "rgba(0, 0, 0, 0.87)", fontWeight: "500", padding: "0 5px" }}>€ {sums.netCosts.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
              </div>

              <div style={{ display: "inline-block", marginRight: "10px" }}>
                <div style={{ textAlign: "center", fontSize: "12px", color: "rgba(0,0,0,0.40)", fontWeight: "500",  padding: "0 5px" }}>VSt.</div>
                <Typography style={{ textAlign: "center", fontSize: "12px", color: "rgba(0, 0, 0, 0.87)", fontWeight: "500", padding: "0 5px" }}>€ {sums.vatCosts.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
              </div>

              <div style={{ display: "inline-block", marginRight: "10px" }}>
                <div style={{ textAlign: "center", fontSize: "12px", color: "rgba(0,0,0,0.40", fontWeight: "500", padding: "0 5px" }}>brutto</div>
                <Typography style={{ textAlign: "center", fontSize: "12px", color: "rgba(0, 0, 0, 0.87)", fontWeight: "500", padding: "0 5px" }}>€ {sums.grossCosts.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
              </div>
            </div>

            <div className="sums-element" style={{ marginRight: "5px", background: (sums.vatRevenue - sums.vatCosts) > 0 ? "red" : "green", borderRadius: "6px", display: "flex", alignItems: "center", padding: "5px" }}>
              <div style={{ display: "inline-block", textAlign: "center" }}>
                <Typography style={{ display: "block", textAlign: "center", fontSize: "12px",  color: "rgb(255 255 255 / 72%)", fontWeight: "500", padding: "0 5px" }}>{(sums.vatRevenue - sums.vatCosts) > 0 ? "USt. Zahllast" : "VSt. Überschuss"}</Typography>
                <Typography style={{ paddingLeft: "4px", fontSize: "12px", paddingRight: "4px", fontWeight: "bold",  color: "#ffffff", textAlign: "center", borderRadius: "4px" }}> € {(sums.vatRevenue - sums.vatCosts).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
              </div>
            </div>

            <div className="sums-element" style={{ background: sums.netRevenue >= sums.netCosts ? "green" : "red", borderRadius: "6px", display: "flex", alignItems: "center", padding: "5px" }}>
              <div style={{ display: "inline-block", textAlign: "center" }}>
                <Typography style={{ display: "block", textAlign: "center", fontSize: "12px", color: "rgb(255 255 255 / 72%)", fontWeight: "500", padding: "0 5px" }}>{sums.netRevenue >= sums.netCosts ? "Gewinn" : "Verlust"}</Typography>
                <Typography style={{ paddingLeft: "4px", fontSize: "12px", paddingRight: "4px", fontWeight: "bold", color: "#ffffff", textAlign: "center", borderRadius: "4px" }}> € {(sums.netRevenue - sums.netCosts).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
              </div>
            </div>
          </div>
  );
}

const Balance = () => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const classes = useStyles();
  const { isLoading, error, sendRequest } = useHttpClient();

  // GLOBAL STATE (RECOIL)
  const [userAtom] = useRecoilState(userAtomState);
  const [selectedMenuItemAtom, setSelectedMenuItemAtom] = useRecoilState(selectedMenuItemAtomState);
  const [subMenuOpenAtom, setSubMenuOpenAtom] = useRecoilState(subMenuOpenAtomState);
  const [openInvoiceDialogAtom, setOpenInvoiceDialogAtom] = useRecoilState(openInvoiceDialogAtomState);
  const [openExpenseDialogAtom, setOpenExpenseDialogAtom] = useRecoilState(openExpenseDialogAtomState);

  const [dataInitial, setDataInitial] = useState([]);
  // Infinite Scroll Variables
  const INITIAL_ITEMS = 5; // Load 10 items initially
  const [offset, setOffset] = useState(0); // Track the current offset for fetching
  const [hasMoreInvoices, setHasMoreInvoices] = useState(true); // To know if more invoices exist
  const [isFetching, setIsFetching] = useState(false); // Track if a fetch is in progress

  // Search Variables
  const [searchTerm, setSearchTerm] = useState('');


  const [invoicesAtom, setInvoicesAtom] = useRecoilState(invoicesAtomState);
  const [dataInvoices, setDataInvoices] = useState([]);
  const [expensesAtom, setExpensesAtom] = useRecoilState(expensesAtomState);
  const [dataExpenses, setDataExpenses] = useState([]);
  const [settingsAtom, setSettingsAtom] = useRecoilState(settingsAtomState);
  const [data, setData] = useState([]);
  const [newData, setNewData] = useState([]);
  const [watermark, setWatermark] = useState(true);

  const [revenue, setRevenue] = useState(0);
  const [costs, setCosts] = useState(0);
  const [profit, setProfit] = useState(0);
  const [vat, setVat] = useState(0);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Using 'md' assuming 960px, change to 1000px if needed
  const [openSums, setOpenSums] = useState(false);


    /***
  FETCH
  ***/
  const fetchData = async (currentOffset, limit, isInitialLoad = false, searchTerm = "", newRecurring) => {
    try {
      const storedUserData = localStorage.getItem('userData');
      if (!storedUserData) {
        throw new Error('No user data found in local storage');
      }

      const resUser = JSON.parse(storedUserData);

      setIsFetching(true); // Set fetching flag to true

      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL_API}/invoices/user/${resUser.userId}?limit=${limit}&offset=${currentOffset}&searchTerm=${searchTerm}&sortField=${settingsAtom.invoicesSortField}&sortOrder=${settingsAtom.invoicesSortOrder}&recurring=${JSON.stringify(newRecurring)}`,
        'GET',
        null,
        { Authorization: 'Bearer ' + resUser.token }
      );

      console.log(response)

      if (response && response.invoices) {
        // if (response.invoices.length < limit) {
        //   setHasMoreInvoices(false); // No more invoices to fetch
        // }

        if (isInitialLoad) {
          setDataInitial(response.invoices); // Replace existing data for initial load
        } else {
          // Append new invoices, making sure no duplicates
          setDataInitial(prevInvoices => [
            ...prevInvoices,
            ...response.invoices,
          ]);
        }
      }

      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);
      console.error('Error fetching invoices:', err);
    }
  };

  useEffect(() => {
    fetchData(0, 5, true, searchTerm); // Fetch the first 5 invoices with updated recurring value
  }, []);

  const handleOpenSums = () => {
    setOpenSums(true);
  };

  const handleCloseSums = () => {
    setOpenSums(false);
  };

  const [sums, setSums] = useState({
    netRevenue: 0,
    vatRevenue: 0,
    grossRevenue: 0,
    netCosts: 0,
    vatCosts: 0,
    grossCosts: 0,
    profit: 0,
  });

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [imageBlobs, setImageBlobs] = useState([])
  const [pdfStrings, setPdfStrings] = useState([])

  useEffect(() => {
    dataInitial.forEach(function (item, i) {
      if (item.payState === "payed") {

        // console.log("herererwer")
        // const pdfBlob = JSON.parse(item.blob).blob;
        // setPdfStrings((prevPdfStrings) => [...prevPdfStrings, pdfBlob]);

        setData((items) => {
          const existingItemIndex = items.findIndex(x => x.id === item.id);
          if (existingItemIndex >= 0) {
            // Item exists, update it
            const updatedItems = [...items];
            updatedItems[existingItemIndex] = {
              ...updatedItems[existingItemIndex],
              type: "invoice",
              company: JSON.parse(item.clientData).company,
              payDate: JSON.stringify(new Date(item.payDate)),
              invoiceNr: JSON.parse(item.invoiceNr).map(i => i.value),
              category: "",
              netInvoice: item.discountedSubtotal,
              vatInvoice: item.total - item.discountedSubtotal,
              grossInvoice: item.total,
              charge: "0",
              netExpense: 0,
              vatExpense: 0,
              grossExpense: 0,
              id: item.id,
              blob: JSON.parse(item.blob).blob,
              filename: JSON.parse(item.blob).filename,
              blobtype: "pdf"
            };
            return updatedItems;
          } else {
            // Item does not exist, add new item
            return [
              ...items,
              {
                type: "invoice",
                company: JSON.parse(item.clientData).company,
                payDate: JSON.stringify(new Date(item.payDate)),
                invoiceNr: JSON.parse(item.invoiceNr).map(i => i.value),
                category: "",
                netInvoice: item.discountedSubtotal,
                vatInvoice: item.total - item.discountedSubtotal,
                grossInvoice: item.total,
                charge: "0",
                netExpense: 0,
                vatExpense: 0,
                grossExpense: 0,
                id: item.id,
                // blob: JSON.parse(item.blob).blob,
                // filename: JSON.parse(item.blob).filename,
                blobtype: "pdf"
              },
            ];
          }
        });
      }
    });
  }, [dataInitial]);


  useEffect(() => {
    expensesAtom.forEach(function (item, i) {
      if (item) {
        setData((items) => {
          const existingItemIndex = items.findIndex(x => x.id === item.id);
          if (existingItemIndex >= 0) {
            // If the item exists, update it
            const updatedItems = [...items];
            updatedItems[existingItemIndex] = {
              ...updatedItems[existingItemIndex],
              type: "expense",
              company: item.vendor_company,
              payDate: JSON.stringify(new Date(item.pay_date)),
              invoiceNr: item.invoice_number,
              category: item.invoice_type,
              netInvoice: 0,
              vatInvoice: 0,
              grossInvoice: 0,
              charge: item.charge,
              netExpense: item.charge === "100" ? item.net_value : item.net_value / 100 * parseFloat(item.charge),
              vatExpense: item.charge === "100" ? item.vat_amount : item.vat_amount / 100 * parseFloat(item.charge),
              grossExpense: item.charge === "100" ? item.gross_value : item.gross_value / 100 * parseFloat(item.charge),
              id: item.id,
              blob: (item.pdfFile === "" || item.pdfFile === undefined || item.pdfFile === null) ? item.url : item.pdfFile,
              filename: item.fileName,
              blobtype: (item.pdfFile === "" || item.pdfFile === undefined || item.pdfFile === null) ? "image" : "pdf"
            };
            return updatedItems;
          } else {
            // If the item does not exist, add as a new item
            return [
              ...items,
              {
                type: "expense",
                company: item.vendor_company,
                payDate: JSON.stringify(new Date(item.pay_date)),
                invoiceNr: item.invoice_number,
                category: item.invoice_type,
                netInvoice: 0,
                vatInvoice: 0,
                grossInvoice: 0,
                charge: item.charge,
                netExpense: item.charge === "100" ? item.net_value : item.net_value / 100 * parseFloat(item.charge),
                vatExpense: item.charge === "100" ? item.vat_amount : item.vat_amount / 100 * parseFloat(item.charge),
                grossExpense: item.charge === "100" ? item.gross_value : item.gross_value / 100 * parseFloat(item.charge),
                id: item.id,
                blob: (item.pdfFile === "" || item.pdfFile === undefined || item.pdfFile === null) ? item.url : item.pdfFile,
                filename: item.fileName,
                blobtype: (item.pdfFile === "" || item.pdfFile === undefined || item.pdfFile === null) ? "image" : "pdf"
              },
            ];
          }
        });
      }
    });
  }, [expensesAtom]);

  useEffect(() => {
    let newData = data.sort((a, b) => {
      return new Date(JSON.parse(a.payDate)) - new Date(JSON.parse(b.payDate))
    });
    setNewData(newData);
  }, [data]);

  console.log(data)
  console.log(newData)

  useEffect(() => {
    const dateRangeFilteredEntries = data.filter(entry => {
      return new Date(JSON.parse(entry.payDate)) >= new Date(settingsAtom.dateRangeStartValue) && new Date(JSON.parse(entry.payDate)) <= new Date(settingsAtom.dateRangeEndValue); // Filter out entries not within the date range
    });
    setNewData(dateRangeFilteredEntries);
  }, [data, settingsAtom]);

  useEffect(() => {
    let netRevenue = _.sumBy(newData, item => (item.type === "invoice") && Number(item.netInvoice))
    let vatRevenue = _.sumBy(newData, item => (item.type === "invoice") && Number(item.vatInvoice))
    let grossRevenue = _.sumBy(newData, item => (item.type === "invoice") && Number(item.grossInvoice))
    let netCosts = _.sumBy(newData, item => (item.type === "expense") && Number(item.netExpense))
    let vatCosts = _.sumBy(newData, item => (item.type === "expense") && Number(item.vatExpense))
    let grossCosts = _.sumBy(newData, item => (item.type === "expense") && Number(item.grossExpense))
    setSums({
      ...sums,
      netRevenue: netRevenue === false ? 0 : netRevenue,
      vatRevenue: vatRevenue === false ? 0 : vatRevenue,
      grossRevenue: grossRevenue === false ? 0 : grossRevenue,
      netCosts: netCosts === false ? 0 : netCosts,
      vatCosts: vatCosts === false ? 0 : vatCosts,
      grossCosts: grossCosts === false ? 0 : grossCosts,
    })
  }, [newData]);

  const escapeCSVField = (field) => {
    if (field === null || field === undefined) {
      return '""';  // Represent null or undefined as empty string in CSV
    }
    // Convert the field to string if it isn't already
    const string = field.toString();
    // Escape double quotes with two double quotes (standard CSV escaping)
    const escaped = string.replace(/"/g, '""');
    // Wrap in double quotes and return
    return `"${escaped}"`;
  };

  const convertValue = (key, field) => {
    const numericFields = ['netInvoice', 'vatInvoice', 'grossInvoice', 'charge', 'netExpense', 'vatExpense', 'grossExpense'];

    if (key === 'type') {
      return field === 'invoice' ? 'Einnahme' : 'Ausgabe';
    }

    if (key === 'payDate') {
      // Remove any extra quotation marks and whitespace that might confuse the Date parser
      const cleanField = field.replace(/["\s]+/g, '');
      const date = new Date(cleanField);
      if (!isNaN(date)) {
        const formattedDate = `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
        return escapeCSVField(formattedDate);
      } else {
        // If parsing fails, return a default or empty string
        return escapeCSVField('Datum ungültig');
      }
    }

    if (Array.isArray(field)) {
      return escapeCSVField(field.join('')); // Concatenate array elements directly
    }

    if (numericFields.includes(key)) {
      const number = parseFloat(field);
      return escapeCSVField(isNaN(number) ? '0,00' : number.toFixed(2).replace('.', ','));
    }

    return escapeCSVField(field);
  };

  const downloadCSV = () => {
    const transactions = newData; // newData should be your data array
    const headers = {
      nr: "Nr.",
      type: "Typ",
      payDate: "Bezahldatum",
      company: "Unternehmen",
      invoiceNr: "R-Nr.",
      category: "Kategorie",
      netInvoice: "Einnahmen netto",
      vatInvoice: "USt.",
      grossInvoice: "Einnahmen brutto",
      charge: "Anteil in %",
      netExpense: "Ausgaben netto",
      vatExpense: "VSt.",
      grossExpense: "Ausgaben brutto"
    };

    // Sum variables
    let totalNetInvoice = 0;
    let totalVatInvoice = 0;
    let totalGrossInvoice = 0;
    let totalNetExpense = 0;
    let totalVatExpense = 0;
    let totalGrossExpense = 0;

    // Create header row from the headers object
    const headerTitles = Object.keys(headers).map(key => `"${headers[key]}"`);
    const csvRows = [
      `"Einnahmen-Ausgaben-Rechnung"`,
      `Unternehmen: ${userAtom.data.company}`,
      `"Zeitraum"`,
      headerTitles.join(',')
    ];

    // Generate each row and calculate sums
    transactions.forEach((transaction, index) => {
      const rowNumber = index + 100;
      const values = [
        rowNumber,
        convertValue('type', transaction.type),
        convertValue('payDate', transaction.payDate),
        convertValue('company', transaction.company),
        convertValue('invoiceNr', transaction.invoiceNr),
        convertValue('category', transaction.category),
        convertValue('netInvoice', transaction.netInvoice),
        convertValue('vatInvoice', transaction.vatInvoice),
        convertValue('grossInvoice', transaction.grossInvoice),
        convertValue('charge', transaction.charge),
        convertValue('netExpense', transaction.netExpense),
        convertValue('vatExpense', transaction.vatExpense),
        convertValue('grossExpense', transaction.grossExpense)
      ];

      // Sum calculations
      totalNetInvoice += parseFloat(transaction.netInvoice);
      totalVatInvoice += parseFloat(transaction.vatInvoice);
      totalGrossInvoice += parseFloat(transaction.grossInvoice);
      totalNetExpense += parseFloat(transaction.netExpense);
      totalVatExpense += parseFloat(transaction.vatExpense);
      totalGrossExpense += parseFloat(transaction.grossExpense);

      csvRows.push(values.join(','));
    });

    // Append the total row
    const totalValues = [
      '""', // Empty to align with the "Nr." column
      '""', // Empty for 'Typ'
      '""', // Empty for 'Bezahldatum'
      '""', // Empty for 'Unternehmen'
      '""', // Empty for 'R-Nr.'
      '""', // Empty for 'Kategorie'
      `"${totalNetInvoice.toFixed(2).replace('.', ',')}"`, // Total for 'Einnahmen netto'
      `"${totalVatInvoice.toFixed(2).replace('.', ',')}"`, // Total for 'USt.'
      `"${totalGrossInvoice.toFixed(2).replace('.', ',')}"`, // Total for 'Einnahmen brutto'
      '""', // Empty for 'Anteil in %'
      `"${totalNetExpense.toFixed(2).replace('.', ',')}"`, // Total for 'Ausgaben netto'
      `"${totalVatExpense.toFixed(2).replace('.', ',')}"`, // Total for 'VSt.'
      `"${totalGrossExpense.toFixed(2).replace('.', ',')}"` // Total for 'Ausgaben brutto'
    ];

    csvRows.push(totalValues.join(','));

    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'transactions.csv');
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    if (!isNaN(date.getTime())) {
      // Properly format the date
      return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
    } else {
      return ''; // Return empty string if date is invalid
    }
  };




  const exportDataToPDF = () => {
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: 'a4'
    });

    // Extract the image data from userAtom
    const imageBase64 = userAtom.data.logo; // This is your conditional Base64 string

    // Check if the image is valid
    const isValidImage = imageBase64 && !["", "undefined", "/", "/#", null, undefined].includes(imageBase64);

    if (isValidImage) {
      const img = new Image();
      img.onload = function () {
        const originalWidth = img.width;
        const originalHeight = img.height;
        const maxWidth = 200;
        const maxHeight = (originalHeight / originalWidth) * maxWidth;
        const widthInMm = (maxWidth / 96) * 25.4;
        const heightInMm = (maxHeight / 96) * 25.4;
        const imageX = (297 - widthInMm) / 2;
        const totalContentHeight = heightInMm + 20 + 10;
        const startY = (210 - totalContentHeight) / 2 - 25;

        // Add image
        doc.addImage(imageBase64, 'JPEG', imageX, startY, widthInMm, heightInMm);

        // Add title and subtitle after the image
        addCoverText(doc, startY + heightInMm);
      };
      img.onerror = function () {
        // Handle image load error
        addCoverText(doc, 50); // Adjust startY for text when no image is loaded
      };
      img.src = imageBase64;
    } else {
      // Skip image and just add text
      addCoverText(doc, 50); // Provide startY directly for text
    }
  };


  let sumNetInvoice = 0;
  let sumVatInvoice = 0;
  let sumGrossInvoice = 0;
  let sumNetExpense = 0;
  let sumVatExpense = 0;
  let sumGrossExpense = 0;
  let rows;

  function addCoverText(doc, titleStartY) {
    const transactions = newData; // Ensure newData contains your data array

    rows = transactions.map((transaction, index) => {
      sumNetInvoice += parseFloat(transaction.netInvoice) || 0;
      sumVatInvoice += parseFloat(transaction.vatInvoice) || 0;
      sumGrossInvoice += parseFloat(transaction.grossInvoice) || 0;
      sumNetExpense += parseFloat(transaction.netExpense) || 0;
      sumVatExpense += parseFloat(transaction.vatExpense) || 0;
      sumGrossExpense += parseFloat(transaction.grossExpense) || 0;

      return {
        nr: index + 100,
        type: transaction.type === 'invoice' ? 'Einnahme' : 'Ausgabe',
        payDate: formatDate(JSON.parse(transaction.payDate)),
        company: transaction.company,
        invoiceNr: Array.isArray(transaction.invoiceNr) ? transaction.invoiceNr.join('') : transaction.invoiceNr,
        category: transaction.category,
        netInvoice: transaction.netInvoice === 0 ? "" : parseFloat(transaction.netInvoice).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        vatInvoice: transaction.vatInvoice === 0 ? "" : parseFloat(transaction.vatInvoice).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        grossInvoice: transaction.grossInvoice === 0 ? "" : parseFloat(transaction.grossInvoice).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        charge: transaction.charge === "0" ? "" : parseFloat(transaction.charge).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        netExpense: transaction.netExpense === 0 ? "" : parseFloat(transaction.netExpense).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        vatExpense: transaction.vatExpense === 0 ? "" : parseFloat(transaction.vatExpense).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        grossExpense: transaction.grossExpense === 0 ? "" : parseFloat(transaction.grossExpense).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      };
    });

    const titleY = titleStartY + 14;
    const subtitleY = titleY + 14;
    const subtitleY1 = subtitleY + 5;
    const tableY = subtitleY1 + 10;
    doc.setFontSize(18);
    doc.text('Einnahmen-Ausgaben-Rechnung', 148, titleY, { align: 'center' });
    doc.setFontSize(14);
    doc.text(`${userAtom.data.company}`, 148, subtitleY, { align: 'center' });
    doc.setFontSize(8);
    doc.text(`${userAtom.data.street} ${userAtom.data.streetNr}, ${userAtom.data.zip} ${userAtom.data.city}, ${JSON.parse(userAtom.data.country).label}${userAtom.data.fbnr !== "" ? ", FN " + userAtom.data.fbnr : ""}`, 148, subtitleY1, { align: 'center' });
    // doc.text(`€ ${sumNetInvoice.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`, 148, subtitleY2, { align: 'center' });
    doc.autoTable({
      columns: [
        { header: "Einnahmen", dataKey: "Einnahmen" },
        { header: "Ausgaben", dataKey: "Ausgaben" },
        {
          header: `${(sumVatInvoice > sumVatExpense ? "USt. Zahllast" : "VSt. Überschuss")
            }`, dataKey: "UST-Zahllast"
        },
        {
          header: `${(sumNetInvoice > sumNetExpense ? "Gewinn" : "Verlust")
            }`, dataKey: "Gewinn"
        }
      ],
      body: [
        { // First row
          Einnahmen: `Netto: € ${sumNetInvoice.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
          Ausgaben: `Netto: € ${sumNetExpense.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
          "UST-Zahllast": `€ ${(sumVatInvoice - sumVatExpense).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
          Gewinn: `€ ${(sumNetInvoice - sumNetExpense).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
        },
        {
          // Second row
          Einnahmen: `USt.: € ${sumVatInvoice.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
          Ausgaben: `VSt.: € ${sumVatExpense.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
          "UST-Zahllast":
            "",
          Gewinn: ""
        },
        { // Third row
          Einnahmen: `Brutto: € ${sumGrossInvoice.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
          Ausgaben: `Brutto: € ${sumGrossExpense.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
          "UST-Zahllast":
            "",
          Gewinn: ""
        }
      ],
      startY: tableY,
      styles: { fontSize: 8 },
      didParseCell: function (data) {
        // Check if the cell is a header and apply specific background colors
        if (data.row.section === 'head') {
          if (data.column.dataKey === 'Einnahmen') {
            data.cell.styles.fillColor = [0, 191, 114]; // RGB for light green
          } else if (data.column.dataKey === 'Ausgaben') {
            data.cell.styles.fillColor = [255, 121, 121]; // RGB for light red
          } else if (data.column.dataKey === 'UST-Zahllast') {
            data.cell.styles.fillColor = (sumVatInvoice > sumVatExpense) ? [255, 0, 0] : [0, 128, 0];
          } else if (data.column.dataKey === 'Gewinn') {
            data.cell.styles.fillColor = (sumNetInvoice < sumNetExpense) ? [255, 0, 0] : [0, 128, 0];
          }
        }
        else {
          // Ensuring body cells have a white background
          data.cell.styles.fillColor = [255, 255, 255]; // White background for body cells
        }
      }
    });



    // Proceed to add additional pages and content
    doc.addPage('landscape');
    setupAdditionalPages(doc);
  }

  function setupAdditionalPages(doc) {
    const transactions = newData; // Ensure newData contains your data array

    const columns = [
      { header: 'Nr.', dataKey: 'nr' },
      { header: 'Typ', dataKey: 'type' },
      { header: 'Bezahldatum', dataKey: 'payDate' },
      { header: 'Unternehmen', dataKey: 'company' },
      { header: 'R-Nr.', dataKey: 'invoiceNr' },
      { header: 'Kategorie', dataKey: 'category' },
      { header: 'Einnahmen netto', dataKey: 'netInvoice' },
      { header: 'USt.', dataKey: 'vatInvoice' },
      { header: 'Einnahmen brutto', dataKey: 'grossInvoice' },
      { header: 'Anteil in %', dataKey: 'charge' },
      { header: 'Ausgaben netto', dataKey: 'netExpense' },
      { header: 'VSt.', dataKey: 'vatExpense' },
      { header: 'Ausgaben brutto', dataKey: 'grossExpense' }
    ];

    // Append a total row at the end
    rows.push({
      nr: 'Gesamt',
      type: '',
      payDate: '',
      company: '',
      invoiceNr: '',
      category: '',
      netInvoice: sumNetInvoice.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      vatInvoice: sumVatInvoice.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      grossInvoice: sumGrossInvoice.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      charge: '',
      netExpense: sumNetExpense.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      vatExpense: sumVatExpense.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      grossExpense: sumGrossExpense.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    });


    // Define column styles for right alignment
    const rightAlignedColumns = {
      netInvoice: { halign: 'right', },
      vatInvoice: { halign: 'right' },
      grossInvoice: { halign: 'right' },
      charge: { halign: 'right' },
      netExpense: { halign: 'right' },
      vatExpense: { halign: 'right' },
      grossExpense: { halign: 'right' }
    };

    doc.autoTable({
      columns: columns,
      body: rows,
      didParseCell: (data) => {
        if (data.row.index === rows.length - 1) {
          // Apply bold style only to the last row
          data.cell.styles.fontStyle = 'bold';
        }
        if (data.section === 'head') {
          switch (data.column.dataKey) {
            case 'netInvoice':
            case 'vatInvoice':
            case 'grossInvoice':
              data.cell.styles.fillColor = [0, 191, 114]; // Light green
              break;
            case 'netExpense':
            case 'vatExpense':
            case 'grossExpense':
              data.cell.styles.fillColor = [255, 121, 121]; // Light red
              break;
            case 'charge':
              data.cell.styles.fillColor = [255, 204, 204]; // Light pink
              data.cell.styles.textColor = [0, 0, 0];
              break;
            default:
              data.cell.styles.fillColor = [232, 236, 255];
              data.cell.styles.textColor = [0, 0, 0];
          }
        }
        if (data.row.section === 'body') { // Apply styles only to body cells
          if (['netInvoice', 'vatInvoice', 'grossInvoice'].includes(data.column.dataKey)) {
            data.cell.styles.textColor = [34, 139, 34]; // Forest Green
          }
          if (['netExpense', 'vatExpense', 'grossExpense'].includes(data.column.dataKey)) {
            data.cell.styles.textColor = [255, 0, 0]; // Red
          }
        }
      },
      margin: { top: 20 },
      didDrawPage: function (data) {
        if (doc.internal.getNumberOfPages() > 1) {
          // Footer settings
          const pageSize = doc.internal.pageSize;
          const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
          // Left side of the footer
          doc.setFontSize(8);
          doc.text(`Einnahmen-Ausgaben-Rechnung: ${userAtom.data.company} | Beträge in €`, data.settings.margin.left, pageHeight - 10);
        }
      },

      theme: 'grid',
      styles: { fontSize: 8 },
      columnStyles: rightAlignedColumns,
    });

    // Right side of the footer
    const totalPages = doc.internal.getNumberOfPages() - 1; // Subtract one to exclude the cover page
    for (let i = 2; i <= doc.internal.getNumberOfPages(); i++) {
      doc.setPage(i);
      const pageHeight = doc.internal.pageSize.getHeight();
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageInfo = (i - 1) + ' / ' + totalPages; // Correct page numbering
      doc.setFontSize(8);
      doc.text(pageInfo, pageWidth - 15, pageHeight - 10, { align: 'right' }); // Redraw the correct footer
    }

    // Save the PDF
    doc.save('einnahmen-ausgaben-rechnung.pdf');
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);

    console.log(event.currentTarget)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div className={classes.toolbar} />
      <Grid container className="subpage-header" maxWidth={false} item direction="row" alignItems="center" alignContent="center">
        <Grid item container alignItems="center" style={{ marginRight: "10px" }}>
          <HorizontalSplitIcon color="primary" fontSize="large" style={{ background: "linear-gradient(to right top, rgb(38, 108, 189), rgb(36, 115, 192), rgb(33, 150, 243), #00bca7, #00bf72)", marginRight: "15px" }} className="icon--with-bg--1" />
          <Typography variant="body1" component="div" style={{ fontWeight: "500" }} className="headline">Einnahmen-Ausgaben-Rechnung</Typography>
        </Grid>

        <Grid item container justifyContent="space-between">
          <Grid item container alignItems="center" style={{ padding: "0px 2px 0px 0px", borderRadius: "6px", marginTop: "10px", width: "auto", boxShadow: "inset rgb(108 116 173 / 12%) 0px 0px 20px 0px" }}>
            <DateRange />

            <IconButton aria-controls="download" aria-haspopup="true" onClick={handleClick} aria-label="download" style={{ zoom: "0.8", color: "inherit" }}>
              <SaveAltIcon />
            </IconButton>

            <Menu
              id="download"
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              PaperProps={{
                elevation: 1,
              }}
              anchorOrigin={{
                vertical: 'bottom',  // Align the top of the menu with the bottom of the button
                horizontal: 'right', // Align the right of the menu with the right of the button
              }}
              transformOrigin={{
                vertical: 'top',    // Transform the menu down from the top
                horizontal: 'right' // Transform the menu from the right
              }}
            >
                  <Typography variant="subtitle1" style={{ padding: '8px 16px' }}>Einnahmen-Ausgaben-Rechnung</Typography>

              <MenuItem
                style={{ fontSize: "13px", marginRight: "5px" }}
                onClick={
                  () => {
                    downloadCSV();
                    handleClose();
                  }
                }
              >
                <SaveAltIcon fontSize="small" style={{ marginRight: "5px" }} />CSV Download
              </MenuItem>

              <MenuItem
                style={{ fontSize: "13px", marginRight: "5px" }}
                onClick={
                  () => {
                    exportDataToPDF();
                    handleClose();
                  }
                }
              >
                <PdfPreviewIcon fontSize="small" style={{ marginRight: "5px" }} />PDF Download
              </MenuItem>

              <Divider style={{ margin: "10px 0"}} />

              <Typography variant="subtitle1" style={{ padding: '8px 16px 0px 16px' }}>Belege</Typography>

              <FormControlLabel
                style={{ padding: '0px 20px' }}
                control={
                  <Checkbox
                    checked={watermark}
                    onChange={()=> setWatermark(!watermark)}
                    name="watermark"
                    color="primary"
                  />
                }
                label={<Typography style={{ fontSize: '12px' }}>Nummerierung auf Belegen anzeigen</Typography>}
              />

              <MenuItem
                style={{ fontSize: "13px", marginRight: "5px" }}
                onClick={()=>DOWNLOAD_ZIP(newData, watermark)}
              >
                <SaveAltIcon  fontSize="small" style={{ marginRight: "5px" }} />ZIP Download (Einzelbelege)
              </MenuItem>

              <MenuItem
                style={{ fontSize: "13px", marginRight: "5px" }}
                onClick={()=>DOWNLOAD_SINGLEPDF(newData, watermark)}
              >
                 <PdfPreviewIcon fontSize="small" style={{ marginRight: "5px" }} />PDF Download (Alle Belege als Sammel-PDF)
              </MenuItem>
            </Menu>

            {isMobile &&
            <IconButton aria-controls="download" aria-haspopup="true" onClick={handleOpenSums} aria-label="download" style={{ zoom: "0.8", color: "inherit" }}>
              <AccountBalanceWalletIcon />
            </IconButton>
            }
            <Modal
              open={openSums}
              onClose={handleCloseSums}
              className={classes.modal}
            >
              <div className={classes.paper}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <IconButton onClick={handleCloseSums} className={classes.closeButton}>
                    <CloseIcon />
                  </IconButton>
                </div>

                <FinancialData sums={sums}/>
              </div>
            </Modal>
          </Grid>

          {!isMobile && <FinancialData sums={sums}/>}
        </Grid>
      </Grid>

      <Container maxWidth={false} disableGutters className="bg--1" >
        <Container maxWidth={false} >

          <Box display="flex"
            flexDirection="column"
            justifyContent="center"
            margin="0 25px 0 25px"
            className="h-gap--25"
          >

            {/* <Grid container maxWidth={false} item direction="row" alignItems="center" style={{ marginBottom: "20px" }}>
              <HorizontalSplitIcon color="primary" fontSize="large" style={{ background: "linear-gradient(to right top, #2196f3, #00a8f1, #00b5d6, #00bca7, #00bf72)", marginRight: "5px" }} className="icon--with-bg--1" />
              <Typography variant="h5" component="h1">Einnahmen-Ausgaben-Rechnung</Typography>
            </Grid> */}
          </Box>

          <Box display="flex"
            flexDirection="column"
            justifyContent="center"
            margin="0 25px 125px 25px"
            className="h-gap--25"
          >
            <Box
              display="flex"
              flexDirection="column"
              padding="25px 0px"
              className="h-gap--20"
            >

                <TableContainer style={{ overflowX: "initial" }} className={classes.container}>
                  <Table stickyHeader aria-label="sticky table" className={classes.table}>
                    <TableHead className={classes.head1}>
                      <TableRow>
                        <TableCell >Nr.</TableCell>
                        <TableCell align="left">Zahldatum</TableCell>
                        <TableCell align="left">Kunde/Lieferant</TableCell>
                        <TableCell align="left">Rechnungsnummer</TableCell>
                        <TableCell align="left">Kategorie</TableCell>
                        <TableCell align="center" style={{ color: "rgb(0, 191, 114)" }}>Netto</TableCell>
                        <TableCell align="center" style={{ color: "rgb(0, 191, 114)" }}>USt.</TableCell>
                        <TableCell align="center" style={{ color: "rgb(0, 191, 114)" }}>Brutto</TableCell>
                        <TableCell align="center" style={{ color: "rgb(201 157 157)"}}>Anteil</TableCell>
                        <TableCell align="center" style={{ color: "rgb(255, 121, 121)" }}>Netto</TableCell>
                        <TableCell align="center" style={{ color: "rgb(255, 121, 121)" }}>VSt.</TableCell>
                        <TableCell align="center" style={{ color: "rgb(255, 121, 121)" }}>Brutto</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {newData.map((item, i) => (
                        <TableRow key={i}
                          className="entry"
                          onClick={() => {
                            item.type === "invoice" ? 
                              setOpenInvoiceDialogAtom({
                                ...openInvoiceDialogAtom,
                                duplicate: false,
                                open: true,
                                invoiceId: item.id,
                                isEstimate: false
                              }) : 
                              setOpenExpenseDialogAtom({
                                ...openExpenseDialogAtom,
                                duplicate: false,
                                open: true,
                                expenseId: item.id
                              });
                          }}
                        >
                          <TableCell style={{ borderLeft: item.type === "invoice" ? "5px solid #00bf72" : "5px solid #ff7979" }} align="left" >{i + 100}</TableCell>
                          <TableCell align="left" >{RETURN_DATE(JSON.parse(item.payDate))}</TableCell>
                          <TableCell align="left"><span className="overflow">{item.company}</span></TableCell>
                          <TableCell align="left">{item.invoiceNr}</TableCell>
                          <TableCell align="left">{item.category}</TableCell>
                          <TableCell align="right">{item.type === "invoice" && parseFloat(item.netInvoice).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                          <TableCell align="right">{item.type === "invoice" && parseFloat(item.vatInvoice).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                          <TableCell align="right">{item.type === "invoice" && parseFloat(item.grossInvoice).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                          <TableCell align="right">{item.type === "expense" && `${item.charge}%`}</TableCell>
                          <TableCell align="right">{item.type === "expense" && parseFloat(item.netExpense).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                          <TableCell align="right">{item.type === "expense" && parseFloat(item.vatExpense).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                          <TableCell align="right">{item.type === "expense" && parseFloat(item.grossExpense).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                          {/* <TableCell align="right">{row.carbs}</TableCell>
                        <TableCell align="right">{row.protein}</TableCell> */}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={newData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}

            </Box>
          </Box>
        </Container>
      </Container>
    </div>
  );
};

export default Balance;

// import React, { useState } from "react";

// // IMPORT hooks & context
// import {
//     NavLink
// } from "react-router-dom";
// import { useAuth } from "./shared/hooks/auth-hook";
// import { useTranslation } from "react-i18next";

// // IMPORT recoil
// import {
//     useRecoilState,
// } from 'recoil';
// import loggedInAtomState from "./_atoms/loggedInAtom";
// import userAtomState from "./_atoms/userAtom";
// import clientsAtomState from "./_atoms/clientsAtom";
// import checkoutAtomState from "./_atoms/checkoutAtom";
// import selectedMenuItemAtomState from "./_atoms/selectedMenuItemAtom";
// import subMenuOpenAtomState from "./_atoms/subMenuOpenAtom";
// import subMenuInvoicesOpenAtomState from "./_atoms/subMenuInvoicesOpenAtom";
// import openCheckoutAtomState from "./_atoms/openCheckoutAtom";
// import openClientDialogAtomState from "./_atoms/openClientDialogAtom";
// import openInvoiceDialogAtomState from "./_atoms/openInvoiceDialogAtom";
// import openInvoiceRecurringDialogAtomState from "./_atoms/openInvoiceRecurringDialogAtom";
// import openExpenseDialogAtomState from "./_atoms/openExpenseDialogAtom";
// import openInvoiceDesignerDialogAtomState from "./_atoms/openInvoiceDesignerDialogAtom";

// // IMPORT components
// import LogoWhite from "./_assets/logo.svg";
// import LogoMonogram from './_assets/logo_monogram.svg'

// // IMPORT components MATERIAL UI
// import {
//     Typography,
//     Icon,
//     Drawer,
//     List,
//     Divider,
//     ListItem,
//     ListItemIcon,
//     ListItemText,
//     ListItemSecondaryAction,
//     Collapse,
//     AppBar,
//     Hidden,
//     IconButton,
//     Toolbar,
//     Badge,
//     Grid,
//     Menu,
//     MenuItem,
//     Box,
//     Tooltip
// } from "@material-ui/core";
// import Backdrop from '@material-ui/core/Backdrop';
// import CircularProgress from '@material-ui/core/CircularProgress';

// // IMPORT icons MATERIAL UI
// import DashboardIcon from "@material-ui/icons/Dashboard";
// import ClientsIcon from "@material-ui/icons/RecentActors";
// import AccountCircle from "@material-ui/icons/AccountCircle";
// import LogoutIcon from '@material-ui/icons/ExitToApp';
// import SettingsIcon from '@material-ui/icons/Settings';
// import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
// import ExpandLess from "@material-ui/icons/ExpandLess";
// import ExpandMore from "@material-ui/icons/ExpandMore";
// import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
// import CloseIcon from "@material-ui/icons/Close";
// import MenuIcon from '@material-ui/icons/Menu';
// import AddCircleIcon from '@material-ui/icons/AddCircle';
// import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
// import CreditCardIcon from '@material-ui/icons/CreditCard';
// import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';
// import GavelIcon from '@material-ui/icons/Gavel';
// import NotificationsIcon from '@material-ui/icons/Notifications';
// import HomeIcon from '@material-ui/icons/Home';
// import BrushIcon from '@material-ui/icons/Brush';
// import RepeatIcon from '@material-ui/icons/Repeat';
// import AnnouncementIcon from '@material-ui/icons/Announcement';

// // IMPORT own components
// import Promo from "./shared/components/UIElements/Promo";


// // IMPORT theme MATERIAL UI and custom scss
// import { makeStyles } from "@material-ui/core/styles";
// import { useTheme } from '@material-ui/core/styles';

// import "./AppDrawer.scss"
// import BrushRounded from "@material-ui/icons/BrushRounded";

// const drawerWidth = 340;
// const useStyles = makeStyles((theme) => ({
//     backdrop: {
//         zIndex: theme.zIndex.drawer + 1,
//         color: '#fff',
//       },
//     root: {
//         display: 'flex',
//     },
//     drawer: {
//         [theme.breakpoints.up('md')]: {
//             width: drawerWidth,
//             flexShrink: 0,
//         },
//     },
//     appBar: {
//         background: "linear-gradient(to right top, rgb(31, 89, 177), rgb(40, 93, 163), rgb(38, 108, 189), rgb(36, 115, 192), rgb(33, 150, 243))",
//         [theme.breakpoints.up('md')]: {
//             width: `calc(100% - ${drawerWidth}px)`,
//             marginLeft: drawerWidth,
//             display: "none"
//         },
//         alignItems: "flex-start"
//     },
//     menuButton: {
//         marginRight: theme.spacing(2),
//         [theme.breakpoints.up('md')]: {
//             display: 'none',
//         },
//     },
//     closeIcon: {
//         display: "flex",
//         flexDirection: "row",
//         justifyContent: "flex-end",
//         [theme.breakpoints.up('md')]: {
//             display: 'none',
//         },
//         position: "absolute",
//         top: "65px",
//         left: "20px",
//         marginRight: "5px",
//         zoom: 0.65,
//     },
//     drawerPaper: {
//         width: "100vw",
//         [theme.breakpoints.up('md')]: {
//             width: drawerWidth,
//         },
//         // background: "#263238",
//         // color: "#ffffff",
//         boxShadow: "0px 0px 4px rgba(200,201,207,0.8)",
//         color: "#000000",
//         borderRight: "0px solid transparent"
//     },
//     content: {
//         flexGrow: 1,
//         padding: theme.spacing(3),
//     },
//     nested: {
//         paddingTop: 0,
//         paddingBottom: 0,
//         paddingLeft: theme.spacing(4)
//     },
//     customBadge: {
//         backgroundColor: '#00bf72',
//         color: 'white',
//     },
//     menuHeader: {
//         display: 'flex',
//         justifyContent: 'space-between',
//         alignItems: 'center',
//         padding: theme.spacing(1, 2),
//         borderBottom: `1px solid ${theme.palette.divider}`,
//     },
//     customMenu: {
//     boxShadow: '0 24px 64px rgba(84, 94, 165, 0.1019607843)',
//     [theme.breakpoints.down('xs')]: {
//         width: '100vw',
//             height: '100vh',
//             maxWidth: '100vw',
//             maxHeight: '100vh',
//             top: '0 !important',
//             left: '0 !important',
//             margin: '0 !important',
//             borderRadius: '0 !important',
//         },
//     },
//     menuContent: {
//     [theme.breakpoints.down('xs')]: {
//         height: 'calc(100vh - 56px)', // Adjust this if you have a different header height
//         overflowY: 'auto',
//         },
//     },
//     iconButton: {
//         color: 'white',
//         '&:hover': {
//           color: '#b3deff',
//         },
//       },
//     yellow: {
//         color: '#2196f3',
//     },
//     notSelected: {
//         color: 'white',
//     },
// }));

// const AppDrawer = (props) => {
//     // HOOKS & CONTEXT
//     const { t } = useTranslation();
//     const theme = useTheme();
//     const classes = useStyles();
//     const {
//         logout,
//     } = useAuth();

//     // GLOBAL STATE (RECOIL)
//     const [userAtom] = useRecoilState(userAtomState);
//     const [loggedInAtom] = useRecoilState(loggedInAtomState);
//     const [clientsAtom, setClientsAtom] = useRecoilState(clientsAtomState);
//     const [selectedMenuItemAtom, setSelectedMenuItemAtom] = useRecoilState(selectedMenuItemAtomState);
//     const [subMenuOpenAtom, setSubMenuOpenAtom] = useRecoilState(subMenuOpenAtomState);
//     const [subMenuInvoicesOpenAtom, setSubMenuInvoicesOpenAtom] = useRecoilState(subMenuInvoicesOpenAtomState);
//     const [, setOpenCheckoutAtom] = useRecoilState(openCheckoutAtomState);
//     const [checkoutAtom, setCheckoutAtom] = useRecoilState(checkoutAtomState);
//     const [openClientDialogAtom, setOpenClientDialogAtom] = useRecoilState(openClientDialogAtomState);
//     const [openInvoiceDialogAtom, setOpenInvoiceDialogAtom] = useRecoilState(openInvoiceDialogAtomState);
//     const [openInvoiceRecurringDialogAtom, setOpenInvoiceRecurringDialogAtom] = useRecoilState(openInvoiceRecurringDialogAtomState);
//     const [openExpenseDialogAtom, setOpenExpenseDialogAtom] = useRecoilState(openExpenseDialogAtomState);

//     // LOCAL STATE
//     const { window } = props;
//     const [mobileOpen, setMobileOpen] = useState(false);
//     const [showBackdrop, setShowBackdrop] = useState(false);

//     /* 
//       FUNCTIONS
//     */
//     const handleListItemClick = (e, i) => {
//         setSelectedMenuItemAtom(i);
//         if(i !== 3 && i !== 5) {
//             setMobileOpen(false);
//         }
//         if(i !== 3 && i !== 31 && i !== 32 && i !== 33) {
//             setSubMenuInvoicesOpenAtom(false);
//         }
//         if (i !== 5 && i !== 51 && i !== 52 && i !== 53) {
//             setSubMenuOpenAtom(false);
//         }
//     };

//     const handleOpenSubmenu = () => {
//         setSubMenuOpenAtom(!subMenuOpenAtom);
//     };

//     const handleOpenSubmenuInvoices = () => {
//         setSubMenuInvoicesOpenAtom(!subMenuInvoicesOpenAtom);
//     };

//     const handleDrawerToggle = () => {
//         setMobileOpen(!mobileOpen);
//     };


//     const [anchorEl, setAnchorEl] = useState(null);

//     const handleMenuOpen = (event) => {
//       setAnchorEl(event.currentTarget);
//     };
  
//     const handleMenuClose = () => {
//       setAnchorEl(null);
//     };
  
//     const isMenuOpen = Boolean(anchorEl);

//     const drawer = (
//         <div>
//             <div className={classes.toolbar} />

//             <Divider />

//             <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "95vh" }}>
//                 <List>
//                     <React.Fragment>
//                         <div className={classes.closeIcon} >
//                             <IconButton onClick={() => setMobileOpen(false)} style={{ background: "rgba(240,240,240,0.2)", color: "white" }}>
//                                 <CloseIcon />
//                             </IconButton>
//                         </div>
                        
//                         <NavLink to={`/`}>
//                             <div style={{ textAlign: "center", marginTop: "-10px" }} onClick={(e) => {handleListItemClick(e, 100); setSubMenuOpenAtom(false)}}>
//                                 <div style={{ textAlign: "center", padding: "7px 7px", paddingBottom: "30px", background: "linear-gradient(to right top, rgb(31 89 177), rgb(40 93 163), rgb(38 108 189), rgb(36, 115, 192), rgb(33, 150, 243))", color: "#ffffff", fontSize: "12px" }}>
//                                     <div style={{ margin: "0 auto", padding: "20px", paddingBottom: "10px", display: "flex" }}>
//                                     <img
//                                         src={LogoWhite}
//                                         alt={`${t("APP.name")} Logo`}
//                                         style={{
//                                             maxHeight: "40px",
//                                             margin: "0 auto"
//                                         }}
//                                     />
//                                     </div>
//                                     {/* <span style={{fontSize: "8px", marginTop: "-10px"}}>v1.0 Alpha</span><br/> */}
//                                     <Tooltip title="Auf eine andere Version wechseln">
//                                     <span onClick={() => {setOpenCheckoutAtom(true); setCheckoutAtom({...checkoutAtom, show: userAtom.data.subscription === "basic" ? "PREMIUM_PRO" : userAtom.data.subscription === "premium" ? "BASIC_PRO" : "BASIC_PREMIUM"})}} style={{ fontSize: "10px" }}><strong>Deine Version: </strong><span className="version" style={{ borderRadius: "3px", paddingLeft: "5px", paddingRight: "5px", paddingTop: "2px", paddingBottom: "2px" }}><strong>Durchstarter</strong></span></span>                        
//                                     </Tooltip>
//                                 </div>
//                             </div>
//                         </NavLink>
                        

//                         <Grid container justifyContent="center" style={{ background: "#2c537d" }}>
//                             <NavLink to={`/`}>
//                                 <IconButton aria-label="notifications" className={`${classes.iconButton} ${selectedMenuItemAtom === 100 ? classes.yellow : classes.notSelected}`} onClick={(e) => {setSubMenuOpenAtom(false); handleListItemClick(e, 100) }}>
//                                     <svg width="24" height="24" viewBox="0 0 24 24" >
//                                         <HomeIcon />
//                                     </svg>
//                                 </IconButton>
//                             </NavLink>

//                             <NavLink to={`/profile`}>
//                                 <IconButton aria-label="profile" className={`${classes.iconButton} ${selectedMenuItemAtom === 5 ? classes.yellow : classes.notSelected}`} onClick={(e)=>{handleListItemClick(e, 5); setSubMenuOpenAtom(true)}}>
//                                     <svg width="24" height="24" viewBox="0 0 24 24">
//                                         <AccountCircle />
//                                     </svg>
//                                 </IconButton>
//                             </NavLink>

//                             <NavLink to={`/settings`}>
//                                 <IconButton aria-label="settings" className={`${classes.iconButton} ${selectedMenuItemAtom === 6 ? classes.yellow : classes.notSelected}`} onClick={(e)=>{handleListItemClick(e, 52); setSubMenuOpenAtom(true)}}>
//                                     <SettingsIcon />
//                                 </IconButton>
//                             </NavLink>

//                             <IconButton aria-label="notifications" className={`${classes.iconButton} ${classes.notSelected}`} onClick={(e) => {setSubMenuOpenAtom(false); handleMenuOpen(e); handleListItemClick(e, selectedMenuItemAtom)}}>
//                                 <Badge badgeContent={1} classes={{ badge: classes.customBadge }}>
//                                 <svg width="24" height="24" viewBox="0 0 24 24" >
//                                     <NotificationsIcon />
//                                 </svg>
//                                 </Badge>
//                             </IconButton>


//                             <Menu
//                                 anchorEl={anchorEl}
//                                 keepMounted
//                                 open={isMenuOpen}
//                                 onClose={handleMenuClose}
//                                 elevation={0}
//                                 classes={{ paper: classes.customMenu }}
//                             >
//                                 <Box className={classes.menuHeader}>
//                                     <Typography variant="body1" component="div" style={{ fontSize: "12px", fontWeight: "500", marginRight: "10px" }} >Benachrichtigungen</Typography>
//                                     <IconButton size="small" onClick={handleMenuClose}>
//                                         <CloseIcon />
//                                     </IconButton>
//                                 </Box>
//                                 <Box className={classes.menuContent} style={{ padding: "10px"}}>
//                                     {userAtom.data.signupCompleted && userAtom.data.subscription === "basic" &&
//                                     <Promo product="premium" />
//                                     }

//                                     {userAtom.data.signupCompleted && userAtom.data.subscription === "premium" &&
//                                     <Promo product="pro" />
//                                     }
//                                 </Box>
//                             </Menu>
//                         </Grid>
                        
//                     </React.Fragment>

//                     <Divider />

//                     {/* <NavLink to={`/cashboard`}>
//                         <ListItem
//                             button
//                             key={"cashboard"}
//                             selected={selectedMenuItemAtom === 0}
//                             onClick={(e) => handleListItemClick(e, 0)}
//                             style={{paddingTop: 4, paddingBottom: 4}}
//                         >
//                             <ListItemIcon>
//                                 <DashboardIcon color="primary" className="icon--with-bg--1"/>
//                             </ListItemIcon>
//                             <ListItemText>
//                                 <ListItemText primary={"Cashboard"} />
//                             </ListItemText>
//                         </ListItem>
//                     </NavLink> */}

//                     <Divider />

//                     <NavLink to={`/clients`}>
//                         <ListItem
//                             button
//                             key={"clients"}
//                             selected={selectedMenuItemAtom === 1}
//                             onClick={(e) => handleListItemClick(e, 1)}
//                             style={{paddingTop: 4, paddingBottom: 4}}
//                         >
//                             <ListItemIcon>
//                                 <ClientsIcon color="primary" className="icon--with-bg--1"/>
//                             </ListItemIcon>
//                             <ListItemText>
//                                 <ListItemText primary={t("DRAWER.clients")} />
//                             </ListItemText>

//                             <ListItemSecondaryAction>
//                                 <IconButton
//                                     className="iconbutton--add"
//                                     edge="end"
//                                     aria-label="clients"
//                                     onClick={(e)=>{
//                                         setOpenClientDialogAtom({
//                                         ...openClientDialogAtom,
//                                         open: true,
//                                         });
//                                         handleListItemClick(e, 1)
//                                     }}
//                                     style={{ padding: 6, marginRight: -8 }}

//                                 >
//                                     <AddCircleIcon />
//                                 </IconButton>
//                             </ListItemSecondaryAction>
//                         </ListItem>
//                     </NavLink>

                    
//                     {/* <NavLink to={`/estimates`}>
//                         <ListItem
//                             button
//                             key={"estimates"}
//                             selected={selectedMenuItemAtom === 10}
//                             onClick={(e) => handleListItemClick(e, 10)}
//                             style={{paddingTop: 4, paddingBottom: 4}}
//                         >
//                             <ListItemIcon>
//                                 <GavelIcon color="primary" className="icon--with-bg--1"/>
//                             </ListItemIcon>
//                             <ListItemText>
//                                 <ListItemText primary="Kostenvoranschläge" />
//                             </ListItemText>

//                             {loggedInAtom && userAtom.data.signupCompleted && clientsAtom.length !== 0 &&
//                             <ListItemSecondaryAction>
//                                 <IconButton
//                                     className="iconbutton--add"
//                                     edge="end"
//                                     aria-label="clients"
//                                     onClick={(e)=>{
//                                         setOpenInvoiceDialogAtom({
//                                         ...openInvoiceDialogAtom,
//                                         open: true,
//                                         isEstimate: true,
//                                         });
//                                         handleListItemClick(e, 10)
//                                     }}
//                                     style={{ padding: 6, marginRight: -8 }}
//                                 >
//                                     <AddCircleIcon />
//                                 </IconButton>
//                             </ListItemSecondaryAction>
//                             }
//                         </ListItem>
//                     </NavLink> */}

//                     <Divider />

//                     <NavLink to={`/invoices`}>
//                     <ListItem
//                         key={"invoices"}
//                         button
//                         style={{ paddingTop: "8px", paddingBottom: "8px"}}
//                         onClick={(e) => { handleOpenSubmenuInvoices(); handleListItemClick(e, 3) }}
//                         >
//                             <ListItemIcon>
//                                 <LibraryBooksIcon color="primary" className="icon--with-bg--1"/>
//                             </ListItemIcon>
//                             <ListItemText primary={t("DRAWER.invoices")}/>
                                                        
//                             {subMenuInvoicesOpenAtom ? <ExpandLess style={{marginRight: "-2px", color: "rgba(0, 0, 0, 0.54)"}}/> : <ExpandMore  style={{marginRight: "-2px", color: "rgba(0, 0, 0, 0.54)"}}/>}
                            
//                         </ListItem>
//                     </NavLink>

//                     <Collapse in={subMenuInvoicesOpenAtom} timeout="auto" unmountOnExit>
//                         <List component="div" disablePadding>
//                             <NavLink to={`/invoices`}>
//                                 <ListItem
//                                     button
//                                     key={"invoices-single"}
//                                     selected={selectedMenuItemAtom === 3 || selectedMenuItemAtom === 31}
//                                     onClick={(e) => handleListItemClick(e, 31)}
//                                     style={{marginLeft: "17px", paddingLeft: "35px"}}
//                                 >
//                                     <ListItemIcon style={{opacity: 0.7, zoom: 0.8}}>
//                                         <LibraryBooksIcon color="primary" />
//                                     </ListItemIcon>
//                                         <ListItemText primary="Einzelrechnungen" />
//                                     <ListItemSecondaryAction>
//                                 <IconButton
//                                     className="iconbutton--add"
//                                     edge="end"
//                                     aria-label="invoices"
//                                     onClick={(e)=>{
//                                         setOpenInvoiceDialogAtom({
//                                         ...openInvoiceDialogAtom,
//                                         open: true,
//                                         isEstimate: false,
//                                         recurring: false,
//                                         });
//                                         handleListItemClick(e, 3)
//                                     }}
//                                     style={{ padding: 6, marginRight: 15 }}
//                                 >
//                                     <AddCircleIcon />
//                                 </IconButton>
//                             </ListItemSecondaryAction>
//                                 </ListItem>
//                             </NavLink>

//                             <NavLink to={`/invoicesrecurrings`}>
//                                 <ListItem
//                                     button
//                                     key={"invoicesrecurrings"}
//                                     selected={selectedMenuItemAtom === 32}
//                                     onClick={(event) => handleListItemClick(event, 32)}
//                                     style={{marginLeft: "17px", paddingLeft: "35px"}}
//                                 >
//                                     <ListItemIcon style={{ opacity: 0.7, zoom: 0.8}}>
//                                         <RepeatIcon color="primary" />
//                                     </ListItemIcon>
//                                         <ListItemText primary="Wiederkehrend" />
//                                         <ListItemSecondaryAction>
//                                 <IconButton
//                                     className="iconbutton--add"
//                                     edge="end"
//                                     aria-label="invoices"
//                                     onClick={(e)=>{
//                                         setOpenInvoiceDialogAtom({
//                                             ...openInvoiceDialogAtom,
//                                             open: true,
//                                             isEstimate: false,
//                                             recurring: true,
//                                             });
//                                         handleListItemClick(e, 32)
//                                     }}
//                                     style={{ padding: 6, marginRight: 15 }}
//                                 >
//                                     <AddCircleIcon />
//                                 </IconButton>
//                             </ListItemSecondaryAction>
//                                 </ListItem>
//                             </NavLink>

//                             <NavLink to={`/reminders`}>
//                                 <ListItem
//                                     button
//                                     key={"reminders"}
//                                     selected={selectedMenuItemAtom === 33}
//                                     onClick={(e) => handleListItemClick(e, 33)}
//                                     style={{marginLeft: "17px", paddingLeft: "35px", marginBottom: "20px"}}
//                                 >
//                                     <ListItemIcon style={{ opacity: 0.7, zoom: 0.8}}>
//                                         <AnnouncementIcon color="primary" />
//                                     </ListItemIcon>
//                                     <ListItemText primary="Mahnungen" />
//                                 </ListItem>
//                             </NavLink>
//                         </List>
//                     </Collapse>

//                     {/* <NavLink to={`/expenses`}>
//                         <ListItem
//                             button
//                             key={"expenses"}
//                             selected={selectedMenuItemAtom === 8}
//                             onClick={(e) => handleListItemClick(e, 8)}
//                             style={{paddingTop: 4, paddingBottom: 4}}
//                         >
//                             <ListItemIcon>
//                                 <CreditCardIcon color="primary" className="icon--with-bg--1"/>
//                             </ListItemIcon>
//                             <ListItemText>
//                                 <ListItemText primary={t("DRAWER.expenses")} />
//                             </ListItemText>

//                             <ListItemSecondaryAction>
//                                 <IconButton
//                                     className="iconbutton--add"
//                                     edge="end"
//                                     aria-label="expenses"
//                                     onClick={(e)=>{
//                                         setOpenExpenseDialogAtom({
//                                         ...openExpenseDialogAtom,
//                                         open: true,
//                                         });
//                                         handleListItemClick(e, 8)
//                                     }}
//                                     style={{ padding: 6, marginRight: -8 }}
//                                 >
//                                     <AddCircleIcon />
//                                 </IconButton>
//                             </ListItemSecondaryAction>
//                         </ListItem>
//                     </NavLink> */}

//                     {/* <NavLink to={`/balance`}>
//                         <ListItem
//                             button
//                             key={"balance"}
//                             selected={selectedMenuItemAtom === 9}
//                             onClick={(e) => handleListItemClick(e, 9)}
//                             style={{paddingTop: 4, paddingBottom: 4}}
//                         >
//                             <ListItemIcon>
//                                 <HorizontalSplitIcon className="icon--with-bg--1"/>
//                             </ListItemIcon>
//                             <ListItemText>
//                                 <ListItemText primary="Einnahmen-Ausgaben-Rechnung" />
//                             </ListItemText>
//                         </ListItem>
//                     </NavLink> */}
                    
//                     <Divider />

//                     {/* <NavLink to={`/designer`}>
//                         <ListItem
//                             button
//                             key={"designer"}
//                             // selected={selectedMenuItemAtom === 9}
//                             // onClick={(e) => handleListItemClick(e, 9)}
//                             style={{paddingTop: 4, paddingBottom: 4}}
//                         >
//                             <ListItemIcon>
//                                 <BrushIcon className="icon--with-bg--1"/>
//                             </ListItemIcon>
//                                 <ListItemText primary="Designer" />
//                         </ListItem>
//                     </NavLink> */}

//                     <NavLink to={`/profile`}>
//                         <ListItem key={"mydagowert"} button style={{ paddingTop: "8px", paddingBottom: "8px" }}

//                             onClick={(e) => { handleOpenSubmenu(); handleListItemClick(e, 5) }}>
//                             {userAtom.data.logo !== undefined && userAtom.data.logo !== "" && userAtom.data.logo !== "/#" ?
//                             <div style={{ margin: "0 auto", display: "flex", marginRight: "10px", background: "#ffffff", borderRadius: "60px" }}>
//                                 <img
//                                     src={userAtom.data.logo}
//                                     alt={`${t("APP.name")} Logo`}
//                                     style={{
//                                         maxHeight: "15px",
//                                     }}
//                                 />
//                             </div>
//                             :
//                             <ListItemIcon>
//                                 <Icon >
//                                     <img src={LogoMonogram} height={25} width={25} alt={`${t("APP.name")} Logo`} />
//                                 </Icon>
//                             </ListItemIcon>
//                             }
//                             <ListItemText primary={t("DRAWER.my")} />
                            
//                             {subMenuOpenAtom ? <ExpandLess style={{marginRight: "-2px", color: "rgba(0, 0, 0, 0.54)"}}/> : <ExpandMore  style={{marginRight: "-2px", color: "rgba(0, 0, 0, 0.54)"}}/>}
//                         </ListItem>
//                     </NavLink>

//                     <Collapse in={subMenuOpenAtom} timeout="auto" unmountOnExit>
//                         <List component="div" disablePadding>
//                             <NavLink to={`/profile`}>
//                                 <ListItem
//                                     button
//                                     key={"profile"}
//                                     selected={selectedMenuItemAtom === 5 || selectedMenuItemAtom === 51}
//                                     onClick={(e) => handleListItemClick(e, 51)}
//                                     style={{marginLeft: "17px", paddingLeft: "35px"}}
//                                 >
//                                     <ListItemIcon style={{ opacity: 0.7, zoom: 0.8}}>
//                                         <AccountCircle color="primary" />
//                                     </ListItemIcon>
//                                         <ListItemText primary={t("DRAWER.profile")} />
//                                 </ListItem>
//                             </NavLink>

//                             <NavLink to={`/settings`}>
//                                 <ListItem
//                                     button
//                                     key={"settings"}
//                                     selected={selectedMenuItemAtom === 52}
//                                     onClick={(event) => handleListItemClick(event, 52)}
//                                     style={{marginLeft: "17px", paddingLeft: "35px"}}
//                                 >
//                                     <ListItemIcon style={{ opacity: 0.7, zoom: 0.8}}>
//                                         <SettingsIcon color="primary" />
//                                     </ListItemIcon>
//                                         <ListItemText primary={t("DRAWER.settings")} />
//                                 </ListItem>
//                             </NavLink>

//                             <NavLink to={`/account`}>
//                                 <ListItem
//                                     button
//                                     key={"account"}
//                                     selected={selectedMenuItemAtom === 53}
//                                     onClick={(e) => handleListItemClick(e, 53)}
//                                     style={{marginLeft: "17px", paddingLeft: "35px", marginBottom: "20px"}}
//                                 >
//                                     <ListItemIcon style={{ opacity: 0.7, zoom: 0.8}}>
//                                         <AccountBalanceWalletIcon color="primary" />
//                                     </ListItemIcon>
//                                         <ListItemText primary={t("DRAWER.account")} />
//                                 </ListItem>
//                             </NavLink>
//                         </List>
//                     </Collapse>
//                 </List>

                
//                 <div style={{ display: "flex", flexDirection: "column", gap: 10, paddingRight: "18px", paddingBottom: "25px" }}>
//                         <List>
//                             <NavLink to={`https://dagowert.com/faq`} target="_blank" rel="noopener noreferrer">
//                                 <ListItem
//                                     button
//                                     key={"faq"}
//                                     >
//                                     <ListItemIcon>
//                                         <HelpOutlineIcon color="primary" />
//                                     </ListItemIcon>
//                                     <ListItemText>
//                                         <ListItemText primary={"Fragen & Antworten"} />
//                                     </ListItemText>
//                                 </ListItem>
//                             </NavLink>

//                             <ListItem
//                                 button
//                                 key={"logout"}
//                                 onClick={() => {
//                                     setShowBackdrop(true);
//                                     logout();
//                                     // socket.disconnect();
//                                 }}
//                             >
//                                 <ListItemIcon>
//                                     <LogoutIcon color="primary" />
//                                 </ListItemIcon>
//                                 <ListItemText>
//                                     <ListItemText primary={t("DRAWER.logout")} />
//                                 </ListItemText>
//                                 <Backdrop className={classes.backdrop} open={showBackdrop}>
//                                     <CircularProgress color="inherit" />
//                                 </Backdrop>
//                             </ListItem>
//                         </List>
//                         <div style={{ textAlign: "left", marginLeft: "25px", color: "rgba(0,0,0,0.5)" }}>
//                             <div style={{ fontSize: "10px" }}>DagoWert © Version Alpha 1.0</div>
//                             <div style={{ fontSize: "10px" }}>Made in Austria with ❤ by AUTbite OG ©</div>
//                         </div>
//                     </div>
                
//             </div>
//         </div>
//     );

//     const container = window !== undefined ? () => window().document.body : undefined;

//     return (
//         <div className={classes.root}>
//             <AppBar position="fixed" className={classes.appBar}>
//                 <div className={classes.toolbarRoot}>
//                     <Toolbar >
//                         <IconButton
//                             color="inherit"
//                             aria-label="open drawer"
//                             edge="start"
//                             onClick={handleDrawerToggle}
//                             className={classes.menuButton}
//                         >
//                             <MenuIcon />
//                         </IconButton>
//                         <img
//                             src={LogoWhite}
//                             alt={`${t("APP.name")} Logo`}
//                             style={{
//                                 maxHeight: "30px",
//                                 margin: "0 auto"
//                             }}
//                         />asdf
//                     </Toolbar>
//                 </div>
//             </AppBar>

//             <nav className={classes.drawer} aria-label="menu">
//                 {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
//                 <Hidden mdUp implementation="css">
//                     <Drawer
//                         container={container}
//                         variant="temporary"
//                         anchor={theme.direction === 'rtl' ? 'right' : 'left'}
//                         open={mobileOpen}
//                         onClose={handleDrawerToggle}
//                         classes={{
//                             paper: classes.drawerPaper,
//                         }}
//                         ModalProps={{
//                             keepMounted: true, // Better open performance on mobile.
//                         }}
//                     >
//                         {drawer}
//                     </Drawer>
//                 </Hidden>

//                 <Hidden smDown implementation="css">
//                     <Drawer
//                         classes={{
//                             paper: classes.drawerPaper,
//                         }}
//                         variant="permanent"
//                         open
//                     >
//                         {drawer}
//                     </Drawer>
//                 </Hidden>
//             </nav>
//         </div>
//     );
// };

// export default AppDrawer;


import React, { useState } from "react";

// IMPORT hooks & context
import { NavLink } from "react-router-dom";
import { useAuth } from "./shared/hooks/auth-hook";
import { useTranslation } from "react-i18next";

// IMPORT recoil
import { useRecoilState } from 'recoil';
import loggedInAtomState from "./_atoms/loggedInAtom";
import userAtomState from "./_atoms/userAtom";
import clientsAtomState from "./_atoms/clientsAtom";
import checkoutAtomState from "./_atoms/checkoutAtom";
import selectedMenuItemAtomState from "./_atoms/selectedMenuItemAtom";
import subMenuOpenAtomState from "./_atoms/subMenuOpenAtom";
import subMenuInvoicesOpenAtomState from "./_atoms/subMenuInvoicesOpenAtom";
import openCheckoutAtomState from "./_atoms/openCheckoutAtom";
import openClientDialogAtomState from "./_atoms/openClientDialogAtom";
import openProductDialogAtomState from "./_atoms/openProductDialogAtom";
import openInvoiceDialogAtomState from "./_atoms/openInvoiceDialogAtom";
import openInvoiceRecurringDialogAtomState from "./_atoms/openInvoiceRecurringDialogAtom";
import openExpenseDialogAtomState from "./_atoms/openExpenseDialogAtom";
import mobileOpenState from "./_atoms/mobileOpenAtom";


// IMPORT components
import LogoWhite from "./_assets/logo.svg";
import LogoMonogram from './_assets/logo_monogram.svg'
import LogoMonogramWhite from './_assets/logo_monogram_white.svg'

// IMPORT components MATERIAL UI
import {
    Typography,
    Icon,
    Drawer,
    List,
    Divider,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
    Collapse,
    AppBar,
    Hidden,
    IconButton,
    Toolbar,
    Badge,
    Grid,
    Menu,
    MenuItem,
    Box,
    Tooltip
} from "@material-ui/core";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

// IMPORT icons MATERIAL UI
import DashboardIcon from "@material-ui/icons/Dashboard";
import ClientsIcon from "@material-ui/icons/RecentActors";
import AccountCircle from "@material-ui/icons/AccountCircle";
import LogoutIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from '@material-ui/icons/Menu';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';
import GavelIcon from '@material-ui/icons/Gavel';
import NotificationsIcon from '@material-ui/icons/Notifications';
import HomeIcon from '@material-ui/icons/Home';
import BrushIcon from '@material-ui/icons/Brush';
import RepeatIcon from '@material-ui/icons/Repeat';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import PanoramaWideAngleIcon from '@material-ui/icons/PanoramaWideAngle';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import RedeemIcon from '@material-ui/icons/Redeem';

// IMPORT own components
import Promo from "./shared/components/UIElements/Promo";

// IMPORT theme MATERIAL UI and custom scss
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';

import "./AppDrawer.scss"
import BrushRounded from "@material-ui/icons/BrushRounded";

const drawerWidth = 340;
const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      },
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        background: "linear-gradient(to right top, rgb(31, 89, 177), rgb(40, 93, 163), rgb(38, 108, 189), rgb(36, 115, 192), rgb(33, 150, 243))",
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
            display: "none"
        },
        alignItems: "flex-start"
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    closeIcon: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
        position: "absolute",
        top: "65px",
        left: "20px",
        marginRight: "5px",
        zoom: 0.65,
    },
    drawerPaper: {
        width: "100vw",
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
        },
        // background: "#263238",
        // color: "#ffffff",
        boxShadow: "0px 0px 4px rgba(200,201,207,0.8)",
        color: "#000000",
        borderRight: "0px solid transparent"
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    nested: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: theme.spacing(4)
    },
    customBadge: {
        backgroundColor: '#00bf72',
        color: 'white',
    },
    menuHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(1, 2),
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    customMenu: {
    boxShadow: '0 24px 64px rgba(84, 94, 165, 0.1019607843)',
    [theme.breakpoints.down('xs')]: {
        width: '100vw',
            height: '100vh',
            maxWidth: '100vw',
            maxHeight: '100vh',
            top: '0 !important',
            left: '0 !important',
            margin: '0 !important',
            borderRadius: '0 !important',
        },
    },
    menuContent: {
    [theme.breakpoints.down('xs')]: {
        height: 'calc(100vh - 56px)', // Adjust this if you have a different header height
        overflowY: 'auto',
        },
    },
    iconButton: {
        color: 'white',
        '&:hover': {
          color: '#b3deff',
        },
      },
    yellow: {
        color: '#2196f3',
    },
    notSelected: {
        color: 'white',
    },
}));

const AppDrawer = (props) => {
    // HOOKS & CONTEXT
    const { t } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    const { logout } = useAuth();

    // GLOBAL STATE (RECOIL)
    const [userAtom] = useRecoilState(userAtomState);
    const [loggedInAtom] = useRecoilState(loggedInAtomState);
    const [clientsAtom, setClientsAtom] = useRecoilState(clientsAtomState);
    const [selectedMenuItemAtom, setSelectedMenuItemAtom] = useRecoilState(selectedMenuItemAtomState);
    const [subMenuOpenAtom, setSubMenuOpenAtom] = useRecoilState(subMenuOpenAtomState);
    const [subMenuInvoicesOpenAtom, setSubMenuInvoicesOpenAtom] = useRecoilState(subMenuInvoicesOpenAtomState);
    const [, setOpenCheckoutAtom] = useRecoilState(openCheckoutAtomState);
    const [checkoutAtom, setCheckoutAtom] = useRecoilState(checkoutAtomState);
    const [openClientDialogAtom, setOpenClientDialogAtom] = useRecoilState(openClientDialogAtomState);
    const [openProductDialogAtom, setOpenProductDialogAtom] = useRecoilState(openProductDialogAtomState);
    const [openInvoiceDialogAtom, setOpenInvoiceDialogAtom] = useRecoilState(openInvoiceDialogAtomState);
    const [openInvoiceRecurringDialogAtom, setOpenInvoiceRecurringDialogAtom] = useRecoilState(openInvoiceRecurringDialogAtomState);
    const [openExpenseDialogAtom, setOpenExpenseDialogAtom] = useRecoilState(openExpenseDialogAtomState);
    const [mobileOpen, setMobileOpen] = useRecoilState(mobileOpenState);

    // LOCAL STATE
    const { window } = props;
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [selectedMenuText, setSelectedMenuText] = useState(""); // Add state for selected menu text

    /* 
      FUNCTIONS
    */
    const handleListItemClick = (e, i, text) => {
        setSelectedMenuItemAtom(i);
        setSelectedMenuText(text); // Update selected menu text
        if(i !== 3 && i !== 5) {
            setMobileOpen(false);
        }
        if(i !== 3 && i !== 31 && i !== 32 && i !== 33) {
            setSubMenuInvoicesOpenAtom(false);
        }
        if (i !== 5 && i !== 51 && i !== 52 && i !== 53) {
            setSubMenuOpenAtom(false);
        }
    };

    const getMenuItemText = (selectedMenuItemAtom) => {
        switch (selectedMenuItemAtom) {
            case 100:
                return (
                    <>
                        <HomeIcon style={{ marginRight: 8 }} />
                        Home
                    </>
                );
            case 1:
                return (
                    <>
                        <ClientsIcon style={{ marginRight: 8 }} />
                        Kunden
                    </>
                );
            case 2:
                return (
                    <>
                        <RedeemIcon style={{ marginRight: 8 }} />
                        Produkte
                    </>
                );
            case 3:
            case 31:
                return (
                    <>
                        <LibraryBooksIcon style={{ marginRight: 8 }} />
                        Einzelrechnungen
                    </>
                );
            case 32:
                return (
                    <>
                        <RepeatIcon style={{ marginRight: 8 }} />
                        Wiederkehrend
                    </>
                );
            case 33:
                return (
                    <>
                        <AnnouncementIcon style={{ marginRight: 8 }} />
                        Mahnungen
                    </>
                );
            case 5:
            case 51:
                return (
                    <>
                        <AccountCircle style={{ marginRight: 8 }} />
                        Profil
                    </>
                );
            case 52:
                return (
                    <>
                        <SettingsIcon style={{ marginRight: 8 }} />
                        Einstellungen
                    </>
                );
            case 53:
                return (
                    <>
                        <AccountBalanceWalletIcon style={{ marginRight: 8 }} />
                        Konto
                    </>
                );
            default:
                return "Unbekannt"; // Default text for unsupported values
        }
    };

    const handleOpenSubmenu = () => {
        setSubMenuOpenAtom(!subMenuOpenAtom);
    };

    const handleOpenSubmenuInvoices = () => {
        setSubMenuInvoicesOpenAtom(!subMenuInvoicesOpenAtom);
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleMenuClose = () => {
      setAnchorEl(null);
    };
  
    const isMenuOpen = Boolean(anchorEl);

    const drawer = (
        <div>
            <div className={classes.toolbar}/>

            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "95vh" }}>
                <List>
                    <React.Fragment>
                        <div className={classes.closeIcon} >
                            <IconButton onClick={() => setMobileOpen(false)} style={{ background: "rgba(240,240,240,0.2)", color: "white" }}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        
                        <NavLink to={`/`}>
                            <div style={{ textAlign: "center", marginTop: "-10px" }} onClick={(e) => {handleListItemClick(e, 100, "Home"); setSubMenuOpenAtom(false)}}>
                                {/* <div style={{ textAlign: "center", padding: "7px 7px", paddingBottom: "30px", background: "linear-gradient(to right top, rgb(31 89 177), rgb(40 93 163), rgb(38 108 189), rgb(36, 115, 192), rgb(33, 150, 243))", color: "#ffffff", fontSize: "12px" }}> */}
                                <div style={{ textAlign: "center", padding: "7px 7px", paddingBottom: "30px", background: "rgb(44, 83, 125)", color: "#ffffff", fontSize: "12px" }}>

                                    <div style={{ margin: "0 auto", padding: "20px", paddingBottom: "10px", display: "flex" }}>
                                    <img
                                        src={LogoWhite}
                                        alt={`${t("APP.name")} Logo`}
                                        style={{
                                            maxHeight: "40px",
                                            margin: "0 auto"
                                        }}
                                    />
                                    </div>
                                    <Tooltip title="Auf eine andere Version wechseln">
                                    <span onClick={() => {setOpenCheckoutAtom(true); setCheckoutAtom({...checkoutAtom, show: userAtom.data.subscription === "basic" ? "PREMIUM_PRO" : userAtom.data.subscription === "premium" ? "BASIC_PRO" : "BASIC_PREMIUM"})}} style={{ fontSize: "10px" }}><strong>Deine Version: </strong><span className="version" style={{ borderRadius: "3px", paddingLeft: "5px", paddingRight: "5px", paddingTop: "2px", paddingBottom: "2px" }}><strong>Durchstarter</strong></span></span>                        
                                    </Tooltip>
                                </div>
                            </div>
                        </NavLink>
                        
                        <Grid container justifyContent="center" style={{ background: "rgb(55 92 133)" }}>
                            <NavLink to={`/`}>
                                <IconButton aria-label="notifications" className={`${classes.iconButton} ${selectedMenuItemAtom === 100 ? classes.yellow : classes.notSelected}`} onClick={(e) => {setSubMenuOpenAtom(false); handleListItemClick(e, 100, "Home") }}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" >
                                        <HomeIcon />
                                    </svg>
                                </IconButton>
                            </NavLink>

                            <NavLink to={`/profile`}>
                                <IconButton aria-label="profile" className={`${classes.iconButton} ${selectedMenuItemAtom === 5 || selectedMenuItemAtom === 51 ? classes.yellow : classes.notSelected}`} onClick={(e)=>{handleListItemClick(e, 51, "Profile"); setSubMenuOpenAtom(true)}}>
                                    <svg width="24" height="24" viewBox="0 0 24 24">
                                        <AccountCircle />
                                    </svg>
                                </IconButton>
                            </NavLink>

                            <NavLink to={`/settings`}>
                                <IconButton aria-label="settings" className={`${classes.iconButton} ${selectedMenuItemAtom === 52 ? classes.yellow : classes.notSelected}`} onClick={(e)=>{handleListItemClick(e, 52, "Settings"); setSubMenuOpenAtom(true)}}>
                                    <SettingsIcon />
                                </IconButton>
                            </NavLink>

                            <IconButton aria-label="notifications" className={`${classes.iconButton} ${classes.notSelected}`} onClick={(e) => {setSubMenuOpenAtom(false); handleMenuOpen(e); handleListItemClick(e, selectedMenuItemAtom, "Notifications")}}>
                                <Badge
                                    badgeContent={1}
                                    classes={{ badge: classes.customBadge }}
                                    overlap="rectangular" // Fix for the deprecated "rectangle" value
                                >
                                <svg width="24" height="24" viewBox="0 0 24 24" >
                                    <NotificationsIcon />
                                </svg>
                                </Badge>
                            </IconButton>

                            <Menu
                                anchorEl={anchorEl}
                                keepMounted
                                open={isMenuOpen}
                                onClose={handleMenuClose}
                                elevation={0}
                                classes={{ paper: classes.customMenu }}
                            >
                                <Box className={classes.menuHeader}>
                                    <Typography variant="body1" component="div" style={{ fontSize: "12px", fontWeight: "500", marginRight: "10px" }} >Benachrichtigungen</Typography>
                                    <IconButton size="small" onClick={handleMenuClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                                <Box className={classes.menuContent} style={{ padding: "10px"}}>
                                    {userAtom.data.signupCompleted && userAtom.data.subscription === "basic" &&
                                    <Promo product="premium" />
                                    }

                                    {userAtom.data.signupCompleted && userAtom.data.subscription === "premium" &&
                                    <Promo product="pro" />
                                    }
                                </Box>
                            </Menu>
                        </Grid>
                        
                    </React.Fragment>

                    <NavLink to={`/clients`}>
                        <ListItem
                            button
                            key={"clients"}
                            selected={selectedMenuItemAtom === 1}
                            onClick={(e) => handleListItemClick(e, 1, "Clients")}
                            style={{paddingTop: 4, paddingBottom: 4}}
                        >
                            <ListItemIcon>
                                <ClientsIcon color="primary" className="icon--with-bg--1"/>
                            </ListItemIcon>
                            <ListItemText>
                                <ListItemText primary={t("DRAWER.clients")} />
                            </ListItemText>

                            <ListItemSecondaryAction>
                                <IconButton
                                    className="iconbutton--add"
                                    edge="end"
                                    aria-label="clients"
                                    onClick={(e)=>{
                                        setOpenClientDialogAtom({
                                        ...openClientDialogAtom,
                                        open: true,
                                        });
                                        handleListItemClick(e, 1, "Clients")
                                    }}
                                    style={{ padding: 6, marginRight: -8 }}

                                >
                                    <AddCircleIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </NavLink>

                    {/* <NavLink to={`/products`}>
                        <ListItem
                            button
                            key={"products"}
                            selected={selectedMenuItemAtom === 2}
                            onClick={(e) => handleListItemClick(e, 2, "Products")}
                            style={{paddingTop: 4, paddingBottom: 4}}
                        >
                            <ListItemIcon>
                                <RedeemIcon color="primary" className="icon--with-bg--1"/>
                            </ListItemIcon>
                            <ListItemText>
                                <ListItemText primary="Produkte" />
                            </ListItemText>

                            <ListItemSecondaryAction>
                                <IconButton
                                    className="iconbutton--add"
                                    edge="end"
                                    aria-label="products"
                                    onClick={(e)=>{
                                        setOpenProductDialogAtom({
                                        ...openProductDialogAtom,
                                        productId: "",
                                        open: true,
                                        });
                                        handleListItemClick(e, 2, "Products")
                                    }}
                                    style={{ padding: 6, marginRight: -8 }}
                                >
                                    <AddCircleIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </NavLink> */}

                    <NavLink to={`/invoices`}>
                    <ListItem
                        key={"invoices"}
                        button
                        style={{ paddingTop: "8px", paddingBottom: "8px"}}
                        onClick={(e) => { handleOpenSubmenuInvoices(); handleListItemClick(e, 3, "Invoices") }}
                        >
                            <ListItemIcon>
                                <LibraryBooksIcon color="primary" className="icon--with-bg--1"/>
                            </ListItemIcon>
                            <ListItemText primary={t("DRAWER.invoices")}/>
                                                        
                            {subMenuInvoicesOpenAtom ? <ExpandLess style={{marginRight: "-2px", color: "rgba(0, 0, 0, 0.54)"}}/> : <ExpandMore  style={{marginRight: "-2px", color: "rgba(0, 0, 0, 0.54)"}}/>}
                            
                        </ListItem>
                    </NavLink>

                    <Collapse in={subMenuInvoicesOpenAtom} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <NavLink to={`/invoices`}>
                                <ListItem
                                    button
                                    key={"invoices-single"}
                                    selected={selectedMenuItemAtom === 3 || selectedMenuItemAtom === 31}
                                    onClick={(e) => handleListItemClick(e, 31, "Einzelrechnungen")}
                                    style={{marginLeft: "17px", paddingLeft: "35px"}}
                                >
                                    <ListItemIcon style={{zoom: 0.8}}>
                                        <LibraryBooksIcon color="primary" />
                                    </ListItemIcon>
                                        <ListItemText primary="Einzelrechnungen" />
                                    <ListItemSecondaryAction>

                                    {userAtom.data.signupCompleted && (userAtom.clientscreated || userAtom.data.clients.length !== 0) &&

                                <IconButton
                                    className="iconbutton--add"
                                    edge="end"
                                    aria-label="invoices"
                                    onClick={(e)=>{
                                        setOpenInvoiceDialogAtom({
                                        ...openInvoiceDialogAtom,
                                        open: true,
                                        isEstimate: false,
                                        recurring: false,
                                        });
                                        handleListItemClick(e, 3, "Einzelrechnungen")
                                    }}
                                    style={{ padding: 6, marginRight: 15 }}
                                >
                                    <AddCircleIcon />
                                </IconButton>
                                }
                            </ListItemSecondaryAction>
                                </ListItem>
                            </NavLink>

                            <NavLink to={`/invoicesrecurrings`}>
                                <ListItem
                                    button
                                    key={"invoicesrecurrings"}
                                    selected={selectedMenuItemAtom === 32}
                                    onClick={(event) => handleListItemClick(event, 32, "Wiederkehrend")}
                                    style={{marginLeft: "17px", paddingLeft: "35px"}}
                                >
                                    <ListItemIcon style={{zoom: 0.8}}>
                                        <RepeatIcon color="primary" />
                                    </ListItemIcon>
                                        <ListItemText primary="Wiederkehrend" />
                                        <ListItemSecondaryAction>
                                        {userAtom.data.signupCompleted && (userAtom.clientscreated || userAtom.data.clients.length !== 0) &&

                                <IconButton
                                    className="iconbutton--add"
                                    edge="end"
                                    aria-label="invoices"
                                    onClick={(e)=>{
                                        setOpenInvoiceDialogAtom({
                                            ...openInvoiceDialogAtom,
                                            open: true,
                                            isEstimate: false,
                                            recurring: true,
                                            });
                                        handleListItemClick(e, 32, "Wiederkehrend")
                                    }}
                                    style={{ padding: 6, marginRight: 15 }}
                                >
                                    <AddCircleIcon />
                                </IconButton>
}
                            </ListItemSecondaryAction>
                                </ListItem>
                            </NavLink>

                            {/* <NavLink to={`/reminders`}>
                                <ListItem
                                    button
                                    key={"reminders"}
                                    selected={selectedMenuItemAtom === 33}
                                    onClick={(e) => handleListItemClick(e, 33, "Mahnungen")}
                                    style={{marginLeft: "17px", paddingLeft: "35px", marginBottom: "20px"}}
                                >
                                    <ListItemIcon style={{ zoom: 0.8}}>
                                        <AnnouncementIcon color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary="Mahnungen" />
                                </ListItem>
                            </NavLink> */}
                        </List>
                    </Collapse>

                    <NavLink to={`/profile`}>
                        <ListItem key={"mydagowert"} button style={{ paddingTop: "8px", paddingBottom: "8px" }}
                            onClick={(e) => { handleOpenSubmenu(); handleListItemClick(e, 5, "My DagoWert") }}>
                            {userAtom.data.logo !== undefined && userAtom.data.logo !== "" && userAtom.data.logo !== "/#" ?
                            <div style={{ margin: "0 auto", display: "flex", marginRight: "10px", background: "#ffffff", borderRadius: "60px" }}>
                                <img
                                    src={userAtom.data.logo}
                                    alt={`${t("APP.name")} Logo`}
                                    style={{
                                        maxHeight: "15px",
                                    }}
                                />
                            </div>
                            :
                            <ListItemIcon>
                                <Icon>
                                    <img src={LogoMonogram} height={25} width={25} alt={`${t("APP.name")} Logo`} />
                                </Icon>
                            </ListItemIcon>
                            }
                            <ListItemText primary={t("DRAWER.my")} />
                            
                            {subMenuOpenAtom ? <ExpandLess style={{marginRight: "-2px", color: "rgba(0, 0, 0, 0.54)"}}/> : <ExpandMore  style={{marginRight: "-2px", color: "rgba(0, 0, 0, 0.54)"}}/>}
                        </ListItem>
                    </NavLink>

                    <Collapse in={subMenuOpenAtom} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <NavLink to={`/profile`}>
                                <ListItem
                                    button
                                    key={"profile"}
                                    selected={selectedMenuItemAtom === 5 || selectedMenuItemAtom === 51}
                                    onClick={(e) => handleListItemClick(e, 51, "Profile")}
                                    style={{marginLeft: "17px", paddingLeft: "35px"}}
                                >
                                    <ListItemIcon style={{ opacity: 0.7, zoom: 0.8}}>
                                        <AccountCircle color="primary" />
                                    </ListItemIcon>
                                        <ListItemText primary={t("DRAWER.profile")} />
                                </ListItem>
                            </NavLink>

                            <NavLink to={`/settings`}>
                                <ListItem
                                    button
                                    key={"settings"}
                                    selected={selectedMenuItemAtom === 52}
                                    onClick={(event) => handleListItemClick(event, 52, "Settings")}
                                    style={{marginLeft: "17px", paddingLeft: "35px"}}
                                >
                                    <ListItemIcon style={{ opacity: 0.7, zoom: 0.8}}>
                                        <SettingsIcon color="primary" />
                                    </ListItemIcon>
                                        <ListItemText primary={t("DRAWER.settings")} />
                                </ListItem>
                            </NavLink>

                            <NavLink to={`/account`}>
                                <ListItem
                                    button
                                    key={"account"}
                                    selected={selectedMenuItemAtom === 53}
                                    onClick={(e) => handleListItemClick(e, 53, "Account")}
                                    style={{marginLeft: "17px", paddingLeft: "35px", marginBottom: "20px"}}
                                >
                                    <ListItemIcon style={{ opacity: 0.7, zoom: 0.8}}>
                                        <AccountBalanceWalletIcon color="primary" />
                                    </ListItemIcon>
                                        <ListItemText primary={t("DRAWER.account")} />
                                </ListItem>
                            </NavLink>
                        </List>
                    </Collapse>
                </List>

                <div style={{ display: "flex", flexDirection: "column", gap: 10, paddingRight: "18px", paddingBottom: "25px" }}>
                        <List>
                            <NavLink to={`https://dagowert.com/faq`} target="_blank" rel="noopener noreferrer">
                                <ListItem
                                    button
                                    key={"faq"}
                                    >
                                    <ListItemIcon>
                                        <HelpOutlineIcon color="primary" />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <ListItemText primary={"Fragen & Antworten"} />
                                    </ListItemText>
                                </ListItem>
                            </NavLink>

                            <ListItem
                                button
                                key={"logout"}
                                onClick={() => {
                                    setShowBackdrop(true);
                                    logout();
                                }}
                            >
                                <ListItemIcon>
                                    <LogoutIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText>
                                    <ListItemText primary={t("DRAWER.logout")} />
                                </ListItemText>
                                <Backdrop className={classes.backdrop} open={showBackdrop}>
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                            </ListItem>
                        </List>
                        <div style={{ textAlign: "left", marginLeft: "25px", color: "rgba(0,0,0,0.5)" }}>
                            <div style={{ fontSize: "10px" }}>DagoWert © Beta 1.0</div>
                            <div style={{ fontSize: "10px" }}>Made in Austria with ❤ by AUTbite OG ©</div>
                        </div>
                    </div>
                
            </div>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar}>
                <div className={classes.toolbarRoot}>
                    <Toolbar >
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" style={{ display: "flex", margin: "0 auto", fontWeight: 500, fontSize: "14px" }}>
                        <img src={LogoMonogramWhite} height={25} width={25} alt={`${t("APP.name")} Logo`} style={{ width: "50px", minWidth: "50px", marginRight: 0, opacity: 0.5, marginLeft: -25 }} />

                            {getMenuItemText(selectedMenuItemAtom)}
                        </Typography>
                    </Toolbar>
                </div>
            </AppBar>

            <nav className={classes.drawer} aria-label="menu">
                <Hidden mdUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>

                <Hidden smDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
        </div>
    );
};

export default AppDrawer;

import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

const openInvoiceRecurringDialogAtom = atom({
  key: "openInvoiceRecurringDialog",
  default: {
    open: false,
    invoiceId: "",
    duplicate: false,
    isEstimate: false,
    recurring: true,
  },
  effects_UNSTABLE: [persistAtom],
});

export default openInvoiceRecurringDialogAtom
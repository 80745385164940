const changeContinuousNumber = (e, values, setValues) => {
  // check if it is a positive number and not empty
  if (/^\d+$/.test(e.target.value)) {
    let clientNumberItems = (typeof (values.clientNumber.state) === "string" ? JSON.parse(values.clientNumber.state) : values.clientNumber.state);
    const currentIndex = clientNumberItems.findIndex((elements) => elements.type === "continuousNumber");
    if (currentIndex !== -1) {
      let currentId = clientNumberItems[currentIndex].id;
      const updatedItem = { ...clientNumberItems[currentIndex], id: currentId, type: "continuousNumber", value: e.target.value };
      const newItems = [...clientNumberItems];
      newItems[currentIndex] = updatedItem;

      setValues({
        ...values,
        clientNumber: {
          ...values.clientNumber,
          state: newItems
        }
      });
    }
  }
};

export default changeContinuousNumber;

import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

const openProductDialogAtom = atom({
  key: "openProductDialog",
  default: {
    open: false,
    procuctId: "",
  },
  effects_UNSTABLE: [persistAtom],
});

export default openProductDialogAtom
import React, { useState,   useContext,
} from "react";
// IMPORT hooks & context
import { useTranslation } from "react-i18next";
import { countryToFlag } from "../../shared/functions/countryToFlag";
import { COUNTRIES } from "../../shared/util/countries_de";
import { useForm } from "../../shared/hooks/form-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from '../../shared/context/auth-context';
import { useAuth } from "../../shared/hooks/auth-hook";

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import invoicesAtomState from "../../_atoms/invoicesAtom";



// IMPORT components MATERIAL UI
import {
  Typography,
  Box,
  Container,
  Grid,
  Tooltip,
  Chip,
  Collapse,
  IconButton,
  Menu,
  MenuItem,
  Button
} from "@material-ui/core";

// IMPORT icons MATERIAL UI
import ClientsIcon from "@material-ui/icons/RecentActors";
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FilterListIcon from '@material-ui/icons/FilterList';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import EditIcon from '@material-ui/icons/Edit';
import ScheduleIcon from '@material-ui/icons/Schedule';
import MailIcon from '@material-ui/icons/Mail';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import DateRangeIcon from '@material-ui/icons/DateRange';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SaveIcon from '@material-ui/icons/Save';


// IMPORT functions
import { DB_DELETE_INVOICE } from './../../_functions/DB_INVOICES';

// IMPORT components
import { useSnackbar } from 'notistack';

// IMPORT own components
import InvoicesCsv from "../components/InvoicesCsv";
import InvoicesFilter from "./InvoicesFilter";
import InvoicesSort from "./InvoicesSort";
import InvoicesDateRange from "./InvoicesDateRange";
import InvoicesSettings from "./InvoicesSettings";
import ConfirmModal from "../../shared/components/UIElements/Modal";
import InvoiceLivePreview from "./InvoiceLivePreview";

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import SendIcon from '@material-ui/icons/Send';


import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';

import { useTheme } from '@material-ui/core/styles';



import DateFnsUtils from '@date-io/date-fns';
import { de } from "date-fns/locale";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);


export default function InvoicesScheduleSend({
  values,
  setValues,
  sendState,
  sendDate,
  send,
  isLoading,
  sendOption,
  setEmailSent,
  setOpenInvoiceDialogAtom,
  openInvoiceDialogAtom,
  sendOptionManualSent,
  recurring,
  setSendMode,
  sendMode,
  emailScheduled
}) {
  // HOOKS & CONTEXT 
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();


  // GLOBAL STATE (RECOIL)
  const [invoicesAtom, setInvoicesAtom] = useRecoilState(invoicesAtomState);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


  return (
    <div>
    {sendOption === "dagowert" && !recurring && !emailScheduled &&
    <Button disabled={isLoading} variant="outlined" style={{marginRight: "5px"}} onClick={handleClickOpen} startIcon={<ScheduleIcon />}  >
      Versandzeitpunkt festlegen
    </Button>
    }

    {recurring &&
    <Button disabled={isLoading} variant="outlined" style={{marginRight: "5px"}} onClick={handleClickOpen} startIcon={<ScheduleIcon />}  >
      Nächsten Versand planen
    </Button>
    }

    {sendOption === "manual" && !values.recurring &&
    <>
    {sendOptionManualSent &&
      <Button
      style={{ marginRight: 5 }}
      variant="contained"
      color="primary"
      startIcon={<SaveIcon />}
      onClick={()=>{
        send(undefined, undefined, undefined, true); // only give manualSaveSend value
        // handleManualSendSaveAndClose();
        setOpen(false);
        setEmailSent(false);
        setOpenInvoiceDialogAtom({
          ...openInvoiceDialogAtom,
          open: false,
          invoiceId: ""
        })
      }} // Attach the click handler
    >
      Speichern und schließen
    </Button>
    }
    <Button disabled={isLoading} variant="outlined" 
      onClick={() => {
        setOpen(false);
        setEmailSent(false);
        setOpenInvoiceDialogAtom({
          ...openInvoiceDialogAtom,
          open: false,
          invoiceId: ""
        })
      }}>
      Schließen
    </Button>
    </>
    }

    {sendOption === "dagowert" && !values.recurring && !emailScheduled &&
    <Button disabled={isLoading} variant="contained" color="secondary" onClick={()=>{send()}} startIcon={<SendIcon />}>
      Jetzt senden
    </Button>
    }

    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Versandzeitpunkt festlegen
      </DialogTitle>
      <DialogContent dividers>
        
        <MuiPickersUtilsProvider locale={de} utils={DateFnsUtils}>
                <Grid item xs={12} md={4} className="datepicker">
                  <KeyboardDatePicker
                    fullWidth
                    inputVariant="filled"
                    id="sendDate"
                    format="dd.MM.yyyy"
                    label="Versanddatum"
                    value={sendDate}
                    onChange={(date) =>
                      setValues({
                        ...values,
                        sendDate: date,
                      })
                    }
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    okLabel="OK"
                    cancelLabel="Abbrechen"
                  />

                  <KeyboardTimePicker
                    fullWidth
                    inputVariant="filled"
                    margin="normal"
                    id="time-picker"
                    label="Versandzeit"
                    value={sendDate}
                    onChange={(date) =>
                      setValues({
                        ...values,
                        sendDate: date,
                      })
                    }
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                    okLabel="OK"
                    cancelLabel="Abbrechen"                    
                  />
                </Grid>
              </MuiPickersUtilsProvider>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" autoFocus onClick={()=> {send("scheduled", sendDate, sendOption); setOpen(false);}} startIcon={sendOption === "dagowert" ? <ScheduleIcon /> : <SaveIcon />}>
          Zum ausgewählten Zeitpunkt versenden
        </Button>
      </DialogActions>
    </Dialog>
  </div>
  );
}
